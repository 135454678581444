import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'

const popperOptions = {
	modifiers: {
		offset: {
			enabled: true,
			offset: '0, 12',
		},
	},
}

export default function OptionsButton({ children, options, onClick }) {
	const [open, setOpen] = React.useState(false)
	const anchorRef = React.useRef(null)
	
	return <div ref={anchorRef} style={{ position: 'relative' }}>
		<ButtonGroup fullWidth sx={{ p: 0 }} variant='contained'>
			<Button sx={{ p: 0 }} onClick={() => setOpen(!open)}>
				{children}
			</Button>
			<Button size='small' style={{ maxWidth: '30px' }} onClick={() => setOpen(!open)}>
				<KeyboardArrowUpIcon />
			</Button>
		</ButtonGroup>
		{ anchorRef.current ? <Popper style={{ width: '100%', zIndex: 1000000 }} disablePortal transition open={open} anchorEl={anchorRef.current} placement='top' popperOptions={popperOptions}>
			{({ TransitionProps }) => (
				<Grow {...TransitionProps}>
					<Paper elevation={12}>
						<ClickAwayListener onClickAway={() => setOpen(false)}>
							<MenuList>
								{options.map(({ id, name }) => (
									<MenuItem key={id} onClick={() => {
										setOpen(false)
										onClick({ target: { value: id ?? '' } })
									}}>{name}</MenuItem>
								))}
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper> : undefined }
	</div>
}

