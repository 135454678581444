import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Header from '../Components/Header'
import Page from './Page'
import React, { useState } from 'react'
import ReactJson from 'react-json-view'
import Stack from '../Components/Stack'
import StandardTextField from '../Components/StandardTextField'
import Typography from '@material-ui/core/Typography'
import api from '../api'

export default function AdminImportPage() {
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')
	const [ response, setResponse ] = useState(null)
	const [ theDrivewayParams, setTheDrivewayParams ] = useState({
		email_delivery_time: '',
	})

	const handleTheDrivewayChange = (fieldName) => (e) => {
		setTheDrivewayParams({ ...theDrivewayParams, [fieldName]: e.target.value })
	}

	const handleImportTheDriveway = async () => {
		setLoadingOverlayText('Loading...')
		const params = { ...theDrivewayParams }
		if (!params.email_delivery_time) delete params.email_delivery_time
		const res = await api.postImportTheDriveway(params)
		setLoadingOverlayText('')
		setResponse(res)
	}

	return <Page padding='bottom' loadingText={loadingOverlayText}>
		<Container>
			<Header>
				<Typography variant='inherit'>Import</Typography>
			</Header>
			{ response ? <Stack>
				<Typography>Response</Typography>
				<ReactJson src={response} theme={'monokai'} enableClipboard={false} displayDataTypes={false} />
			</Stack> : undefined }
			<Accordion>
				<AccordionSummary expandIcon={<ExpandMoreIcon />} >
					<Typography>The Driveway</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Stack>
						<Stack horizontal={true}>
							<StandardTextField label='Email Delivery Time' style={{ width: 300 }} defaultValue={theDrivewayParams.email_delivery_time}
								onChange={handleTheDrivewayChange('email_delivery_time')} />
							<Button variant={'contained'} onClick={handleImportTheDriveway}>Import</Button>
						</Stack>
					</Stack>
				</AccordionDetails>
			</Accordion>
		</Container>
	</Page>
}
