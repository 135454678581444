export function async(func) {
	return func()
}

export function clone(obj) {
	return JSON.parse(JSON.stringify(obj))
}

// copied from https://stackoverflow.com/a/9834261
export async function downloadFile(id, name) {
	const res = await fetch(`/files/${id}`)
	const blob = await res.blob()
	const url = window.URL.createObjectURL(blob)
	const a = document.createElement('a')
	a.style.display = 'none'
	a.href = url
	a.download = name
	document.body.appendChild(a)
	a.click()
	window.URL.revokeObjectURL(url)
}

export function formatDuration(totalSeconds) {
	const parts = []
	let consumedSeconds = 0

	const days = Math.floor(totalSeconds / 86400)
	if (days > 0) {
		consumedSeconds += (days * 86400)
		parts.push(days + ' day' + (days === 1 ? '' : 's'))
	}

	const hours = Math.floor((totalSeconds - consumedSeconds) / 3600)
	if (hours > 0) {
		consumedSeconds += (hours * 3600)
		parts.push(hours + ' hour' + (hours === 1 ? '' : 's'))
	}

	const minutes = Math.floor((totalSeconds - consumedSeconds) / 60)
	if (minutes > 0) {
		consumedSeconds += (minutes * 60)
		parts.push(minutes + ' minute' + (minutes === 1 ? '' : 's'))
	}

	const seconds = totalSeconds - consumedSeconds
	if (seconds > 0) {
		parts.push(seconds + ' second' + (seconds === 1 ? '' : 's'))
	}

	return parts.join(', ')
}

export function formatDurationCompact(totalSeconds) {
	const parts = []
	let consumedSeconds = 0

	const hours = Math.floor(totalSeconds / 3600)
	if (hours > 0) {
		consumedSeconds += (hours * 3600)
		parts.push(hours.toString().padStart(2, '0'))
	}

	const minutes = Math.floor((totalSeconds - consumedSeconds) / 60)
	consumedSeconds += (minutes * 60)
	parts.push(minutes.toString().padStart(2, '0'))

	const seconds = totalSeconds - consumedSeconds
	parts.push(seconds.toString().padStart(2, '0'))

	return parts.join(':')
}

export function hasTextSelected() {
	if (window.getSelection) return window.getSelection().toString().length > 0
	else if (document.getSelection) return document.getSelection().toString().length > 0
	else if (document.selection) return document.selection.createRange().text.length > 0
	return false
}

export function isObject(value) {
	return value !== null && typeof value === 'object'
}

export function padArray(arr, length, value) {
	return arr.concat(Array(length).fill(value ?? null)).slice(0, length)
}

export function sleep(millis) {
	return new Promise((resolve) => setTimeout(resolve, millis))
}

export function sortFunc(type, key, desc) {
	if (type === 'string') {
		return desc ? (a, b) => b[key].localeCompare(a[key])
			: (a, b) => a[key].localeCompare(b[key])
	}
	if (type === 'number') {
		return desc ? (a, b) => b[key] - a[key]
			: (a, b) => a[key] - b[key]
	}
	throw new Error(`Unhandled sorting type ${type}`)
}

export function sortDates(arr, fieldName, desc) {
	const map = new Map()

	for (const item of arr) {
		map.set(item, new Date(item[fieldName]))
	}

	if (desc) arr.sort((a, b) => map.get(b) - map.get(a))
	else arr.sort((a, b) => map.get(a) - map.get(b))
}

// category link specific functions

export function diffCategoryLinksForUpload(oldLinks, newLinks) {
	const links = []
	for (const { entity_id, kind, sort_order } of newLinks) {
		const oldLink = oldLinks.find(link => link.entity_id === entity_id && link.kind === kind)
		if (sort_order !== oldLink.sort_order) links.push({ entity_id, kind, sort_order })
	}
	return links
}

export function prepareCategoryLinksForOrdering(links) {
	const ordered = []
	const unordered = []
	for (const link of links) {
		if (link.sort_order) ordered.push(link)
		else unordered.push(link)
	}
	ordered.sort((a, b) => a.sort_order - b.sort_order)
	sortDates(unordered, 'entity_updated_at', true)
	return [ordered, unordered]
}

const timestampRegex = /^[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9] [0-9][0-9]:[0-9][0-9]:[0-9][0-9]$/

// expects a timestamp with the following format: 2020-12-30 10:20:30
export function timestampParse(str) {
	if (typeof str !== 'string') return null
	if (str.length !== 19) return null
	if (!timestampRegex.test(str)) return null
	const value = new Date(str + 'Z')
	return isNaN(value.getTime()) ? null : value
}

// returns a string in the same format that parseTimestamp takes in
export function timestampFormat(d, excludeTime) {
	const year = d.getUTCFullYear()
	const month = (d.getUTCMonth() + 1).toString().padStart(2, '0')
	const day = d.getUTCDate().toString().padStart(2, '0')
	if (excludeTime) return `${year}-${month}-${day} 00:00:00`
	const hours = d.getUTCHours().toString().padStart(2, '0')
	const minutes = d.getUTCMinutes().toString().padStart(2, '0')
	const seconds = d.getUTCSeconds().toString().padStart(2, '0')
	return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

export function withCancel(func) {
	let cancelled = false
	func(() => cancelled)
	return () => cancelled = true
}
