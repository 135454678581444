import * as utils from '../utils'
import Button from '@material-ui/core/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import IconButton from '@material-ui/core/IconButton'
import React, { useEffect, useState } from 'react'
import StandardDialog from './StandardDialog'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import api from '../api'
import transform from '../transform'
import { makeStyles } from '@material-ui/core/styles'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => ({
	body: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(2),
		padding: theme.spacing(2),
	},
}))

export default function GunStateLogDialog({ gunId, open, onCancel, onExited }) {
	const classes = useStyles()
	const { state, dispatch } = useStore()
	const [ fetchedData, setFetchedData ] = useState(null)

	const fetchData = async () => {
		state.showLoading(dispatch)
		const res = await api.getFiles({ uploaded_by_entity_id: gunId, uploaded_by_entity_kind: 'gun', kind: 'gun_state_log' })
		state.hideLoading(dispatch)
		if (!res.ok) {
			await state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}
		setFetchedData(res)
		return res
	}

	const fetchAndSetData = async () => {
		const res = await fetchData()
		if (res) setFetchedData(res)
	}

	const handleDownloadGunStateLog = (id, createdAt) => () => {
		utils.downloadFile(id, `gun-${gunId}-state-log--${createdAt.replace(' ', '--').replaceAll(':', '-')}.jsonl`)
	}

	const handleRecord = async () => {
		state.showLoading(dispatch)
		const res = await api.postSsePush({
			entity_id: gunId,
			entity_kind: 'gun',
			message: {
				command: 'upload_state_log',
			},
		})
		state.hideLoading(dispatch)
		if (!res.ok) {
			await state.showError(dispatch, res.extractRemainingErrorsMessage())
		}
	}

	const handleRefresh = async () => {
		state.showLoading(dispatch, 'Loading state logs...')
		await fetchAndSetData()
		state.hideLoading(dispatch)
	}

	useEffect(() => {
		if (!gunId) {
			setFetchedData(null)
			return
		}
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [gunId])

	let content = <div style={{ textAlign: 'center', padding: 16 }}>No Logs</div>
	if (fetchedData) {
		if (fetchedData.files.length > 0) {
			content = <TableContainer style={{ maxHeight: '50vh' }}>
				<Table size='small'>
					<TableHead>
						<TableRow>
							<TableCell>Uploaded At</TableCell>
							<TableCell>Size</TableCell>
							<TableCell align='right'></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{fetchedData.files.map(({ id, created_at, size }) => <TableRow key={id} tabIndex={-1} hover>
							<TableCell>{transform.timestamp(created_at)}</TableCell>
							<TableCell>{transform.memory(size)}</TableCell>
							<TableCell align='right'>
								<IconButton size='small' onClick={handleDownloadGunStateLog(id, created_at)}><GetAppIcon /></IconButton>
							</TableCell>
						</TableRow>)}
					</TableBody>
				</Table>
			</TableContainer>
		}
	}

	return <StandardDialog open={open} title='State Logs' contain={false} onCancel={onCancel} onExited={onExited}>
		<div className={classes.body}>
			{content}
			<div style={{ display: 'flex', gap: 16 }}>
				<Button variant='contained' style={{ flexGrow: 1 }} onClick={handleRefresh}>REFRESH</Button>
				<Button variant='contained' style={{ flexGrow: 1, background: 'green', color: 'white' }} onClick={handleRecord}>RECORD LOG</Button>
			</div>
		</div>
	</StandardDialog>
}
