import {
	StandardTable as Table,
	StandardTableAction as TableAction,
	StandardTableActions as TableActions,
	StandardTableColumn as TableColumn,
	StandardTableColumns as TableColumns,
} from '../Components/StandardTable'
import Container from '@material-ui/core/Container'
import Header from '../Components/Header'
import Page from './Page'
import React, { Fragment, useEffect, useState } from 'react'
import Stack from '../Components/Stack'
import StandardTextField from '../Components/StandardTextField'
import TvDialog from '../Dialogs/TvDialog'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useAlertDialog } from '../Dialogs/AlertDialog'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'

export default function AdminOrganizationsPage() {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
	const location = useLocation()

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const [ editSession, setEditSession ] = useState(null)
	const [ fetchedData, setFetchedData ] = useState(null)
	const [ filter, setFilter ] = useState('')
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')
	const [ shouldFetchDataCounter, setShouldFetchDataCounter ] = useState(0)

	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const fetchData = async () => {
		setLoadingOverlayText('Loading...')
		const res = await api.getOrganizations({ all: true })
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		return res
	}

	const fetchAndSetData = async () => {
		const res = await fetchData()
		if (!res) return
		setFetchedData(res)
		setShouldFetchDataCounter(shouldFetchDataCounter + 1)
	}

	let organizations = fetchedData?.organizations
	if (organizations && filter) {
		organizations = organizations.filter(({ name, owner_user_name }) => {
			return name.toLowerCase().includes(filter) || owner_user_name.toLowerCase().includes(filter)
		})
	}

	const handleChangeFilter = ({ target: { value } }) => {
		setFilter(value.toLowerCase())
		setShouldFetchDataCounter(shouldFetchDataCounter + 1)
	}

	const handleFetchData = async (page, rowsPerPage) => {
		const startIndex = rowsPerPage * page
		const endIndex = startIndex + rowsPerPage - 1
		return [ organizations.slice(startIndex, endIndex), organizations.length ]
	}

	const handleToggleCanCreateFacilities = async (item) => {
		setLoadingOverlayText('Updating Facility...')
		const res = await api.patchOrganization(item.id, {
			facilities_enabled: !item.facilities_enabled,
		})
		if (!res.ok) {
			setLoadingOverlayText('')
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return false
		}
		await fetchAndSetData()
		setLoadingOverlayText('')
		return true
	}

	useEffect(() => {
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [location])

	return <Page padding='bottom' alertDialogOptions={alertDialogOptions} loadingText={loadingOverlayText}>
		<TvDialog editSession={editSession} setEditSession={setEditSession} />
		<Container>
			<Header>
				<Stack horizontal={!isMobile} small={isMobile}>
					<Typography variant='inherit' style={{ flexGrow: 0 }}>Organizations</Typography>
					<StandardTextField placeholder='Filter Organizations...' value={filter} onChange={handleChangeFilter} />
				</Stack>
			</Header>
			{ fetchedData ? <Fragment>
				<Table pagination={true} shouldFetchDataCounter={shouldFetchDataCounter} onFetchData={handleFetchData}>
					<TableColumns>
						<TableColumn fieldName='name'>Name</TableColumn>
						<TableColumn fieldName='owner_user_name'>Owner</TableColumn>
						<TableColumn fieldName='facilities_enabled' width={140} onTransform={(v) => v ? 'Yes' : 'No'}>Facilities Enabled</TableColumn>
					</TableColumns>
					<TableActions>
						<TableAction onClick={handleToggleCanCreateFacilities} onContent={({ facilities_enabled }) => facilities_enabled ? 'Disable Facilities' : 'Enable Facilities'} />
					</TableActions>
				</Table>
			</Fragment> : undefined }
		</Container>
	</Page>
}
