import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

export default function HeightPicker({ defaultValue, label, onChange }) {
    const options = []
	options.push(<option key='0' value='0'>Select your height...</option>)
    for (let i = 3 * 12; i <= 8 * 12 + 11; i++) {
        options.push(<option key={i} value={i}>{Math.floor(i / 12)}' {i % 12}"</option>)
    }

    return <FormControl>
        <InputLabel variant="standard" htmlFor="uncontrolled-native">{label}</InputLabel>
        <Select native style={{ width: '100%' }} defaultValue={defaultValue} onChange={onChange}>
            {options}
        </Select>
    </FormControl>
}
