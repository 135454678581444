import EditDialog from './EditDialog'
import React from 'react'
import StandardTextField from '../Components/StandardTextField'
import Stack from '../Components/Stack'
import { getEditSessionGetterAndSetter } from '../EditSession'

export default function ChangePasswordForUserDialog({ editSession, setEditSession }) {
	const [ get, set ] = getEditSessionGetterAndSetter(editSession, setEditSession)
	return <EditDialog title='Change Password' editSession={editSession} ContainerProps={{ style: { maxWidth: 300 } }}>
		<Stack>
			<StandardTextField fullWidth label='NEW PASSWORD' error={get('errors.newPassword', '')} onChange={set('newPassword')} type='password' />
			<StandardTextField fullWidth label='REPEAT NEW PASSWORD' error={get('errors.newPasswordRepeat', '')} onChange={set('newPasswordRepeat')} type='password' />
		</Stack>
	</EditDialog>
}
