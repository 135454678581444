import Button from '@material-ui/core/Button'
import EditDialog from './EditDialog'
import HeightPicker from '../Components/HeightPicker'
import React from 'react'
import Stack from '../Components/Stack'
import StandardTextField from '../Components/StandardTextField'
import { StandardDatePicker } from '../Components/StandardDatePicker'
import { getEditSessionGetterAndSetter } from '../EditSession'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
	const styles = {
		root: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			minWidth: 350,
			padding: theme.spacing(3),
		},
		top: {
			flexGrow: 1,
			flexShrink: 1,
			marginBottom: theme.spacing(1),
		},
		bottom: {
			flexGrow: 0,
			flexShrink: 0,
			'& > *': {
				marginTop: theme.spacing(2),
			}
		},
	}

	styles[theme.breakpoints.down('sm')] = {
		root: {
			maxWidth: '100%',
			minWidth: 0,
		},
	}

	return styles
})

export default function MyAccountDialog({ editSession, errors, setEditSession, onChangePassword }) {
	const classes = useStyles()

	const [ getUser, setUser ] = getEditSessionGetterAndSetter(editSession, setEditSession)

	return <EditDialog title='My Account' editSession={editSession} contain={false}>
		<div className={classes.root}>
			<div className={classes.top}>
				<Stack>
					<StandardTextField fullWidth label='FULL NAME' error={errors.name} defaultValue={getUser('name', '')} onChange={setUser('name')} autoFocus />
					<StandardTextField fullWidth label='EMAIL' error={errors.email} defaultValue={getUser('email', '')} onChange={setUser('email')} type='email' />
					<StandardTextField fullWidth label='USERNAME' error={errors.username} defaultValue={getUser('username', '')} onChange={setUser('username')} />
					<StandardDatePicker clearable={false} label='DATE OF BIRTH' defaultValue={getUser('date_of_birth', '')} onChange={setUser('date_of_birth')} />
					<HeightPicker label='HEIGHT' defaultValue={getUser('height', '')} onChange={setUser('height')} />
				</Stack>
			</div>
			<div className={classes.bottom}>
				<Button variant='contained' style={{ width: '100%' }} onClick={onChangePassword}>Change password</Button>
			</div>
		</div>
	</EditDialog>
}
