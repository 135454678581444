import * as utils from '../utils'
import EditDialog from './EditDialog'
import InfoIcon from '@material-ui/icons/Info'
import React  from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { CategoryLinkKind } from '../types'
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd'
import { makeStyles } from '@material-ui/core/styles'

const itemHeight = 68.5

const CategoryLinkKindMap = {
	[CategoryLinkKind.category]: 'CATEGORY',
	[CategoryLinkKind.drill]: 'DRILL',
	[CategoryLinkKind.placeholder]: 'PLACEHOLDER',
}

const useStyles = makeStyles((theme) => {
	const styles = {
		root: {
			display: 'flex',
			flexDirection: 'row',
			gap: theme.spacing(2),
			overflow: 'hidden',
			padding: theme.spacing(2),
			userSelect: 'none',
		},
		title: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',
			paddingLeft: theme.spacing(0.5),
		},
		titleIcon: {
			marginLeft: theme.spacing(1),
			marginTop: -4,
		},
		list: {
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
			flexGrow: 1,
			height: '60vh',
			marginTop: theme.spacing(1),
			maxHeight: '50vh',
			minHeight: itemHeight * 2,
			maxWidth: '300px',
			overflowX: 'hidden',
			overflowY: 'auto',
			padding: theme.spacing(0.5),
			paddingTop: 0,
			width: '300px',
		},
		listDragging: {
			backgroundColor: 'rgba(0, 0, 0, 0.2)',
		},
		listItem: {
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
			borderLeftWidth: '4px',
			borderLeftStyle: 'solid',
			display: 'flex',
			flexDirection: 'column',
			marginTop: theme.spacing(0.5),
			maxWidth: 292,
			minWidth: 292,
			padding: theme.spacing(0.5),
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1.5),
			width: 292,
		},
		['listItemKind' + CategoryLinkKind.category]: {
			borderColor: '#c12727',
		},
		['listItemKind' + CategoryLinkKind.drill]: {
			borderColor: '#2e2eb8',
		},
		['listItemKind' + CategoryLinkKind.placeholder]: {
			borderColor: 'gold',
		},
		listItemDragging: {
			backgroundColor: 'rgba(0, 0, 0, 0.2)',
		},
		listItemKind: {
			fontSize: '12px',
			fontWeight: '300',
		},
	}

	styles[theme.breakpoints.down('xs')] = {
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			height: 'inherit',
			overflowY: 'auto',
		},
		list: {
			flexGrow: 0,
			flexShrink: 0,
			fontSize: '24px',
			margin: 0,
			height: 'fit-content !important',
			maxHeight: 'fit-content',
			maxWidth: '100%',
			overflowY: 'hidden',
			padding: theme.spacing(1),
			width: '100%',
		},
		listItem: {
			maxWidth: '100%',
			minWidth: '100%',
		},
	}

	return styles
})

export default function SortCategoryLinksDialog({ editSession, setEditSession }) {
	const classes = useStyles()

	const handleDragEnd = (result) => {
		const { source, destination } = result

		if (!destination) return

		const { ordered, unordered } = editSession.data
		let newOrdered = ordered
		let newUnordered = unordered

		const isOrderedItems = source.droppableId === 'ordered'

		if (source.droppableId === destination.droppableId) {
			const items = Array.from(isOrderedItems ? ordered : unordered)
			const [removed] = items.splice(source.index, 1)
			items.splice(destination.index, 0, removed)
			if (isOrderedItems) newOrdered = items
			else newUnordered = items
		} else {
			const sourceItems = Array.from(isOrderedItems ? ordered : unordered)
			const destItems = Array.from(isOrderedItems ? unordered : ordered)
			const [removed] = sourceItems.splice(source.index, 1)
			destItems.splice(destination.index, 0, removed)
			newOrdered = isOrderedItems ? sourceItems : destItems
			newUnordered = isOrderedItems ? destItems : sourceItems
		}

		for (let i = 0; i < newOrdered.length; i++) {
			const item = newOrdered[i]
			item.sort_order = i + 1
		}

		for (const item of newUnordered) {
			delete item.sort_order
		}

		utils.sortDates(newUnordered, 'entity_updated_at', true)

		const newEditSession = editSession.clone()
		newEditSession.data.ordered = newOrdered
		newEditSession.data.unordered = newUnordered
		setEditSession(newEditSession)
	}

	const renderItem = ({ entity_id, kind, name }, index) => {
		const id = kind + '-' + entity_id
		return <Draggable key={id} draggableId={id} index={index}>
			{(provided, snapshot) => {
				const className = clsx(classes.listItem, classes['listItemKind' + kind], snapshot.isDragging && classes.listItemDragging)
				return <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={className}>
					<Typography variant='h6'>{name}</Typography>
					<Typography className={classes.listItemKind}>{CategoryLinkKindMap[kind]}</Typography>
				</div>
			}}
		</Draggable>
	}

	const height = (editSession ? (editSession.data.ordered.length + editSession.data.unordered.length) : 0) * itemHeight

	return <EditDialog title='Change Order' editSession={editSession} contain={false}>
		<div className={classes.root}>
			<DragDropContext onDragEnd={handleDragEnd}>
				<div>
					<div className={classes.title}>
						<Typography variant='h6'>Highlighted</Typography>
						<Tooltip title='These show up first and are ordered manually.'>
							<InfoIcon className={classes.titleIcon} />
						</Tooltip>
					</div>
					<Droppable droppableId='ordered'>{(provided, snapshot) =>
						<div {...provided.droppableProps} ref={provided.innerRef} style={{ height }}
							className={clsx(classes.list, snapshot.isDraggingOver && classes.listDragging)}
						>
							{editSession.data.ordered.map(renderItem)}
							{provided.placeholder}
						</div>
					}</Droppable>
				</div>
				<div>
					<div className={classes.title}>
						<Typography variant='h6'>By Updated Time</Typography>
						<Tooltip title='These show up last and are ordered automatically by last updated time.'>
							<InfoIcon className={classes.titleIcon} />
						</Tooltip>
					</div>
					<Droppable droppableId='unordered'>{(provided, snapshot) =>
						<div {...provided.droppableProps} ref={provided.innerRef} style={{ height }}
							className={clsx(classes.list, snapshot.isDraggingOver && classes.listDragging)}
						>
							{editSession.data.unordered.map(renderItem)}
							{provided.placeholder}
						</div>
					}</Droppable>
				</div>
			</DragDropContext>
		</div>
	</EditDialog>
}
