import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React  from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiDialog-paper': {
			minWidth: 300,
		},
	}
}))

class AlertDialogOptions {
	constructor(setState, onDismiss, title, message, okay) {
		this.open = true
		this.title = title
		this.message = message
		this.onDismiss = onDismiss
		this.setState = setState
		this.okay = okay
	}

	closed() {
		const copy = { ...this }
		copy.open = false
		Object.setPrototypeOf(copy, AlertDialogOptions.prototype)
		return copy
	}
}

export function AlertDialog({ options }) {
	const classes = useStyles()
	const history = useHistory()
	const { state } = useStore()

	const dismiss = () => () => {
		// eslint-disable-next-line
		options.onDismiss?.()
		options.setState(options.closed())
	}

	const handleEnter = () => {
		state.pushBackHandler(history)
	}

	const handleExited = () => {
		state.popBackHandler()
		options.setState(null)
	}

	return <Dialog className={classes.root} maxWidth='xs' open={options?.open ?? false} onClose={dismiss()} TransitionProps={{ onEnter: handleEnter, onExited: handleExited }}>
		<DialogTitle>{options?.title ?? ''}</DialogTitle>
		<DialogContent>
			<DialogContentText>{options?.message ?? ''}</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={dismiss()}>{options?.okay ?? 'Ok'}</Button>
		</DialogActions>
	</Dialog>
}

export function useAlertDialog(setState) {
	return (title, message, okay) => new Promise((resolve) => {
		setState(new AlertDialogOptions(setState, resolve, title, message, okay))
	})
}
