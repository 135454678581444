import React from 'react'
import StandardDialog from './StandardDialog'

export default function EditDialog({ children, editSession, entityName, title, onExited, ...rest }) {
	const handleExited = () => {
		// eslint-disable-next-line
		onExited?.()
		// eslint-disable-next-line
		editSession?.dispose()
	}

	const handleCancel = () => {
		// eslint-disable-next-line
		editSession?.cancel()?.()
	}

	const handleComplete = () => {
		// eslint-disable-next-line
		editSession?.complete()?.()
	}

	title = title || editSession?.title || (`${editSession?.data.id ? 'Edit' : 'Create'} ${entityName}`)

	return <StandardDialog title={title} open={editSession?.active ?? false}
		onCancel={handleCancel} onComplete={handleComplete} onExited={handleExited}
		{...rest}
	>{children}</StandardDialog>
}
