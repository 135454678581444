import {
	StandardTable as Table,
	StandardTableAction as TableAction,
	StandardTableActions as TableActions,
	StandardTableColumn as TableColumn,
	StandardTableColumns as TableColumns,
} from '../Components/StandardTable'
import Container from '@material-ui/core/Container'
import Header from '../Components/Header'
import Page from './Page'
import React, { Fragment, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import { useAlertDialog } from '../Dialogs/AlertDialog'
import transform from '../transform'
import * as utils from '../utils'

export default function AdminTasksPage() {
	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const [ fetchedData, setFetchedData ] = useState(null)
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')

	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const fetchData = async () => {
		setLoadingOverlayText('Loading...')
		const res = await api.getTasks()
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		return res
	}

	const fetchAndSetData = async () => {
		const res = await fetchData()
		if (!res) return
		setFetchedData(res)
	}

	const handleExecuteNow = async ({ id }) => {
		setLoadingOverlayText('Executing Task...')
		const res = await api.postTasksExecute(id)
		await utils.sleep(2000)
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
	}

	useEffect(() => {
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [])

	return <Page padding='bottom' alertDialogOptions={alertDialogOptions} loadingText={loadingOverlayText}>
		<Container>
			<Header>
				<Typography variant='inherit' style={{ flexGrow: 0 }}>Tasks</Typography>
			</Header>
			{ fetchedData ? <Fragment>
				<Table items={fetchedData.tasks}>
					<TableColumns>
						<TableColumn fieldName='id'>ID</TableColumn>
						<TableColumn fieldName='executes_at' onTransform={transform.timestamp}>Executes At</TableColumn>
					</TableColumns>
					<TableActions>
						<TableAction onClick={handleExecuteNow}>Execute Now</TableAction>
					</TableActions>
				</Table>
			</Fragment> : undefined }
		</Container>
	</Page>
}
