import {
	StandardTable as Table,
	StandardTableAction as TableAction,
	StandardTableActions as TableActions,
	StandardTableColumn as TableColumn,
	StandardTableColumns as TableColumns,
} from '../Components/StandardTable'

import Container from '@material-ui/core/Container'
import EditDialog from './EditDialog'
import LoadingOverlay from '../Components/LoadingOverlay'
import React, { useEffect, useState } from 'react'
import StandardDialog from './StandardDialog'
import StandardTextField from '../Components/StandardTextField'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import { AlertDialog, useAlertDialog } from './AlertDialog'
import { ConfirmationDialog, useConfirmationDialog } from './ConfirmationDialog'
import { getEditSessionGetterAndSetter, startEditSession } from '../EditSession'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'

const isMobileMediaQuery = '@media (max-width: 800px)'

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(2),
		minWidth: 800,
		minHeight: 600,
	},
	header: {
		fontSize: 32,
		fontWeight: 'bold',
		marginBottom: theme.spacing(1),
	},
	table: {
		marginBottom: theme.spacing(2),
	},
	[isMobileMediaQuery]: {
		container: {
			minWidth: '100%',
			minHeight: 0,
		},
	}
}))

class ManageFacilityDialogOptions {
	constructor(setState, onDismiss, facility) {
		this.open = true
		this.onDismiss = onDismiss
		this.setState = setState
		this.facility = facility
	}

	closed() {
		const copy = { ...this }
		copy.open = false
		Object.setPrototypeOf(copy, ManageFacilityDialogOptions.prototype)
		return copy
	}
}

export function ManageFacilityDialog({ options }) {
	const classes = useStyles()
	const isMobile = useMediaQuery(isMobileMediaQuery)

	const [ fetchedData, setFetchedData ] = useState(null)
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')

	const [ teamUserEditSession, setFacilityUserEditSession ] = useState(null)
	const [ teamUserErrors, setFacilityUserErrors ] = useState(null)

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const [ confirmationDialogOptions, setConfirmationDialogOptions ] = useState(null)
	const showConfirmationDialog = useConfirmationDialog(setConfirmationDialogOptions)

	const dismiss = () => () => {
		// eslint-disable-next-line
		options.onDismiss?.()
		options.setState(options.closed())
	}

	const fetchData = async () => {
		if (fetchedData) setLoadingOverlayText('Loading...')
		const res = await api.getFacilityUsers(options.facility.id)
		setLoadingOverlayText('')

		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}

		return {
			users: res.facilities_users,
		}
	}

	const fetchAndSetData = async () => {
		const res = await fetchData()
		if (!res) return
		setFetchedData(res)
	}

	useEffect(() => {
		if (!options) return
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) {
				setFetchedData(res)
			}
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [options])

	const handleCreateFacilityUser = async () => {
		await startEditSession(setFacilityUserEditSession, {}, {
			onCancel() { setFacilityUserErrors(null) },
			async onComplete(item) {
				setLoadingOverlayText('Adding User...')
				const res = await api.postFacilityUser(options.facility.id, item)
				if (!res.ok) {
					setLoadingOverlayText('')

					const errors = {}
					errors.username = res.extractErrorMessage('username')
					setFacilityUserErrors(errors)

					const msg = res.extractRemainingErrorsMessage()
					if (msg) await showAlertDialog('ERROR', msg)

					return false
				}
				setFacilityUserErrors(null)
				await fetchAndSetData()
				setLoadingOverlayText('')
				return true
			},
		})
	}

	const handleRemoveFacilityUser = async (item) => {
		if (!await showConfirmationDialog('CONFIRM', `Are you sure you'd like to remove "${item.name}"?`)) return
		setLoadingOverlayText('Removing User...')
		const res = await api.deleteFacilityUser(item.facility_id, item.user_id)
		if (!res.ok) {
			setLoadingOverlayText('')
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
		setLoadingOverlayText('')
	}

	const [ getFacilityUser, setFacilityUser ] = getEditSessionGetterAndSetter(teamUserEditSession, setFacilityUserEditSession)

	return <StandardDialog title={'Manage ' + (options?.facility.name ?? '')} cancelTooltip='Close' contain={false} open={options?.open ?? false} isMobile={isMobile} onCancel={dismiss()}>
		<LoadingOverlay open={Boolean(loadingOverlayText)} text={loadingOverlayText} />
		<AlertDialog options={alertDialogOptions} />
		<ConfirmationDialog options={confirmationDialogOptions} />
		<EditDialog title='Add Administrator' editSession={teamUserEditSession}>
			<StandardTextField label='Username' fullWidth error={teamUserErrors?.username}
				defaultValue={getFacilityUser('username', '')} onChange={setFacilityUser('username')} autoFocus />
		</EditDialog>
		<Container className={classes.container}>
			<Typography className={classes.header}>Administrators</Typography>
			<Table variant='outlined' className={classes.table} items={fetchedData?.users} pagination={false} idFieldName='user_id' onCreateItem={handleCreateFacilityUser}>
				<TableColumns>
					<TableColumn fieldName='name'>Name</TableColumn>
				</TableColumns>
				<TableActions>
					<TableAction onClick={handleRemoveFacilityUser}>Remove</TableAction>
				</TableActions>
			</Table>
		</Container>
	</StandardDialog>
}

export function useManageFacilityDialog(setState) {
	return (facility) => new Promise((resolve) => {
		setState(new ManageFacilityDialogOptions(setState, resolve, facility))
	})
}
