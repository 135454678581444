const merge = (target, source) => Object.assign(target, source || {})
const readonly = Object.freeze

export const ROOT_USER = 1
export const ROOT_CATEGORY = 1

// enumerations

export const CatalogEntryMetadataEntityKind = readonly({
	category: 1,
	drill: 2,
})

export const CategoryKind = readonly({
	normal: 1,
	my_drills: 2,
	assigned_drills: 3,
	featured_drills: 4,
})

export const CategoryLinkKind = readonly({
	category: 1,
	drill: 2,
	placeholder: 3,
	virtual: 4,
})

export const CategoryOwnerKind = readonly({
	facility: 1,
	team: 2,
	user: 3,
})

export const DrillBallMode = readonly({
	makes_in_a_row_per_spot: 1,
	must_be_made_per_spot: 2,
	shots_per_spot: 3,
})

export const DrillBallShotType = readonly({
	catch_and_shoot: 1,
	off_the_dribble: 2,
	on_the_move: 3,
})

export const DrillZone = readonly({
	z1_p2: 1,
	z1_p3: 2,
	z2_p2: 3,
	z2_p3: 4,
	z3_p1: 5,
	z3_p2: 6,
	z3_p3: 7,
	z4_p2: 8,
	z4_p3: 9,
	z5_p2: 10,
	z5_p3: 11,
})

export const FileKinds = readonly({
	category_image: 1,
	drill_video: 2,
	drill_video_thumbnail: 3,
	drill_video_upload: 4,
	leaderboard_section_background: 5,
	gun_state_log: 6,
	organization_logo: 7,
})

export const FileLinksEntityKind = readonly({
	organization: 1,
	tv: 2,
})

export const FileLinkKind = readonly({
	tv_bg_leaderboard_most_career_makes: 1,
	tv_bg_leaderboard_most_daily_makes: 2,
	tv_bg_leaderboard_most_daily_shots: 3,
	tv_bg_leaderboard_most_weekly_makes: 4,
	tv_bg_leaderboard_totals_lifetime: 5,
	tv_bg_leaderboard_totals_today: 6,
	tv_bg_leaderboard_org_most_career_makes: 7,
	tv_bg_leaderboard_org_most_daily_makes: 8,
	tv_bg_leaderboard_org_most_daily_shots: 9,
	tv_bg_leaderboard_org_most_weekly_makes: 10,
	tv_bg_leaderboard_combined: 11,
	tv_logo: 12,
	tv_bg_leaderboard_featured_drills: 13,
})

export const RtcMethod = readonly({
    sse: 1,
    websocket: 2,
})

export const TvLeaderboardEntityKind = readonly({
	facility: 1,
	team: 2,
})

export const TvLeaderboardSections = readonly({
	most_career_makes: 1,
	most_daily_makes: 2,
	most_daily_shots: 3,
	most_weekly_makes: 4,
	totals_lifetime: 5,
	totals_today: 6,
	org_most_career_makes: 7,
	org_most_daily_makes: 8,
	org_most_daily_shots: 9,
	org_most_weekly_makes: 10,
	combined: 11,
	featured_drills: 12,
})

export const TvLeaderboardSectionNames = {
	[TvLeaderboardSections.most_career_makes]: 'Most Makes Lifetime',
	[TvLeaderboardSections.most_daily_makes]: 'Most Makes Today',
	[TvLeaderboardSections.most_daily_shots]: 'Most Shots Today',
	[TvLeaderboardSections.most_weekly_makes]: 'Most Makes This Week',
	[TvLeaderboardSections.totals_lifetime]: 'Totals Lifetime',
	[TvLeaderboardSections.totals_today]: 'Totals Today',
	[TvLeaderboardSections.org_most_career_makes]: 'Organization Most Makes Lifetime',
	[TvLeaderboardSections.org_most_daily_makes]: 'Organization Most Makes Today',
	[TvLeaderboardSections.org_most_daily_shots]: 'Organization Most Shots Today',
	[TvLeaderboardSections.org_most_weekly_makes]: 'Organization Most Makes This Week',
	[TvLeaderboardSections.combined]: 'Multi Section',
	[TvLeaderboardSections.featured_drills]: 'Featured Drills',
}

export const TvLeaderboardSectionFileLinkKinds = {
	[TvLeaderboardSections.most_career_makes]: FileLinkKind.tv_bg_leaderboard_most_career_makes,
	[TvLeaderboardSections.most_daily_makes]: FileLinkKind.tv_bg_leaderboard_most_daily_makes,
	[TvLeaderboardSections.most_daily_shots]: FileLinkKind.tv_bg_leaderboard_most_daily_shots,
	[TvLeaderboardSections.most_weekly_makes]: FileLinkKind.tv_bg_leaderboard_most_weekly_makes,
	[TvLeaderboardSections.totals_lifetime]: FileLinkKind.tv_bg_leaderboard_totals_lifetime,
	[TvLeaderboardSections.totals_today]: FileLinkKind.tv_bg_leaderboard_totals_today,
	[TvLeaderboardSections.org_most_career_makes]: FileLinkKind.tv_bg_leaderboard_org_most_career_makes,
	[TvLeaderboardSections.org_most_daily_makes]: FileLinkKind.tv_bg_leaderboard_org_most_daily_makes,
	[TvLeaderboardSections.org_most_daily_shots]: FileLinkKind.tv_bg_leaderboard_org_most_daily_shots,
	[TvLeaderboardSections.org_most_weekly_makes]: FileLinkKind.tv_bg_leaderboard_org_most_weekly_makes,
	[TvLeaderboardSections.combined]: FileLinkKind.tv_bg_leaderboard_combined,
	[TvLeaderboardSections.featured_drills]: FileLinkKind.tv_bg_leaderboard_featured_drills,
}

export const TvKind = readonly({
	box: 1,
	browser: 2,
	mobile: 3,
})

export const TvMode = readonly({
	info: 1,
	leaderboard: 2,
	advanced_display: 3,
})

// enumeration names

export const DrillLimitGoal = {
	none: 1,
	makes: 2,
	shots: 3,
	time: 4,
}

export const DrillLimitKind = {
	none: 1,
	makes: 2,
	shots: 3,
	time: 4,
	number_of_runs: 5,
}

export const DrillZoneNames = {
	[DrillZone.z1_p2]: 'Left Wing 2pt',
	[DrillZone.z1_p3]: 'Left Wing 3pt',
	[DrillZone.z2_p2]: 'Left Elbow 2pt',
	[DrillZone.z2_p3]: 'Left Elbow 3pt',
	[DrillZone.z3_p1]: 'Free Throw',
	[DrillZone.z3_p2]: 'Key 2pt',
	[DrillZone.z3_p3]: 'Key 3pt',
	[DrillZone.z4_p2]: 'Right Elbow 2pt',
	[DrillZone.z4_p3]: 'Right Elbow 3pt',
	[DrillZone.z5_p2]: 'Right Wing 2pt',
	[DrillZone.z5_p3]: 'Right Wing 3pt',
}

export const TvKindNames = {
	[TvKind.box]: 'Box',
	[TvKind.browser]: 'Browser',
	[TvKind.mobile]: 'Android TV',
}

export const TvModeNames = {
	[TvMode.info]: 'Info',
	[TvMode.leaderboard]: 'Leaderboard',
	[TvMode.advanced_display]: 'Advanced Display',
}

// classes

export class Ball {
	constructor(obj) {
		this.angle = 0
		this.distance = 0
		this.is_custom = false
		this.mode = DrillBallMode.shots_per_spot
		this.per_spot = 1
		this.seconds_between_throws = 1
		this.shot_type = DrillBallShotType.catch_and_shoot
		this.zone = null
		merge(this, obj)
	}
}

export class Category {
	constructor(obj) {
		this.category_id = undefined
		this.name = ''
		this.image_file_id = undefined
		merge(this, obj)
	}
}

export class Drill {
	constructor(obj) {
		if (!obj.data) throw new Error('data must be provided')

		this.category_id = undefined
		this.description = ''
		this.is_group_drill = false
		this.name = ''
		this.data = {}

		merge(this, obj)
	}
}

export const drillCourtLocationSvgDatas = [
	[DrillZone.z1_p3, 'leftwing3pt', 'M24.6,76.9V6.4H4.8V134c6.7,10.6,14.6,20.3,23.4,29.2l29.6-29.6C42.3,118,30.7,98.6,24.6,76.9z'],
	[DrillZone.z5_p3, 'rightwing3pt', 'M275.6,6.4v70.5c-6.1,21.7-17.8,41.1-33.3,56.7l29.6,29.6c8.8-8.8,16.6-18.5,23.3-29V6.4H275.6z'],
	[DrillZone.z4_p3, 'rightelbow3pt', 'M183.8,167.4l10.8,40.4c29.6-7.9,56.1-23.6,77.2-44.6l-29.6-29.6C226.3,149.5,206.3,161.4,183.8,167.4z'],
	[DrillZone.z2_p3, 'leftelbow3pt', 'M57.9,133.6l-29.6,29.6c21.1,21.1,47.6,36.7,77.2,44.6l10.8-40.4C93.9,161.4,73.8,149.5,57.9,133.6z'],
	[DrillZone.z3_p3, 'key3pt', 'M150.1,171.8L150.1,171.8c-11.7,0-23-1.5-33.8-4.4l-10.8,40.4c14.2,3.8,29.2,5.8,44.6,5.8s30.4-2,44.6-5.8 l-10.8-40.4C173,170.2,161.7,171.8,150.1,171.8z'],
	[DrillZone.z5_p2, 'rightwing2pt', 'M275.6,6.4h-38.3v35c0,24.1-9.8,45.9-25.5,61.6l30.6,30.6c15.5-15.5,27.2-34.9,33.3-56.7V6.4z'],
	[DrillZone.z1_p2, 'leftwing2pt', 'M62.8,41.4v-35H24.6v70.5c6.1,21.7,17.8,41.1,33.3,56.7l30.5-30.5C72.5,87.3,62.8,65.5,62.8,41.4z'],
	[DrillZone.z4_p2, 'rightelbow2pt', 'M183.8,167.4c22.4-6,42.5-17.8,58.4-33.8L211.7,103c-10.7,10.7-24.1,18.6-39.1,22.6L183.8,167.4z'],
	[DrillZone.z2_p2, 'leftelbow2pt', 'M127.5,125.7c-15-4-28.4-11.9-39.1-22.6l-30.5,30.5c15.9,15.9,36,27.8,58.4,33.8L127.5,125.7z'],
	[DrillZone.z3_p2, 'key2pt', 'M172.6,125.6c-5.2,1.4-10.5,2.3-16,2.7v13.4h-13.2v-13.4c-5.5-0.4-10.8-1.3-15.9-2.7l-11.2,41.7 c10.8,2.9,22.1,4.4,33.8,4.4h0c11.7,0,23-1.5,33.8-4.4L172.6,125.6z'],
	[DrillZone.z3_p1, 'freethrow', 'M156.6,128.3c-2.2,0.2-4.4,0.3-6.6,0.3h0c-2.2,0-4.4-0.1-6.6-0.3v13.4h13.2V128.3z'],
]

const DrillZones = Object.values(DrillZone)
const DrillBallShotTypes = Object.values(DrillBallShotType)

const obj = {}
obj.overall = ['m', 's']
obj.byZone = DrillZones.reduce((a, zone) => {
	return a.concat(['m_z_' + zone, 's_z_' + zone])
}, [])
obj.byShotType = DrillBallShotTypes.reduce((a, shotType) => {
	return a.concat(['m_st_' + shotType, 's_st_' + shotType])
}, [])
obj.byZoneAndShotType = DrillZones.reduce((a, zone) => {
	return a.concat(DrillBallShotTypes.reduce((a, shotType) => {
		const suffix = zone + '_' + shotType
		return a.concat(['m_z_st_' + suffix, 's_z_st_' + suffix])
	}, []))
}, [])
obj.all = obj.overall.concat(obj.byZone, obj.byShotType, obj.byZoneAndShotType)
obj.overallAndByZone = obj.overall.concat(obj.byZone)
export const drillSessionStatsColumnNames = obj

// defaults / presets

export const TvLeaderboardSectionsPalette = {
	crimson: 'crimson',
	orange: 'orange',
	green: 'rgb(0, 175, 0)',
	blue: 'rgb(0, 0, 220)',
}

export const DefaultTvLeaderboardSections = [
	{ id: TvLeaderboardSections.most_daily_makes, color: TvLeaderboardSectionsPalette.crimson, enabled: false },
	{ id: TvLeaderboardSections.most_daily_shots, color: TvLeaderboardSectionsPalette.orange, enabled: false },
	{ id: TvLeaderboardSections.most_weekly_makes, color: TvLeaderboardSectionsPalette.green, enabled: false },
	{ id: TvLeaderboardSections.most_career_makes, color: TvLeaderboardSectionsPalette.blue, enabled: false },
	{ id: TvLeaderboardSections.totals_today, color: TvLeaderboardSectionsPalette.crimson, enabled: false },
	{ id: TvLeaderboardSections.totals_lifetime, color: TvLeaderboardSectionsPalette.crimson, enabled: false },
	{ id: TvLeaderboardSections.org_most_daily_makes, color: TvLeaderboardSectionsPalette.crimson, enabled: false },
	{ id: TvLeaderboardSections.org_most_daily_shots, color: TvLeaderboardSectionsPalette.orange, enabled: false },
	{ id: TvLeaderboardSections.org_most_weekly_makes, color: TvLeaderboardSectionsPalette.green, enabled: false },
	{ id: TvLeaderboardSections.org_most_career_makes, color: TvLeaderboardSectionsPalette.blue, enabled: false },
	{ id: TvLeaderboardSections.featured_drills, color: TvLeaderboardSectionsPalette.crimson, enabled: false },
	{ id: TvLeaderboardSections.combined, color: TvLeaderboardSectionsPalette.crimson, enabled: true, sections: [
		{ id: TvLeaderboardSections.most_career_makes },
		{ id: TvLeaderboardSections.most_daily_makes },
		{ id: TvLeaderboardSections.most_daily_shots },
		{ id: TvLeaderboardSections.most_weekly_makes },
		{ id: TvLeaderboardSections.totals_lifetime },
		{ id: TvLeaderboardSections.totals_today },
	] },
]
