import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiDialog-paper': {
			minWidth: 300,
		},
	}
}))

class ConfirmationDialogOptions {
	constructor(setState, onResult, title, message, yes, no) {
		this.open = true
		this.title = title
		this.message = message
		this.onResult = onResult
		this.setState = setState
		this.yes = yes
		this.no = no
	}

	closed() {
		const copy = { ...this }
		copy.open = false
		Object.setPrototypeOf(copy, ConfirmationDialogOptions.prototype)
		return copy
	}
}

export function ConfirmationDialog({ options }) {
	const classes = useStyles()
	const history = useHistory()
	const { state } = useStore()

	const confirmed = (value) => () => {
		// eslint-disable-next-line
		options.onResult?.(value)
		options.setState(options.closed())
	}

	const handleEnter = () => {
		state.pushBackHandler(history)
	}

	const handleExited = () => {
		state.popBackHandler()
		options.setState(null)
	}

	return <Dialog className={classes.root} maxWidth='xs' open={options?.open ?? false} TransitionProps={{ onEnter: handleEnter, onExited: handleExited }}>
		<DialogTitle>{options?.title ?? ''}</DialogTitle>
		<DialogContent>
			<DialogContentText>{options?.message ?? ''}</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={confirmed(false)}>{options?.no ?? 'No'}</Button>
			<Button onClick={confirmed(true)}>{options?.yes ?? 'Yes'}</Button>
		</DialogActions>
	</Dialog>
}

export function useConfirmationDialog(setState) {
	return (title, message, yes, no) => new Promise((resolve) => {
		setState(new ConfirmationDialogOptions(setState, resolve, title, message, yes, no))
	})
}
