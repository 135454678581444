import * as utils from '../utils'
import ClearIcon from '@material-ui/icons/Clear'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import Page from './Page'
import Paper from '@material-ui/core/Paper'
import React, { useEffect, useState } from 'react'
import ReactJson from 'react-json-view'
import Stack from '../Components/Stack'
import TablePagination from '@material-ui/core/TablePagination'
import VisibilityIcon from '@material-ui/icons/Visibility'
import api from '../api'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { FormControl, Typography } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useAlertDialog } from '../Dialogs/AlertDialog'
import transform from '../transform'
import dayjs from 'dayjs'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'

const useStyles = makeStyles((theme) => ({
	kind: {
		cursor: 'pointer',
		userSelect: 'none',
	},
	paper: {
		marginTop: theme.spacing(4),
		padding: theme.spacing(2),
	},
	pre: {
		overflow: 'auto',
	},
	viewIconButton: {
		padding: theme.spacing(0.75),
	},
}))

const tenDaysAgo = dayjs().subtract(10, 'day').format('YYYY-MM-DD HH:mm:ss')
const twentyDaysAgo = dayjs().subtract(20, 'day').format('YYYY-MM-DD HH:mm:ss')

export default function AdminAnalyticsErrorsPage() {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [ error, setError ] = useState(null)
	const [ filter, setFilter ] = useState('')
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')
	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const [ fetchedData, setFetchedData ] = useState(null)
	const [ paginationPage, setPaginationPage ] = useState(0)
	const [ paginationRowsPerPage, setPaginationRowsPerPage ] = useState(10)
	const [ lastOccurredRange, setLastOccurredRange ] = useState('')

	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const handleChangePaginationPage = (event, value) => {
		setPaginationPage(value)
	}

	const handleChangePaginationRowsPerPage = ({ target: { value } }) => {
		setPaginationRowsPerPage (value)
	}

	const preProcessErrorForView = (event) => {
		function eachObject(obj) {
			for (const [ key, value ] of Object.entries(obj)) {
				if (utils.isObject(value)) {
					eachObject(value)
				}
				else if (typeof value === 'string') {
					const lines = value.split('\n')
					if (lines.length > 1) obj[key] = lines
				}
			}
		}

		event = JSON.parse(JSON.stringify(event))

		eachObject(event.data)

		return event
	}

	const fetchData = async () => {
		setLoadingOverlayText('Loading...')
		const res = await api.getAnalyticsEventsLists()

		if (!res.ok) {
			setLoadingOverlayText('')
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}

		const { entity_versions, kinds } = res
		entity_versions.unshift({ id: '' })
		kinds.unshift({ id: '' })

		const entity_versions_map = {}
		for (const { id, name } of entity_versions)
			entity_versions_map[id] = name

		const kinds_map = {}
		for (const { id, name } of kinds)
			kinds_map[id] = name

		setLoadingOverlayText('Fetching errors...')
		const res2 = await api.getAnalyticsErrors()
		setLoadingOverlayText('')

		if (!res2.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}

		res2.errors.sort((a, b) => b.count - a.count)

		return { entity_versions, entity_versions_map, kinds, kinds_map, errors: res2.errors }
	}

	useEffect(() => {
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [])

	let errors = fetchedData?.errors

	if (errors && lastOccurredRange) {
		const isRecently = lastOccurredRange === 'recently'
		const isGt10DaysAgo = lastOccurredRange === 'gt-10-days-ago'
		const isGt20DaysAgo = lastOccurredRange === 'gt-20-days-ago'
		errors = errors.filter(({ last_occurred_at }) => {
			if (isRecently) return last_occurred_at > tenDaysAgo
			if (isGt10DaysAgo) return last_occurred_at < tenDaysAgo
			if (isGt20DaysAgo) return last_occurred_at < twentyDaysAgo
			return true
		})
	}

	if (errors && filter) {
		errors = errors.filter(({ data: { err, message, traceback }, kind }) => {
			if (err && err.toLowerCase().includes(filter)) return true
			if (message && message.toLowerCase().includes(filter)) return true
			if (traceback && traceback.toLowerCase().includes(filter)) return true
			const kindName = fetchedData.kinds_map[kind]
			if (kindName && kindName.toLowerCase().includes(filter)) return true
			return false
		})
	}

	const paginationCount = errors?.length ?? 0
	if (errors && paginationCount > 0) {
		const offset = paginationPage * paginationRowsPerPage
		errors = errors.slice(offset, offset + paginationRowsPerPage)
	}

	return <Page alertDialogOptions={alertDialogOptions} loadingText={loadingOverlayText}>
		<Dialog open={Boolean(error)} transitionDuration={0} onClose={() => setError(null)} maxWidth={'lg'} BackdropProps={{ style: { background: 'rgba(0, 0, 0, 0.9)' } }}>
			<DialogContent style={{ padding: 0 }}>
				<ReactJson src={error || {}} theme={'monokai'} enableClipboard={false} displayDataTypes={false} />
			</DialogContent>
		</Dialog>
		{ fetchedData ? <Container>
			<Stack grow={false} horizontal={!isMobile}>
				<FormControl style={{ flexGrow: 1 }}>
					<FormHelperText>Filter</FormHelperText>
					<Input value={filter} onChange={e => setFilter((e?.target?.value ?? '').toLowerCase())}
						endAdornment={ Boolean(filter) ? <InputAdornment position='end'>
							<IconButton size={'small'} onClick={() => setFilter('')}><ClearIcon /></IconButton>
						</InputAdornment> : undefined }>
					</Input>
				</FormControl>
				<FormControl>
					<FormHelperText>Occurred</FormHelperText>
					<Select style={{ width: 200 }} value={lastOccurredRange} onChange={({ target: { value } }) => setLastOccurredRange(value)}>
						<MenuItem value=''><em>&nbsp;</em></MenuItem>
						<MenuItem value='recently'>Recently</MenuItem>
						<MenuItem value='gt-10-days-ago'>&#62; 10 Days Ago</MenuItem>
						<MenuItem value='gt-20-days-ago'>&#62; 20 Days Ago</MenuItem>
					</Select>
				</FormControl>
				<TablePagination
					component='div'
					count={paginationCount}
					page={paginationPage}
					onChangePage={handleChangePaginationPage}
					rowsPerPage={paginationRowsPerPage}
					onChangeRowsPerPage={handleChangePaginationRowsPerPage}
				/>
			</Stack>
			{errors.map(error => {
				const { count, data, last_occurred_at, kind } = error
				const kindName = fetchedData.kinds_map[kind]

				let lastOccurredColor = '#f00'
				if (last_occurred_at < twentyDaysAgo) lastOccurredColor = '#ff0'
				else if (last_occurred_at < tenDaysAgo) lastOccurredColor = '#ff9d00'

				const lastOccurredTypography = <Typography variant={'h6'} style={{ color: lastOccurredColor }}>{transform.timestamp(last_occurred_at)}</Typography>

				const occurrencesTypography = <Typography variant={'h5'}>{count} occurrence{count === 1 ? '' : 's'}</Typography>

				const kindTypography = <Typography className={classes.kind} variant={'h6'} onClick={() => setFilter(kindName)}>{kindName}</Typography>

				const viewIconButton = <IconButton className={classes.viewIconButton} onClick={() => setError(preProcessErrorForView(error))}>
					<VisibilityIcon />
				</IconButton>

				return <Paper key={JSON.stringify(error)} className={classes.paper}>
					{ isMobile ? <Stack grow={false} horizontal={false} small={true}>
						<Stack grow={false} horizontal={true}>
							{ occurrencesTypography }
							<div style={{ flexGrow: 1 }} />
							{ viewIconButton }
						</Stack>
						<Stack grow={false} horizontal={true}>
							{ kindTypography }
							<div style={{ flexGrow: 1 }} />
							{ lastOccurredTypography }
						</Stack>
					</Stack> : <Stack grow={false} horizontal={true} style={{ alignItems: 'center' }}>
						{ occurrencesTypography }
						<div style={{ flexGrow: 1 }} />
						{ kindTypography }
						{ lastOccurredTypography }
						{ viewIconButton }
					</Stack> }
					<pre className={classes.pre}>{data.err || data.message}</pre>
					<pre className={classes.pre}>{data.traceback}</pre>
				</Paper>
			})}
		</Container> : undefined }
	</Page>
}
