import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		alignItems: 'center',
		display: 'flex',
		fontSize: 24,
		fontWeight: '300',
		minHeight: 48,
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
		width: '100%',
		"& > :first-child": {
			flexGrow: 1,
			flexShrink: 1,
		},
	},
}))

export default function Header({ children, className, ...rest }) {
	const classes = useStyles()
	return <div className={clsx(classes.root, className)} {...rest}>{children}</div>
}
