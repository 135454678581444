import Page from './Page'
import React from 'react'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
	const styles = {
		icon: {
			height: 300,
			left: '50%',
			position: 'relative',
			transform: 'translate(-50%, 0)',
			width: 300,
			marginTop: 64,
		},
		text: {
			textAlign: 'center',
		},
	}

	styles[theme.breakpoints.down('xs')] = {
		icon: {
			height: 200,
			left: '50%',
			position: 'relative',
			transform: 'translate(-50%, 0)',
			width: 200,
			marginTop: 0,
		},
	}

	return styles
})

export default function NotFoundPage() {
	const classes = useStyles()
	return <Page>
		<SentimentVeryDissatisfiedIcon className={classes.icon}/>
		<Typography className={classes.text} variant='h1' style={{ fontWeight: 'bold' }}>404</Typography>
		<Typography className={classes.text} variant='h4' style={{ marginTop: 16 }}>Page not found</Typography>
		<Typography className={classes.text} variant='h5' style={{ marginTop: 16 }}>The requested page does not exist</Typography>
	</Page>
}
