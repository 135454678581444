import * as shared from '../shared'
import * as utils from '../utils'
import Container from '@material-ui/core/Container'
import DeleteIcon from '@material-ui/icons/Delete'
import Header from '../Components/Header'
import IconButton from '@material-ui/core/IconButton'
import Page from './Page'
import Paper from '@material-ui/core/Paper'
import React, { useEffect, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import { makeStyles } from '@material-ui/core/styles'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => ({
	groupHeader: {
		fontSize: 32,
		fontWeight: 'bold',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	itemActions: {
		display: 'flex',
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'end',
		paddingRight: '8px !important',
	},
}))

function Group({ group, onDelete }) {
	const classes = useStyles()

	const body = group.items.map(({ id, entity_name, start_at, end_at }) => (
		<TableRow key={id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell>{entity_name}</TableCell>
			<TableCell>{shared.getFeaturedDateLabel(start_at, end_at)}</TableCell>
			<TableCell align='right' className={classes.itemActions}>
				<Tooltip title='Unfeature'>
					<IconButton size='small' onClick={() => onDelete?.(id)}><DeleteIcon /></IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	))

	return <div>
		<Header className={classes.groupHeader}>
			<Typography variant='inherit'>{group.name}</Typography>
		</Header>
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: '100%' }} size="small">
				<TableHead>
					<TableRow>
						<TableCell>Drill</TableCell>
						<TableCell width={190}>Featured</TableCell>
						<TableCell align='right' width={80}></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>{body}</TableBody>
			</Table>
		</TableContainer>
	</div>
}

export default function FeaturedDrillsPage() {
	const { dispatch, state } = useStore()
	const [ groups, setGroups ] = useState(null)

	const fetchAndSetData = async (cancelled) => {
		state.showLoading(dispatch)
		const res = await api.getCategoryLinksFeatures()
		state.hideLoading(dispatch)

		if (!res.ok) {
			state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}

		if (cancelled?.() ?? false) return

		const { facilities, metadatas } = res

		const groups = facilities.map(({ id, name, is_admin }) => {
			const items = metadatas.filter(({ facility_id }) => id === facility_id)
			return { id, name, is_admin, items }
		}).filter(x => x.items.length > 0)
		groups.sort((a, b) => a.name.localeCompare(b.name))

		setGroups(groups)
	}

	const handleDelete = async (id) => {
		if (!await state.confirm(dispatch, 'CONFIRM', 'Unfeature drill?', 'YES', 'NO')) return
		state.showLoading(dispatch)
		const res = await api.deleteCategoryLinksFeature(id)
		if (!res.ok) {
			state.hideLoading(dispatch)
			await state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
		state.hideLoading(dispatch)
	}

	useEffect(() => {
		return utils.withCancel(fetchAndSetData)
		// eslint-disable-next-line
	}, [location])

	const content = groups && (() => {
		if (groups.length === 0) {
			return <Typography variant='h5'>No featured drills.</Typography>
		}

		return <>
			{groups.map((group) => <Group key={group.id} group={group} onDelete={handleDelete} />)}
		</>
	})()

	return <Page padding='bottom'>
		<Container>
			<Header>
				<Typography variant='inherit'>Featured Drills</Typography>
			</Header>
			{content}
		</Container>
	</Page>
}
