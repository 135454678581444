import { CategoryOwnerKind, ROOT_USER } from './types'

export default {
	entityOwnedByCurrentUser(user, entity) {
		if (user.id === entity.user_id) {
			const { owner_id, owner_kind } = entity
			if (!owner_id || !owner_kind) return true
			if (owner_kind === CategoryOwnerKind.user && owner_id === user.id)
				return true
		}
		if ((user.is_admin || user.is_curator) && entity.user_id === ROOT_USER) return true
		if (entity.team_id) {
			for (const team of user.info.teams)
				if (team.is_coach && team.id === entity.team_id)
					return true
		}
		return false
	},
}
