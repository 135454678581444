import { useAlertDialog } from '../Dialogs/AlertDialog'
import { useHistory, useParams } from 'react-router-dom'
import React, { useState } from 'react'

import Button from '@material-ui/core/Button'
import Page from './Page'
import PageDialog from '../Dialogs/PageDialog'
import StandardTextField from '../Components/StandardTextField'
import Typography from '@material-ui/core/Typography'
import api from '../api'

export default function AccountRecoverPerformPage({ alert }) {
	const history = useHistory()
	const { tokenId } = useParams()
	const [ isLoading, setIsLoading ] = useState(false)

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const [ passwordError, setPasswordError ] = useState('')
	const [ params, setParams ] = useState({ password: '' })

	const handleChange = (fieldName) => (e) => {
		params[fieldName] = e.target.value
		setParams(params)
	}

	const handleSubmit = async (e) => {
		e.preventDefault()

		setIsLoading(true)
		const res = await api.postUserRecoverPerform(params, tokenId)
		setIsLoading(false)

		setPasswordError('')

		if (!res.ok) {
			let msg = res.extractErrorMessage('password')
			if (msg) setPasswordError(msg)

			msg = res.extractRemainingErrorsMessage()
			if (msg) await showAlertDialog('ERROR', msg)

			return
		}

		history.push('/login', { alert: 'Password updated. You may now login.' })
	}

	return <Page alertDialogOptions={alertDialogOptions} loading={isLoading}>
		<PageDialog>
			<Typography variant='h5'>New Password</Typography>
			<form onSubmit={handleSubmit}>
				<StandardTextField label='Password' error={passwordError} onChange={handleChange('password')} type='password' autoFocus />
				<Button type='submit' color='primary' variant='contained'>Submit</Button>
			</form>
		</PageDialog>
	</Page>
}
