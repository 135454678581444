import {
	StandardTable as Table,
	StandardTableColumn as TableColumn,
	StandardTableColumns as TableColumns,
} from '../Components/StandardTable'
import * as dayjs from 'dayjs'
import * as utils from '../utils'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import Page from './Page'
import React, { Fragment, useEffect, useState } from 'react'
import ReactJson from 'react-json-view'
import Select from '@material-ui/core/Select'
import VisibilityIcon from '@material-ui/icons/Visibility'
import api from '../api'
import transform from '../transform'
import Stack from '../Components/Stack'
import { StandardDatePicker } from '../Components/StandardDatePicker'
import { makeStyles } from '@material-ui/core/styles'
import { useAlertDialog } from '../Dialogs/AlertDialog'
import StandardTextField from '../Components/StandardTextField'

const AnalyticEventsEntityKindNames = {
	1: 'Gun',
	2: 'Tv',
}

const useStyles = makeStyles((theme) => ({
	table: {
		marginTop: theme.spacing(3),
	},
}))

export default function AdminAnalyticsEventsPage() {
	const classes = useStyles()

	const [ event, setEvent ] = useState(null)
	const [ events, setEvents ] = useState([])
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')
	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const [ shouldFetchDataCounter, setShouldFetchDataCounter ] = useState(0)
	const [ fetchedData, setFetchedData ] = useState(null)

	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const [ params, setParams ] = useState({
		end_at: null,
		entity_id: null,
		kind: '',
		start_at: dayjs().startOf('month').format('YYYY-MM-DD'),
		version: '',
		min_temp: null,
		max_temp: null,
	})

	const formatData = (data, { kind }) => {
		switch (fetchedData.kinds_map[kind]) {
			case 'gun.power_change':
				return data.on ? 'ON' : 'OFF'
			default: return ''
		}
	}

	const preProcessEventForView = (event) => {
		function eachObject(obj) {
			for (const [ key, value ] of Object.entries(obj)) {
				if (utils.isObject(value)) {
					eachObject(value)
				}
				else if (typeof value === 'string') {
					const lines = value.split('\n')
					if (lines.length > 1) obj[key] = lines
				}
			}
		}

		event = JSON.parse(JSON.stringify(event))

		eachObject(event.data)

		const kind = fetchedData.kinds_map[event.kind]
		if (kind === 'gun.xorg_timeout')
			event.data.log = atob(event.data.log).split('\n')

		return event
	}

	const getViewIcon = (_, event) => {
		return <IconButton size='small' onClick={() => setEvent(preProcessEventForView(event))}>
			<VisibilityIcon />
		</IconButton>
	}

	const handleChange = (fieldName) => (e) => {
		if (fieldName === 'kind') {
			if (!(fetchedData.kinds_map[e.target.value] ?? '').includes('temp')) {
				params.min_temp = null
				params.max_temp = null
			}
		}

		setParams({ ...params, [fieldName]: e.target.value })
	}

	const handleFetchData = async (page, rowsPerPage) => {
		const startIndex = rowsPerPage * page
		const endIndex = startIndex + rowsPerPage - 1
		return [ events.slice(startIndex, endIndex), events.length ]
	}

	const handleSubmit = async () => {
		setLoadingOverlayText('Fetching events...')
		const res = await api.getAnalyticsEvents(params)
		setLoadingOverlayText('')

		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}

		setEvents(res.events)
		setShouldFetchDataCounter(shouldFetchDataCounter + 1)
	}

	const fetchData = async () => {
		setLoadingOverlayText('Loading...')
		const res = await api.getAnalyticsEventsLists()
		setLoadingOverlayText('')

		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}

		const { entity_versions, kinds } = res
		entity_versions.unshift({ id: '', name: '' })
		kinds.unshift({ id: '', name: '' })

		const entity_versions_map = {}
		for (const { id, name } of entity_versions)
			entity_versions_map[id] = name

		const kinds_map = {}
		for (const { id, name } of kinds)
			kinds_map[id] = name

		return { entity_versions, entity_versions_map, kinds, kinds_map }
	}

	useEffect(() => {
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [])

	return <Page alertDialogOptions={alertDialogOptions} loadingText={loadingOverlayText}>
		<Dialog open={Boolean(event)} transitionDuration={0} onClose={() => setEvent(null)} maxWidth={'lg'} BackdropProps={{ style: { background: 'rgba(0, 0, 0, 0.9)' } }}>
			<DialogContent style={{ padding: 0 }}>
				<ReactJson src={event || {}} theme={'monokai'} displayDataTypes={false} enableClipboard={false} groupArraysAfterLength={100000} />
			</DialogContent>
		</Dialog>
		<Container>
			<Stack horizontal>
				<StandardDatePicker label='Start Date' defaultValue={params.start_at} onChange={handleChange('start_at')} />
				<StandardDatePicker label='End Date' defaultValue={params.end_at} onChange={handleChange('end_at')} />
				<StandardTextField label='Entity ID' type='number' defaultValue={params.entity_id} onChange={handleChange('entity_id')} />
				<FormControl className={classes.formControl}>
					<InputLabel>Kind</InputLabel>
					<Select value={params.kind} native onChange={handleChange('kind')}>
						{ fetchedData ? fetchedData.kinds.map(({ id, name }) => <option key={id} value={id}>{name}</option>) : undefined }
					</Select>
				</FormControl>
				{(fetchedData?.kinds_map[params.kind] ?? '').includes('temp') ? <Fragment>
					<StandardTextField label='Min Temp' type='number' defaultValue={params.min_temp} onChange={handleChange('min_temp')} />
					<StandardTextField label='Max Temp' type='number' defaultValue={params.max_temp} onChange={handleChange('max_temp')} />
				</Fragment> : null}
				<FormControl className={classes.formControl}>
					<InputLabel>Version</InputLabel>
					<Select value={params.version} native onChange={handleChange('version')}>
						{ fetchedData ? fetchedData.entity_versions.map(({ id, name }) => <option key={id} value={id}>{name}</option>) : undefined }
					</Select>
				</FormControl>
				<Button variant='contained' onClick={handleSubmit}>Submit</Button>
			</Stack>
			<Table className={classes.table} variant='outlined' shouldFetchDataCounter={shouldFetchDataCounter} onFetchData={handleFetchData} idFieldName={r => r.created_at + ' ' + r.chunk_id}>
				<TableColumns>
					<TableColumn width={160} fieldName='created_at' onTransform={transform.timestamp}>Created At</TableColumn>
					<TableColumn width={160} fieldName='produced_at' onTransform={transform.timestamp}>Produced At</TableColumn>
					<TableColumn width={100} fieldName='entity' onTransform={(_, v) => `${AnalyticEventsEntityKindNames[v.entity_kind]} ${v.entity_id}`}>From</TableColumn>
					<TableColumn width={100} fieldName='entity_version' onTransform={(_, v) => `${fetchedData.entity_versions_map[v.entity_version]}`}>Version</TableColumn>
					<TableColumn width={100} fieldName='kind' onTransform={(_, v) => `${fetchedData.kinds_map[v.kind]}`}>Kind</TableColumn>
					<TableColumn width={100} fieldName='data' onTransform={formatData}>Data</TableColumn>
					<TableColumn align='right' width={50} onTransform={getViewIcon} />
				</TableColumns>
			</Table>
		</Container>
	</Page>
}
