import CategoryBreadcrumbs from '../Components/CategoryBreadcrumbs'
import LoadingOverlay from '../Components/LoadingOverlay'
import React, { useEffect, useState } from 'react'
import StandardDialog from './StandardDialog'
import api from '../api'
import { AlertDialog, useAlertDialog } from './AlertDialog'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import {
	StandardTable as Table,
	StandardTableColumn as TableColumn,
	StandardTableColumns as TableColumns,
} from '../Components/StandardTable'
import { CategoryLinkKind } from '../types'

const useStyles = makeStyles((theme) => {
	const styles = {
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			minHeight: 0,
			padding: theme.spacing(1),
			width: 600,
		},
		breadcrumbs: {
			maxWidth: '100%',
			paddingTop: theme.spacing(1),
			paddingBottom: theme.spacing(1),
		},
		table: {
			height: 300,
		},
		placeholder: {
			display: 'flex',
			flexDirection: 'column',
			height: 300,
			justifyContent: 'center',
		},
	}

	styles[theme.breakpoints.down('sm')] = {
		root: {
			width: '100%',
		},
		table: {
			height: 'auto',
			flexGrow: 1,
		},
		placeholder: {
			height: 'auto',
			flexGrow: 1,
		},
	}

	return styles
})

export default function CategoryPickerDialog({ state, title, onCancel, onChange, onComplete, onExited }) {
	const classes = useStyles()

	const [ fetchedData, setFetchedData ] = useState(null)
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const getActionText = (action) => {
		switch (action) {
			case 'link': return 'Linking'
			case 'move': return 'Moving'
			default: return ''
		}
	}

	const handleExited = () => {
		setFetchedData(null)
		// eslint-disable-next-line
		onExited?.(null)
	}

	const handleClickBreadcrumb = (categoryIds) => {
		// eslint-disable-next-line
		onChange?.({ ...state, categoryIds })
	}

	const handleClickCategory = (item) => {
		// eslint-disable-next-line
		onChange?.({ ...state, categoryIds: state.categoryIds.concat([item.entity_id]) })
	}

	const handleComplete = () => {
		// eslint-disable-next-line
		onComplete?.(state)
	}

	useEffect(() => {
		if (!state) return
		let cancelled = false
		;(async () => {
			setLoadingOverlayText('Loading...')
			const res = await api.getCategoryList(state.categoryIds, { for_selection: true })
			setLoadingOverlayText('')
			if (!res.ok) {
				await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
				return
			}
			res.categories.sort((a, b) => a.name.localeCompare(b.name))
			if (!cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [state])

	const breadcrumbs = []
	if (fetchedData && fetchedData.path) {
		for (const category of fetchedData.path) {
			breadcrumbs.push(category)
		}
	}

	let items = []
	if (state && fetchedData) {
		items = fetchedData.categories
		if (state.link.kind === CategoryLinkKind.category)
			items = items.filter(c => c.entity_id !== state.link.entity_id)
	}

	const placeholderContent = <div className={classes.placeholder}>
		<Typography variant='h6' style={{ textAlign: 'center' }}>No Subcategories</Typography>
	</div>

	return <StandardDialog open={state ? state.open : false} title={title} contain={false}
		cancelTooltip='Cancel' completeTooltip='Continue'
		onCancel={onCancel} onComplete={handleComplete} onExited={handleExited}
	>
		<AlertDialog options={alertDialogOptions} />
		<LoadingOverlay open={Boolean(loadingOverlayText)} text={loadingOverlayText} />
		<div className={classes.root}>
			{ state ? <Typography variant='h6' style={{ textAlign: 'center' }}>{getActionText(state.action)} <b>"{state.link.name}"</b> to <b>"{fetchedData?.name}"</b></Typography> : undefined }
			<CategoryBreadcrumbs items={breadcrumbs} className={classes.breadcrumbs} onClick={handleClickBreadcrumb} />
			<Table className={classes.table} header={false} pagination={false} items={items} idFieldName='entity_id' variant='outlined' placeholderContent={placeholderContent} onClickRow={handleClickCategory}>
				<TableColumns>
					<TableColumn fieldName='name'>Name</TableColumn>
				</TableColumns>
			</Table>
		</div>
	</StandardDialog>
}
