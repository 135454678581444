import {
	StandardTable as Table,
	StandardTableAction as TableAction,
	StandardTableActions as TableActions,
	StandardTableColumn as TableColumn,
	StandardTableColumns as TableColumns,
} from '../Components/StandardTable'

import * as utils from '../utils'
import Box from '@material-ui/core/Box'
import CategoryBreadcrumbs from '../Components/CategoryBreadcrumbs'
import CategoryPickerDialog from '../Dialogs/CategoryPickerDialog'
import Container from '@material-ui/core/Container'
import EditDialog from '../Dialogs/EditDialog'
import FileUpload from '../Components/FileUpload'
import IconButton from '@material-ui/core/IconButton'
import ImageOverlay from '../Components/ImageOverlay'
import Page from './Page'
import React, { Fragment, useEffect, useState } from 'react'
import SortCategoryLinksDialog from '../Dialogs/SortCategoryLinksDialog'
import SortIcon from '@material-ui/icons/Sort'
import Stack from '../Components/Stack'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import useIsMounted from '../hooks/useIsMounted'
import { Category, CategoryLinkKind, Drill } from '../types'
import { FileKinds } from '../types'
import { getEditSessionGetterAndSetter, startEditSession } from '../EditSession'
import { makeStyles } from '@material-ui/core/styles'
import { useAlertDialog } from '../Dialogs/AlertDialog'
import { useConfirmationDialog } from '../Dialogs/ConfirmationDialog'
import { useHistory, useLocation } from 'react-router-dom'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => {
	const styles = {
		buttons: {
			position: 'absolute',
			right: -theme.spacing(1),
			top: 0,
			'& .MuiButton-root': { marginLeft: theme.spacing(1) },
		},
		container: {
			position: 'relative',
			padding: 0,
		},
		header: {
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
	}

	styles[theme.breakpoints.down('xs')] = {
		buttons: {
			right: theme.spacing(0.5),
			top: theme.spacing(0.5),
		},
	}

	return styles
})

export default function ManageDrillsPage() {
	const classes = useStyles()
	const history = useHistory()
	const location = useLocation()
	const isMounted = useIsMounted()
	const { dispatch, state } = useStore()

	const categoryIds = location.pathname.slice(15).split('/').filter(id => id).map(id => parseInt(id))

	const [ categoryPickerDialogState, setCategoryPickerDialogState ] = useState(null)
	const [ drillEditDialogWasOpen, setDrillEditDialogWasOpen ] = useState(false)
	const [ editSessionCategory, setEditSessionCategory ] = useState(null)
	const [ editSessionOrderLinks, setEditSessionOrderLinks ] = useState(null)
	const [ fetchedData, setFetchedData ] = useState(null)
	const [ imageOverlaySrc, setImageOverlaySrc ] = useState('')
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const [ confirmationDialogOptions, setConfirmationDialogOptions ] = useState(null)
	const showConfirmationDialog = useConfirmationDialog(setConfirmationDialogOptions)

	const fetchData = async () => {
		setLoadingOverlayText('Loading...')
		const res = await api.getCategoryList(categoryIds, { for_managing: true })
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			history.goBack()
			return
		}
		res.categories.sort((a, b) => a.name.localeCompare(b.name))
		res.drills.sort((a, b) => a.name.localeCompare(b.name))
		return res
	}

	const fetchAndSetData = async () => {
		const res = await fetchData()
		if (res) setFetchedData(res)
	}

	const handleCategoryPickerExited = (state) => {
		setCategoryPickerDialogState(null)
	}

	const handleCategoryPickerCancel = () => {
		setCategoryPickerDialogState({ ...categoryPickerDialogState, open: false })
	}

	const handleCategoryPickerChange = (state) => {
		setCategoryPickerDialogState(state)
	}

	const handleCategoryPickerComplete = async ({ action, categoryIds, link: { entity_id, kind } }) => {
		const params = { from_category_id: fetchedData.id, to_category_id: categoryIds[categoryIds.length - 1], entity_id, kind }

		const what = kind === CategoryLinkKind.category ? 'Category' : 'Drill'

		let res
		switch (action) {
			case 'link':
				setLoadingOverlayText(`Linking ${what}...`)
				res = await api.postCategoryLinkCopy(params)
				break
			case 'move':
				setLoadingOverlayText(`Moving ${what}...`)
				res = await api.patchCategoryLinkMove(params)
				break
			default: throw new Error('Invalid action.')
		}

		if (!res.ok) {
			setLoadingOverlayText('')
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		setCategoryPickerDialogState({ ...categoryPickerDialogState, open: false })
		await fetchAndSetData()
		setLoadingOverlayText('')
	}

	const handleDeleteCategory = async (item) => {
		if (!await showConfirmationDialog('CONFIRM', `Delete category "${item.name}"?`)) return
		setLoadingOverlayText('Deleting Category...')
		const res = await api.deleteCategoryLink({ category_id: fetchedData.id, entity_id: item.entity_id, kind: CategoryLinkKind.category })
		if (!res.ok) {
			setLoadingOverlayText('')
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
		setLoadingOverlayText('')
	}

	const handleDeleteDrill = async (item) => {
		if (!await showConfirmationDialog('CONFIRM', `Delete drill "${item.name}"?`)) return
		setLoadingOverlayText('Deleting Drill...')
		const res = await api.deleteCategoryLink({ category_id: fetchedData.id, entity_id: item.entity_id, kind: CategoryLinkKind.drill })
		if (!res.ok) {
			setLoadingOverlayText('')
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
		setLoadingOverlayText('')
	}

	// TODO get rid of wasChanged in startEditSession and just show a nested confirmation dialog

	const handleClickCategory = (item) => {
		history.push('/drills/manage/' + categoryIds.join('/') + '/' + item.entity_id)
	}

	// NOTE if you update this you probably need to update the same function in DrillsPage
	// noinspection DuplicatedCode
	const handleClickOrderLinks = async () => {
		setLoadingOverlayText('Loading Category...')
		const res = await api.getCategoryLinks(categoryIds[categoryIds.length - 1])
		if (!isMounted()) return
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}

		const [ordered, unordered] = utils.prepareCategoryLinksForOrdering(res.links)

		await startEditSession(setEditSessionOrderLinks, utils.clone({ ordered, unordered }), {
			async onCancel() {
				return true
			},
			async onComplete({ ordered: newOrdered, unordered: newUnordered }) {
				const links = utils.diffCategoryLinksForUpload(ordered.concat(unordered), newOrdered.concat(newUnordered))
				if (links.length === 0) return true

				setLoadingOverlayText('Updating Order...')
				const res = await api.patchCategoryLinks(fetchedData.id, links)
				if (!res.ok) {
					setLoadingOverlayText('')
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return false
				}
				setLoadingOverlayText('')
				return true
			},
		})
	}

	const handleCloneDrill = async (item) => {
		state.editDrill(dispatch, { clone: true, drillId: item.entity_id })
	}

	const handleCreateCategory = async (item) => {
		await startEditSession(setEditSessionCategory, new Category({ category_id: fetchedData.id }), {
			async onCancel() {
				return await showConfirmationDialog(
					'CONFIRM',
					"Are you sure you'd like to discard your new category?"
				)
			},
			async onComplete(item) {
				const imageFile = item.imageFile
				item.imageFile = null
				if (imageFile) {
					setLoadingOverlayText('Uploading image...')
					const res = await api.uploadFile(imageFile, FileKinds.category_image)

					if (!res.ok) {
						setLoadingOverlayText('')
						await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
						return
					}

					if (!res.id) {
						setLoadingOverlayText('')
						return false
					}

					item.image_file_id = res.id
				}

				setLoadingOverlayText('Creating Category...')

				const res = await api.postCategory(item)
				if (!res.ok) {
					setLoadingOverlayText('')
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return false
				}
				await fetchAndSetData()
				setLoadingOverlayText('')
				return true
			},
		})
	}

	const handleCreateDrill = async () => {
		state.editDrill(dispatch, {
			drill: new Drill({
				category_id: fetchedData.id,
				data: window.courtLib.types.newDrill(),
			}),
		})
	}

	const handleEditCategory = async (item) => {
		setLoadingOverlayText('Loading Category...')
		const res = await api.getCategory(item.entity_id)
		if (!isMounted()) return
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}

		await startEditSession(setEditSessionCategory, res.category, {
			async onCancel() {
				return await showConfirmationDialog(
					'CONFIRM',
					"Are you sure you'd like to discard your changes to this category?"
				)
			},
			async onComplete(item) {
				const imageFile = item.imageFile
				item.imageFile = null
				if (imageFile) {
					setLoadingOverlayText('Uploading image...')
					const res = await api.uploadFile(imageFile, FileKinds.category_image)

					if (!res.ok) {
						setLoadingOverlayText('')
						await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
						return
					}

					if (!res.id) {
						setLoadingOverlayText('')
						return false
					}

					item.image_file_id = res.id
				}

				setLoadingOverlayText('Updating Category...')
				const res = await api.patchCategory(item.id, item)
				if (!res.ok) {
					setLoadingOverlayText('')
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return false
				}
				await fetchAndSetData()
				setLoadingOverlayText('')
				return true
			},
		})
	}

	const handleEditDrill = async (item) => {
		state.editDrill(dispatch, { drillId: item.entity_id })
	}

	const handleLinkCategory = async (item) => {
		setCategoryPickerDialogState({ action: 'link', link: { ...item, kind: CategoryLinkKind.category }, categoryIds: categoryIds.slice(), open: true })
	}

	const handleMoveCategory = async (item) => {
		setCategoryPickerDialogState({ action: 'move', link: { ...item, kind: CategoryLinkKind.category }, categoryIds: categoryIds.slice(), open: true })
	}

	const handlePublishCategory = async (item) => {
		setLoadingOverlayText((item.is_published ? 'Unpublishing' : 'Publishing') + ' Category...')
		const res = await api.patchCategory(item.entity_id, { is_published: !item.is_published })
		if (!res.ok) {
			setLoadingOverlayText('')
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return false
		}
		await fetchAndSetData()
		setLoadingOverlayText('')
		return true
	}

	const handleLinkDrill = async (item) => {
		setCategoryPickerDialogState({ action: 'link', link: { ...item, kind: CategoryLinkKind.drill }, categoryIds: categoryIds.slice(), open: true })
	}

	const handleMoveDrill = async (item) => {
		setCategoryPickerDialogState({ action: 'move', link: { ...item, kind: CategoryLinkKind.drill }, categoryIds: categoryIds.slice(), open: true })
	}

	const handlePublishDrill = async (item) => {
		setLoadingOverlayText((item.is_published ? 'Unpublishing' : 'Publishing') + ' Drill...')
		const res = await api.patchDrill(item.entity_id, { is_published: !item.is_published })
		if (!res.ok) {
			setLoadingOverlayText('')
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return false
		}
		await fetchAndSetData()
		setLoadingOverlayText('')
		return true
	}

	const [ getCategory, setCategory ] = getEditSessionGetterAndSetter(editSessionCategory, setEditSessionCategory)

	const breadcrumbs = []
	if (fetchedData && fetchedData.path) {
		for (const category of fetchedData.path) {
			breadcrumbs.push(category)
		}
	}

	let isCurator = false
	if (state.user) isCurator = state.user.is_admin || state.user.is_curator

	const handleImageChange = (file) => {
		editSessionCategory.changed = true
		editSessionCategory.data.imageFile = file
	}

	const handleImageInvalidTypeProvided = async () => {
		await showAlertDialog('ERROR', 'Only PNG image files can be uploaded.')
	}

	const handleImageRemove = async (e) => {
		if (await showConfirmationDialog('CONFIRM', "Are you sure you'd like to clear the previously uploaded image?", 'YES', 'NO')) {
			editSessionCategory.changed = true
			editSessionCategory.data.image_file_id = null
			setEditSessionCategory(editSessionCategory.clone())
		}
	}

	const handleImageView = (file) => {
		if (file) {
			setImageOverlaySrc(URL.createObjectURL(file))
		}
		else {
			setImageOverlaySrc('/files/' + editSessionCategory.data.image_file_id)
		}
	}

	const handleImageOverlayClose = (e) => {
		setImageOverlaySrc(null)
	}

	useEffect(() => {
		let cancelled = false
		;(async () => {
			if (categoryIds.length === 0) {
				await showAlertDialog('ERROR', 'Unknown category.')
				return
			}
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [location])

	useEffect(() => {
		if (state.drillEditDialog.open) {
			setDrillEditDialogWasOpen(true)
			return
		}

		if (!drillEditDialogWasOpen) {
			return
		}
		setDrillEditDialogWasOpen(false)

		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [state.drillEditDialog.open])

	return <Page alertDialogOptions={alertDialogOptions} confirmationDialogOptions={confirmationDialogOptions} loadingText={loadingOverlayText}>
		<ImageOverlay open={Boolean(imageOverlaySrc)} src={imageOverlaySrc} onClose={handleImageOverlayClose} />
		<EditDialog entityName='Category' editSession={editSessionCategory}>
			<Stack horizontal={false}>
				<TextField label='Name' fullWidth defaultValue={getCategory('name', '')} onChange={setCategory('name')} autoFocus />
				{ isCurator ? <FileUpload what='Image'
					acceptTypes={['image/png']}
					hasExistingFile={Boolean(getCategory('image_file_id', null))}
					onChange={handleImageChange}
					onInvalidTypeProvided={handleImageInvalidTypeProvided}
					onRemove={handleImageRemove}
					onView={handleImageView}
				/> : undefined }
			</Stack>
		</EditDialog>
		<SortCategoryLinksDialog editSession={editSessionOrderLinks} setEditSession={setEditSessionOrderLinks} />
		<CategoryPickerDialog title='Move Category'
			state={categoryPickerDialogState}
			onExited={handleCategoryPickerExited}
			onCancel={handleCategoryPickerCancel}
			onChange={handleCategoryPickerChange}
			onComplete={handleCategoryPickerComplete}
		/>
		{ fetchedData ? <Container className={classes.container}>
			<Box className={classes.buttons}>
				{ isCurator ? <Tooltip title='Change Order'>
					<IconButton onClick={handleClickOrderLinks}><SortIcon /></IconButton>
				</Tooltip> : undefined }
			</Box>
			<CategoryBreadcrumbs prefix='/drills/manage' items={breadcrumbs} className={classes.breadcrumbs} />
			<Typography variant='h6' className={classes.header}>Categories</Typography>
			<Table pagination={false} items={fetchedData.categories} onCreateItem={handleCreateCategory} onClickRow={handleClickCategory} idFieldName='entity_id'>
				<TableColumns>
					<TableColumn fieldName='name' onStyle={(_, item) => ({ borderLeft: isCurator ? `10px solid ${item.is_published ? '#0f0' : '#f00'}` : '' })}>Name</TableColumn>
					{ isCurator ? <TableColumn fieldName='is_published' onTransform={(v) => v ? 'Yes' : 'No'} width={100}>Published</TableColumn> : undefined }
				</TableColumns>
				<TableActions>
					{ isCurator ? <TableAction onClick={handlePublishCategory} onContent={({ is_published }) => is_published ? 'Unpublish' : 'Publish'} /> : undefined }
					{ isCurator ? <TableAction onClick={handleLinkCategory}>Link</TableAction> : undefined }
					{ isCurator ? <TableAction onClick={handleMoveCategory}>Move</TableAction> : undefined }
					<TableAction onClick={handleEditCategory}>Edit</TableAction>
					<TableAction onClick={handleDeleteCategory}>Delete</TableAction>
				</TableActions>
			</Table>
			{ fetchedData.id !== 1 ? <Fragment>
				<Typography variant='h6' className={classes.header}>Drills</Typography>
				<Table pagination={false} items={fetchedData.drills} onCreateItem={handleCreateDrill} onClickRow={handleEditDrill} idFieldName='entity_id'>
					<TableColumns>
						<TableColumn fieldName='name' onStyle={(_, item) => ({ borderLeft: isCurator ? `10px solid ${item.is_published ? '#0f0' : '#f00'}` : '' })}>Name</TableColumn>
						{ isCurator ? <TableColumn fieldName='is_published' onTransform={(v) => v ? 'Yes' : 'No'} width={100}>Published</TableColumn> : undefined }
					</TableColumns>
					<TableActions>
						{ isCurator ? <TableAction onClick={handlePublishDrill} onContent={({ is_published }) => is_published ? 'Unpublish' : 'Publish'} /> : undefined }
						{ isCurator ? <TableAction onClick={handleLinkDrill}>Link</TableAction> : undefined }
						{ isCurator ? <TableAction onClick={handleMoveDrill}>Move</TableAction> : undefined }
						{ isCurator ? <TableAction onClick={handleCloneDrill}>Clone</TableAction> : undefined }
						<TableAction onClick={handleEditDrill}>Edit</TableAction>
						<TableAction onClick={handleDeleteDrill}>Delete</TableAction>
					</TableActions>
				</Table>
			</Fragment> : undefined }
		</Container> : undefined }
	</Page>
}
