import Button from '@material-ui/core/Button'
import Page from './Page'
import PageDialog from '../Dialogs/PageDialog'
import React, { Fragment, useEffect, useState } from 'react'
import StandardTextField from '../Components/StandardTextField'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import useQuery from '../hooks/useQuery'

export default function AccountRecoverRequestPage({ alert }) {
	const query = useQuery()

	const [ isLoading, setIsLoading ] = useState(false)

	const [ activated, setActivated ] = useState(false)
	const [ error, setError ] = useState('')
	const [ params, setParams ] = useState({ code: query.get('code') ?? '' })

	const submit = async () => {
		setIsLoading(true)
		const res = await api.postActivationPerform(params)
		setIsLoading(false)

		setError('')

		if (!res.ok) {
			setError(res.extractRemainingErrorsMessage())
			return
		}

		setActivated(true)
	}

	const handleChange = (fieldName) => (e) => {
		params[fieldName] = e.target.value
		setParams(params)
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		await submit()
	}

	useEffect(() => {
		;(async () => {
			if (query.get('code')) await submit()
		})()
		// eslint-disable-next-line
	}, [])

	return <Page loading={isLoading}>
		<PageDialog>
			{ activated ? <Fragment>
				<Typography variant='h5'>Device Activated!</Typography>
				<Typography variant='body2'>Your device will update in a moment. This page can be closed.</Typography>
			</Fragment> : <Fragment>
				<Typography variant='h5'>Activate Your Device</Typography>
				<Typography variant='body2'>Please enter the activation code shown on your device.</Typography>
				<form onSubmit={handleSubmit}>
					<StandardTextField defaultValue={params.code} error={error} onChange={handleChange('code')} autoFocus />
					<Button type='submit' color='primary' variant='contained'>Submit</Button>
				</form>
			</Fragment> }
		</PageDialog>
	</Page>
}
