import React from 'react'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: { display: 'flex' },
	expandEqually: { flexBasis: 0, flexGrow: 1 },
	grow: {
		'& > *': { flexBasis: '0%', flexGrow: 1 },
	},
	small: {},
	medium: {},
	endPaddingHorizontal: {
		marginRight: theme.spacing(3),
		'&$medium': { marginRight: theme.spacing(2) },
		'&$small': { marginRight: theme.spacing(1) },
	},
	endPaddingVertical: {
		marginBottom: theme.spacing(3),
		'&$medium': { marginBottom: theme.spacing(2) },
		'&$small': { marginBottom: theme.spacing(1) },
	},
	horizontal: { flexDirection: 'row' },
	vertical: { flexDirection: 'column' },
	spacing: {
		gap: theme.spacing(3),
		'&$medium': { gap: theme.spacing(2) },
		'&$small': { gap: theme.spacing(1) },
	},
	wrap: {
		flexWrap: 'wrap',
	},
}))

export default function Stack({ children, className, expandEqually, horizontal, endPadding, grow, medium, small, spacing, wrap, ...rest }) {
	const classes = useStyles()

	spacing = spacing ?? true

	className = clsx(classes.root, {
		[classes.expandEqually]: expandEqually,
		[classes.horizontal]: horizontal,
		[classes.vertical]: !horizontal,
		[classes.spacing]: spacing,
		[classes.grow]: grow ?? true,
		[classes.wrap]: wrap ?? false,
		[classes.endPaddingHorizontal]: horizontal && (endPadding ?? false),
		[classes.endPaddingVertical]: !horizontal && (endPadding ?? false),
		[classes.small]: small ?? false,
		[classes.medium]: medium ?? false,
	}, className)

	return <div className={className} {...rest}>{children}</div>
}
