import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flex: 1,
		justifyContent: 'space-around',
		paddingBottom: 8,
		paddingLeft: 16,
		paddingRight: 16,
		paddingTop: 16,
		gap: 8,
		userSelect: 'none',
		width: '100%',
		flexWrap: 'wrap',
	},
	rootExpandedItems: {
		'& $item': {
			flexBasis: '0px',
			flexGrow: 1,
		},
	},
	item: {
		alignItems: 'center',
		cursor: 'pointer',
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
		paddingBottom: 3,
		paddingTop: 3,
		paddingLeft: 8,
		paddingRight: 8,
	},
	itemRadio: {
		borderColor: theme.palette.text.primary,
		borderRadius: '99em',
		borderStyle: 'solid',
		borderWidth: 2,
		height: 16,
		width: 16,
	},
	itemRadioSelected: {
		background: theme.palette.text.primary,
	},
	itemDisabled: {
		opacity: 0.4,
	},
}))

export default function RadioBar({ disabledTooltip, fieldName, isMobile, items, value, onChange, onGetItemDisabled }) {
	const classes = useStyles()

	return <div className={clsx(classes.root, items.length === 2 && classes.rootExpandedItems)}>
		{items.map((item, i) => {
			const text = item[fieldName]
			const disabled = onGetItemDisabled && onGetItemDisabled(item)
			const selected = i === value
			const className = clsx(classes.item, disabled && classes.itemDisabled)
			const content = <div key={i} className={className} onClick={disabled && !selected ? undefined : () => onChange({ target: { value: i } })}>
				<div className={clsx(classes.itemRadio, selected && classes.itemRadioSelected)} />
				<Typography variant={isMobile ? 'subtitle1' : 'h6'}>{text}</Typography>
			</div>
			if (!disabled) return content
			return <Tooltip key={i} title={disabledTooltip ?? 'Disabled'}>{content}</Tooltip>
		})}
	</div>
}
