import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Header from '../Components/Header'
import Page from './Page'
import React, { useEffect, useState } from 'react'
import Stack from '../Components/Stack'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useAlertDialog } from '../Dialogs/AlertDialog'
import { useHistory, useLocation } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'

export default function AdminOrganizationsPage() {
	const theme = useTheme()
	const history = useHistory()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
	const location = useLocation()

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const [ fetchedData, setFetchedData ] = useState(null)
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')

	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const fetchData = async () => {
		setLoadingOverlayText('Loading...')
		const res = await api.getSessionValid()
		console.log(res)
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		return res
	}

	let user = fetchedData?.user

	useEffect(() => {
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [location])

	const navigate = (to) => () => {
		history.push(to)
	}

	return <Page padding='bottom' alertDialogOptions={alertDialogOptions} loadingText={loadingOverlayText}>
		<Container>
			<Header>
				<Stack horizontal={!isMobile} small={isMobile}>
					<Typography variant='inherit' style={{ flexGrow: 0 }}>Links</Typography>
				</Stack>
			</Header>
			{ user?.is_admin ? <Box display="flex">
				<Box mr={2}><Button size="small" variant="contained" onClick={navigate('/admin/guns')}>Guns</Button></Box>
				<Box mr={2}><Button size="small" variant="contained" onClick={navigate('/admin/organizations')}>Organizations</Button></Box>
				<Box mr={2}><Button size="small" variant="contained" onClick={navigate('/admin/tvs')}>TVs</Button></Box>
			</Box> : null }
			{ user?.is_super_admin ? <Box display="flex" mt={2}>
				<Box mr={2}><Button size="small" variant="contained" onClick={navigate('/admin/analytics/errors')}>Analytics Errors</Button></Box>
				<Box mr={2}><Button size="small" variant="contained" onClick={navigate('/admin/analytics/events')}>Analytics Events</Button></Box>
				<Box mr={2}><Button size="small" variant="contained" onClick={navigate('/admin/import')}>Import</Button></Box>
				<Box mr={2}><Button size="small" variant="contained" onClick={navigate('/admin/tasks')}>Tasks</Button></Box>
			</Box> : null }
		</Container>
	</Page>
}
