import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import NavigateNext from '@material-ui/icons/NavigateNext'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	link: {
		cursor: 'pointer',
		userSelect: 'none',
	},
	typography: {
		userSelect: 'none',
	},
}))

export default function CategoryBreadcrumbs({ items, prefix, firstIsPrefixOnly, onClick, ...rest }) {
	const classes = useStyles()
	const history = useHistory()

	items = items.slice()
	const currentItem = items.pop()

	const formatUrl = (ids) => {
		const arr = []
		if (prefix) arr.push(prefix)
		return arr.concat(ids).join('/')
	}

	const handleClick = (ids) => (e) => {
		e.preventDefault() // needed otherwise the browser selects text on the page on Android
		if (onClick) onClick(ids)
		else history.push(formatUrl(ids))
	}

	return <Breadcrumbs aria-label='breadcrumb' separator={<NavigateNext fontSize='small' />} {...rest}>
		{items.reduce((result, breadcrumb, i) => {
			if (i === 0) {
				if (!firstIsPrefixOnly) {
					result.ids.push(breadcrumb.id)
				}
			}
			else {
				result.ids.push(breadcrumb.id)
			}

			const ids = result.ids.slice()
			result.els.push(<Link color='inherit' className={classes.link} key={formatUrl(ids)} onClick={handleClick(ids)}>{breadcrumb.name}</Link>)
			return result
		}, { els: [], ids: [] }).els}
		{ currentItem ? <Typography className={classes.typography} color='textPrimary'>{currentItem.name}</Typography> : undefined }
	</Breadcrumbs>
}
