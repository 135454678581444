import Button from '@material-ui/core/Button'
import Page from './Page'
import PageDialog from '../Dialogs/PageDialog'
import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import { useHistory, useParams } from 'react-router-dom'

export default function AccountVerifyPage() {
	const history = useHistory()
	const { tokenId } = useParams()
	const [ isLoading, setIsLoading ] = useState(false)
	const [ tokenCheckDescription, setTokenCheckedDescription ] = useState('')

	useEffect(() => {
		if (tokenCheckDescription) return
		(async () => {
			setIsLoading(true)
			const res = await api.postUserVerify(tokenId)
			setIsLoading(false)
			if (!res.ok) {
				setTokenCheckedDescription(res.extractRemainingErrorsMessage())
				return
			}
			history.push('/login', { alert: 'Email validated, you may now login.' })
		})()
		// eslint-disable-next-line
	}, [tokenCheckDescription])

	const handleGoToLogin = async (e) => {
		history.push('/login')
	}

	return <Page loading={isLoading}>
		<PageDialog style={{ display: tokenCheckDescription ? '' : 'none' }}>
			<Typography>{tokenCheckDescription}</Typography>
			<Button type='submit' color='primary' variant='contained' onClick={handleGoToLogin}>Go to login</Button>
		</PageDialog>
	</Page>
}
