import React, { Fragment, useState } from 'react'
import { DrillBallShotType, drillCourtLocationSvgDatas, DrillZone, DrillZoneNames } from '../types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => ({
	path: {
		fill: '#414141',
		stroke: '#ffffff',
		strokeWidth: 4,
		strokeMiterlimit: 10,
	},
	statZoneName: {
		fill: 'white',
		fontSize: '22px',
		fontWeight: 'bold',
	},
	statKey: {
		fill: 'white',
		fontSize: '20px',
	},
	statValue: {
		fill: 'white',
		fontSize: '24px',
		fontWeight: 'bold',
	},
	statValueLarge: {
		fill: 'white',
		fontSize: '30px',
		fontWeight: 'bold',
	},
}))

const DrillZones = Object.values(DrillZone)
DrillZones.sort((a, b) => a - b)
const columnNamesByShotType  = Object.values(DrillBallShotType).reduce((obj, shotType) => {
	obj[shotType] = DrillZones.map(zone => [ `m_z_st_${zone}_${shotType}`, `s_z_st_${zone}_${shotType}` ])
	return obj
}, {})
const overallColumnNames = DrillZones.map(zone => [ 'm_z_' + zone, 's_z_' + zone ])

export default function StatsCourt({ className, displayStats, filledZones, shotType, stats }) {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [ statForDisplay, setStatForDisplay ] = useState(null)

	const columnNamePairs = shotType ? columnNamesByShotType[shotType] : overallColumnNames

	return <svg viewBox='0 0 300 220' className={className}>
		{ displayStats && statForDisplay ? <Fragment>
			<text className={classes.statZoneName} x='150' y='22' textAnchor='middle'>
				{DrillZoneNames[statForDisplay.zone]}
			</text>
			<text className={classes.statKey} x='80' y='52'>MAKES</text>
			<text className={classes.statValue} x='80' y='62' alignmentBaseline='hanging'>
				{statForDisplay.makes}
			</text>
			<text className={classes.statKey} x='220' y='52' textAnchor='end'>SHOTS</text>
			<text className={classes.statValue} x='220' y='62' alignmentBaseline='hanging' textAnchor='end'>
				{statForDisplay.shots}
			</text>
			<text className={classes.statValueLarge} x='150' y='92' textAnchor='middle' alignmentBaseline='hanging'>
				{statForDisplay.percent}%
			</text>
		</Fragment> : undefined }
		{drillCourtLocationSvgDatas.map(([ zone, location, d ], i) => {
			let fill
			let makes, shots, percent
			if (stats) {
				const [ makesColumnName, shotsColumnName ] = columnNamePairs[zone - 1]
				shots = stats[shotsColumnName]
				makes = stats[makesColumnName]
				if (shots > 0) {
					percent = Math.floor((makes / shots) * 100)
					fill = percent < 72 ? '#4766c9' : '#af2200'
				}
			}
			if (!fill && filledZones && filledZones.includes(zone)) fill = '#aaa'
			if (statForDisplay && zone === statForDisplay.zone) fill = 'gold'

			return <path pointerEvents={'visibleFill'}
				key={i} d={d}
				className={classes.path} style={{ fill }}
				onMouseEnter={shots > 0
					? () => setStatForDisplay({ zone, makes, shots, percent })
					: () => setStatForDisplay(null)}
				onMouseLeave={isMobile ? undefined : () => setStatForDisplay(null)}
			/>
		})}
	</svg>
}