import LoadingOverlay from '../Components/LoadingOverlay'
import React, { forwardRef } from 'react'
import clsx from 'clsx'
import { AlertDialog } from '../Dialogs/AlertDialog'
import { ConfirmationDialog } from '../Dialogs/ConfirmationDialog'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		flexBasis: '0%',
		flexGrow: 1,
		flexShrink: 0,
		overflow: 'auto',
		position: 'relative',
		width: '100%',
	},
	paddingBottom: { paddingBottom: '1em', },
	paddingLeft: { paddingLeft: '1em', },
	paddingRight: { paddingRight: '1em', },
	paddingTop: { paddingTop: '1em', },
}))

function Page({ alertDialogOptions, children, className, confirmationDialogOptions, loading, loadingText, loadingProgress, padding, ...rest }, ref) {
	const classes = useStyles()

	const paddingAll = padding === undefined || padding === true
	className = clsx(classes.root, className, {
		[classes.paddingBottom]: paddingAll || padding === 'bottom',
		[classes.paddingLeft]: paddingAll || padding === 'left',
		[classes.paddingRight]: paddingAll || padding === 'right',
		[classes.paddingTop]: paddingAll || padding === 'top',
	})

	return <div ref={ref} className={className} {...rest}>
		<LoadingOverlay open={Boolean(loading) || Boolean(loadingText) || Boolean(loadingProgress)} text={loadingText} progress={loadingProgress} />
		<AlertDialog options={alertDialogOptions} />
		<ConfirmationDialog options={confirmationDialogOptions} />
		{children}
	</div>
}

export default forwardRef(Page)
