import DayjsUtils from '@date-io/dayjs'
import React, { useState } from 'react'
import dayjs from 'dayjs'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		maxWidth: 124,
	},
}))

export function StandardDatePicker({ clearable=true, defaultValue, error, label, onChange }) {
	const classes = useStyles()

	const [value, setValue] = useState(defaultValue || null)

	const handleChange = (value) => {
		setValue(value)
		if (value) value = dayjs(value).format('YYYY-MM-DD')
		// eslint-disable-next-line
		onChange?.({ target: { value } })
	}

	return <MuiPickersUtilsProvider utils={DayjsUtils}>
		<DatePicker
			className={classes.root}
			clearable={clearable}
			error={Boolean(error)}
			helperText={error}
			format='MMMM D YYYY'
			label={label}
			value={value}
			onChange={handleChange}
		/>
	</MuiPickersUtilsProvider>
}
