import Container from '@material-ui/core/Container'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import ListSubheader from '@material-ui/core/ListSubheader'
import MenuItem from '@material-ui/core/MenuItem'
import Page from './Page'
import Paper from '@material-ui/core/Paper'
import React, { Fragment, useEffect, useState } from 'react'
import Select from '@material-ui/core/Select'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import dayjs from 'dayjs'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useQuery from '../hooks/useQuery'
import { Line } from 'react-chartjs-2'
import Stack from '../Components/Stack'
import { StandardDatePicker } from '../Components/StandardDatePicker'
import { drillCourtLocationSvgDatas, drillSessionStatsColumnNames, DrillBallShotType, DrillZone } from '../types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useAlertDialog } from '../Dialogs/AlertDialog'
import { useHistory, useLocation } from 'react-router-dom'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => ({
	container: {
		padding: 0,
	},
	court: {
		filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.5))',
		maxWidth: 290,
		maxHeight: 210,
		minWidth: 290,
		minHeight: 210,
	},
	courtPath: {
		fill: '#414141',
		stroke: '#ffffff',
		strokeWidth: 4,
		strokeMiterlimit: 10,
	},
	dateRangeText: {
		flexGrow: 0,
		whiteSpace: 'nowrap',
	},
	line: {
		background: theme.palette.grey[400],
		height: 1,
		width: '100%',
	},
	makesAndShotsTextKey: {
		paddingLeft: theme.spacing(2),
		padding: theme.spacing(1.5),
	},
	makesAndShotsTextValue: {
		paddingRight: theme.spacing(2),
		padding: theme.spacing(1.5),
		fontWeight: 'bold',
		textAlign: 'end',
		flexGrow: 0,
	},
	userStatsPaper: {
		padding: theme.spacing(1),
	},
	userStatsName: {
		fontWeight: 'bold',
		paddingBottom: theme.spacing(1),
	},
	userStatsValue: {
		fontWeight: 'bold',
	},
}))

const DrillZones = Object.values(DrillZone)
DrillZones.sort((a, b) => a - b)
const drillSessionStatsColumnNamesForDisplay = {
	overall: DrillZones.map(zone => [
		'm_z_' + zone,
		's_z_' + zone,
	]),
	catch_and_shoot: DrillZones.map(zone => [
		'm_z_st_' + zone + '_' + DrillBallShotType.catch_and_shoot,
		's_z_st_' + zone + '_' + DrillBallShotType.catch_and_shoot,
	]),
	off_the_dribble: DrillZones.map(zone => [
		'm_z_st_' + zone + '_' + DrillBallShotType.off_the_dribble,
		's_z_st_' + zone + '_' + DrillBallShotType.off_the_dribble,
	]),
	on_the_move: DrillZones.map(zone => [
		'm_z_st_' + zone + '_' + DrillBallShotType.on_the_move,
		's_z_st_' + zone + '_' + DrillBallShotType.on_the_move,
	]),
}

function UserStats({ items, name, onUpdateQuery, tapsEnabled }) {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const getFormattedData = ({ id, name, m: makes, s: shots }) => {
		const percent = shots > 0 ? (Math.floor((makes / shots) * 100) + '%') : '-'
		return { id, name, makes, shots, percent }
	}

	return <Stack>
		<Typography variant={isMobile ? 'h5' : 'h4'}>{name}</Typography>
		{isMobile ? <div>
			{items.map(item => {
				const { id, name, makes, shots, percent } = getFormattedData(item)
				return <Paper style={{ cursor: tapsEnabled ? 'pointer' : '' }} className={classes.userStatsPaper} key={id}
					onClick={() => tapsEnabled && onUpdateQuery?.('user', id)}>
					<Typography variant={'h6'} className={classes.userStatsName}>{name}</Typography>
					<Stack horizontal small>
						<Stack small>
							<Typography>Makes</Typography>
							<Typography className={classes.userStatsValue}>{makes}</Typography>
						</Stack>
						<Stack small>
							<Typography>Shots</Typography>
							<Typography className={classes.userStatsValue}>{shots}</Typography>
						</Stack>
						<Stack small>
							<Typography>Percent</Typography>
							<Typography className={classes.userStatsValue}>{percent}</Typography>
						</Stack>
					</Stack>
				</Paper>
			})}
		</div> : <TableContainer component={Paper} style={{ flexBasis: 'auto' }}>
			<Table size='small'>
				<TableHead>
					<TableRow>
						<TableCell>Name</TableCell>
						<TableCell>Makes</TableCell>
						<TableCell>Shots</TableCell>
						<TableCell>Percent</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map(item => {
						const { id, name, makes, shots, percent, sessions } = getFormattedData(item)
						return <TableRow hover style={{ cursor: tapsEnabled ? 'pointer' : '' }} key={id}
							onClick={() => tapsEnabled && onUpdateQuery?.('user', id)}>
							<TableCell>{name}</TableCell>
							<TableCell>{makes}</TableCell>
							<TableCell>{shots}</TableCell>
							<TableCell>{percent}</TableCell>
							<TableCell>{sessions}</TableCell>
						</TableRow>
					})}
				</TableBody>
			</Table>
		</TableContainer>}
	</Stack>
}

export default function StatsPage() {
	const classes = useStyles()
	const history = useHistory()
	const query = useQuery()
	const theme = useTheme()
	const location = useLocation()

	const { state } = useStore()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [ errors, setErrors ] = useState(null)

	const [ fetchedData, setFetchedData ] = useState(null)
	const [ relations, setRelations ] = useState(null)
	const [ selectedStatSection, setSelectedStatSection ] = useState('overall')

	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')
	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const sortObjectsByName = (items) => {
		items.sort((a, b) => a.name.localeCompare(b.name))
	}

	const getFacilityName = (id) => {
		if (!relations.facilities) return 'Facility'
		const facility = relations.facilities.find(i => i.id === id)
		return facility?.name ?? 'Unknown Facility'
	}
	
	const getStatSectionForKind = (kind) => {
		function calculateMakeShotPercent(makesColumnName, shotsColumnName) {
			const shots = fetchedData.stats[shotsColumnName]
			if (shots > 0) {
				const makes = fetchedData.stats[makesColumnName]
				return Math.floor((makes / shots) * 100)
			}
		}

		function makeRow(locationName, twoPointColumnNamePair, threePointColumnNamePair, freeThrowColumnNamePair) {
			let twoPointText = '-'
			if (twoPointColumnNamePair) {
				const [ makesColumnName, shotsColumnName ] = twoPointColumnNamePair
				const percent = calculateMakeShotPercent(makesColumnName, shotsColumnName)
				if (percent !== undefined) twoPointText = percent + '%'
			}

			let threePointText = '-'
			if (threePointColumnNamePair) {
				const [ makesColumnName, shotsColumnName ] = threePointColumnNamePair
				const percent = calculateMakeShotPercent(makesColumnName, shotsColumnName)
				if (percent !== undefined) threePointText = percent + '%'
			}

			let freeThrowText = '-'
			if (freeThrowColumnNamePair) {
				const [ makesColumnName, shotsColumnName ] = freeThrowColumnNamePair
				const percent = calculateMakeShotPercent(makesColumnName, shotsColumnName)
				if (percent !== undefined) freeThrowText = percent + '%'
			}

			return <TableRow key={locationName}>
				<TableCell>{locationName}</TableCell>
				<TableCell>{threePointText}</TableCell>
				<TableCell>{twoPointText}</TableCell>
				<TableCell>{freeThrowText}</TableCell>
			</TableRow>
		}

		const statColumnNamePairs = drillSessionStatsColumnNamesForDisplay[kind]
		const [
			z1_p2, z1_p3,
			z2_p2, z2_p3,
			z3_p1, z3_p2, z3_p3,
			z4_p2, z4_p3,
			z5_p2, z5_p3
		] = statColumnNamePairs

		return <Stack>
			<Stack horizontal={!isMobile} style={{ alignItems: 'center' }}>
				<svg viewBox='0 0 300 220' className={classes.court}>
					{drillCourtLocationSvgDatas.map(([ zone, location, d ], i) => {
						let fill
						if (fetchedData.stats) {
							const [ makesColumnName, shotsColumnName ] = statColumnNamePairs[zone - 1]
							const percent = calculateMakeShotPercent(makesColumnName, shotsColumnName)
							if (percent !== undefined) fill = percent < 72 ? '#4766c9' : '#af2200'
						}
						if (!fill && fetchedData.zones.includes(zone)) fill = '#aaa'
						return <path key={i} d={d} className={classes.courtPath} style={{ fill }}/>
					})}
				</svg>
				<TableContainer component={Paper} style={{ overflowX: 'visible' }}>
					<Table size='small'>
						<TableHead>
							<TableRow>
								<TableCell>Location</TableCell>
								<TableCell>3pt</TableCell>
								<TableCell>2pt</TableCell>
								<TableCell>Free Throw</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{makeRow('Left Wing', z1_p2, z1_p3)}
							{makeRow('Left Elbow', z2_p2, z2_p3)}
							{makeRow('Key', z3_p2, z3_p3, z3_p1)}
							{makeRow('Right Elbow', z4_p2, z4_p3)}
							{makeRow('Right Wing', z5_p2, z5_p3)}
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
		</Stack>
	}

	const getTeamName = (id) => {
		if (!relations.teams) return 'Team'
		const team = relations.teams.find(i => i.id === id)
		return team?.name ?? 'Unknown Team'
	}

	const updateQuery = (key, value) => {
		if (key === 'statsFor') {
			const [ what, id ] = value.split('-')
			if (what === 'me') {
				query.delete('facility')
				query.delete('team')
				query.delete('user')
				key = value = undefined
			}
			else {
				key = what
				value = id
			}
		}

		if (key) {
			if (value === undefined || value === null) query.delete(key)
			else query.set(key, value)
		}

		if (key === 'facility') {
			query.delete('team')
			query.delete('user')
		}
		else if (key === 'team') {
			query.delete('facility')
			query.delete('user')
		}
		else if (key === 'user') {
			query.delete('facility')
			query.delete('team')
		}

		history.push('/stats-old?' + query.toString())
	}

	const fetchData = async () => {
		if (!relations) return // wait until we load the our list of related teams and facilities

		let startDate, endDate
		const range = query.get('range') ?? 'today'
		const facilityId = parseInt(query.get('facility'))
		const teamId = parseInt(query.get('team'))
		const userId = parseInt(query.get('user'))

		async function showError(res) {
			setLoadingOverlayText('')

			const errors = {}
			errors.startDate = res.extractErrorMessage('start_at')
			errors.endDate = res.extractErrorMessage('end_at')
			setErrors(errors)

			const msg = res.extractRemainingErrorsMessage()
			if (msg) await showAlertDialog('ERROR', msg)
		}

		function returnComputedData(days) {
			setLoadingOverlayText('')
			setErrors(null)

			let dateRangeText
			let dayCount = 1
			if (!startDate && !endDate) {
				dateRangeText = 'Career'
			}
			else if (startDate === endDate) {
				dateRangeText = dayjs(startDate).format('MM/DD/YY')
			}
			else {
				dateRangeText = `${dayjs(startDate).format('MM/DD/YY')} - ${dayjs(endDate).format('MM/DD/YY')}`
				const millisDiff = -dayjs(startDate).startOf('day').diff(dayjs(endDate).endOf('day'))
				dayCount = Math.floor(millisDiff / (1000 * 60 * 60 * 24)) + 1
			}

			const data = { dayCount, dateRangeText, days, stats: {}, zones: [] }

			for (const columnName of drillSessionStatsColumnNames.all) {
				data.stats[columnName] = 0
				for (const day of days) {
					data.stats[columnName] += day[columnName] ?? 0
				}
			}

			return data
		}

		if (range === 'career') {
			setLoadingOverlayText('Loading...')

			if (facilityId) {
				const res = await api.getDrillSessionStatsForFacilityCareer(facilityId)
				if (!res.ok) return showError(res)

				const res2 = await api.getDrillSessionStatsForFacilityCareerUsers(facilityId)
				if (!res2.ok) return showError(res2)
				if (res2.users) sortObjectsByName(res2.users)

				const data = returnComputedData(res.day ? [res.day] : [])
				data.name = getFacilityName(facilityId)
				data.facilityUsers = res2.users

				const facility = relations.facilities.find(i => i.id === facilityId)
				if (facility) {
					const millisSinceCreation = -dayjs(facility.created_at).startOf('day').diff(dayjs().endOf('day'))
					// add one so we include today in the count
					data.dayCount = Math.floor(millisSinceCreation / (1000 * 60 * 60 * 24)) + 1
				}

				return data
			}
			else if (teamId) {
				const res = await api.getDrillSessionStatsForTeamCareer(teamId)
				if (!res.ok) return showError(res)

				const res2 = await api.getDrillSessionStatsForTeamCareerUsers(teamId)
				if (!res2.ok) return showError(res2)
				if (res2.users) sortObjectsByName(res2.users)

				const data = returnComputedData(res.day ? [res.day] : [])
				data.name = getTeamName(teamId)
				data.teamUsers = res2.users

				const team = relations.teams.find(i => i.id === teamId)
				if (team) {
					const millisSinceCreation = -dayjs(team.created_at).startOf('day').diff(dayjs().endOf('day'))
					// add one so we include today in the count
					data.dayCount = Math.floor(millisSinceCreation / (1000 * 60 * 60 * 24)) + 1
				}

				return data
			}
			else {
				const res = await api.getDrillSessionStatsForUserCareer({ user_id: userId })
				if (!res.ok) return showError(res)
				const data = returnComputedData(res.day ? [res.day] : [])
				data.name = res.user_name || (state.user.name ?? 'Me')

				const millisSinceCreation = -dayjs(res.user_created_at || state.user.created_at).startOf('day').diff(dayjs().endOf('day'))
				// add one so we include today in the count
				data.dayCount = Math.floor(millisSinceCreation / (1000 * 60 * 60 * 24)) + 1

				return data
			}
		}

		if (range === 'today') {
			startDate = endDate = dayjs().format('YYYY-MM-DD')
		}
		else if (range === 'yesterday') {
			startDate = endDate = dayjs().subtract(1, 'day').format('YYYY-MM-DD')
		}
		else if (range === 'this-week') {
			startDate = dayjs().startOf('week').format('YYYY-MM-DD')
			endDate = dayjs().format('YYYY-MM-DD')
		}
		else if (range === 'this-month') {
			startDate = dayjs().startOf('month').format('YYYY-MM-DD')
			endDate = dayjs().format('YYYY-MM-DD')
		}
		else if (range === 'last-week') {
			const endOfLastWeek = dayjs().startOf('week').subtract(1, 'day')
			startDate = endOfLastWeek.startOf('week').format('YYYY-MM-DD')
			endDate = endOfLastWeek.format('YYYY-MM-DD')
		}
		else if (range === 'last-month') {
			const endOfLastMonth = dayjs().startOf('month').subtract(1, 'day')
			startDate = endOfLastMonth.startOf('month').format('YYYY-MM-DD')
			endDate = endOfLastMonth.format('YYYY-MM-DD')
		}
		else if (range === 'custom') {
			startDate = query.get('start')
			endDate = query.get('end')
		}

		if (!startDate || !endDate) return null

		setLoadingOverlayText('Loading...')

		if (facilityId) {
			// TJ 
			const res = await api.getDrillSessionStatsForFacilityDailyRange(facilityId, { start_at: startDate, end_at: endDate })
			if (!res.ok) return showError(res)

			// TJ
			const res2 = await api.getDrillSessionStatsForFacilityDailyRangeUsers(facilityId, { start_at: startDate, end_at: endDate })
			if (!res2.ok) return showError(res2)
			if (res2.users) sortObjectsByName(res2.users)

			const data = returnComputedData(res.days)
			data.name = getFacilityName(facilityId)
			data.facilityUsers = res2.users
			return data
		}
		else if (teamId) {
			const res = await api.getDrillSessionStatsForTeamDailyRange(teamId, { start_at: startDate, end_at: endDate })
			if (!res.ok) return showError(res)

			const res2 = await api.getDrillSessionStatsForTeamDailyRangeUsers(teamId, { start_at: startDate, end_at: endDate })
			if (!res2.ok) return showError(res2)
			if (res2.users) sortObjectsByName(res2.users)

			const data = returnComputedData(res.days)
			data.name = getTeamName(teamId)
			data.teamUsers = res2.users
			return data
		}
		else {
			const res = await api.getDrillSessionStatsForUserDailyRange({ user_id: userId, start_at: startDate, end_at: endDate })
			if (!res.ok) return showError(res)
			const data = returnComputedData(res.days)
			data.name = res.user_name || (state?.user?.name ?? 'Me')
			return data
		}
	}

	const fetchAndSetData = async () => {
		const fetchedData = await fetchData()
		setFetchedData(fetchedData)
	}

	useEffect(() => {
		if (relations) return
		let cancelled = false
		;(async () => {
			setLoadingOverlayText('Loading...')

			const res = await api.getDrillSessionStatsEntities()
			if (!res.ok) {
				setLoadingOverlayText('')
				await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
				return
			}
			if (cancelled) {
				setLoadingOverlayText('')
				return
			}

			setRelations({
				facilities: res.facilities,
				teams: res.teams,
			})

			setLoadingOverlayText('')
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		;(async () => {
			await fetchAndSetData()
		})()
		// eslint-disable-next-line
	}, [location, relations])

	const handleChange = (fieldName) => async ({ target: { value } }) => {
		if (!value) return
		updateQuery(fieldName, value)
	}

	const statsForOptions = [
		<MenuItem key='me' value='me'>{state?.user?.name ?? 'Me'}</MenuItem>
	]

	if (relations) {
		const { facilities, teams } = relations

		if (facilities && facilities.length > 0) {
			statsForOptions.push(<ListSubheader key={'facilities'} style={{ pointerEvents: 'none' }}>Facilities</ListSubheader>)
			for (const facility of facilities) {
				const id = 'facility-' + facility.id
				statsForOptions.push(<MenuItem key={id} value={id}>{facility.name}</MenuItem>)
			}
		}

		if (teams && teams.length > 0) {
			statsForOptions.push(<ListSubheader key={'teams'} style={{ pointerEvents: 'none' }}>Teams</ListSubheader>)
			for (const team of teams) {
				const id = 'team-' + team.id
				statsForOptions.push(<MenuItem key={id} value={id}>{team.name}</MenuItem>)
			}
		}
	}

	const days = fetchedData?.days
	let mainContent
	if (days && days.length > 0) {
		const labels = []
		const makesData = []
		const shotsData = []
		let totalDrillSessions = 0
		let totalMakes = 0
		let totalShots = 0

		for (const day of days) {
			totalDrillSessions += day.total_drill_sessions
			labels.push(dayjs(day.day).format('MM/DD/YY'))
			const makes = day.m ?? 0
			const shots = day.s ?? 0
			makesData.push(makes)
			shotsData.push(shots)
			totalMakes += makes
			totalShots += shots
		}

		mainContent = <Fragment>
			<Stack horizontal={!isMobile}>
				<Paper>
					<Stack horizontal={true}>
						<Typography variant={isMobile ? 'h6': 'h5'} className={classes.makesAndShotsTextKey}>Total Shots</Typography>
						<Typography variant={isMobile ? 'h6': 'h5'} className={classes.makesAndShotsTextValue}>{totalShots}</Typography>
					</Stack>
					<div className={classes.line} />
					<Stack horizontal={true}>
						<Typography variant={isMobile ? 'h6': 'h5'} className={classes.makesAndShotsTextKey}>Total Makes</Typography>
						<Typography variant={isMobile ? 'h6': 'h5'} className={classes.makesAndShotsTextValue}>{totalMakes}</Typography>
					</Stack>
				</Paper>
				<Paper>
					<Stack horizontal={true}>
						<Typography variant={isMobile ? 'h6': 'h5'} className={classes.makesAndShotsTextKey}>Total Sessions</Typography>
						<Typography variant={isMobile ? 'h6': 'h5'} className={classes.makesAndShotsTextValue}>{totalDrillSessions}</Typography>
					</Stack>
					<div className={classes.line} />
					<Stack horizontal={true}>
						<Typography variant={isMobile ? 'h6': 'h5'} className={classes.makesAndShotsTextKey}>Average Sessions Per Day</Typography>
						<Typography variant={isMobile ? 'h6': 'h5'} className={classes.makesAndShotsTextValue}>{Math.round(totalDrillSessions / fetchedData.dayCount)}</Typography>
					</Stack>
				</Paper>
			</Stack>

			{ labels.length > 1 ? <div style={{ width: '100%', height: 300, minHeight: 300, maxHeight: 300 }} >
				<Line data={{
					labels,
					datasets: [
						{ label: 'Makes', data: makesData, backgroundColor: 'rgba(204, 0, 2, 0.75)' },
						{ label: 'Shots', data: shotsData, backgroundColor: 'rgba(50, 102, 204, 0.75)' },
					],
				}} options={{
					responsive: true,
					maintainAspectRatio: false,
					legend: { labels: { fontColor: theme.palette.text.primary } },
					scales: {
						xAxes: [{ ticks: { fontColor: theme.palette.text.primary } }],
						yAxes: [{
							ticks: {
								beginAtZero: true,
								fontColor: theme.palette.text.primary,
								precision: 0,
							}
						}],
					},
				}} />
			</div> : undefined }

			<Select value={selectedStatSection} SelectDisplayProps={{ style: { fontSize: 32, paddingTop: 20, paddingBottom: 0 } }}
				onChange={({ target: { value } }) => setSelectedStatSection(value)} variant={'outlined'}>
				<MenuItem value='overall'>OVERALL</MenuItem>
				<MenuItem value='catch_and_shoot'>CATCH & SHOOT</MenuItem>
				<MenuItem value='off_the_dribble'>OFF THE DRIBBLE</MenuItem>
				<MenuItem value='on_the_move'>ON THE MOVE</MenuItem>
			</Select>

			{getStatSectionForKind(selectedStatSection)}
		</Fragment>
	}

	const facilityUsers = fetchedData?.facilityUsers
	let facilityUsersContent
	if (facilityUsers && facilityUsers.length > 0) {
		facilityUsersContent = <UserStats name='Members' items={facilityUsers} onUpdateQuery={updateQuery} />
	}

	const teamUsers = fetchedData?.teamUsers
	let teamUsersContent
	if (teamUsers && teamUsers.length > 0) {
		teamUsersContent = <UserStats tapsEnabled={true} name='Players' items={teamUsers} onUpdateQuery={updateQuery} />
	}

	let headerContent = fetchedData ? <Stack horizontal={true} wrap>
		<Typography variant={isMobile ? 'h4': 'h3'}>{fetchedData.name}</Typography>
		<Typography variant={isMobile ? 'h5': 'h4'} className={classes.dateRangeText}>
			{fetchedData.dateRangeText} { fetchedData.dayCount === 1 ? '' : `(${fetchedData.dayCount} days)` }
		</Typography>
	</Stack> : undefined

	let content
	if (mainContent || facilityUsersContent || teamUsersContent) {
		content = <Stack>
			{headerContent}
			{mainContent}
			{facilityUsersContent}
			{teamUsersContent}
		</Stack>
	}
	else {
		content = <Stack>
			{headerContent}
			<Typography variant={'h5'}>No Data</Typography>
		</Stack>
	}

	const isCustomDateRangeKind = query.get('range') === 'custom'
	const facilityId = query.get('facility')
	const teamId = query.get('team')
	const userId = query.get('user')
	let statsFor = 'me'
	if (facilityId) statsFor = 'facility-' + facilityId
	else if (teamId) statsFor = 'team-' + teamId

	return <Page alertDialogOptions={alertDialogOptions} loadingText={loadingOverlayText}>
		<Container className={classes.container}>
			<Stack horizontal grow={false} wrap style={{ paddingBottom: theme.spacing(2) }}>
				{ !userId && statsForOptions.length > 1 ? <FormControl>
					<FormHelperText>STATS FOR</FormHelperText>
					<Select value={statsFor} onChange={handleChange('statsFor')}>
						{statsForOptions}
					</Select>
				</FormControl> : undefined }
				<FormControl>
					<FormHelperText>DATE RANGE</FormHelperText>
					<Select value={query.get('range') || 'today'} onChange={handleChange('range')}>
						<MenuItem value='today'>Today</MenuItem>
						<MenuItem value='yesterday'>Yesterday</MenuItem>
						<MenuItem value='this-week'>This Week</MenuItem>
						<MenuItem value='this-month'>This Month</MenuItem>
						<MenuItem value='last-week'>Last Week</MenuItem>
						<MenuItem value='last-month'>Last Month</MenuItem>
						<MenuItem value='career'>Career</MenuItem>
						<MenuItem value='custom'>Custom</MenuItem>
					</Select>
				</FormControl>
				<FormControl style={{ display: isCustomDateRangeKind ? undefined : 'none' }}>
					<FormHelperText>START DATE</FormHelperText>
					<StandardDatePicker defaultValue={query.get('start')} onChange={handleChange('start')} error={errors?.startDate} />
				</FormControl>
				<FormControl style={{ display: isCustomDateRangeKind ? undefined : 'none' }}>
					<FormHelperText>END DATE</FormHelperText>
					<StandardDatePicker defaultValue={query.get('end')} onChange={handleChange('end')} error={errors?.endDate} />
				</FormControl>
			</Stack>
			{ content }
		</Container>
	</Page>
}
