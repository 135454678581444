import Container from '@material-ui/core/Container'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Header from '../Components/Header'
import IconButton from '@material-ui/core/IconButton'
import Page from './Page'
import Paper from '@material-ui/core/Paper'
import React, { useEffect, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import { CategoryLinkKind, CategoryOwnerKind } from '../types'
import { makeStyles } from '@material-ui/core/styles'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => ({
	groupHeader: {
		fontSize: 32,
		fontWeight: 'bold',
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
	},
	itemActions: {
		display: 'flex',
		flexDirection: 'row',
		gap: 8,
		justifyContent: 'end',
		paddingRight: '8px !important',
	},
}))

function Group({ items, name, onClear, onEdit }) {
	const classes = useStyles()

	const body = items.map(({ assignments, count, entityId, kind, name }) => (
		<TableRow key={`${kind}-${entityId}`} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell component='th' scope='row'>{name}</TableCell>
			<TableCell>{count}</TableCell>
			<TableCell align='right' className={classes.itemActions}>
				<Tooltip title='Edit Assignments'>
					<IconButton size='small' onClick={() => onEdit?.(entityId)}><EditIcon /></IconButton>
				</Tooltip>
				<Tooltip title='Clear Assignments'>
					<IconButton size='small' onClick={() => onClear?.(assignments)}><DeleteIcon /></IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	))

	return <div>
		<Header className={classes.groupHeader}>
			<Typography variant='inherit'>{name}</Typography>
		</Header>
		<TableContainer component={Paper}>
			<Table sx={{ minWidth: '100%' }} size="small">
				<TableHead>
					<TableRow>
						<TableCell>Drill</TableCell>
						<TableCell>Assigned Users</TableCell>
						<TableCell align='right'></TableCell>
					</TableRow>
				</TableHead>
				<TableBody>{body}</TableBody>
			</Table>
		</TableContainer>
	</div>
}

export default function DrillAssignmentsPage() {
	const { dispatch, state } = useStore()
	const [ drillViewDialogWasOpen, setDrillViewDialogWasOpen ] = useState(false)
	const [ groups, setGroups ] = useState(null)

	const handleClear = async (assignments) => {
		if (!await state.confirm(dispatch, 'CONFIRM', 'Clear all assigned users?'))
			return

		for (const assignment of assignments)
			assignment.assign = false

		state.showLoading(dispatch)
		const res = await api.patchCategoryLinksAssignments(assignments)

		if (!res.ok) {
			state.hideLoading(dispatch)
			state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}

		const groups = await fetchData()
		state.hideLoading(dispatch)
		if (groups) setGroups(groups)
	}

	const handleEdit = (drillId) => {
		state.viewDrill(dispatch, { drillId })
	}

	const fetchData = async () => {
		state.showLoading(dispatch)
		const res = await api.getCategoryLinksAssignments()
		state.hideLoading(dispatch)

		if (!res.ok) {
			state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}

		const entityNameMap = new Map()
		for (const [entities, categoryLinkKind] of [[res.categories, CategoryLinkKind.category], [res.drills, CategoryLinkKind.drill]]) {
			for (const { id, name } of entities) {
				entityNameMap.set(`${categoryLinkKind}-${id}`, name)
			}
		}

		const groupMap = new Map()
		for (const [items, ownerKind] of [[res.facilities, CategoryOwnerKind.facility], [res.teams, CategoryOwnerKind.team]]) {
			for (const { id: ownerId, name } of items) {
				groupMap.set(`${ownerKind}-${ownerId}`, { count: 0, map: new Map(), name })
			}
		}

		for (const assignment of res.assignments) {
			const { entity_id, kind, owner } = assignment
			if (kind !== CategoryLinkKind.drill) continue
			const group = groupMap.get(`${owner.kind}-${owner.id}`)
			const key = `${kind}-${entity_id}`
			let item = group.map.get(key)
			if (!item) {
				item = { name: entityNameMap.get(key), count: 0, kind, entityId: entity_id, assignments: [] }
				group.map.set(key, item)
			}
			group.count++
			item.count++
			item.assignments.push(assignment)
		}

		const groups = []
		for (const group of groupMap.values()) {
			if (group.count === 0) continue
			groups.push({
				name: group.name,
				items: Array.from(group.map.values()),
			})
		}
		return groups
	}

	useEffect(() => {
		if (state.drillViewDialog.open) {
			setDrillViewDialogWasOpen(true)
			return
		}

		if (!drillViewDialogWasOpen) {
			return
		}
		setDrillViewDialogWasOpen(false)

		let cancelled = false
		;(async () => {
			const groups = await fetchData()
			if (groups && !cancelled) setGroups(groups)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [state.drillViewDialog.open])

	useEffect(() => {
		let cancelled = false
		;(async () => {
			const groups = await fetchData()
			if (groups && !cancelled) setGroups(groups)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [location])

	const content = groups && (() => {
		if (groups.length === 0) {
			return <Typography variant='h5'>No assignments.</Typography>
		}

		return <>
			{groups.map(({ items, name }) => <Group key={name} name={name} items={items} onClear={handleClear} onEdit={handleEdit} />)}
		</>
	})()

	return <Page padding='bottom'>
		<Container>
			<Header>
				<Typography variant='inherit'>Drill Assignments</Typography>
			</Header>
			{content}
		</Container>
	</Page>
}
