import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import HeightPicker from '../Components/HeightPicker'
import InputLabel from '@material-ui/core/InputLabel'
import Page from './Page'
import PageDialog from '../Dialogs/PageDialog'
import React, { useEffect, useState } from 'react'
import Stack from '../Components/Stack'
import StandardTextField from '../Components/StandardTextField'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import { StandardDatePicker } from '../Components/StandardDatePicker'
import { useAlertDialog } from '../Dialogs/AlertDialog'
import { useHistory, useParams } from 'react-router-dom'
import { useStore } from '../StoreProvider'

const recaptchaSiteKey = '6LcsFfsUAAAAAKGdcr6WDmHvWN1FcCDcYIw9JzSd'

export default function SignupPage({ alert }) {
	const history = useHistory()
	const { dispatch } = useStore()
	const { tokenId } = useParams()
	const [ isLoading, setIsLoading ] = useState(false)

	const [alertDialogOptions, setAlertDialogOptions] = useState(null)
	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const [ emailError, setEmailError ] = useState('')
	const [ nameError, setNameError ] = useState('')
	const [ passwordError, setPasswordError ] = useState('')
	const [ usernameError, setUsernameError ] = useState('')
	const [ params, setParams ] = useState({ email: '', name: '', password: '', username: '' })

	const getRecaptchaToken = () => {
		return new Promise((resolve, reject) => {
			// eslint-disable-next-line
			grecaptcha.ready(async () => {
				try {
					// eslint-disable-next-line
					resolve(await grecaptcha.execute(recaptchaSiteKey, { action: 'submit' }))
				}
				catch (e) {
					reject(e)
				}
			})
		})
	}

	useEffect(() => {
		if (document.getElementById('recaptcha-api-script')) {
			const recaptchaBadge = document.querySelector('.grecaptcha-badge')
			if (recaptchaBadge) recaptchaBadge.style.display = ''
		}
		else {
			const node = document.createElement('script')
			node.id = 'recaptcha-api-script'
			node.src = 'https://www.google.com/recaptcha/api.js?render=' + recaptchaSiteKey
			document.head.appendChild(node)
		}

		return () => {
			const recaptchaBadge = document.querySelector('.grecaptcha-badge')
			if (recaptchaBadge) recaptchaBadge.style.display = 'none'
		}
	}, [])

	const handleChange = (fieldName) => (e) => {
		params[fieldName] = e.target.value
		setParams(params)
	}

	const handleLogin = () => {
		history.push('/login' + (tokenId ? ('/' + tokenId) : ''))
	}

	const handleSignup = async (e) => {
		e.preventDefault()

		params.grecaptcha_token = await getRecaptchaToken()

		setIsLoading(true)
		const res = await api.postUser(params)
		setIsLoading(false)

		setEmailError('')
		setNameError('')
		setPasswordError('')
		setUsernameError('')

		if (!res.ok) {
			let msg = res.extractErrorMessage('email')
			if (msg) setEmailError(msg)

			msg = res.extractErrorMessage('name')
			if (msg) setNameError(msg)

			msg = res.extractErrorMessage('username')
			if (msg) setUsernameError(msg)

			msg = res.extractErrorMessage('password')
			if (msg) setPasswordError(msg)

			msg = res.extractRemainingErrorsMessage()
			if (msg) await showAlertDialog('ERROR', msg)

			return
		}

		const token = localStorage.getItem('team_invite_token')
		if (token) {
			localStorage.removeItem('team_invite_token')
			window.location.href = '/team/join/' + token
			return
		}

		dispatch({ type: 'user', user: res.user })
	}

	return <Page alertDialogOptions={alertDialogOptions} loading={isLoading}>
		<PageDialog alert={alert}>
			<Typography variant='h5'>Sign Up</Typography>
			<form onSubmit={handleSignup}>
				<StandardTextField label='Your Full Name' error={nameError} onChange={handleChange('name')} autoFocus />
				<StandardTextField label='Your Email' error={emailError} onChange={handleChange('email')} type='email' />
				<StandardTextField label='Choose a Username' error={usernameError} onChange={handleChange('username')} />
				<StandardTextField label='Choose a Password' error={passwordError} onChange={handleChange('password')} type='password' />
				<Stack horizontal={true}>
					<StandardDatePicker clearable={false} label='DATE OF BIRTH' onChange={handleChange('date_of_birth')} />
					<HeightPicker label='HEIGHT' onChange={handleChange('height')} />
				</Stack>
				<Button type='submit' color='primary' variant='contained'>Sign Up</Button>
			</form>
			<Divider light={true} />
			<InputLabel style={{ marginBottom: 10 }} shrink={true}>Already have an account?</InputLabel>
			<Button color='secondary' variant='contained' onClick={handleLogin}>Log In</Button>
		</PageDialog>
		<div id='recaptcha-badge'></div>
	</Page>
}
