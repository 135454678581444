import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React  from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiDialog-paper': {
			minWidth: 300,
		},
	}
}))

export default function AlertDialog2() {
	const classes = useStyles()
	const history = useHistory()
	const { state, dispatch } = useStore()
	const { open, title, message, okay, resolve } = state.alertDialog

	return <Dialog className={classes.root} maxWidth='xs'
		open={open}
		onClose={() => dispatch({ type: 'alert_dialog.hide' })}
		TransitionProps={{
			onEnter: () => state.pushBackHandler(history),
			onExited: () => {
				state.popBackHandler()
				resolve()
			},
		}}>
		<DialogTitle>{title ?? ''}</DialogTitle>
		<DialogContent>
			<DialogContentText>{message ?? ''}</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={() => { dispatch({ type: 'alert_dialog.hide' }) }}>{okay ?? 'Ok'}</Button>
		</DialogActions>
	</Dialog>
}
