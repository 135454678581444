import IconButton from '@material-ui/core/IconButton'
import ImageIcon from '@material-ui/icons/Image'
import React from 'react'

// need to wrap this in a forward ref so it can be a child of Tooltip
// SEE https://mui.com/material-ui/guides/composition/#caveat-with-refs
export default React.forwardRef(({ acceptTypes, onChange, onInvalidTypeProvided, ...rest }, ref) => {
	const isValidType = (type) => {
		if (!acceptTypes) return true
		if (acceptTypes.includes('audio/*') && type.startsWith('audio/')) return true
		if (acceptTypes.includes('image/*') && type.startsWith('image/')) return true
		if (acceptTypes.includes('video/*') && type.startsWith('video/')) return true
		return acceptTypes.includes(type)
	}

	const handleFileChange = (e) => {
		const file = e.target.files[0]
		if (!file) return
		if (!isValidType(file.type)) {
			// eslint-disable-next-line
			onInvalidTypeProvided?.()
			return
		}
		// eslint-disable-next-line
		onChange?.(file)
	}

	return <IconButton ref={ref} size={'small'} style={{ width: '36px' }} {...rest}>
		<label style={{ display: 'flex', alignItems: 'center' }}>
			<input type='file' accept={acceptTypes.join(',')} onChange={handleFileChange} style={{ display: 'none' }} />
			<ImageIcon style={{ cursor: 'pointer' }} />
		</label>
	</IconButton>
})
