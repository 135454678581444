import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'

export default function StandardTextField({ label, error, type, InputProps, ...rest }) {
	InputProps = InputProps ?? {}

	const [ passwordVisible, setPasswordVisible ] = useState(false)

	if (type === 'password') {
		InputProps.endAdornment = <InputAdornment position='end'>
			<IconButton style={{ marginBottom: 22 }} onClick={() => setPasswordVisible(!passwordVisible)}>
				{passwordVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
			</IconButton>
		</InputAdornment>
	}

	return <TextField
		label={label}
		error={Boolean(error)}
		helperText={error}
		type={type === 'password' && passwordVisible ? 'text' : type}
		InputProps={InputProps}
		{...rest}
	/>
}
