import clsx from 'clsx'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import CircularProgressWithLabel from './CircularProgressWithLabel'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => {
	const h6 = theme.typography.h6
	return {
		root: {
			zIndex: 100000,
			flexDirection: 'column',
			transitionDelay: '500ms !important',
		},
		text: {
			// Backdrop is always dark, so make sure we use a light color
			color: '#fff',
			marginTop: theme.spacing(2),
			// ensure the CircularProgress is always centered
			marginBottom: `calc(-${theme.spacing(2)}px - ${h6.fontSize} * ${h6.lineHeight})`,
		},
		opaque: {
			backgroundColor: theme.palette.background.default,
		},
	}
})

export default function LoadingOverlay2() {
	const classes = useStyles()
	const { state } = useStore()

	const { open, text, opaque, progress } = state.loadingOverlay

	return <Backdrop open={open} className={clsx(classes.root, opaque && classes.opaque)} transitionDuration={opaque ? 0 : undefined}>
		{ typeof progress === typeof 1
			 ? <CircularProgressWithLabel value={progress} />
			 : <CircularProgress />
		}
		<Typography variant='h6' className={classes.text} style={{ display: Boolean(text) ? '' : 'none' }}>{text ?? ''}</Typography>
	</Backdrop>
}
