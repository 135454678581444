import Button from '@material-ui/core/Button'
import Page from './Page'
import PageDialog from '../Dialogs/PageDialog'
import React, { Fragment, useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import { useHistory, useParams } from 'react-router-dom'
import { useStore } from '../StoreProvider'
import Stack from '../Components/Stack'

export default function TeamJoinPage() {
	const history = useHistory()
	const { state } = useStore()
	const { tokenId } = useParams()
	const [ isLoading, setIsLoading ] = useState(true)
	const [ tokenCheckResult, setTokenCheckResult ] = useState(null)
	const [ effectPerformed, setEffectPerformed ] = useState(false)

	useEffect(() => {
		if (!state.sessionChecked || effectPerformed) return
		setEffectPerformed(true)

		if (!state.user) {
			localStorage.setItem('team_invite_token', tokenId)
			setTokenCheckResult({
				title: 'You must be logged in to accept a team invite.',
			})
			setIsLoading(false)
			return
		}

		(async () => {
			const res = await api.postTeamContractAccept(tokenId)
			setIsLoading(false)
			if (!res.ok) {
				setTokenCheckResult({
					title: 'An error occurred while accepting the invite.',
					message: res.extractRemainingErrorsMessage(),
				})
				return
			}
			setTokenCheckResult({
				success: true,
				title: 'Team joined!',
			})
		})()
		// eslint-disable-next-line
	}, [state])

	let buttons
	if (state.user) {
		if (tokenCheckResult && tokenCheckResult.success) {
			buttons = <Button type='submit' color='primary' variant='contained' onClick={_ => history.push('/drills')}>Go to drills</Button>
		}
	}
	else {
		buttons = <Fragment>
			<Stack horizontal={true}>
				<Button type='submit' color='primary' variant='contained' onClick={_ => history.push('/login')}>Log in</Button>
				<Button type='submit' color='primary' variant='contained' onClick={_ => history.push('/signup')}>Sign up</Button>
			</Stack>
		</Fragment>
	}

	return <Page loading={isLoading}>
		<PageDialog style={{ display: tokenCheckResult ? '' : 'none' }}>
			<Typography variant={'h5'}>{tokenCheckResult?.title}</Typography>
			<Typography variant={'body1'}>{tokenCheckResult?.message}</Typography>
			{buttons}
		</PageDialog>
	</Page>
}
