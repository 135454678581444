import dayjs from 'dayjs'

export default {
	// assumes the passed in timestamp is in utc
	date: (value, local) => {
		if (!value) return ''
		value = local ? dayjs(value) : dayjs.utc(value).local()
		return value.format('MM/DD/YYYY')
	},
	memory: (value) => {
		if (!value) return ''
		if (value < 1024) return value.toString()
		if (value < 1024 ** 2) return (value / 1024).toFixed(1) + ' KB'
		if (value < 1024 ** 3) return (value / 1024 ** 2).toFixed(1) + ' MB'
		if (value < 1024 ** 4) return (value / 1024 ** 3).toFixed(1) + ' GB'
		if (value < 1024 ** 5) return (value / 1024 ** 4).toFixed(1) + ' TB'
		return value.toString()
	},
	timestamp: (value) => value ? (dayjs.utc(value).local().format('MM/DD/YYYY hh:mm:ss A')) : '',
	timestampRelative: (value, withoutSuffix) => value ? (dayjs.utc(value).local().fromNow(withoutSuffix)) : '',
}
