import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import React, { Fragment } from 'react'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	title: {
		flexGrow: 1,
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		textAlign: 'center',
		userSelect: 'none',
	},
	body: {
		display: 'flex',
		flexBasis: '0%',
		flexDirection: 'column',
		flexGrow: 1,
		flexShrink: 1,
		height: 'inherit',
		minHeight: 0,
		'& > *': {
			flexGrow: 1,
		},
	},
	paper: {
		margin: 0,
		maxHeight: '100%',
		maxWidth: '100%',
		overflow: 'hidden',
	},
	hiddenIconButton: {
		visibility: 'hidden',
	},
	section: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		minHeight: 0,
	},
}))

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

export function WizardDialog({ children, open, onClose, onExited }) {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const paperProps = { className: classes.paper }

	return <Dialog fullScreen={isMobile} maxWidth={false} open={open} PaperProps={paperProps}
		TransitionComponent={isMobile ? Transition : undefined} TransitionProps={{ onClose, onExited }}>
		{children}
	</Dialog>
}

export function WizardDialogSection({
	children, title,
	previousIcon, previousTooltip, onPrevious,
	nextIcon, nextTooltip, onNext,
}) {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const previousIconClassName = clsx({ [classes.hiddenIconButton]: !onPrevious })
	let previousIconEl = <IconButton className={previousIconClassName} edge={isMobile ? 'start' : undefined} color='inherit' onClick={onPrevious}>
		{ previousIcon ?? <KeyboardArrowLeftIcon /> }
	</IconButton>
	if (onPrevious && previousTooltip)
		previousIconEl = <Tooltip title={previousTooltip} enterDelay={500} placement='right'>{previousIconEl}</Tooltip>

	const nextIconClassName = clsx({ [classes.hiddenIconButton]: !onNext })
	let nextIconEl = <IconButton className={nextIconClassName} edge={isMobile ? 'end' : undefined} color='inherit' onClick={onNext}>
		{ nextIcon ?? <KeyboardArrowRightIcon /> }
	</IconButton>
	if (onNext && nextTooltip)
		nextIconEl = <Tooltip title={nextTooltip} enterDelay={500} placement='left'>{nextIconEl}</Tooltip>

	return <div className={classes.section}>
		<Fragment>
			<AppBar position='relative'>
				<Toolbar variant={isMobile ? 'regular' : 'dense'} disableGutters={!isMobile}>
					{previousIconEl}
					<Typography variant={isMobile ? 'body1' : 'h6'} className={classes.title}>{title}</Typography>
					{nextIconEl}
				</Toolbar>
			</AppBar>
		</Fragment>
		<div className={classes.body}>{children}</div>
	</div>
}