import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		gap: 8,
	},
	slot: {
		alignItems: 'center',
		borderColor: 'white',
		borderRadius: 12,
		borderStyle: 'solid',
		borderWidth: 2,
		display: 'flex',
		height: 24,
		justifyContent: 'center',
		lineHeight: 24,
		userSelect: 'none',
		width: 24,
	},
}))

export default function DrillPlayerSlots({ count }) {
	const classes = useStyles()

	if (count <= 1) return <div></div>

	return <div className={classes.root}>
		{new Array(count).fill(0).map((_, i) => {
			const borderColor = window.courtLib.colors.PLAYERS[i]
			return <div key={i} className={classes.slot} style={{ borderColor }}>{i + 1}</div>
		})}
	</div>
}
