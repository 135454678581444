import dayjs from 'dayjs'

export function getFeaturedDateLabel(startAt, endAt) {
	if (!endAt) {
		return `From ${dayjs(startAt).format('MMMM D')}`
	}

	// NOTE end_at is always exactly 24 hours ahead of the start of the selected end_at day
	//      therefore, until we let them select time as well, we can simply subtract one minute to get the correct day
	endAt = dayjs(endAt).subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss')
	if (dayjs(startAt).isSame(endAt, 'day')) {
		return `${dayjs(startAt).format('MMMM D')}`
	}

	if (dayjs(startAt).isSame(endAt, 'month')) {
		return `${dayjs(startAt).format('MMMM D')} - ${dayjs(endAt).format('D')}`
	}

	return `${dayjs(startAt).format('MMMM D')} - ${dayjs(endAt).format('MMMM D')}`
}

export async function openTvPage(options) {
	const origin = document.location.hostname === 'localhost' ? 'http://localhost:8080' : document.location.origin
	options = Object.entries(options).filter(([k, v]) => !!v).map(([k, v]) => {
		return typeof v === 'boolean' ? k : `${k}=${v}`
	}).join('&')
	const win = await window.open(`${origin}/tv/#${options}`)
	return { origin, window: win }
}
