import { makeStyles, useTheme } from '@material-ui/core/styles'

import Alert from '@material-ui/lab/Alert'
import Dialog from '@material-ui/core/Dialog'
import React from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const useStyles = makeStyles((theme) => {
	const styles = {
		alert: {
			marginLeft: -10,
			marginRight: -10,
			marginTop: -10,
		},
		root: {
			pointerEvents: 'none',
			'& form': {
				display: 'flex',
				flexDirection: 'column',
				'& >*:not(:last-child)': {
					marginBottom: 20,
				},
			},
			'& .MuiBackdrop-root': {
				display: 'none',
			},
			'& .MuiDialog-paper': {
				padding: 32,
				width: 400,
				pointerEvents: 'all',
				'& >*:not(:last-child)': {
					marginBottom: 20,
				},
			},
		},
	}

	styles[theme.breakpoints.down('xs')] = {
		alert: {
			marginLeft: -12,
			marginRight: -12,
			marginTop: -12,
		},
		root: {
			'& .MuiDialog-paper': {
				padding: 24,
				width: '100%',
			},
		},
	}

	return styles
})

export default function PageDialog({ alert, children, ...rest }) {
	const classes = useStyles()
	const theme = useTheme()
	const fullScreen = useMediaQuery(theme.breakpoints.down('xs'))

	return <Dialog fullScreen={fullScreen} open transitionDuration={0} className={classes.root} {...rest}>
		{ alert ? <Alert severity='info' icon={false} className={classes.alert}>{alert}</Alert> : null }
		{children}
	</Dialog>
}
