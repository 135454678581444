import * as utils from '../utils'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CheckboxGroupList from '../Components/CheckboxGroupList'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import React, { useEffect, useState } from 'react'
import SaveIcon from '@material-ui/icons/Save'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import { CategoryLinkKind, CategoryOwnerKind } from '../types'
import { useDebounce } from 'use-debounce'
import { useStore } from '../StoreProvider'

const MAX_ASSIGNMENT_USERS_PER_GROUP = 30

// TODO probably just merge CheckboxGroupList into this
// TODO needs cleanup and probably some performance improvements for large lists of users
export default function AssignedDrillsList({ drill }) {
	const [ assignmentState, setAssignmentState ] = useState(null)
	const [ filter, setFilter ] = useState('')
	const [ filterDebounced ] = useDebounce(filter, 250)
	const { state, dispatch } = useStore()

	const getChangedAssignments = () => {
		const { groups, currentAssignmentsSet } = assignmentState
		// merge all the groups into one list of updated assignments
		const assignments = []
		for (const { items } of groups) {
			for (const { data } of items) {
				const key = `${data.owner.kind}-${data.owner.id}-${data.user_id}`
				const alreadyAssigned = currentAssignmentsSet.has(key)
				if (data.assign && alreadyAssigned) continue
				if (!data.assign && !alreadyAssigned) continue
				assignments.push(data)
			}
		}
		return assignments
	}

	const getFilteredGroups = () => {
		const filteredGroups = []
		for (const group of assignmentState.groups) {
			const items = []
			let count = 0
			let truncated = false
			for (const item of group.items) {
				if (item.name.toLowerCase().includes(filter)) {
					if (!group.showAll && count >= MAX_ASSIGNMENT_USERS_PER_GROUP) {
						truncated = true
						break
					}
					items.push(item)
					count++
				}
			}
			if (items.length > 0) {
				filteredGroups.push({ group, name: group.name, items, totalItems: group.items.length, truncated })
			}
		}
		return filteredGroups
	}

	const handleAssignmentToggled = (assignment, checked) => {
		assignment.assign = checked
		setAssignmentState({ ...assignmentState, changed: getChangedAssignments().length > 0 })
	}

	const handleShowAllAssignments = (group) => {
		group.group.showAll = true
		setAssignmentState({ ...assignmentState, filteredGroups: getFilteredGroups() })
	}

	const handleUpdateAssignmentsClick = async () => {
		const assignments = getChangedAssignments()

		state.showLoading(dispatch)
		const res = await api.patchCategoryLinksAssignments(assignments)
		state.hideLoading(dispatch)

		if (!res.ok) {
			state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}

		// keep the currentAssignmentsSet up to date
		for (const { assign, owner, user_id } of assignments) {
			const key = `${owner.kind}-${owner.id}-${user_id}`
			assignmentState.currentAssignmentsSet[assign ? 'add' : 'delete'](key)
		}
		setAssignmentState({ ...assignmentState, changed: false })
	}

	useEffect(() => {
		if (!drill) return

		return utils.withCancel(async (cancelled) => {
			const res = await api.getCategoryLinksAssignments({ entity_id: drill.id, kind: CategoryLinkKind.drill })

			if (!res.ok) {
				state.showError(dispatch, res.extractRemainingErrorsMessage())
				return
			}

			if (cancelled()) return

			const currentAssignmentsSet = new Set()
			for (const { user_id, entity_id, kind, owner } of res.assignments) {
				if (kind !== CategoryLinkKind.drill) continue
				if (entity_id !== drill.id) continue
				const key = `${owner.kind}-${owner.id}-${user_id}`
				currentAssignmentsSet.add(key)
			}

			const usersMap = new Map()
			for (const user of res.users) {
				usersMap.set(user.id, user)
			}

			const groups = []

			for (const [items, ownerKind] of [[res.facilities, CategoryOwnerKind.facility], [res.teams, CategoryOwnerKind.team]]) {
				for (const { id: ownerId, name, user_ids } of items) {
					if (user_ids.length === 0) continue
					const owner = { id: ownerId, kind: ownerKind }
					const group = { name, items: [], showAll: false }
					for (const userId of user_ids) {
						const user = usersMap.get(userId)
						const data = {
							assign: currentAssignmentsSet.has(`${ownerKind}-${ownerId}-${userId}`),
							user_id: userId,
							entity_id: drill.id,
							kind: CategoryLinkKind.drill,
							owner,
						}
						group.items.push({ data, name: user.name })
					}
					group.items.sort((a, b) => a.name.localeCompare(b.name))
					groups.push(group)
				}
			}

			const filteredGroups = []
			for (const group of groups) {
				const items = group.items.slice(0, MAX_ASSIGNMENT_USERS_PER_GROUP)
				filteredGroups.push({ group, name: group.name, items, totalItems: group.items.length, truncated: group.items.length > MAX_ASSIGNMENT_USERS_PER_GROUP })
			}

			setAssignmentState({ changed: false, groups, filteredGroups, currentAssignmentsSet })
		})
		// eslint-disable-next-line
	}, [drill])

	useEffect(() => {
		if (!assignmentState) return
		setAssignmentState({ ...assignmentState, filteredGroups: getFilteredGroups() })
		// eslint-disable-next-line
	}, [filterDebounced])

	if (!assignmentState)
		return <></>

	return <Card variant='outlined'>
		<CardContent style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
			<div style={{ position: 'relative', marginBottom: 8, marginTop: 16 }}>
				<Typography variant='h6' style={{ paddingLeft: 16 }}>Assignments</Typography>
				{assignmentState.changed && <Tooltip title='Update Assignments'>
					<IconButton style={{ position: 'absolute', right: -3 + 8, top: -6 }} color='inherit'
						onClick={handleUpdateAssignmentsClick}><SaveIcon /></IconButton>
				</Tooltip> }
			</div>
			<Input style={{ marginLeft: 16, marginRight: 16, marginBottom: 16 }} value={filter}
				placeholder={'Filter users...'} onChange={e => setFilter((e?.target?.value ?? '').toLowerCase())}
				endAdornment={ Boolean(filter) ? <InputAdornment position='end'>
					<IconButton size={'small'} onClick={() => setFilter('')}><ClearIcon /></IconButton>
				</InputAdornment> : undefined }>
			</Input>
			<CheckboxGroupList checkedField='assign' keyField='user_id' groups={assignmentState.filteredGroups}
				onToggle={handleAssignmentToggled} onShowAll={handleShowAllAssignments} />
		</CardContent>
	</Card>
}
