import * as dayjs from 'dayjs'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useEffect, useState } from 'react'
import Select from '@material-ui/core/Select'
import Stack from '../Components/Stack'
import StandardDialog from './StandardDialog'
import StandardTextField from '../Components/StandardTextField'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import { StandardDatePicker } from '../Components/StandardDatePicker'
import { useStore } from '../StoreProvider'

const getDefaultParams = (facilities) => ({
	facilityId: facilities[0]?.id,
	startedAt: dayjs().startOf('day'),
	makes: 1,
})

export default function AddStatsDialog() {
	const { state, dispatch } = useStore()
	const { open, facilities, userId, userName } = state.addStatsDialog
	const [ params, setParams ] = useState(getDefaultParams(facilities))

	const handleChange = (fieldName) => ({ target: { value } }) => {
		const updates = {}
		if (fieldName === 'makes') {
			const newValue = parseInt(value)
			if (!isNaN(newValue)) {
				value = Math.max(1, newValue)
			}
		}
		updates[fieldName] = value
		setParams({ ...params, ...updates })
	}

	const hide = () => {
		dispatch({ type: 'add_stats_dialog.hide' })
	}

	const handleSave = async () => {
		state.showLoading(dispatch)
		const res = await api.postDrillSession({
			facility_id: params.facilityId,
			makes: params.makes,
			started_at: dayjs(params.startedAt).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
			user_id: userId,
		})
		state.hideLoading(dispatch)

		if (!res.ok) {
			await state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}

		hide()
	}

	useEffect(() => {
		setParams(getDefaultParams(facilities))
		// eslint-disable-next-line
	}, [state.addStatsDialog])

	return <StandardDialog title='Add Stats' open={open} onCancel={hide} onComplete={handleSave}>
		<Stack medium>
			{ facilities.length > 1 && <FormControl>
				<FormHelperText>Facility</FormHelperText>
				<Select value={params.ownerId} onChange={handleChange('facilityId')}>
					{facilities.map(i => <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>)}
				</Select>
			</FormControl> }
			<FormControl>
				<FormHelperText>Player</FormHelperText>
				<Typography variant='body1'>{userName}</Typography>
			</FormControl>
			<Stack horizontal medium>
				<StandardDatePicker label='Date' clearable={false} defaultValue={params.startedAt} onChange={handleChange('startedAt')} />
				<StandardTextField label='Makes' style={{ maxWidth: 124 }} type='number' value={params.makes} onChange={handleChange('makes')} />
			</Stack>
		</Stack>
	</StandardDialog>
}
