import React, { useEffect, useImperativeHandle, useState } from 'react'

export default function Court({
	data,
	interactive,
	mode,
	stats,
	responsive,
	showTotalsGradient,
	showTotalsLabels,
	showTotalsStatChart,

	onLocationAdded,
	onLocationCountChanged,
	onLocationSelected,
	onLocationsChanged,

	children,
	passedInRef,
	...rest
}) {
	const [ court, setCourt ] = useState(null)

	useImperativeHandle(passedInRef, () => ({
		locations: {
			add(location, index) { court.locations.add(location, index) },
			clear() { court.locations.clear(l => l.kind !== window.courtLib.types.LocationKind.globalBallAction) },
			contains(location) { return court.locations.contains(location) },
			convert(options, location) { court.locations.convert(options, location) },
			draw(location, state) { court.locations.draw(location, state) },
			getVisualIndex(location) { return court.locations.getVisualIndex(location) },
			last() { return court.locations.last() },
			remove(location) { court.locations.remove(location) },
			select(location = undefined) { court.locations.select(location) },
			selected() { return court.locations.selected() },
			shift(backwards) { court.locations.shift(backwards) },
		},
		statChart: {
			hide(x, y) {
				court.statChart.show(false)
				if (x && y) court.statChart.setPosition(x, y)
			},
			show(x, y, m, s) {
				court.statChart.show(true)
				court.statChart.setPosition(x, y)
				court.statChart.setValue({ s, m })
			},
		},
	}))

	useEffect(() => {
		if (court) return

		const c = new window.courtLib.Court()
		c.setBackgroundImage(window.courtLib.images.background)
		if (interactive) c.interactive()
		if (responsive) c.responsive()
		c.setMode(mode ?? 'shoot-now')

		setCourt(c)
		// eslint-disable-next-line
	}, [court, data])

	useEffect(() => {
		if (!court || !data) return

		requestAnimationFrame(() => {
			court.locations.set(data.locations)

			court.locations.on('added', (location) => {
				if (onLocationAdded) onLocationAdded(location)
			})

			court.locations.on('changed', (_location) => {
				if (onLocationsChanged) onLocationsChanged()
				if (onLocationCountChanged) onLocationCountChanged()
			})

			court.locations.on('selected', (location) => {
				if (onLocationSelected) onLocationSelected(location)
			})
		})

		// HACK this is an easy way to get the parent to update the ui based on the ball count
		// (DrillEditDialog uses this to determine if it should show the CLEAR LOCATIONS button)
		// if (onLocationCountChanged) onLocationCountChanged()

		// eslint-disable-next-line
	}, [court, data])

	useEffect(() => {
		if (court) court.setStats(stats ?? { highestStreak: 0, zones: {} })
		// eslint-disable-next-line
	}, [court, stats])

	useEffect(() => {
		if (court) court.showTotalsStatChart(showTotalsStatChart ?? false)
		// eslint-disable-next-line
	}, [court, showTotalsStatChart])

	useEffect(() => {
		if (court) court.showTotalsLabels(showTotalsLabels ?? false)
		// eslint-disable-next-line
	}, [court, showTotalsLabels])

	useEffect(() => {
		if (court) court.showTotalsGradient(showTotalsGradient ?? false, 0.8)
		// eslint-disable-next-line
	}, [court, showTotalsGradient])

	return <div {...rest} ref={ref => {
		if (ref && court) return ref.prepend(court)
	}}>{children}</div>
}
