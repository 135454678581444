import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import InputLabel from '@material-ui/core/InputLabel'
import Link from '@material-ui/core/Link'
import Page from './Page'
import PageDialog from '../Dialogs/PageDialog'
import React, { useEffect, useState } from 'react'
import StandardTextField from '../Components/StandardTextField'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import { useAlertDialog } from '../Dialogs/AlertDialog'
import { useConfirmationDialog } from '../Dialogs/ConfirmationDialog'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useStore } from '../StoreProvider'

export default function LoginPage({ alert }) {
	const history = useHistory()
	const location = useLocation()
	const { dispatch } = useStore()

	const { tokenId } = useParams()
	const [ isLoading, setIsLoading ] = useState(false)
	const [ locationAlert, setLocationAlert ] = useState(null)

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const [ confirmationDialogOptions, setConfirmationDialogOptions ] = useState(null)
	const showConfirmationDialog = useConfirmationDialog(setConfirmationDialogOptions)

	const [ identifierError, setIdentifierError ] = useState('')
	const [ passwordError, setPasswordError ] = useState('')
	const [ params, setParams ] = useState({ identifier: '', password: '' })

	// get and clear the location.state.alert field
	// refreshing the page will clear this alert
	useEffect(() => {
		if (!location.state) return

		const alert = location.state.alert
		if (alert) {
			const state = { ...history.location.state }
			delete state.alert
			delete state.signupEmail
			history.replace({ ...history.location, state })
			setLocationAlert(alert)
		}

		const from = location.state.from
		if (from) {
			const state = { ...history.location.state }
			delete state.from
			history.replace({ ...history.location, state })
			dispatch({ type: 'redirectToAfterLogin', to: from })
		}
		// eslint-disable-next-line
	}, [])

	const handleChange = (fieldName) => (e) => {
		params[fieldName] = e.target.value
		setParams(params)
	}

	const handleForgotPassword = () => {
		history.push('/account/recover', { identifier: params.identifier })
	}

	const handleLogin = async (e) => {
		e.preventDefault()

		setIsLoading(true)
		const res = await api.postSession(params)
		setIsLoading(false)

		setIdentifierError('')
		setPasswordError('')

		if (!res.ok) {
			let msg = res.extractErrorMessage('identifier')
			if (msg) setIdentifierError(msg)

			msg = res.extractErrorMessage('password')
			if (msg) setPasswordError(msg)

			msg = res.extractErrorMessageForReason('email_needs_verified')
			if (msg) {
				if (!await showConfirmationDialog('Login Failed', msg, 'Ok', 'Resend Email')) {
					setIsLoading(true)
					const res = await api.postUserVerifyResend({ identifier: params.identifier })
					setIsLoading(false)
					if (!res.ok) {
						let msg = res.extractRemainingErrorsMessage()
						if (msg) await showAlertDialog('ERROR', msg)
					}
				}
			}

			msg = res.extractRemainingErrorsMessage()
			if (msg) await showAlertDialog('ERROR', msg)

			return
		}

		const token = localStorage.getItem('team_invite_token')
		if (token) {
			localStorage.removeItem('team_invite_token')
			window.location.href = '/team/join/' + token
			return
		}

		dispatch({ type: 'user', user: res.user })
	}

	const handleSignup = () => {
		history.push('/signup' + (tokenId ? ('/' + tokenId) : ''))
	}

	if (locationAlert) alert = locationAlert

	return <Page alertDialogOptions={alertDialogOptions} confirmationDialogOptions={confirmationDialogOptions} loading={isLoading}>
		<PageDialog alert={alert}>
			<Typography variant='h5'>Log In</Typography>
			<form onSubmit={handleLogin}>
				<StandardTextField label='Username or Email' error={identifierError} onChange={handleChange('identifier')} autoFocus />
				<StandardTextField label='Password' error={passwordError} onChange={handleChange('password')} type='password' />
				<Button type='submit' color='primary' variant='contained'>Log In</Button>
			</form>
			<Link component='button' color='secondary' style={{ textAlign: 'left' }} onClick={handleForgotPassword}>Forgot your password?</Link>
			<Divider light={true} />
			<InputLabel style={{ marginBottom: 10 }} shrink={true}>New to the Gun?</InputLabel>
			<Button color='secondary' variant='contained' onClick={handleSignup}>Sign Up</Button>
		</PageDialog>
	</Page>
}
