import {
	DefaultTvLeaderboardSections,
	FileKinds,
	FileLinkKind,
	FileLinksEntityKind,
	TvLeaderboardEntityKind,
	TvLeaderboardSectionFileLinkKinds,
	TvLeaderboardSections,
	TvLeaderboardSectionNames,
	TvLeaderboardSectionsPalette,
	TvMode,
} from '../types'

import * as shared from '../shared'
import * as utils from '../utils'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import EditDialog from './EditDialog'
import EditIcon from '@material-ui/icons/Edit'
import FileUploadIcon from '../Components/FileUploadIcon'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import ListSubheader from '@material-ui/core/ListSubheader'
import MenuItem from '@material-ui/core/MenuItem'
import OptionsButton from '../Components/OptionsButton'
import React, { Fragment, useEffect, useState } from 'react'
import Select from '@material-ui/core/Select'
import Stack from '../Components/Stack'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import TvCombinedLeaderboardEditorDialog from './TvCombinedLeaderboardEditorDialog'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import clsx from 'clsx'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { ColorPicker } from 'material-ui-color'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { getEditSessionGetterAndSetter } from '../EditSession'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useStore } from '../StoreProvider'

const leaderboardEntityKindsFromString = {
	facility: TvLeaderboardEntityKind.facility,
	team: TvLeaderboardEntityKind.team,
}

const leaderboardEntityKindsToString = {
	[TvLeaderboardEntityKind.facility]: 'facility',
	[TvLeaderboardEntityKind.team]: 'team',
}

const useStyles = makeStyles((theme) => {
	const styles = {
		root: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			minWidth: 350,
			padding: theme.spacing(2),
			gap: theme.spacing(2),
		},
		leaderboardLogo: {
			marginTop: '32px',
			maxHeight: '185px',
			maxWidth: '185px',
		},
		leaderboardLogoUploadIcon: {
			position: 'absolute',
			top: -8,
			right: -9,
			height: 36,
		},
		list: {
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
			height: '55vh',
			overflowY: 'auto',
			padding: theme.spacing(0.5),
			paddingTop: 0,
			width: '360px',
		},
		listItem: {
			alignItems: 'center',
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
			display: 'flex',
			flexDirection: 'row',
			marginTop: theme.spacing(0.5),
			paddingRight: theme.spacing(0.5),
		},
		listItemName: {
			lineHeight: '1.3',
			maxHeight: 46,
			maxWidth: 170,
			paddingLeft: 10,
			paddingTop: 6,
		},
		listItemDragging: {
			backgroundColor: 'rgba(0, 0, 0, 0.2)',
		},
		listItemDisabled: {
			opacity: 0.5,
		},
		listItemImage: {
			objectFit: 'cover',
			width: 16 * 10,
			height: 9 * 10,
		},
		listItemImageLoading: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	}

	styles[theme.breakpoints.down('xs')] = {
		root: {
			maxWidth: '100%',
			minWidth: 0,
			overflowY: 'auto',
		},
		list: {
			overflowY: 'visible',
			height: 'auto',
			width: 'auto',
		},
	}

	return styles
})

function AdvancedDisplay({ generalContent, get, set, guns }) {
	const classes = useStyles()
	const selectedGunId = get('config_advanced_display_gun_id') || ''
	const setGunId = set('config_advanced_display_gun_id', { updatesEditSession: true })

	return <div className={classes.root}>
		<Stack style={{ overflow: 'auto' }}>
			{generalContent}
			<Stack spacing={false}>
				<Typography variant={'h6'}>Config</Typography>
				<Typography variant={'body1'}>Gives a gun an additional screen to show info on.</Typography>
			</Stack>
			<FormControl>
				<InputLabel>Gun</InputLabel>
				<Select value={selectedGunId} onChange={({ target: { value } }) => {
					if (!value) return
					setGunId({ target: { value: parseInt(value) } })
				}}>{(guns ?? []).map(({ id, name }) =>
					<MenuItem key={id} value={id}>{name}</MenuItem>
				)}</Select>
			</FormControl>
		</Stack>
	</div>
}

function Leaderboard({ generalContent, get, set, facilities, teams, editSession }) {
	const addSectionOptions = Object.entries(TvLeaderboardSectionNames)
		.filter(([ id ]) => id !== TvLeaderboardSections.featured_drills.toString())
		.map(([ id, name ]) => ({ id, name }))
	const classes = useStyles()
	const entityId = get('config_leaderboard_entity_id')
	const entityKind = get('config_leaderboard_entity_kind')
	const hideDeletedUsers = get('config_leaderboard_hide_deleted_users')
	const sections = get('config_leaderboard_sections', utils.clone(DefaultTvLeaderboardSections))
		.filter(({ enabled }) => enabled)
	const selectedId = (entityId && entityKind) ? (leaderboardEntityKindsToString[entityKind] + '-' + entityId) : ''
	const setEntityId = set('config_leaderboard_entity_id', { updatesEditSession: true })
	const setEntityKind = set('config_leaderboard_entity_kind', { updatesEditSession: true })
	const setHideDeletedUsers = set('config_leaderboard_hide_deleted_users', { isCheckbox: true, updatesEditSession: true })
	const setSections = set('config_leaderboard_sections', { updatesEditSession: true })
	const theme = useTheme()
	const tvId = editSession.data.id
	const [ currentCombinedSection, setCurrentCombinedSection ] = useState(null)
	const [ loadingFileLinks, setLoadingFileLinks ] = useState(true)

	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

	const { state, dispatch } = useStore()

	const [ oldFileLinks, setOldFileLinks ] = useState({})
	const [ newFileLinks, setNewFileLinks ] = useState({})
	const [ mergedFileLinks, setMergedFileLinks ] = useState({})
	const [ previewWindow, setPreviewWindow ] = useState(null)

	const handleAddSectionClick = ({ target: { value: id } }) => {
		id = parseInt(id)
		const newSection = utils.clone(DefaultTvLeaderboardSections.find(i => i.id === id))
		newSection.enabled = true
		setSections({ target: { value: sections.slice().concat([newSection]) } })
	}

	const handleEditCombinedSection = (section) => {
		setCurrentCombinedSection(section)
	}

	const handleEditCombinedSectionCancel = () => {
		setCurrentCombinedSection(null)
	}

	const handleEditCombinedSectionComplete = (newSections) => {
		currentCombinedSection.sections = newSections
		setCurrentCombinedSection(null)
		setSections({ target: { value: sections.slice() } })
	}

	const handleSectionImageChange = (fileLinkKind) => async (file) => {
		const fileId = await uploadImage(file)
		setNewFileLinks({
			...newFileLinks,
			[fileLinkKind]: {
				kind: fileLinkKind,
				entity_id: tvId,
				entity_kind: FileLinksEntityKind.tv,
				file,
				file_id: fileId,
			},
		})
	}

	const uploadImage = async (file) => {
		state.showLoading(dispatch, 'Uploading Image...')
		const res = await api.uploadFile(file, FileKinds.leaderboard_section_background)
		state.hideLoading(dispatch)
		if (!res.ok) {
			await state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}
		return res.id
	}

	const handleRemoveSection = (index) => () => {
		setSections({ target: { value: sections.filter((_, i) => i !== index) } })
	}

	const handleDragEnd = (result) => {
		const { source, destination } = result
		if (!destination) return
		const items = Array.from(sections)
		const [removed] = items.splice(source.index, 1)
		items.splice(destination.index, 0, removed)
		setSections({ target: { value: items } })
	}

	const handleSetSectionColor = (section) => (e) => {
		section.color = e.css.backgroundColor
		setSections({ target: { value: sections.slice() } })
	}

	const handleImgDragOver = (e) => {
		e.preventDefault()
	}

	const handleImgDrop = (fileLinkKind) => async (e) => {
		e.preventDefault()
		if (!e.dataTransfer.items) return
		const item = Array.from(e.dataTransfer.items).filter(item => item.kind === 'file')[0]
		if (!item) return
		const file = item.getAsFile()
		if (!file.type.startsWith('image/')) return
		const fileId = await uploadImage(file)
		setNewFileLinks({
			...newFileLinks,
			[fileLinkKind]: {
				kind: fileLinkKind,
				entity_id: tvId,
				entity_kind: FileLinksEntityKind.tv,
				file,
				file_id: fileId,
			},
		})
	}

	const handleTogglePreviewWindow = async () => {
		if (previewWindow) {
			previewWindow.window.close()
			return
		}

		let newPreviewWindow

		const onBeforeUnload = ({ data, source }) => {
			if (!newPreviewWindow) return
			newPreviewWindow.window.close()
		}
		const onMessage = ({ data, source }) => {
			if (!newPreviewWindow) return
			if (source !== newPreviewWindow.window) return
			switch (data.command) {
				case 'ready':
					setPreviewWindow(newPreviewWindow)
					break
				default:
			}
		}

		window.addEventListener('beforeunload', onBeforeUnload, false)
		window.addEventListener('message', onMessage, false)

		newPreviewWindow = await shared.openTvPage({ token: editSession.data.token, preview: true })
		const interval = setInterval(() => {
			if (!newPreviewWindow.window.closed) return
			clearInterval(interval)
			window.removeEventListener('beforeunload', onBeforeUnload)
			window.removeEventListener('message', onMessage)
			setPreviewWindow(null)
		}, 1000)
	}

	const sendConfigToPreviewWindow = () => {
		const {
			id,
			config_info_message,
			config_info_title,
			config_leaderboard_entity_id,
			config_leaderboard_entity_kind,
            config_leaderboard_hide_deleted_users,
		} = editSession.data

		const file_links = []
		for (const fileLink of Object.values(mergedFileLinks)) {
			file_links.push({
				entity_kind: 'tv',
				entity_id: id,
				file_id: fileLink.file_id,
				kind: fileLink.kind,
			})
		}

		previewWindow.window.postMessage({
			command: 'preview',
			config: {
				tv: {
					config_info_message,
					config_info_title,
					config_leaderboard_entity_id,
					config_leaderboard_entity_kind,
                    config_leaderboard_hide_deleted_users,
					config_leaderboard_sections: sections,
				},
				file_links,
			},
		}, previewWindow.origin)
	}

	useEffect(() => {
		const merged = {}
		for (const [fileLinkKind, fileLink] of Object.entries(oldFileLinks))
			merged[fileLinkKind] = fileLink
		for (const [fileLinkKind, fileLink] of Object.entries(newFileLinks))
			merged[fileLinkKind] = fileLink
		setMergedFileLinks(merged)
		editSession.data.newFileLinks = newFileLinks
		// eslint-disable-next-line
	}, [oldFileLinks, newFileLinks])

	useEffect(() => {
		if (!editSession || !editSession.active) {
			if (previewWindow) {
				previewWindow.window.close()
			}
		}
		// eslint-disable-next-line
	}, [editSession])

	useEffect(() => {
		if (!previewWindow) return
		sendConfigToPreviewWindow()
		// eslint-disable-next-line
	}, [editSession, mergedFileLinks, previewWindow])

	useEffect(() => {
		;(async () => {
			setLoadingFileLinks(true)
			state.showLoading(dispatch)
			const res = await api.getFilesLinks({ entity_id: tvId, entity_kind: 'tv' })
			state.hideLoading(dispatch)
			if (!res.ok) {
				await state.showError(dispatch, res.extractRemainingErrorsMessage())
				return
			}
			const links = {}
			for (const item of res.links)
				links[parseInt(item.kind)] = item
			setOldFileLinks(links)
			setLoadingFileLinks(false)
		})()
		// eslint-disable-next-line
	}, [])

	facilities = facilities?.slice() ?? []
	teams = teams?.slice() ?? []

	if (facilities.length > 0 && teams.length > 0) {
		facilities.unshift({ id: 'header' })
		teams.unshift({ id: 'header' })
	}

	const entityOptions = facilities.map(({ id, name }) => {
		if (id === 'header')
			return <ListSubheader key={'facilities'} style={{ pointerEvents: 'none' }}>Facilities</ListSubheader>
		id = 'facility-' + id
		return <MenuItem key={id} value={id}>{name}</MenuItem>
	}).concat(teams.map(({ id, name }) => {
		if (id === 'header')
			return <ListSubheader key={'teams'} style={{ pointerEvents: 'none' }}>Teams</ListSubheader>
		id = 'team-' + id
		return <MenuItem key={id} value={id}>{name}</MenuItem>
	}))

	const droppable = <Droppable droppableId='ordered'>{(provided, _) =>
		<div {...provided.droppableProps} ref={provided.innerRef} className={classes.list} style={{ flexGrow: isMobile ? 0 : 1 }}>
			{sections.map((section, index) => {
				const { id, color, enabled } = section
				const key = id.toString() + "-" + index
				const fileLinkKind = TvLeaderboardSectionFileLinkKinds[id]
				const fileLink = mergedFileLinks[fileLinkKind]
				const imageEl = loadingFileLinks || !fileLink
					? <div className={clsx(classes.listItemImage, classes.listItemImageLoading)}>Loading...</div>
					: <img alt='' src={`/files/${fileLink.file_id}`} className={classes.listItemImage} onDrop={handleImgDrop(fileLinkKind)} onDragOver={handleImgDragOver} />
				return <Draggable key={key} draggableId={key} index={index}>
					{({ dragHandleProps, draggableProps, innerRef }, snapshot) => {
						const className = clsx(classes.listItem, snapshot.isDragging && classes.listItemDragging, !enabled && classes.listItemDisabled)
						const content = <Box style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
							<Typography variant='subtitle1' className={classes.listItemName}>{TvLeaderboardSectionNames[id]}</Typography>
							<Box style={{ display: 'flex', flexDirection: 'row', marginTop: 'auto', padding: 4, paddingRight: 0 }}>
								<Tooltip title='Change Color'>
									<div>
										<ColorPicker deferred disableAlpha hideTextfield
											palette={TvLeaderboardSectionsPalette} value={color}
											onChange={handleSetSectionColor(section)} />
									</div>
								</Tooltip>
								<Tooltip title='Upload New Background Image'>
									<FileUploadIcon acceptTypes={['image/*']} onChange={handleSectionImageChange(fileLinkKind)} />
								</Tooltip>
								{ id === TvLeaderboardSections.combined && <Tooltip title='Change Sections'>
									<IconButton size={'small'} style={{ width: '36px' }} onClick={() => handleEditCombinedSection(section)}>
										<EditIcon />
									</IconButton>
								</Tooltip> }
								<Tooltip title='Remove Section'>
									<IconButton size={'small'} style={{ marginLeft: 'auto', width: '36px' }} onClick={handleRemoveSection(index)}>
										<DeleteOutlinedIcon />
									</IconButton>
								</Tooltip>
							</Box>
						</Box>
						return <div ref={innerRef} {...draggableProps} {...dragHandleProps} className={className}>
							<Box style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
								{imageEl}
								{content}
							</Box>
						</div>
					}}
				</Draggable>
			})}
			{provided.placeholder}
		</div>
	}</Droppable>

	const logoFileLink = mergedFileLinks[FileLinkKind.tv_logo]
	const logoSrc = logoFileLink ? `/files/${logoFileLink.file_id}` : '/tv/images/logo.png'

	return <div className={classes.root} style={{ flexDirection: isMobile ? 'column' : 'row' }}>
		<TvCombinedLeaderboardEditorDialog entityKind={entityKind} open={Boolean(currentCombinedSection)} sections={currentCombinedSection?.sections ?? []}
			onCancel={handleEditCombinedSectionCancel} onComplete={handleEditCombinedSectionComplete} />
		<DragDropContext onDragEnd={handleDragEnd}>
			<Stack grow={false}>
				{generalContent}
				<Stack spacing={false}>
					<Typography variant={'h6'}>Config</Typography>
					<Typography variant={'body1'}>Show stats for a facility or team.</Typography>
				</Stack>
				<FormControl>
					<InputLabel>Show Leaderboard For</InputLabel>
					<Select value={selectedId} onChange={({ target: { value } }) => {
						if (!value) return
						const [what, id] = value.split('-')
						setEntityId({ target: { value: parseInt(id) } })
						setEntityKind({ target: { value: leaderboardEntityKindsFromString[what] } })
					}}>{entityOptions}</Select>
				</FormControl>
                <FormControlLabel label='HIDE REMOVED PLAYERS' control={<Checkbox checked={hideDeletedUsers} onChange={setHideDeletedUsers} />}/>
				<FormControl>
					<InputLabel>Logo</InputLabel>
					<Tooltip title='Upload New Logo'>
						<FileUploadIcon acceptTypes={['image/*']} className={classes.leaderboardLogoUploadIcon} onChange={handleSectionImageChange(FileLinkKind.tv_logo)} />
					</Tooltip>
					{ loadingFileLinks
						? <div className={classes.leaderboardLogo}>Loading...</div>
						: <img alt='' src={logoSrc} className={classes.leaderboardLogo} onDrop={handleImgDrop(FileLinkKind.tv_logo)} onDragOver={handleImgDragOver} />
					}
				</FormControl>
			<Button color='primary' variant='contained' style={{ marginTop: 'auto' }} onClick={handleTogglePreviewWindow}>
				{ previewWindow ? 'Stop Previewing' : 'Preview' }
			</Button>
			</Stack>
			<Stack horizontal={false} grow={false}>
				<Stack spacing={false} grow={false} style={{ flexGrow: 1 }}>
					<InputLabel style={{ paddingBottom: 6 }}>Sections</InputLabel>
					{droppable}
				</Stack>
				<OptionsButton options={addSectionOptions} onClick={handleAddSectionClick}>Add Section</OptionsButton>
			</Stack>
		</DragDropContext>
	</div>
}

export default function TvDialog({ editSession, setEditSession }) {
	const classes = useStyles()
	const { state, dispatch } = useStore()

	const [ fetchedData, setFetchedData ] = useState(null)

	const fetchData = async () => {
		state.showLoading(dispatch)
		const res = await api.getTvConfigEntities()
		state.hideLoading(dispatch)
		if (!res.ok) {
			await state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}
		return res
	}

	useEffect(() => {
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [])

	const [ get, set ] = getEditSessionGetterAndSetter(editSession, setEditSession)
	const mode = get('mode', TvMode.info)

	const generalContent = <Fragment>
		<TextField label='Name' fullWidth defaultValue={get('name', '')} onChange={set('name')} autoFocus />
		<FormControl>
			<FormHelperText>Mode</FormHelperText>
			<Select value={mode} onChange={set('mode', { updatesEditSession: true })}>
				<MenuItem value={1}>Info</MenuItem>
				<MenuItem value={2}>Leaderboard</MenuItem>
				<MenuItem value={3}>Advanced Display</MenuItem>
			</Select>
		</FormControl>
	</Fragment>

	let content
	switch (mode) {
		case TvMode.advanced_display: {
			const { guns } = fetchedData ?? {}
			content = <AdvancedDisplay generalContent={generalContent} get={get} set={set} guns={guns} />
		} break
		case TvMode.leaderboard: {
			const { facilities, teams } = fetchedData ?? {}
			content = <Leaderboard editSession={editSession} generalContent={generalContent} get={get} set={set} facilities={facilities} teams={teams} />
		} break
		case TvMode.info:
		default:
			content = <div className={classes.root}>
				<Stack style={{ overflow: 'auto' }}>
					{generalContent}
					<Stack spacing={false}>
						<Typography variant={'h6'}>Config</Typography>
						<Typography variant={'body1'}>Shows a title and message.</Typography>
					</Stack>
					<TextField label='Title' fullWidth defaultValue={get('config_info_title', '')} onChange={set('config_info_title')} />
					<TextField label='Message' fullWidth defaultValue={get('config_info_message', '')} onChange={set('config_info_message')} multiline />
				</Stack>
			</div>
			break
	}

	return <EditDialog  entityName='TV' editSession={editSession} contain={false}>
		{content}
	</EditDialog>
}
