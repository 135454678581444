import * as utils from '../utils'
import Container from '@material-ui/core/Container'
import React, { useEffect, useState } from 'react'
import Stack from '../Components/Stack'
import StandardDialog from './StandardDialog'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import clsx from 'clsx'
import drillHelpers from '../drillHelpers'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { useStore } from '../StoreProvider'
import * as types from '../types'

const useStyles = makeStyles((theme) => {
	const styles = {
		root: {
			display: 'flex',
			flexDirection: 'row',
		},
		container: {
			backgroundImage: 'url("/images/limit-stats-background.jpg")',
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			height: 526,
			overflowY: 'auto',
			padding: theme.spacing(2),
			width: 1024,
		},
		roundedRect: {
			alignItems: 'center',
			backgroundColor: 'rgba(0, 0, 0, 0.35)',
			borderRadius: 24,
			display: 'flex',
			flexDirection: 'column',
			height: 206,
			maxHeight: 206,
			minHeight: 206,
			padding: 16,
			'& > *': {
				color: 'white',
			},
			'& $score': {
				fontSize: 56,
				fontWeight: 'bold',
				height: 48,
				lineHeight: '56px',
				marginBottom: 12,
				marginTop: 'auto',
			},
			'& $startedAt': {
				fontSize: 32,
				height: 28,
				lineHeight: '32px',
				marginTop: 'auto',
			},
		},
		roundedRectLight: {
			backgroundColor: 'rgba(255, 255, 255, 0.75)',
			'& > *': {
				color: 'black',
			},
			'& $score': {
				fontSize: 72,
				height: 60,
				lineHeight: '72px',
				marginBottom: 8,
			},
		},
		rankedBadge: {
			alignItems: 'center',
			backgroundColor: 'rgba(0, 0, 0, 0.3)',
			borderRadius: 52,
			color: 'white',
			display: 'flex',
			fontSize: 32,
			height: 52,
			justifyContent: 'center',
			paddingTop: 8,
			width: 52,
		},
		score: {},
		startedAt: {},
	}

	styles[theme.breakpoints.down('sm')] = {
		root: {
			flexDirection: 'column',
			height: '100%',
			overflowX: 'hidden',
			overflowY: 'auto',
			padding: theme.spacing(2),
		},
		container: {
			height: '100%',
			width: '100%',
		},
	}

	return styles
})

function RoundedRect({ index, item, limitGoal }) {
	const classes = useStyles()

	if (!item) return <div className={classes.roundedRect} />

	let { score, started_at } = item

	score = limitGoal === types.DrillLimitGoal.time
		? utils.formatDurationCompact(score)
		: `${score} ${drillHelpers.DrillLimitGoalNames[limitGoal].toLowerCase()}`

	started_at = new Date(started_at + 'z').toLocaleDateString()

	return <div className={clsx(classes.roundedRect, index <= 2 && classes.roundedRectLight)}>
		<div className={classes.rankedBadge}>{index}</div>
		<Typography className={classes.score}>{score}</Typography>
		<Typography className={classes.startedAt}>{started_at}</Typography>
	</div>
}

export default function DrillLimitStatsDialog() {
	const classes = useStyles()
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.up('md'))
	const { state, dispatch } = useStore()
	const { open, drillId } = state.drillLimitStatsDialog
	const [ fetchedData, setFetchedData ] = useState(null)

	const hide = () => {
		dispatch({ type: 'drill_limit_stats_dialog.hide' })
	}

	useEffect(() => {
		if (!open) return

		let cancelled = false
		;(async () => {
			state.showLoading(dispatch)
			const res = await api.getDrillSessionBestLimitScores({ drill_id: drillId, count: 5 })
			state.hideLoading(dispatch)

			if (!res.ok) {
				hide()
				state.showError(dispatch, res.extractRemainingErrorsMessage())
				return
			}

			if (!cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [state.drillLimitStatsDialog])

	let content
	if (fetchedData) {
		const { drill, scores } = fetchedData
		const { limit_count, limit_goal, limit_kind } = drill.data

		let subContent
		if (scores.length === 0) {
			subContent = <Typography style={{ fontSize: matches ? 56 : 46, textAlign: 'center', marginBottom: 32 }}>
				NO SCORES YET
			</Typography>
		}
		else {
			let i = 1
			const rows = matches
				? [ utils.padArray(scores.slice(0, 2), 2), utils.padArray(scores.slice(2), 3) ]
				: [ utils.padArray(scores, 5) ]
			subContent = rows.map((slice, ii) => <Stack key={ii} horizontal={matches} medium expandEqually>
				{slice.map((item) => <RoundedRect key={i} index={i++} limitGoal={limit_goal} item={item} />)}
			</Stack>)
		}

		content = <Stack medium style={{ height: (scores.length === 0 || matches) ? '100%' : undefined }}>
			<Typography style={{ fontSize: matches ? 48 : 32, fontWeight: 'lighter', lineHeight: matches ? '48px' : '32px', textAlign: 'center' }}>
				{drillHelpers.getLimitDescription(limit_count, limit_goal, limit_kind, false)}
			</Typography>
			{subContent}
		</Stack>
	}

	return <StandardDialog
		title={fetchedData?.drill.name}
		contain={false}
		open={open && Boolean(fetchedData)}
		onCancel={hide}
		onExited={() => setFetchedData(null)}
	>
		<Container className={classes.container}>{content}</Container>
	</StandardDialog>
}
