import AccountRecoverPerformPage from './Pages/AccountRecoverPerformPage'
import AccountRecoverRequestPage from './Pages/AccountRecoverRequestPage'
import AccountSetupPage from './Pages/AccountSetupPage'
import AccountVerifyPage from './Pages/AccountVerifyPage'
import AdminAnalyticsErrorsPage from './Pages/AdminAnalyticsErrorsPage'
import AdminAnalyticsEventsPage from './Pages/AdminAnalyticsEventsPage'
import AdminGunsPage from './Pages/AdminGunsPage'
import AdminImportPage from './Pages/AdminImportPage'
import AdminOrganizationsPage from './Pages/AdminOrganizationsPage'
import AdminPage from './Pages/AdminPage'
import AdminTasksPage from './Pages/AdminTasksPage'
import AdminTvsPage from './Pages/AdminTvsPage'
import AddStatsDialog from './Dialogs/AddStatsDialog'
import AlertDialog2 from './Dialogs/AlertDialog2'
import AppBar from './Components/AppBar'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import Brightness7Icon from '@material-ui/icons/Brightness7'
import ConfirmationDialog2 from './Dialogs/ConfirmationDialog2'
import CreateFeatureDialog from './Dialogs/CreateFeatureDialog'
import CssBaseline from '@material-ui/core/CssBaseline'
import DrillAssignmentsPage from './Pages/DrillAssignmentsPage'
import DrillEditDialog2 from './Dialogs/DrillEditDialog2'
import DrillViewDialog2 from './Dialogs/DrillViewDialog2'
import DrillLimitStatsDialog from './Dialogs/DrillLimitStatsDialog'
import DrillsPage from './Pages/DrillsPage'
import FeaturedDrillsPage from './Pages/FeaturedDrillsPage'
import GunActivationPage from './Pages/GunActivationPage'
import GunsPage from './Pages/GunsPage'
import LoadingOverlay2 from './Components/LoadingOverlay2'
import LoginPage from './Pages/LoginPage'
import ManageDrillsPage from './Pages/ManageDrillsPage'
import ManageOrganizationMembersPage from './Pages/ManageOrganizationMembersPage'
import NotFoundPage from './Pages/NotFoundPage'
import OrganizationsPage from './Pages/OrganizationsPage'
import React, { Fragment, useEffect, useState } from 'react'
import SignupPage from './Pages/SignupPage'
import Stats2Page from './Pages/Stats2Page'
import StatsPage from './Pages/StatsPage'
import TeamJoinPage from './Pages/TeamJoinPage'
import TestPage from './Pages/TestPage'
import TvsPage from './Pages/TvsPage'
import VideoOverlay from './Components/VideoOverlay'
import api from './api'
import { Redirect, Route, BrowserRouter as Router, Switch, matchPath } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { useStore } from './StoreProvider'

const overrides = {
	MuiAppBar: {
		colorPrimary: {
			backgroundColor: '#850b00',
		},
	},
	MuiButton: {
		text: {
			padding: '6px 12px',
		},
		textPrimary: {
			color: 'inherit',
		},
	},
	MuiDialog: {
		container: {
			transitionTimingFunction: 'ease !important',
		},
	},
	MuiFormLabel: {
		root: {
			userSelect: 'none',
		},
	},
	MuiTablePagination: {
		caption: {
			userSelect: 'none',
		},
	},
	MuiFormControlLabel: {
		root: {
			userSelect: 'none',
		},
	},
	MuiSvgIcon: {
		root: {
			fontSize: '1.5rem',
		},
	},
	MuiTooltip: {
		tooltip: {
			fontSize: 16,
		},
	}
}

const props = {
	MuiInputLabel: {
		color: 'secondary',
		shrink: true,
	},
	MuiSelect: {
		color: 'secondary',
	},
	MuiTextField: {
		color: 'secondary',
		InputLabelProps: { shrink: true },
	},
}

const typography = {
	fontFamily: `'Countach', 'Helvetica', 'Arial', sans-serif`,
	fontSize: 16,
}

const lightTheme = createTheme({
	palette: {
		type: 'light',
		background: {
			default: '#eeeeee',
		},
		primary: { main: '#c00' },
		secondary: { main: '#000' },
	},
	overrides,
	props,
	typography,
})

const darkTheme = createTheme({
	palette: {
		type: 'dark',
		primary: { main: '#c00' },
		secondary: { main: '#fff' },
	},
	overrides,
	props,
	typography,
})

function ConditionalRoute({ children, condition, redirectsTo, alert, ...rest }) {
	const render = ({ location }) => {
		return condition ? children : <Redirect to={{ pathname: redirectsTo, state: { alert, from: location } }} />
	}
	return <Route render={render} {...rest} />
}

function defaultTheme() {
	const storedThemeType = localStorage['theme']
	if (storedThemeType === lightTheme.palette.type) return lightTheme
	if (storedThemeType === darkTheme.palette.type) return darkTheme
	return darkTheme
}

export default function App() {
	const { state, dispatch } = useStore()

	const [ theme, setTheme ] = useState(defaultTheme())
	const [ loginAndSignupAlert, setLoginAndSignupAlert ] = useState(null)
	const [ invite, setInvite ] = useState(null)
	const [ sessionChecked, setSessionChecked ] = useState(false)

	useEffect(() => {
		if (sessionChecked) return
		(async () => {
			state.showLoading(dispatch)
			const res = await api.getSessionValid()
			dispatch({ type: 'sessionChecked' })
			state.hideLoading(dispatch)
			if (!res.ok) {
				setSessionChecked(true)
				await state.showError(dispatch, res.extractRemainingErrorsMessage())
				return
			}
			dispatch({ type: 'user', user: res.user })
			setSessionChecked(true)
		})()
		// eslint-disable-next-line
	}, [sessionChecked])

	useEffect(() => {
		if (invite) return

		const pathname = document.location.pathname
		const match = matchPath(pathname, { path: '/login/:tokenId', exact: true, strict: false }) || matchPath(pathname, { path: '/signup/:tokenId', exact: true, strict: false })
		if (!match) return

		// TODO load the invite info and create a real alert message
		const id = match.params.tokenId
		const newLoginAndSignupAlert = id.toUpperCase()
		if (loginAndSignupAlert !== newLoginAndSignupAlert) {
			setInvite(true) // this will be an object in the real thing
			setLoginAndSignupAlert(newLoginAndSignupAlert)
		}
	}, [invite, loginAndSignupAlert])

	const getThemeIcon = () => {
		return theme === darkTheme ? <Brightness7Icon /> : <Brightness4Icon />
	}

	const handleToggleTheme = () => {
		const newTheme = theme === darkTheme ? lightTheme : darkTheme
		localStorage['theme'] = newTheme.palette.type
		setTheme(newTheme)
	}

	const isAuthenticated = Boolean(state.user)

	return <ThemeProvider theme={theme}>
		<svg style={{ width: 0, height: 0 }}>
			<defs>
				<linearGradient id='category-gradient' gradientTransform='rotate(90)'>
					<stop offset='0%'  stopColor='#eee' />
					<stop offset='100%' stopColor='#bbb' />
				</linearGradient>
				<filter id='inset-shadow' x='-50%' y='-50%' width='200%' height='200%'>
					<feComponentTransfer in='SourceAlpha'>
						<feFuncA type='table' tableValues='1 0' />
					</feComponentTransfer>
					<feGaussianBlur stdDeviation='15' />
					<feOffset dx='0' dy='0' result='offset-blur' />
					<feFlood floodColor='black' result='color' />
					<feComposite in2='offset-blur' operator='in' />
					<feComposite in2='SourceAlpha' operator='in' />
					<feMerge>
						<feMergeNode in='SourceGraphic' />
						<feMergeNode />
					</feMerge>
				</filter>
			</defs>
		</svg>
		<CssBaseline />
		<Router>
			<AddStatsDialog />
			<AlertDialog2 />
			<ConfirmationDialog2 />
			<CreateFeatureDialog />
			<LoadingOverlay2 />
			<DrillEditDialog2 />
			<DrillViewDialog2 />
			<DrillLimitStatsDialog />
			<VideoOverlay />
			<Fragment>
				<AppBar themeIcon={getThemeIcon()} onToggleTheme={handleToggleTheme} />
			</Fragment>
			{ sessionChecked ? <Switch>
				<Route exact path='/' render={() => isAuthenticated ? <Redirect to='/drills' /> : <Redirect to='/login' />}/>
				<ConditionalRoute exact condition={!isAuthenticated} redirectsTo='/drills' path='/login'>
					<LoginPage alert={loginAndSignupAlert} />
				</ConditionalRoute>
				<ConditionalRoute exact condition={!isAuthenticated} redirectsTo='/drills' path='/signup'>
					<SignupPage alert={loginAndSignupAlert} />
				</ConditionalRoute>
				<ConditionalRoute exact condition={!isAuthenticated} redirectsTo='/drills' path='/account/recover'>
					<AccountRecoverRequestPage />
				</ConditionalRoute>
				<Route exact path='/account/recover/:tokenId'>
					<AccountRecoverPerformPage />
				</Route>
				<ConditionalRoute condition={!isAuthenticated} redirectsTo='/drills' path='/account/setup/:tokenId'>
					<AccountSetupPage />
				</ConditionalRoute>
				<Route exact path='/account/verify/:tokenId'>
					<AccountVerifyPage />
				</Route>
				<Route exact path='/team/join/:tokenId'>
					<TeamJoinPage />
				</Route>
				<Route exact path='/test'>
					<TestPage />
				</Route>
				<ConditionalRoute exact condition={!isAuthenticated} redirectsTo='/drills' path='/login/:tokenId'>
					<LoginPage alert={loginAndSignupAlert} />
				</ConditionalRoute>
				<ConditionalRoute exact condition={!isAuthenticated} redirectsTo='/drills' path='/signup/:tokenId'>
					<SignupPage alert={loginAndSignupAlert} />
				</ConditionalRoute>
				<ConditionalRoute condition={isAuthenticated} redirectsTo='/login' path='/activate'><GunActivationPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated} redirectsTo='/login' path='/drills/assignments'><DrillAssignmentsPage /></ConditionalRoute>
				<ConditionalRoute condition={isAuthenticated} redirectsTo='/login' path='/drills/manage'><ManageDrillsPage /></ConditionalRoute>
				<ConditionalRoute condition={isAuthenticated} redirectsTo='/login' path='/drills'><DrillsPage /></ConditionalRoute>
				{/*<ConditionalRoute exact condition={isAuthenticated} redirectsTo='/login' path='/file-manager'><FileManagerPage /></ConditionalRoute>*/}
				<ConditionalRoute exact condition={isAuthenticated} redirectsTo='/login' path='/guns'><GunsPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated} redirectsTo='/login' path='/organizations'><OrganizationsPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated && state.user.is_super_admin} redirectsTo='/login' path='/admin/analytics/errors'><AdminAnalyticsErrorsPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated && state.user.is_super_admin} redirectsTo='/login' path='/admin/analytics/events'><AdminAnalyticsEventsPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated && state.user.is_super_admin} redirectsTo='/login' path='/admin/import'><AdminImportPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated && state.user.is_super_admin} redirectsTo='/login' path='/admin/tasks'><AdminTasksPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated && state.user.is_admin} redirectsTo='/login' path='/admin/guns'><AdminGunsPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated && state.user.is_admin} redirectsTo='/login' path='/admin/tvs'><AdminTvsPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated && state.user.is_admin} redirectsTo='/login' path='/admin/organizations'><AdminOrganizationsPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated && state.user.info.can_assign_drills} redirectsTo='/login' path='/admin/drills/assignments'><DrillAssignmentsPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated && state.user.info.can_feature_drills} redirectsTo='/login' path='/admin/drills/featured'><FeaturedDrillsPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated && state.user.is_admin} redirectsTo='/login' path='/admin'><AdminPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated} redirectsTo='/login' path='/organizations/members'><ManageOrganizationMembersPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated} redirectsTo='/login' path='/stats'><Stats2Page /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated} redirectsTo='/login' path='/stats-old'><StatsPage /></ConditionalRoute>
				<ConditionalRoute exact condition={isAuthenticated} redirectsTo='/login' path='/tvs'><TvsPage /></ConditionalRoute>
				<Route><NotFoundPage /></Route>
			</Switch> : undefined }
		</Router>
	</ThemeProvider>
}
