import * as shared from '../shared'
import * as utils from '../utils'
import AddIcon from '@material-ui/icons/Add'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import CategoryBreadcrumbs from '../Components/CategoryBreadcrumbs'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import Page from './Page'
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
// import Search from '@material-ui/icons/Search'
import Skeleton from '@material-ui/lab/Skeleton'
import SortCategoryLinksDialog from '../Dialogs/SortCategoryLinksDialog'
import SortIcon from '@material-ui/icons/Sort'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import catalogHelpers from '../catalogHelpers'
import clsx from 'clsx'
// import drillHelpers from '../drillHelpers'
import useIsMounted from '../hooks/useIsMounted'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Easing, getTween, setTween } from '../TweenManager'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { drillCourtLocationSvgDatas, CategoryKind, CategoryLinkKind, Drill } from '../types'
import { faCheckCircle, faMedal, faPlayCircle, faUser, faUsers } from '@fortawesome/free-solid-svg-icons'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { startEditSession } from '../EditSession'
import { useAlertDialog } from '../Dialogs/AlertDialog'
import { useHistory, useLocation } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import { useStore } from '../StoreProvider'

const drillsListVerticalPadding = 10
const drillsListHeight = 200
const drillsListItemHeight = drillsListHeight - (drillsListVerticalPadding * 2)
const drillsListItemWidth = 300
const drillsListTitleFontSize = 32
const drillsListItemMargin = 24
const drillsListItemMarginHalf = drillsListItemMargin / 2

const groupSize = 8

const skeletonTitleWidths = [90, 160, 90, 220, 160, 200]

const categoryPath = 'M 277.258 17.104 L 112.461 17.104 L 99.359 0 L 22.467 0 C 10.059 0 0 10.059 0 22.467 L 0 157.267 C 0 169.676 10.059 179.734 22.467 179.734 L 277.258 179.734 C 289.666 179.734 299.724 169.676 299.724 157.267 L 299.724 39.572 C 299.724 27.163 289.666 17.104 277.258 17.104 Z'
const cache = new Map()

const useStyles = makeStyles((theme) => {
	const styles = {
		root: {
			padding: '0 !important',
			overflowX: 'hidden',
			userSelect: 'none',
		},
		breadcrumbs: {
			maxWidth: 'calc(100% - 144px)',
			padding: drillsListItemMargin,
		},
		buttons: {
			position: 'absolute',
			right: theme.spacing(2),
			top: theme.spacing(2),
			'& .MuiButton-root': { marginLeft: theme.spacing(1) },
		},
		categories: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			overflow: 'visible',
			width: '100%',
		},
		categoriesSkeleton: {
			paddingTop: 75,
			overflowY: 'hidden',
		},
		drillsList: {
			position: 'relative',
			width: '100%',
		},
		drillsListContainer: {
			display: 'flex',
			minHeight: 196,
			overflow: 'hidden',
			whiteSpace: 'nowrap',
			'& > :first-child': {
				marginLeft: drillsListItemMargin,
			},
		},
		drillsListItem: {
			borderRadius: 23,
			boxShadow: 'none',
			boxSizing: 'border-box',
			display: 'inline-flex',
			filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.5))',
			flexDirection: 'column',
			flexGrow: 0,
			flexShrink: 0,
			minHeight: drillsListItemHeight,
			marginBottom: 16,
			marginRight: drillsListItemMargin,
			padding: 8,
			position: 'relative',
			userSelect: 'none',
			width: drillsListItemWidth,
		},
		drillsListItemCategory: {
			background: 'transparent',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			cursor: 'pointer',
		},
		drillsListItemCategorySvg: {
			fill: 'url(#category-gradient)',
			height: 180,
			left: 0,
			position: 'absolute',
			top: 0,
			width: 300,
			zIndex: -1,
		},
		drillsListItemCategoryTitle: {
			color: 'black',
			fontSize: 32,
			textAlign: 'center',
			whiteSpace: 'normal',
		},
		drillsListItemDrill: {
			backgroundImage: 'linear-gradient(to bottom, #8e8e8e, #262626)',
			cursor: 'pointer',
		},
		drillsListItemDrillBottomLabel: {
			position: 'relative',
			textAlign: 'center',
			top: '16px',
			marginBottom: '-42px',
		},
		drillsListItemDrillWithBottomLabel: {
			marginBottom: '42px',
		},
		drillsListItemDrillTitle: {
			color: 'white',
			fontSize: 20,
			overflow: 'hidden',
			textAlign: 'center',
			textOverflow: 'ellipsis',
		},
		drillsListItemDrillPerformedIcon: {
			fill: 'white',
			height: '68px !important',
			width: '68px !important',
		},
		drillsListItemDrillNotPerformedIcon: {
			fill: '#444',
		},
		drillsListItemDrillIconCluster: {
			position: 'relative',
			width: 90,
			height: 90,
			top: 12,
		},
		drillsListItemDrillIconClusterIcon: {
			color: 'white',
			position: 'absolute',
		},
		drillsListItemDrillIconClusterIconBadge: {
			alignItems: 'center',
			backgroundColor: '#039',
			borderRadius: 12,
			color: 'white',
			display: 'flex',
			flexDirection: 'row',
			fontFamily: 'Countach, sans-serif',
			fontSize: 16,
			height: 24,
			justifyContent: 'center',
			paddingTop: 4,
			position: 'absolute',
			right: -10,
			top: -10,
			width: 24,
		},
		drillsListItemDrillIconClusterIconDisabled: {
			color: 'rgba(32, 32, 32, 0.75) !important',
		},
		drillsListItemDrillIconClusterCompleteIcon: {
			height: '38px !important',
			width: '38px !important',
			top: 0,
			left: 0,
		},
		drillsListItemDrillIconClusterMedalIcon: {
			height: '38px !important',
			width: '38px !important',
			bottom: 0,
			left: 0,
		},
		drillsListItemDrillIconClusterUserIcon: {
			height: '36px !important',
			width: '36px !important',
			top: 0,
			right: 0,
		},
		drillsListItemDrillIconClusterGroupIcon: {
			height: '40px !important',
			width: '40px !important',
			top: 0,
			right: 0,
		},
		drillsListItemDrillIconClusterVideoIcon: {
			height: '36px !important',
			width: '36px !important',
			bottom: 0,
			right: 0,
		},
		drillsListItemCourtPath: {
			fill: '#414141',
			stroke: '#ffffff',
			strokeWidth: 4,
			strokeMiterlimit: 10,
		},
		drillsListItemError: {
			alignItems: 'center',
			background: '#3f3f3f',
			justifyContent: 'center',
		},
		drillsListItemGroup: {
			display: 'inline-flex',
			flexDirection: 'row',
			flexGrow: 0,
			flexShrink: 0,
			minHeight: 196,
			opacity: '0',
			transition: 'opacity 300ms',
			verticalAlign: 'top',
		},
		drillsListNav: {
			bottom: 16,
			cursor: 'pointer',
			minHeight: drillsListItemHeight,
			opacity: '0',
			position: 'absolute',
			transition: 'opacity 300ms',
			width: 64,
			zIndex: 1,
			'&:hover': {
				'& .MuiSvgIcon-root': { transform: 'scale(1.25)' },
			},
			'& .MuiSvgIcon-root': {
				color: 'white',
				filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.8))',
				fontSize: 72,
				transition: 'transform 300ms',
			},
		},
		drillsListNavHidden: {
			opacity: '0',
			'&:hover': {
				opacity: '0.2',
				'& .MuiSvgIcon-root': { transform: 'scale(1)' },
			},
		},
		drillsListNavVisible: {
			opacity: '1',
		},
		drillsListNavLeft: { left: 0 },
		drillsListNavRight: { right: 0 },
		drillsListHeader: {
			alignItems: 'center',
			borderRadius: theme.shape.borderRadius,
			display: 'flex',
			flexWrap: 'wrap',
			fontSize: drillsListTitleFontSize,
			fontWeight: 'bold',
			lineHeight: 1,
			marginBottom: 8,
			marginLeft: drillsListItemMargin,
			marginRight: drillsListItemMargin,
		},
		drillsListHeaderName: {
			alignItems: 'center',
			cursor: 'pointer',
			display: 'flex',
			'&:hover': {
				'& .MuiSvgIcon-root path': {
					transform: 'translateX(6px)',
				},
			},
			'& .MuiSvgIcon-root': {
				transform: 'translateX(-8px)',
			},
			'& .MuiSvgIcon-root path': {
				transform: 'translateX(3px)',
				transition: 'transform 200ms'
			},
		},

		noContent: {
			alignItems: 'center',
			display: 'flex',
			flexGrow: 1,
			justifyContent: 'center',
		},

		expand: {
			flexGrow: 1,
			flexShrink: 1,
		},
		hStack: {
			display: 'flex',
			flexDirection: 'row',
		},
		vStack: {
			display: 'flex',
			flexDirection: 'column',
		},
	}

	styles[theme.breakpoints.down('xs')] = {
		breadcrumbs: {
			maxWidth: 'calc(100% - 105px)',
			padding: drillsListItemMarginHalf,
		},
		buttons: {
			right: theme.spacing(0.5),
			top: theme.spacing(0.5),
		},
		drillsListContainer: {
			overflowX: 'auto',
			'& > :first-child': {
				marginLeft: drillsListItemMarginHalf,
			},
		},
		drillsListItem: {
			marginRight: drillsListItemMarginHalf,
		},
		drillsListNav: {
			display: 'none',
		},
		drillsListHeader: {
			marginLeft: drillsListItemMarginHalf,
			marginRight: drillsListItemMarginHalf,
		},
		drillsListHeaderName: {
			alignItems: 'center',
			cursor: 'pointer',
			display: 'flex',
			'&:hover': {
				'& .MuiSvgIcon-root path': {
					transform: 'translateX(3px)',
				},
			},
		},
	}

	return styles
})

function HStack({ children, expand, ...props }) {
	const classes = useStyles()
	return <div className={clsx(classes.hStack, expand && classes.expand)} {...props}>{children}</div>
}

function VStack({ children, expand, ...props }) {
	const classes = useStyles()
	return <div className={clsx(classes.vStack, expand && classes.expand)} {...props}>{children}</div>
}

function Space({ size }) {
	if (size) size = Math.floor(size * 8)
	return <div style={{ width: size, height: size, flexGrow: !size ? 1 : undefined }} />
}

function scrollBy(element, delta) {
	const itemWidth = 324 // this is the size of an item when not in mobile mode
	const offset = (itemWidth * Math.floor(element.clientWidth / itemWidth)) * delta
	const current = getTween(element)
	const from = { scrollLeft: element.scrollLeft }
	const targetStart = current ? current.to.scrollLeft : element.scrollLeft
	const to = { scrollLeft: targetStart + offset }

	setTween(element, from, to, Easing.Cubic.Out, 850, (obj) => {
		const prevScrollLeft = element.scrollLeft
		element.scrollLeft = obj.scrollLeft
		if (prevScrollLeft === element.scrollLeft) return true
	})
}

function Drills({ data: { categories, drills, path }, categoryIds, onOrderLinks }) {
	const classes = useStyles()
	const { state } = useStore()

	const pathname = window.location.pathname
	let scrollOffsetCache = state.drillsPageScrollLeftCache.get(pathname)
	if (!scrollOffsetCache) {
		scrollOffsetCache = new Map()
		state.drillsPageScrollLeftCache.set(pathname, scrollOffsetCache)
	}

	const breadcrumbs = [{ name: 'All Drills' }]
	if (path) {
		for (const category of path) {
			breadcrumbs.push(category)
		}
	}

	let childCount = categories.length
	if (drills) childCount += drills.length

	return <Box className={clsx(classes.categories)}>
		<CategoryBreadcrumbs prefix='/drills' firstIsPrefixOnly={true} items={breadcrumbs} className={classes.breadcrumbs} />
		{ childCount ? <Fragment>
			{drills && drills.length > 0 ? <List links={drills} categoryIds={categoryIds} scrollOffsetCache={scrollOffsetCache} /> : undefined}
			{categories.map((category, index) => {
				return <List key={index} category={category} links={category.links} categoryIds={categoryIds} scrollOffsetCache={scrollOffsetCache} onOrderLinks={onOrderLinks} />
			})}
		</Fragment> : <div style={{ display: 'flex', flexGrow: 1 }}>
			<Typography variant='h5' className={classes.noContent}>No Content</Typography>
		</div> }
	</Box>
}

function List({ category, links, categoryIds, scrollOffsetCache, onOrderLinks }) {
	const classes = useStyles()
	const history = useHistory()
	const navLeft = useRef(null)
	const navRight = useRef(null)
	const { dispatch, state } = useStore()

	// 0 is used for the drills list
	const scrollOffsetMapKey = category ? category.entity_id : 0

	const [ ref, setRef ] = useState(null)

	const updateRowNavVisibilities = () => {
		if (!ref) return

		if (navLeft.current) {
			const hidden = ref.scrollLeft < 1
			const classList = navLeft.current.classList
			classList.toggle(classes.drillsListNavHidden, hidden)
			classList.toggle(classes.drillsListNavVisible, !hidden)
		}

		if (navRight.current) {
			const hidden = Math.abs(ref.scrollLeft + ref.clientWidth - ref.scrollWidth) < 1
			const classList = navRight.current.classList
			classList.toggle(classes.drillsListNavHidden, hidden)
			classList.toggle(classes.drillsListNavVisible, !hidden)
		}
	}

	// eslint-disable-next-line
	const handleRefSet = useCallback(ref => {
		setRef(ref)
		if (!ref) return
		ref.onscroll = () => {
			scrollOffsetCache.set(scrollOffsetMapKey, ref.scrollLeft)
			updateRowNavVisibilities()
		}
		updateRowNavVisibilities()
	})

	const handleScrollLeft = () => {
		scrollBy(ref, -1)
	}

	const handleScrollRight = () => {
		scrollBy(ref, 1)
	}

	const handleClickCreateDrill = () => {
		state.editDrill(dispatch, {
			drill: new Drill({
				category_id: category.entity_id,
				data: window.courtLib.types.newDrill(),
				is_published: true
			}),
		})
	}

	const handleClickCategory = (categoryId) => {
		history.push('/drills/' + categoryIds.concat([category.entity_id, categoryId]).join('/'))
	}

	const handleClickManage = () => {
		history.push('/drills/manage/' + category.entity_id)
	}

	const handleClickViewAll = () => {
		history.push('/drills/' + categoryIds.concat([category.entity_id]).join('/'))
	}

	useEffect(() => {
		if (ref) ref.scrollLeft = scrollOffsetCache.get(scrollOffsetMapKey) || 0
		// eslint-disable-next-line
	}, [ref])

	let className = classes.drillsList
	if (!category) className += ' ' + classes.drillsListCompact

	const groups = []
	let i = 0
	while (i < links.length) {
		groups.push(links.slice(i, i + groupSize))
		i += groupSize
	}

	const isCurator = state.user && (state.user.is_admin || state.user.is_curator)

	let header
	if (category) {
		if (category.kind === CategoryKind.featured_drills) {
			header = <div className={classes.drillsListHeader}>
				<Typography variant='inherit'>{category.name}</Typography>
			</div>
		}
		else {
			header = <div className={classes.drillsListHeader}>
				<Tooltip title='View All' placement='right' enterDelay={500} enterNextDelay={500}>
					<div onClick={handleClickViewAll} className={classes.drillsListHeaderName}>
						<Typography variant='inherit'>{category.name}</Typography>
						<ChevronRightIcon fontSize='large' />
					</div>
				</Tooltip>
				{/*{ category.entity_id ? <Button endIcon={<KeyboardArrowRightIcon />} onClick={handleClickViewAll}>VIEW ALL</Button> : undefined }*/}
				{/*{ category.is_manageable ? <Button endIcon={<KeyboardArrowRightIcon />} onClick={handleClickManage}>MANAGE</Button> : undefined }*/}
				{/*<Tooltip title='View All'><IconButton onClick={handleClickViewAll}><VisibilityIcon /></IconButton></Tooltip>*/}
				{ state.user && catalogHelpers.entityOwnedByCurrentUser(state.user, category) && <>
					<Tooltip title='Create Drill'><IconButton onClick={handleClickCreateDrill}><AddIcon /></IconButton></Tooltip>
					<Tooltip title='Manage'><IconButton onClick={handleClickManage}><EditIcon /></IconButton></Tooltip>
				</> }
				{ isCurator ? <Tooltip title='Change Order'><IconButton onClick={onOrderLinks.bind(undefined, category.entity_id)}><SortIcon /></IconButton></Tooltip> : undefined }
			</div>
		}
	}

	return <Box className={className}>
		{header}
		<ButtonBase ref={navLeft} className={`${classes.drillsListNav} ${classes.drillsListNavLeft}`} onClick={handleScrollLeft}>
			<KeyboardArrowLeftIcon />
		</ButtonBase>
		<ButtonBase ref={navRight} className={`${classes.drillsListNav} ${classes.drillsListNavRight}`} onClick={handleScrollRight}>
			<KeyboardArrowRightIcon />
		</ButtonBase>
		<Box ref={handleRefSet} className={classes.drillsListContainer}>
			{groups.length > 0
				? groups.map((links, i) => <ListItemGroup key={i} links={links} onClickCategory={handleClickCategory} />)
				: <Typography variant='h5' className={classes.noContent}>No Content</Typography>}
		</Box>
	</Box>
}

function ListItemGroup({ links, onClickCategory }) {
	const classes = useStyles()
	const theme = useTheme()
	const extraSmall = useMediaQuery(theme.breakpoints.down('xs'))

	const [ fetched, setFetched ] = useState(false)
	const [ ref, inView, entry ] = useInView()

	useEffect(() => {
		if (entry) entry.target.style.opacity = inView ? '1' : '0'
		if (!inView || fetched) return

		let cancelled = false
		;(async () => {
			await Promise.all(links.map(async (link) => {
				if (cache.has(link)) return

				if (link.kind === CategoryLinkKind.category) {
					const res = await api.getCategorySummary(link.entity_id)
					cache.set(link, res)
					return
				}

				const [ res1, res2 ] = await Promise.all([
					await api.getDrillSummary(link.entity_id),
					await api.getDrillSessionStatsForDrill(link.entity_id)
				])
				if (res1.ok && res2.ok) res1.summary.stats = res2.day
				cache.set(link, res1)
			}))

			if (!cancelled) setFetched(true)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [inView, links])

	const width = (drillsListItemWidth + (extraSmall ? drillsListItemMarginHalf : drillsListItemMargin)) * links.length

	const errorClassName = clsx(classes.drillsListItem, classes.drillsListItemError)

	return <div ref={ref} className={classes.drillsListItemGroup} style={{ width }}>
		{ !inView ? undefined : links.map((link, index) => {
			const res = cache.get(link)
			if (!res) return <Skeleton key={index} variant='rect' className={classes.drillsListItem} />
			if (res.ok) return <ListItem key={index} link={link} data={res.summary} onClickCategory={onClickCategory} />
			return <div key={index} className={errorClassName}>
				<Typography variant='body2' style={{ fontWeight: 'bold' }}>ERROR</Typography>
				<Typography variant='body1'>{res.extractRemainingErrorsMessage()}</Typography>
			</div>
		})}
	</div>
}

function ListItemDrillIconClusterIcon({ badgeCount, className, disabled, icon, ...rest }) {
	const classes = useStyles()

	className = clsx(
		classes.drillsListItemDrillIconClusterIcon,
		disabled && classes.drillsListItemDrillIconClusterIconDisabled,
		className
	)

	const iconEl = <FontAwesomeIcon className={className} icon={icon} {...rest} />

	if (!badgeCount) {
		return iconEl
	}

	return <div>
		{iconEl}
		<div className={classes.drillsListItemDrillIconClusterIconBadge}>{badgeCount}</div>
	</div>
}

function ListItemDrillIconCluster({ data, drillId }) {
	const classes = useStyles()
	const hasPerformedDrill = data.stats && data.stats.total_drill_sessions > 0
	const { state, dispatch } = useStore()

	const { is_group_drill, is_limited, player_count, video_file_id } = data
	const isMultiplePlayers = is_group_drill || player_count > 1
	const userClassName = isMultiplePlayers ? classes.drillsListItemDrillIconClusterGroupIcon : classes.drillsListItemDrillIconClusterUserIcon
	const userIcon = isMultiplePlayers ? faUsers : faUser

	const completeClassName = classes.drillsListItemDrillIconClusterCompleteIcon
	const medalClassName = classes.drillsListItemDrillIconClusterMedalIcon
	const videoClassName = classes.drillsListItemDrillIconClusterVideoIcon

	const handleClickMedal = (e) => {
		if (!is_limited) return
		e.stopPropagation()
		state.showDrillLimitStats(dispatch, drillId)
	}

	const handleClickVideo = (e) => {
		if (!video_file_id) return
		e.stopPropagation()
		state.showVideo(dispatch, '/files/' + video_file_id)
	}

	return <div className={classes.drillsListItemDrillIconCluster}>
		<ListItemDrillIconClusterIcon className={completeClassName} icon={faCheckCircle} disabled={!hasPerformedDrill} />
		<ListItemDrillIconClusterIcon className={medalClassName} icon={faMedal} disabled={!is_limited} onClick={handleClickMedal} />
		<ListItemDrillIconClusterIcon className={userClassName} icon={userIcon} badgeCount={player_count > 1 && player_count} />
		<ListItemDrillIconClusterIcon className={videoClassName} icon={faPlayCircle} disabled={!video_file_id} onClick={handleClickVideo} />
	</div>
}

function ListItem({ link, data, onClickCategory }) {
	const classes = useStyles()
	const { dispatch, state } = useStore()

	if (link.kind === CategoryLinkKind.category) {
		const { image_file_id } = data
		const className = clsx(classes.drillsListItem, classes.drillsListItemCategory)
		let style
		if (image_file_id) {
			style = { backgroundImage: `url(/files/${image_file_id})` }
		}
		return <div className={className} style={style} onClick={() => onClickCategory(link.entity_id)}>
			{ !image_file_id ? <Fragment>
				<svg viewBox='0 0 300 180' className={classes.drillsListItemCategorySvg}>
					<path d={categoryPath} />
				</svg>
				<VStack expand>
					<Space size={3.5} />
					<Space />
					<div className={classes.drillsListItemCategoryTitle}>{data.name}</div>
					<Space />
				</VStack>
			</Fragment> : undefined }
		</div>
	}

	// TODO return data.format_version from the api and use this code
	// if (!drillHelpers.isDrillSupported(data)) {
	// 	return <div className={clsx(classes.drillsListItem, classes.drillsListItemDrill)}
	//		onClick={() => document.location.reload()}>
	// 		<VStack expand>
	// 			<Space />
	// 			<Typography variant='h5'>INCOMPATIBLE DRILL</Typography>
	// 			<Space />
	// 			<Typography variant='h5'>You must update your gun to view this drill</Typography>
	// 			<Space />
	// 			<Typography variant='h5'>Tap to go to reload</Typography>
	// 			<Space />
	// 		</VStack>
	// 	</div>
	// }

	let bottomLabel
	if (link.metadata) {
		const { start_at, end_at } = link.metadata
		bottomLabel = <Typography variant='h6' className={classes.drillsListItemDrillBottomLabel}>
			{shared.getFeaturedDateLabel(start_at, end_at)}
		</Typography>
	}

	return <div className={clsx(classes.drillsListItem, classes.drillsListItemDrill, link.metadata && classes.drillsListItemDrillWithBottomLabel)}
		onClick={() => state.viewDrill(dispatch, { drillId: link.entity_id })}>
		<VStack expand>
			<Space size={0.5} />
			<div className={classes.drillsListItemDrillTitle}>{data.name}</div>
			<Space size={0.5} />
			<HStack>
				<Space size={2} />
				<VStack>
					<svg viewBox='0 0 300 220' style={{ width: 160 }}>
						{drillCourtLocationSvgDatas.map(([ zone, location, d ], i) => {
							let fill
							if (data.stats) {
								const shots = data.stats['s_z_' + zone]
								if (shots > 0) {
									const makes = data.stats['m_z_' + zone]
									const percent = Math.floor((makes / shots) * 100)
									fill = window.courtLib.colors.getHeatmapColor(percent)
								}
							}
							if (!fill && data.zones.includes(zone)) fill = '#aaa'
							return <path key={i} d={d} className={classes.drillsListItemCourtPath} style={{ fill }}/>
						})}
					</svg>
					<Space size={1} />
				</VStack>
				<Space />
				<Space size={1} />
				<ListItemDrillIconCluster drillId={link.entity_id} data={data} />
				<Space />
			</HStack>
			{bottomLabel}
		</VStack>
	</div>
}

function DrillsSkeleton() {
	const classes = useStyles()

	return <Box className={clsx(classes.categories, classes.categoriesSkeleton)}>
		{skeletonTitleWidths.map((width, index) => {
			const skeletons = []
			for (let i = 0; i < 8; i++) {
				skeletons.push(<Skeleton key={i} variant='rect' className={classes.drillsListItem} />)
			}
			return <Box key={index} className={classes.drillsList}>
				<Skeleton variant='rect' className={classes.drillsListHeader} width={width} />
				<Box className={classes.drillsListContainer}>{skeletons}</Box>
			</Box>
		})}
	</Box>
}

export default function DrillsPage() {
	const classes = useStyles()
	const history = useHistory()
	const location = useLocation()
	const isMounted = useIsMounted()
	const { dispatch, state } = useStore()
	const [ ref, setRef ] = useState(null)

	// stands for re-render and is passed down through the component hierarchy to force re-renders
	const [ forceRender, setForceRender ] = useState(0)

	const categoryIds = location.pathname.slice(8).split('/').filter(id => id).map(id => parseInt(id))

	const [ canEditCategory, setCanEditCategory ] = useState(false)
	const [ fetchedData, setFetchedData ] = useState(null)
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')
	const [ editSessionOrderLinks, setEditSessionOrderLinks ] = useState(null)

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const scrollOffsetMapKey = window.location.pathname

	const fetchData = async () => {
		if (fetchedData) setLoadingOverlayText('Loading...')
		const res = await api.getCategoryList(categoryIds)
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			history.goBack()
			return
		}
		return res
	}

	const fetchAndSetData = async () => {
		const res = await fetchData()
		if (!res) return
		cache.clear()
		setFetchedData(res)
		setForceRender(forceRender + 1)
	}

	const handleClickEdit = () => {
		const is_curator = state.user.is_admin || state.user.is_curator

		// they're at the All Drills page, only curators can click the edit button here
		if (categoryIds.length === 0) {
			if (is_curator) history.push('/drills/manage/1')
			return
		}

		if (fetchedData.is_core) {
			if (is_curator) history.push('/drills/manage/1/' + categoryIds.join('/'))
		}
		else {
			history.push('/drills/manage/' + categoryIds.join('/'))
		}
	}

	// const handleClickSearch = () => {
	// }

	// NOTE if you update this you probably need to update the same function in ManageDrillsPage
	// noinspection DuplicatedCode
	const handleClickOrderLinks = async (categoryId) => {
		setLoadingOverlayText('Loading Category...')
		const res = await api.getCategoryLinks(categoryId)
		if (!isMounted()) return
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}

		const [ordered, unordered] = utils.prepareCategoryLinksForOrdering(res.links)

		await startEditSession(setEditSessionOrderLinks, utils.clone({ ordered, unordered }), {
			async onCancel() {
				return true
			},
			async onComplete({ ordered: newOrdered, unordered: newUnordered }) {
				const links = utils.diffCategoryLinksForUpload(ordered.concat(unordered), newOrdered.concat(newUnordered))
				if (links.length === 0) return true

				setLoadingOverlayText('Updating Order...')
				const res = await api.patchCategoryLinks(categoryId, links)
				setLoadingOverlayText('')
				if (!res.ok) {
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return false
				}
				await fetchAndSetData()
				return true
			},
		})
	}

	const updateScrollTop = () => {
		if (ref) ref.scrollTop = state.drillsPageScrollTopCache.get(scrollOffsetMapKey) || 0
	}

	useEffect(() => {
		if (!fetchedData) return
		updateScrollTop()
		// eslint-disable-next-line
	}, [ref])

	useEffect(() => {
		if (!fetchedData) {
			setCanEditCategory(false)
			return
		}
		setCanEditCategory(categoryIds.length === 0 || fetchedData.is_core
			? state.user ? (state.user.is_admin || state.user.is_curator) : false
			: true)
		updateScrollTop()
		// eslint-disable-next-line
	}, [fetchedData])

	useEffect(() => {
		if (!state.drillsDirty) return
		return utils.withCancel(async (cancelled) => {
			const res = await fetchData()
			if (!res || cancelled()) return
			setFetchedData(res)
			setForceRender(forceRender + 1)
			state.setDrillsDirty(dispatch, false)
		})
		// eslint-disable-next-line
	}, [state.drillsDirty])

	useEffect(() => {
		const { redirectToAfterLogin } = state
		if (redirectToAfterLogin && !redirectToAfterLogin.redirected) return
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) {
				cache.clear()
				setFetchedData(res)
				setForceRender(forceRender + 1)
			}
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [location])

	useEffect(() => {
		const { redirectToAfterLogin } = state
		if (!redirectToAfterLogin) return
		const { to, redirected } = redirectToAfterLogin
		if (redirected) {
			dispatch({ type: 'redirectToAfterLogin', to: null })
			return
		}
		redirectToAfterLogin.redirected = true
		history.push(to.pathname + (to.search ?? ''))
		// eslint-disable-next-line
	}, [state])

	const isCurator = state.user.is_admin || state.user.is_curator

	// eslint-disable-next-line
	const handleRefSet = useCallback(ref => {
		setRef(ref)
		if (!ref) return
		ref.onscroll = () => {
			state.drillsPageScrollTopCache.set(scrollOffsetMapKey, ref.scrollTop)
		}
	})

	return <Page ref={handleRefSet} alertDialogOptions={alertDialogOptions} className={classes.root} loadingText={loadingOverlayText}>
		<SortCategoryLinksDialog editSession={editSessionOrderLinks} setEditSession={setEditSessionOrderLinks} />
		<Box className={classes.buttons}>
			{ state.user && state.user.info.can_assign_drills && <Button style={{ marginRight: '12px' }} variant='contained' size='small'
				onClick={() => history.push('/admin/drills/assignments')}>Drill Assignments</Button> }
			{ state.user && state.user.info.can_feature_drills && <Button style={{ marginRight: '12px' }} variant='contained' size='small'
				onClick={() => history.push('/admin/drills/featured')}>Featured Drills</Button> }
			{ canEditCategory ? <Fragment>
				<Tooltip title={categoryIds.length === 0 ? 'Manage Core Drills' : 'Manage Category'}>
					<IconButton onClick={handleClickEdit}><EditIcon /></IconButton>
				</Tooltip>
				{ isCurator && <Tooltip title='Change Order'>
					<IconButton onClick={handleClickOrderLinks.bind(undefined, categoryIds[categoryIds.length - 1] || '1')}><SortIcon /></IconButton>
				</Tooltip> }
			</Fragment> : undefined }
			{/*<Tooltip title='Search'>*/}
			{/*	<IconButton onClick={handleClickSearch}><Search /></IconButton>*/}
			{/*</Tooltip>*/}
		</Box>
		{fetchedData ? <Drills key={forceRender} data={fetchedData} categoryIds={categoryIds} onOrderLinks={handleClickOrderLinks} /> : <DrillsSkeleton />}
	</Page>
}
