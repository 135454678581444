import * as shared from '../shared'
import * as utils from '../utils'
import AddIcon from '@material-ui/icons/Add'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import React, { useEffect, useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import { CatalogEntryMetadataEntityKind } from '../types'
import { makeStyles } from '@material-ui/core/styles'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
	},
	group: {
		borderTop: '1px solid rgba(255, 255, 255, 0.12)',
		padding: theme.spacing(1),
		paddingTop: theme.spacing(1.5),
	},
	header: {
		paddingLeft: theme.spacing(1),
	},
	item: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		gap: theme.spacing(1),
		height: 40.5,
		justifyContent: 'space-between',
		paddingLeft: theme.spacing(2.5),
		paddingRight: theme.spacing(1),
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
		},
	},
}))

export default function FeaturedDrillsList({ drill, onChange }) {
	const [ fetchedData, setFetchedData ] = useState(null)
	const { state, dispatch } = useStore()
	const classes = useStyles()

	const fetchAndSetData = async (cancelled) => {
		const res = await api.getCategoryLinksFeatures({ entity_id: drill.id, entity_kind: CatalogEntryMetadataEntityKind.drill })

		if (!res.ok) {
			state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}

		if (cancelled?.() ?? false) return

		setFetchedData(res)
	}

	const handleDelete = (id) => async (e) => {
		if (!await state.confirm(dispatch, 'CONFIRM', 'Unfeature drill?', 'YES', 'NO')) return
		state.showLoading(dispatch)
		const res = await api.deleteCategoryLinksFeature(id)
		if (!res.ok) {
			state.hideLoading(dispatch)
			await state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}
		onChange()
		await fetchAndSetData()
		state.hideLoading(dispatch)
	}

	const handleCreate = () => {
		state.createFeature(dispatch, {
			entityId: drill.id,
			entityKind: CatalogEntryMetadataEntityKind.drill,
			facilities: fetchedData.facilities.filter(x => x.is_admin),
			cb: () => {
				onChange()
				fetchAndSetData()
			},
		})
	}

	useEffect(() => {
		if (!drill) return
		return utils.withCancel(fetchAndSetData)
		// eslint-disable-next-line
	}, [drill])

	if (!fetchedData)
		return <></>

	const { facilities, metadatas } = fetchedData

	const canFeatureDrills = facilities.some(i => i.is_admin)

	if (!canFeatureDrills && metadatas.length === 0)
		return <></>

	const groups = facilities.map(({ id, name, is_admin }) => {
		const items = metadatas.filter(({ facility_id }) => id === facility_id)
		return { id, name, is_admin, items }
	}).filter(x => x.items.length > 0)
	groups.sort((a, b) => a.name.localeCompare(b.name))

	const content = groups.length > 0 ? <div className={classes.root}>
		{groups.map(({ id, name, is_admin, items }) => {
			return <div key={id} className={classes.group}>
				<div className={classes.header}>
					<Typography variant='h6'>{name}</Typography>
				</div>
				{items.map(({ id, start_at, end_at }) => {
					return <div key={id} className={classes.item}>
						<Typography variant='body1'>{shared.getFeaturedDateLabel(start_at, end_at)}</Typography>
						{ is_admin && <Tooltip title='Unfeature'>
							<IconButton size='small' onClick={handleDelete(id)}><DeleteIcon /></IconButton>
						</Tooltip> }
					</div>
				})}
			</div>
		})}
	</div> : <div style={{ position: 'relative', marginBottom: 8 }}>
		<Typography variant={'body1'} style={{ paddingLeft: 16 }}>Not featured yet.</Typography>
	</div>

	return <Card variant='outlined'>
		<CardContent style={{ display: 'flex', flexDirection: 'column', padding: 0 }}>
			<div style={{ position: 'relative', marginBottom: 8, marginTop: 16 }}>
				<Typography variant={'h6'} style={{ paddingLeft: 16 }}>Featured</Typography>
				{ canFeatureDrills && <Tooltip title='Feature'>
					<IconButton style={{ position: 'absolute', right: -3 + 8, top: -10 }} color='inherit'
						onClick={handleCreate}><AddIcon /></IconButton>
				</Tooltip> }
			</div>
			{content}
		</CardContent>
	</Card>
}
