import {
	StandardTable as Table,
	StandardTableAction as TableAction,
	StandardTableActions as TableActions,
	StandardTableColumn as TableColumn,
	StandardTableColumns as TableColumns,
} from '../Components/StandardTable'
import Container from '@material-ui/core/Container'
import GunStateLogsDialog from '../Dialogs/GunStateLogsDialog'
import Header from '../Components/Header'
import IconButton from '@material-ui/core/IconButton'
import NgrokDialog from '../Dialogs/NgrokDialog'
import Page from './Page'
import Paper from '@material-ui/core/Paper'
import React, { Fragment, useEffect, useState } from 'react'
import RefreshIcon from '@material-ui/icons/Refresh'
import Stack from '../Components/Stack'
import StandardTextField from '../Components/StandardTextField'
import Tooltip from '@material-ui/core/Tooltip'
import TvDialog from '../Dialogs/TvDialog'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import clsx from 'clsx'
import transform from '../transform'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { RtcMethod } from '../types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useAlertDialog } from '../Dialogs/AlertDialog'
import { useLocation } from 'react-router-dom'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => ({
	count: {
		padding: theme.spacing(0.75),
		paddingRight: theme.spacing(1.25),
		paddingLeft: theme.spacing(1.25),
		userSelect: 'none',
	},
	countStack: {
		marginBottom: theme.spacing(3),
		overflowX: 'auto',
	},
	toggleFilter: {
		cursor: 'pointer',
	},
	toggleFilterToggled: {
		background: theme.palette.primary.main,
		color: 'white',
	},
}))

function getFormattedBoardVersion({
	version_board_firmware_major: firmware_major,
	version_board_firmware_minor: firmware_minor,
	version_board_hardware_revision: hardware_revision,
}) {
	if (firmware_major === undefined) return ''
	return `v${hardware_revision}.${firmware_major}.${firmware_minor}`
}

function Count({ title, value, toggled, ...rest }) {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

	return <Paper className={clsx(classes.toggleFilter, toggled && classes.toggleFilterToggled)} {...rest}>
		<Stack horizontal medium={!isMobile} small={isMobile} className={classes.count}>
			<Typography variant={isMobile ? 'h6' : 'h5'} style={{ whiteSpace: 'nowrap' }}>{title}</Typography>
			<Typography variant={isMobile ? 'h6' : 'h5'} style={{ fontWeight: 'bold' }}>{value}</Typography>
		</Stack>
	</Paper>
}

export default function AdminGunsPage() {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
	const location = useLocation()
	const { dispatch, state } = useStore()

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const [ editSession, setEditSession ] = useState(null)
	const [ fetchedData, setFetchedData ] = useState(null)
	const [ filter, setFilter ] = useState('')
	const [ gunStateLogsDialogOpen, setGunStateLogsDialogOpen ] = useState(false)
	const [ toggleFilter, setToggleFilter ] = useState({ online: false, previouslyConnected: false, registered: false })
	const [ selectedGunId, setSelectedGunId ] = useState(null)
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')
	const [ ngrokDialogOpen, setNgrokDialogOpen ] = useState(false)
	const [ tableShouldFetchDataCounter, setTableShouldFetchDataCounter ] = useState(0)
	const [ fetchDataCounter, setFetchDataCounter ] = useState(0)

	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const fetchData = async () => {
		setLoadingOverlayText('Loading...')
		const res = await api.getGuns({ all: true })
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		return res
	}

	let guns = fetchedData?.guns
	if (guns && filter) {
		const parts = (filter.toLowerCase()).split('|').map(p => p.trim()).filter(p => !!p)

		guns = guns.filter(gun => {
			let { facility_name, id, name, organization_name, registered_by_user_name, serial_number, version } = gun
			const boardVersion = getFormattedBoardVersion(gun)
			id = id.toString()
			serial_number = serial_number.toLowerCase()
			if (name) name = name.toLowerCase()
			if (organization_name) organization_name = organization_name.toLowerCase()
			if (facility_name) facility_name = facility_name.toLowerCase()
			if (registered_by_user_name) registered_by_user_name = registered_by_user_name.toLowerCase()
			if (version) version = version.toLowerCase()
			for (const part of parts) {
				if (id.includes(part)) return true
				if (name.includes(part)) return true
				if (serial_number.includes(part)) return true
				if (organization_name && organization_name.includes(part)) return true
				if (facility_name && facility_name.includes(part)) return true
				if (registered_by_user_name && registered_by_user_name.includes(part)) return true
				if (version && version.includes(part)) return true
				if (boardVersion && boardVersion.includes(part)) return true
			}
			return false
		})
	}

	let onlineCount = 0
	let previouslyConnectedCount = 0
	let registeredCount = 0
	let totalCount = 0
	let sseCount = 0
	let wsCount = 0
	if (guns) {
		totalCount = guns.length
		for (const gun of guns) {
			if (gun.online) {
				onlineCount++
                switch (gun.online_method) {
                    case RtcMethod.sse: sseCount++; break
                    case RtcMethod.websocket: wsCount++; break
                }
			}
			if (gun.registered_at) {
				registeredCount++
			}
			if (gun.online !== undefined && !gun.registered_at) {
				previouslyConnectedCount++
			}
		}

		guns = guns.filter(({ online, registered_at }) => {
			switch (true) {
				case toggleFilter.online:
					return Boolean(online)
				case toggleFilter.previouslyConnected:
					return online !== undefined && !Boolean(registered_at)
				case toggleFilter.registered:
					return Boolean(registered_at)
				default:
					return true
			}
		})
	}

	const changeToggleFilter = (key) => () => {
		setToggleFilter({ ...toggleFilter, [key]: !toggleFilter[key] })
		setTableShouldFetchDataCounter(tableShouldFetchDataCounter + 1)
	}

	const getConnectionStatusColor = (online, online_method) => {
		if (online === undefined) return '#ff0'
        if (!online) return '#f00'
        switch (online_method) {
            case RtcMethod.sse: return '#0ff'
            case RtcMethod.websocket: return '#0f0'
            default: return '#fff'
        }
	}

	const handleChangeFilter = ({ target: { value } }) => {
		setFilter(value)
		setTableShouldFetchDataCounter(tableShouldFetchDataCounter + 1)
	}

	const handleFetchData = async (page, rowsPerPage) => {
		const startIndex = rowsPerPage * page
		const endIndex = startIndex + rowsPerPage - 1
		return [ guns.slice(startIndex, endIndex), guns.length ]
	}

	const handleGetDescriptionValue = (_, { facility_name, name, organization_name }) => {
		const parts = []
		if (organization_name) parts.push(organization_name)
		if (facility_name) parts.push(facility_name)
		if (name) parts.push(name)
		if (parts.length === 0) return '-'
		return parts.join(' • ')
	}

	const handleGetConnectionStatusValue = (_, { online, online_at, online_changed_at }) => {
		if (online === undefined) return '-'
		const span = <span>{`${online ? 'Online' : 'Offline'} for ${transform.timestampRelative(online_changed_at, true)}`}</span>
		if (!online) return span
		return <Tooltip title={`Last ping ${transform.timestampRelative(online_at)}`}>{span}</Tooltip>
	}

	const handleGetRegisteredValue = (_, { registered_at, registered_by_user_name }) => {
		if (!registered_at) return '-'
		return transform.date(registered_at) + ' by ' + registered_by_user_name
	}

	const handleGunStateLogsDialogShow = async (item) => {
		setGunStateLogsDialogOpen(true)
		setSelectedGunId(item.id)
	}

	const handleNgrokDialogShow = async (item) => {
		setNgrokDialogOpen(true)
		setSelectedGunId(item.id)
	}

	const handleDialogCancel = () => {
		setNgrokDialogOpen(false)
		setGunStateLogsDialogOpen(false)
	}

	const handleDialogExited = () => {
		setSelectedGunId(null)
	}

	useEffect(() => {
		let cancelled = false
		;(async () => {
			state.showLoading(dispatch)
			const res = await fetchData()
			state.hideLoading(dispatch)
			if (res && !cancelled) {
				setFetchedData(res)
				setTableShouldFetchDataCounter(tableShouldFetchDataCounter + 1)
			}
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [fetchDataCounter, location])

	return <Page padding='bottom' alertDialogOptions={alertDialogOptions} loadingText={loadingOverlayText}>
		<GunStateLogsDialog gunId={selectedGunId} open={gunStateLogsDialogOpen} onCancel={handleDialogCancel} onExited={handleDialogExited} />
		<NgrokDialog entityId={selectedGunId} entityKind='gun' open={ngrokDialogOpen} onCancel={handleDialogCancel} onExited={handleDialogExited} />
		<TvDialog editSession={editSession} setEditSession={setEditSession} />
		<Container>
			<Header>
				<Stack horizontal medium={isMobile}>
					<Typography variant='inherit' style={{ flexGrow: 0 }}>Guns</Typography>
					<StandardTextField placeholder='Filter Guns...' value={filter} onChange={handleChangeFilter} />
				</Stack>
			</Header>
			{ fetchedData ? <Fragment>
				<Stack grow={false} horizontal medium={isMobile} className={classes.countStack}>
					<Count title={'Total'} value={totalCount} />
					<Count title={'Registered'} value={registeredCount} toggled={toggleFilter.registered} onClick={changeToggleFilter('registered')} />
					<Count title={'Unregistered'} value={previouslyConnectedCount} toggled={toggleFilter.previouslyConnected} onClick={changeToggleFilter('previouslyConnected')} />
					<Count title={'Online'} value={onlineCount} toggled={toggleFilter.online} onClick={changeToggleFilter('online')} />
					<Count title={'SSE'} value={sseCount} style={{ borderLeft: `10px solid ${getConnectionStatusColor(true, RtcMethod.sse)}` }} />
					<Count title={'WS'} value={wsCount} style={{ borderLeft: `10px solid ${getConnectionStatusColor(true, RtcMethod.websocket)}` }} />
					<IconButton onClick={() => setFetchDataCounter(fetchDataCounter + 1)} style={{ marginLeft: 'auto' }}><RefreshIcon /></IconButton>
				</Stack>
				<Table pagination={true} shouldFetchDataCounter={tableShouldFetchDataCounter} onFetchData={handleFetchData}>
					<TableColumns>
						<TableColumn fieldName='id' width={80} onStyle={(_, { online, online_method }) => ({ borderLeft: `10px solid ${getConnectionStatusColor(online, online_method)}` })}>ID</TableColumn>
						<TableColumn fieldName='serial_number' width={80}>Serial #</TableColumn>
						<TableColumn fieldName='registered' onTransform={handleGetRegisteredValue} width={200}>Registered</TableColumn>
						<TableColumn fieldName='description' onTransform={handleGetDescriptionValue}>Description</TableColumn>
						<TableColumn fieldName='connection_status' onTransform={handleGetConnectionStatusValue} width={100}>Connection Status</TableColumn>
						<TableColumn fieldName='version' onTransform={value => value || '-'} width={100}>SW Version</TableColumn>
						<TableColumn fieldName='board_version' onTransform={(_, gun) => getFormattedBoardVersion(gun) || '-'} width={100}>HW Version</TableColumn>
					</TableColumns>
					<TableActions>
						<TableAction onClick={handleNgrokDialogShow} onAvailable={() => state.user.is_super_admin}>Ngrok</TableAction>
						<TableAction onClick={handleGunStateLogsDialogShow} onAvailable={() => state.user.is_super_admin}>State Logs</TableAction>
					</TableActions>
				</Table>
			</Fragment> : undefined }
		</Container>
	</Page>
}
