import * as Cookies from 'js-cookie'
import * as utils from '../utils'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import BusinessIcon from '@material-ui/icons/Business'
import Button from "@material-ui/core/Button"
import ChangePasswordDialog from '../Dialogs/ChangePasswordDialog'
import Divider from '@material-ui/core/Divider'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import FaceIcon from '@material-ui/icons/Face'
// import FolderIcon from '@material-ui/icons/Folder'
import IconButton from '@material-ui/core/IconButton'
import ImpersonationDialog from '../Dialogs/ImpersonationDialog'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import LoadingOverlay from './LoadingOverlay'
import Menu from '@material-ui/core/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MyAccountDialog from '../Dialogs/MyAccountDialog'
import React, { Fragment, useState } from 'react'
import SportsIcon from '@material-ui/icons/Sports'
import StarIcon from '@material-ui/icons/Star'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import Toolbar from '@material-ui/core/Toolbar'
import TvIcon from '@material-ui/icons/Tv'
import api from '../api'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { AlertDialog, useAlertDialog } from '../Dialogs/AlertDialog'
import { ConfirmationDialog, useConfirmationDialog } from '../Dialogs/ConfirmationDialog'
import { default as MuiAppBar } from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'
import { startEditSession } from '../EditSession'
import { useHistory, useLocation } from 'react-router-dom'
import { useStore } from '../StoreProvider'
import { useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	drawer: {
		width: 250,
	},
	toolbar: {
		flexGrow: 0,
		flexShrink: 0,
	},
	toolbarButton: {
		minWidth: 0,
	},
	toolbarButtonDownArrow: {
		marginLeft: 4,
		marginRight: -6,
	},
	toolbarImg: {
		cursor: 'pointer',
		height: 30,
		userSelect: 'none',
	},
	toolbarMenuIconButton: {
		marginRight: theme.spacing(2),
	},
	toolbarSpacer: {
		flexGrow: 1,
	},
	toolbarThemeIconButton: {
		marginLeft: theme.spacing(1),
	},
}))

function Desktop({ themeIcon, onImpersonateUser, onLogout, onShowMyAccount, onToggleTheme }) {
	const classes = useStyles()
	const history = useHistory()
	const location = useLocation()
	const { state, dispatch } = useStore()
	const [ userMenuAnchorEl, setUserMenuAnchorEl ] = useState(null)

	const getButtonParams = (path) => {
		const params = {
			className: classes.toolbarButton,
			color: 'inherit',
		}

		if (!path) return params

		params.onClick = navigate(path)

		if (location?.pathname?.startsWith(path) ?? false)
			params.style = { color: 'gold' }

		return params
	}

	const isAuthenticated = Boolean(state.user)

	const handleCloseUserMenu = () => {
		setUserMenuAnchorEl(null)
	}

	const handleCreateOrganization = () => {
		setUserMenuAnchorEl(null)
		history.push('/organizations')
	}

	const handleImpersonateUser = (event) => {
		setUserMenuAnchorEl(null)
		// eslint-disable-next-line
		onImpersonateUser?.()
	}

	const handleOpenUserMenu = (event) => {
		setUserMenuAnchorEl(event.currentTarget)
	}

	// const handleShowFileManager = (event) => {
	// 	setUserMenuAnchorEl(null)
	// 	history.push('/file-manager')
	// }

	const handleShowMyAccount = (event) => {
		setUserMenuAnchorEl(null)
		// eslint-disable-next-line
		onShowMyAccount?.()
	}

	const toggleTheme = (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return
		// eslint-disable-next-line
		onToggleTheme?.()
	}

	const navigate = (to) => () => {
		// explicitly navigating to this page should reset all the scroll offsets
		if (to === '/drills') dispatch({ type: 'clearScrollOffsets' })
		history.push(to)
	}

	return <MuiAppBar position='static'>
		<Toolbar className={classes.toolbar}>
			<img className={classes.toolbarImg} src='/images/logo.png' alt='logo' onClick={navigate('/drills')} />
			<div className={classes.toolbarSpacer} />
			{ isAuthenticated ? <Fragment>
				<Button {...getButtonParams('/drills')}>Drills</Button>
				{ state.user.info.has_gun ? <Button {...getButtonParams('/guns')}>Guns</Button> : undefined }
				{ state.user.is_admin || state.user.info.has_organization ? <Button {...getButtonParams('/organizations')}>Organizations</Button> : undefined }
				<Button {...getButtonParams('/stats')}>Stats</Button>
				<Button {...getButtonParams('/tvs')}>TVs</Button>
				<Button {...getButtonParams()} onClick={handleOpenUserMenu}>
					{state.user.name}
					<KeyboardArrowDownIcon className={classes.toolbarButtonDownArrow} />
				</Button>
				<Menu keepMounted anchorEl={userMenuAnchorEl} getContentAnchorEl={null} open={Boolean(userMenuAnchorEl)}
					anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
					onClose={handleCloseUserMenu}
				>
					<MenuItem onClick={handleShowMyAccount}>My Account</MenuItem>
					{ state.user.is_admin && !state.user.is_impersonated ? <MenuItem onClick={handleImpersonateUser}>Impersonate User</MenuItem> : undefined }
					{/*<MenuItem onClick={handleShowFileManager}>File Manager</MenuItem>*/}
					{ !state.user.info.has_organization ? <MenuItem onClick={handleCreateOrganization}>Create First Organization</MenuItem> : undefined }
					<MenuItem onClick={onLogout}>{ state.user.is_impersonated ? 'Stop Impersonating' : 'Logout' }</MenuItem>
				</Menu>
			</Fragment> : undefined }
			<IconButton edge='end' className={classes.toolbarThemeIconButton} color='inherit' aria-label='theme' onClick={toggleTheme}>
				{themeIcon}
			</IconButton>
		</Toolbar>
	</MuiAppBar>
}

function Mobile({ themeIcon, onImpersonateUser, onLogout, onShowMyAccount, onToggleTheme }) {
	const classes = useStyles()
	const history = useHistory()
	const location = useLocation()
	const { state, dispatch } = useStore()
	const isAuthenticated = Boolean(state.user)

	const [drawerOpen, setDrawerOpen] = useState(false)

	const getButtonParams = (path) => {
		const params = {
			button: true,
		}

		if (!path) return params

		params.onClick = navigate(path)

		if (location?.pathname?.startsWith(path) ?? false)
			params.style = { color: 'gold' }

		return params
	}

	const getIconParams = (path) => {
		const params = {
			style: { justifyContent: 'flex-end' },
		}

		if (!path) return params

		if (location?.pathname?.startsWith(path) ?? false)
			params.style.color = 'gold'

		return params
	}

	const handleCreateOrganization = () => {
		history.push('/organizations')
	}

	const toggleDrawer = (open) => (event) => {
		if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return
		setDrawerOpen(open)
	}

	const toggleTheme = (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return
		// eslint-disable-next-line
		onToggleTheme?.()
	}

	const navigate = (to) => () => {
		// explicitly navigating to this page should reset all the scroll offsets
		if (to === '/drills') dispatch({ type: 'clearScrollOffsets' })
		history.push(to)
	}

	return <MuiAppBar position='static'>
		<Toolbar className={classes.toolbar}>
			{ isAuthenticated ?
				<IconButton edge='start' className={classes.toolbarMenuIconButton} color='inherit' aria-label='menu' onClick={toggleDrawer(true)}>
					<MenuIcon />
				</IconButton> : null }
			<img className={classes.toolbarImg} src='/images/logo.png' alt='logo' onClick={navigate('/drills')} />
			<div className={classes.toolbarSpacer} />
			<IconButton edge='end' className={classes.toolbarThemeIconButton} color='inherit' aria-label='theme' onClick={toggleTheme}>
				{themeIcon}
			</IconButton>
		</Toolbar>
		<SwipeableDrawer anchor='left' open={drawerOpen} onOpen={toggleDrawer(true)} onClose={toggleDrawer(false)} disableSwipeToOpen>
			{ isAuthenticated ? <div className={classes.drawer} role='presentation' onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
				<ListItem {...getButtonParams('/drills')}>
					<ListItemText>Drills</ListItemText>
					<ListItemIcon {...getIconParams('/drills')}>
						<SportsIcon />
					</ListItemIcon>
				</ListItem>
				{ state.user.is_admin || state.user.info.has_gun ? <ListItem {...getButtonParams('/guns')}>
					<ListItemText>Guns</ListItemText>
					<ListItemIcon {...getIconParams('/guns')}>
						<StarIcon />
					</ListItemIcon>
				</ListItem> : undefined }
				{ state.user.is_admin || state.user.info.has_organization ? <ListItem {...getButtonParams('/organizations')}>
					<ListItemText>Organizations</ListItemText>
					<ListItemIcon {...getIconParams('/organizations')}>
						<BusinessIcon />
					</ListItemIcon>
				</ListItem> : undefined }
				<ListItem {...getButtonParams('/stats')}>
					<ListItemText>Stats</ListItemText>
					<ListItemIcon {...getIconParams('/stats')}>
						<EqualizerIcon />
					</ListItemIcon>
				</ListItem>
				<ListItem {...getButtonParams('/tvs')}>
					<ListItemText>TVs</ListItemText>
					<ListItemIcon {...getIconParams('/tvs')}>
						<TvIcon />
					</ListItemIcon>
				</ListItem>
				<Divider />
				<ListItem button onClick={onShowMyAccount}>
					<ListItemText primary={state.user.name} />
					<ListItemIcon {...getIconParams()}>
						<AccountCircleIcon />
					</ListItemIcon>
				</ListItem>
				<Divider />
				{ state.user.is_admin && !state.user.is_impersonated ? <Fragment>
					<ListItem button onClick={onImpersonateUser}>
						<ListItemText>Impersonate User</ListItemText>
						<ListItemIcon {...getIconParams()}>
							<FaceIcon />
						</ListItemIcon>
					</ListItem>
					<Divider />
				</Fragment> : undefined }
				{/*<ListItem {...getButtonParams('/file-manager')}>*/}
				{/*	<ListItemText primary={'File Manager'} />*/}
				{/*	<ListItemIcon {...getIconParams('/file-manager')}>*/}
				{/*		<FolderIcon />*/}
				{/*	</ListItemIcon>*/}
				{/*</ListItem>*/}
				{/*<Divider />*/}
				{ !state.user.info.has_organization ? <Fragment>
					<ListItem button onClick={handleCreateOrganization}>
						<ListItemText primary='Create First Organization' />
						<ListItemIcon {...getIconParams()}>
							<BusinessIcon />
						</ListItemIcon>
					</ListItem>
					<Divider />
				</Fragment> : undefined }
				<ListItem button onClick={onLogout}>
					<ListItemText primary={state.user.is_impersonated ? 'Stop Impersonating' : 'Logout'} />
					<ListItemIcon {...getIconParams()}>
						<ExitToAppIcon />
					</ListItemIcon>
				</ListItem>
			</div> : undefined }
		</SwipeableDrawer>
	</MuiAppBar>
}

export default function AppBar({ themeIcon, onToggleTheme }) {
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.up('md'))
	const { state, dispatch } = useStore()

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const [ confirmationDialogOptions, setConfirmationDialogOptions ] = useState(null)
	const [ editSessionPassword, setEditSessionPassword ] = useState(null)
	const [ editSessionUser, setEditSessionUser ] = useState(null)
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')
	const [ myAccountErrors, setMyAccountErrors ] = useState({ email: '', name: '', username: '' })
	const [ impersonationDialogOpen, setImpersonationDialogOpen ] = useState(false)

	const showAlertDialog = useAlertDialog(setAlertDialogOptions)
	const showConfirmationDialog = useConfirmationDialog(setConfirmationDialogOptions)

	const Comp = matches ? Desktop : Mobile

	const handleChangePassword = async () => {
		await startEditSession(setEditSessionPassword, { currentPassword: '', newPassword: '', newPasswordRepeat: '', errors: {} }, {
			async onCancel() { return true },
			async onComplete({ currentPassword, newPassword, newPasswordRepeat }, editSession) {
				if (newPassword !== newPasswordRepeat) {
					setEditSessionPassword(editSession.clone({ errors: { newPasswordRepeat: 'The passwords do not match' } }))
					return false
				}

				setLoadingOverlayText('Updating Password...')
				const res = await api.patchUserPassword({ current_password: currentPassword, new_password: newPassword })
				setLoadingOverlayText('')

				if (!res.ok) {
					let msg = res.extractErrorMessage('new_password')
					if (msg) {
						setEditSessionPassword(editSession.clone({ errors: { newPassword: msg } }))
						return false
					}

					msg = res.extractErrorMessage('current_password')
					if (msg) {
						setEditSessionPassword(editSession.clone({ errors: { currentPassword: msg } }))
						return false
					}

					msg = res.extractRemainingErrorsMessage()
					if (msg) await showAlertDialog('ERROR', msg)

					return false
				}

				return true
			},
		})
	}

	const handleImpersonateUser = () => {
		setImpersonationDialogOpen(true)
	}

	const handleLogout = async () => {
		setLoadingOverlayText('Logging out...')
		const res = await api.deleteSession()
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
		}
		if (!res.user) Cookies.remove('session_token')
		// HACK the following two lines don't work, so lets just reload for now
		// dispatch({ type: 'user', user: null })
		// history.push('/login')
		window.location = window.location.origin + '/login'
	}

	const handleShowMyAccount = async () => {
		await startEditSession(setEditSessionUser, utils.clone(state.user), {
			async onCancel() {
				const yes = await showConfirmationDialog(
					'CONFIRM',
					"Are you sure you'd like to discard your changes to your account?"
				)
				if (yes) setMyAccountErrors({ email: '', name: '', username: '' })
				return yes
			},
			async onComplete(item) {
				setLoadingOverlayText('Updating Account...')
				const res = await api.patchUser(state.user.id, item)
				setLoadingOverlayText('')
				if (!res.ok) {
					const newMyAccountErrors = { email: '', name: '', username: '' }

					let msg = res.extractErrorMessage('email')
					if (msg) newMyAccountErrors.email = msg

					msg = res.extractErrorMessage('name')
					if (msg) newMyAccountErrors.name = msg

					msg = res.extractErrorMessage('username')
					if (msg) newMyAccountErrors.username = msg

					msg = res.extractRemainingErrorsMessage()
					if (msg) await showAlertDialog('ERROR', msg)

					setMyAccountErrors(newMyAccountErrors)

					return false
				}
				setMyAccountErrors({ email: '', name: '', username: '' })
				dispatch({ type: 'user', user: { ...state.user, ...item } })
				return true
			},
		})
	}

	return <Fragment>
		<MyAccountDialog editSession={editSessionUser} setEditSession={setEditSessionUser} errors={myAccountErrors} onChangePassword={handleChangePassword} />
		<ChangePasswordDialog editSession={editSessionPassword} setEditSession={setEditSessionPassword} />
		<ImpersonationDialog open={impersonationDialogOpen} onClose={() => setImpersonationDialogOpen(false)} />
		<AlertDialog options={alertDialogOptions} />
		<ConfirmationDialog options={confirmationDialogOptions} />
		<LoadingOverlay open={Boolean(loadingOverlayText)} text={loadingOverlayText} />
		<Comp themeIcon={themeIcon}
			onLogout={handleLogout}
			onImpersonateUser={handleImpersonateUser}
			onShowMyAccount={handleShowMyAccount}
			onToggleTheme={onToggleTheme} />
	</Fragment>
}
