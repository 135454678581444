import DeleteIcon from '@material-ui/icons/Delete'
import EditDialog from './EditDialog'
import FileUploadIcon from '../Components/FileUploadIcon'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import React from 'react'
import Stack from '../Components/Stack'
import StandardTextField from '../Components/StandardTextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import { FileKinds } from '../types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'
import { getEditSessionGetterAndSetter } from '../EditSession'
import { makeStyles } from '@material-ui/core/styles'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => {
	const styles = {
		root: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			minWidth: 350,
			padding: theme.spacing(3),
		},
		icons: {
			display: 'flex',
			height: 36,
			position: 'absolute',
			right: -9,
			top: -8,
		},
		logo: {
			marginTop: '32px',
			maxWidth: '302px',
		},
		logoDropArea: {
			alignItems: 'center',
			background: 'rgba(0, 0, 0, 0.25)',
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2),
			height: '128px',
			justifyContent: 'center',
			marginTop: '32px',
			position: 'relative',
			userSelect: 'none',
		},
		logoDropAreaFileInputLabel: {
			bottom: 0,
			cursor: 'pointer',
			left: 0,
			position: 'absolute',
			right: 0,
			top: 0,
		},
	}

	styles[theme.breakpoints.down('sm')] = {
		root: {
			maxWidth: '100%',
			minWidth: 0,
		},
		logo: {
			maxWidth: 'initial',
		},
	}

	return styles
})

export default function OrganizationDialog({ editSession, setEditSession }) {
	const classes = useStyles()

	const [ get, set ] = getEditSessionGetterAndSetter(editSession, setEditSession)

	const { dispatch, state } = useStore()

	const setLogoFileId = set('logo_file_id', { updatesEditSession: true })
	const hasLogo = !!get('logo_file_id')

	const handleLogoChange = async (file) => {
		state.showLoading(dispatch, 'Uploading logo...')
		const res = await api.uploadFile(file, FileKinds.organization_logo)
		state.hideLoading(dispatch)
		if (!res.ok) {
			await state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}
		setLogoFileId({ target: { value: res.id } })
	}

	const handleImgDrop = async (e) => {
		e.preventDefault()
		if (!e.dataTransfer.items) return
		const item = Array.from(e.dataTransfer.items).filter(item => item.kind === 'file')[0]
		if (!item) return
		const file = item.getAsFile()
		if (!file.type.startsWith('image/')) return
		handleLogoChange(file)
	}

	const handleFileChange = (e) => {
		const file = e.target.files[0]
		if (!file) return
		if (!file.type.startsWith('image/')) return
		handleLogoChange(file)
	}

	return <EditDialog entityName='Organization' editSession={editSession} contain={false}>
		<div className={classes.root}>
			<Stack>
				<StandardTextField fullWidth label='NAME' defaultValue={get('name', '')} onChange={set('name')} autoFocus />
				<FormControl>
					<InputLabel>Logo</InputLabel>
					{ hasLogo && <div className={classes.icons}>
						<Tooltip title='Upload New Logo'>
							<FileUploadIcon acceptTypes={['image/*']} onChange={handleLogoChange} />
						</Tooltip>
						<Tooltip title='Clear Logo'>
							<IconButton size={'small'} style={{ width: '36px' }} onClick={() => setLogoFileId({ target: { value: null } })} >
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</div> }
					{ hasLogo
						? <img alt='' src={`/files/${get('logo_file_id')}`} className={classes.logo} onDrop={handleImgDrop} onDragOver={e => e.preventDefault()} />
						: <div className={classes.logoDropArea} onDrop={handleImgDrop} onDragOver={e => e.preventDefault()}>
							<FontAwesomeIcon icon={faUpload} style={{ fontSize: 32 }} />
							<Typography variant='body1'><b>Choose a file</b> or drag it here.</Typography>
							<label className={classes.logoDropAreaFileInputLabel}>
								<input type='file' accept='image/*' onChange={handleFileChange} style={{ display: 'none' }} />
							</label>
					  	</div> }
				</FormControl>
			</Stack>
		</div>
	</EditDialog>
}
