import TWEEN from '@tweenjs/tween.js'

function animate(time) {
	requestAnimationFrame(animate);
	TWEEN.update(time)
}
requestAnimationFrame(animate)

const tweenMap = new Map()

export function getTween(element) {
	return tweenMap.get(element)
}

export function setTween(element, from, to, easing, duration, onUpdate) {
	const currentTween = tweenMap.get(element)
	if (currentTween) currentTween.tween.stop()

	const tween = new TWEEN.Tween(from)
		.to(to, duration)
		.easing(easing)
		.onUpdate((obj) => { if (onUpdate(obj)) tween.stop() })
		.onComplete(() => { tweenMap.delete(element) })
		.onStop(() => { tweenMap.delete(element) })

	tweenMap.set(element, { tween, to })
	tween.start()
}

export const Easing = TWEEN.Easing
