import AppBar from '@material-ui/core/AppBar'
import CloseIcon from '@material-ui/icons/Close'
import Container from '@material-ui/core/Container'
import Dialog from '@material-ui/core/Dialog'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import React, { Fragment, useRef } from 'react'
import SaveIcon from '@material-ui/icons/Save'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => ({
	title: {
		flexGrow: 1,
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		textAlign: 'center',
		userSelect: 'none',
	},
	body: {
		display: 'flex',
		flexDirection: 'column',
		flexGrow: 1,
		flexShrink: 1,
		height: 'inherit',
		minHeight: 0,
	},
	container: {
		padding: theme.spacing(2),
		minWidth: 300,
	},
	paper: {
		margin: 0,
		maxHeight: '100%',
		maxWidth: '100%',
		overflow: 'hidden',
	},
	hiddenIconButton: {
		visibility: 'hidden',
	},
}))

const FadeTransition = React.forwardRef(function Transition(props, ref) {
	return <Fade ref={ref} {...props} />
})

const SlideTransition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

export default function StandardDialog(props) {
	const { title, open, children } = props
	const { contain, ContainerProps } = props
	const { onExited, onPreComplete, onCancel, onComplete, onPreCancel } = props
	const { cancelTooltip = 'Discard', completeTooltip = 'Save' } = props
	const { ToolbarProps } = props
	let { fullScreen, isMobile } = props

	const classes = useStyles()
	const history = useHistory()
	const theme = useTheme()
	const isMobileDefault = useMediaQuery(theme.breakpoints.down('xs'))
	const ref = useRef(null)
	const { state } = useStore()

	if (isMobile === undefined)
		isMobile = isMobileDefault

	if (fullScreen === undefined)
		fullScreen = isMobile

	const handleCancel = async () => {
		// eslint-disable-next-line
		if (await onPreCancel?.() ?? true) onCancel?.()
	}

	const handleComplete = async () => {
		// eslint-disable-next-line
		if (await onPreComplete?.() ?? true) onComplete?.()
	}

	const handleEnter = () => {
		state.pushBackHandler(history, async () => {
			await handleCancel()
		})
	}

	const handleExited = () => {
		state.popBackHandler()
		// eslint-disable-next-line
		onExited?.()
	}

	const handleKeyDown = (e) => {
		if (e.key !== 'Escape') return
		if (!ref.current) return
		if (ref.current.contains(e.target)) {
			e.target.blur()
			return
		}
		handleCancel()
		e.stopPropagation()
	}

	const paperProps = { className: classes.paper }

	let cancelIconEl = <IconButton className={clsx({ [classes.hiddenIconButton]: !onCancel })} edge={isMobile ? 'start' : undefined} color='inherit' onClick={handleCancel}><CloseIcon /></IconButton>
	let completeIconEl = <IconButton className={clsx({ [classes.hiddenIconButton]: !onComplete })} edge={isMobile ? 'end' : undefined} color='inherit' onClick={handleComplete}><SaveIcon /></IconButton>

	if (onCancel) cancelIconEl = <Tooltip title={cancelTooltip} enterDelay={500} placement='right'>{cancelIconEl}</Tooltip>
	if (onComplete) completeIconEl = <Tooltip title={completeTooltip} enterDelay={500} placement='left'>{completeIconEl}</Tooltip>

	return <Dialog disableEscapeKeyDown={true} className={classes.root} maxWidth={false} fullScreen={fullScreen} open={open}
		PaperProps={paperProps}
		TransitionComponent={isMobile ? SlideTransition : FadeTransition}
		TransitionProps={{ onEnter: handleEnter, onExited: handleExited }}
		onClose={handleCancel}
		onKeyDown={handleKeyDown}>
		<Fragment>
			<AppBar position='relative'>
				<Toolbar variant={isMobile ? 'regular' : 'dense'} disableGutters={!isMobile} {...ToolbarProps}>
					{cancelIconEl}
					<Typography variant={'h6'} className={classes.title}>{title}</Typography>
					{completeIconEl}
				</Toolbar>
			</AppBar>
		</Fragment>
		<div ref={ref} className={classes.body}>
			{contain === false ? children : <Container className={classes.container} {...ContainerProps}>{children}</Container>}
		</div>
	</Dialog>
}
