import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import ClearIcon from '@material-ui/icons/Clear'
import Delete from '@material-ui/icons/Delete'
import ErrorIcon from '@material-ui/icons/Error'
import IconButton from '@material-ui/core/IconButton'
import InfoIcon from '@material-ui/icons/Info'
import React, { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: 135,
	},
}))

export default function FileUpload({
	what, acceptTypes,
	hasError, hasExistingFile, hasInfo,
	onChange, onInvalidTypeProvided, onRemove,
	onView, onViewError, onViewInfo,
}) {
	const whatLowerCase = what.toLowerCase()

	const classes = useStyles()
	const theme = useTheme()

	const [ file, setFile ] = useState(null)
	const [ fileKey, setFileKey ] = useState(1)

	const isValidType = (type) => {
		if (!acceptTypes) return true
		if (acceptTypes.includes('audio/*') && type.startsWith('audio/')) return true
		if (acceptTypes.includes('image/*') && type.startsWith('image/')) return true
		if (acceptTypes.includes('video/*') && type.startsWith('video/')) return true
		return acceptTypes.includes(type)
	}

	const handleClear = (e) => {
		// eslint-disable-next-line
		onChange?.(null)
		setFile(null)
		setFileKey(fileKey + 1)
	}

	const handleDragOver = (e) => {
		e.preventDefault()
	}

	const handleDrop = (e) => {
		e.preventDefault()
		if (!e.dataTransfer.items) return
		const item = Array.from(e.dataTransfer.items).filter(item => item.kind === 'file')[0]
		if (!item) return
		const file = item.getAsFile()
		if (!isValidType(file.type)) {
			// eslint-disable-next-line
			onInvalidTypeProvided?.()
			return
		}
		// eslint-disable-next-line
		onChange?.(file)
		setFile(file)
		setFileKey(fileKey + 1)
	}

	const handleFileChange = (e) => {
		const file = e.target.files[0]
		if (!file) return
		if (!isValidType(file.type)) {
			// eslint-disable-next-line
			onInvalidTypeProvided?.()
			return
		}
		// eslint-disable-next-line
		onChange?.(file)
		setFile(file)
		setFileKey(fileKey + 1)
	}

	let children = []

	if (hasInfo) {
		children.push(<Tooltip key='view_info' title='View info'>
			<IconButton onClick={onViewInfo}><InfoIcon fontSize='small' /></IconButton>
		</Tooltip>)
	}

	if (file) {
		children.push(<Tooltip key='view_selected' title={`View selected ${whatLowerCase}`}>
			<IconButton onClick={() => onView?.(file)}><VisibilityIcon fontSize='small' /></IconButton>
		</Tooltip>)
		children.push(<Tooltip key='clear_selected' title={`Clear selected ${whatLowerCase}`}>
			<IconButton onClick={handleClear}><ClearIcon fontSize='small' /></IconButton>
		</Tooltip>)
	}
	else {
		if (hasError) {
			children.push(<Tooltip key='view_error' title='View error'>
				<IconButton onClick={onViewError}><ErrorIcon fontSize='small' /></IconButton>
			</Tooltip>)
		}

		if (hasExistingFile) {
			children.push(<Tooltip key='view_current' title={`View current ${whatLowerCase}`}>
				<IconButton onClick={() => onView?.()}><VisibilityIcon fontSize='small' /></IconButton>
			</Tooltip>)
			children.push(<Tooltip key='remove_current' title={`Remove current ${whatLowerCase}`}>
				<IconButton onClick={onRemove}><Delete fontSize='small' /></IconButton>
			</Tooltip>)
		}
	}

	return <Card variant='outlined' className={classes.root} onDrop={handleDrop} onDragOver={handleDragOver}>
		<CardContent>
			<div style={{ display: 'flex', marginBottom: theme.spacing(1) }}>
				<Typography variant='h6' style={{ flexGrow: 1, height: 47 }}>{what}</Typography>
				{children}
			</div>
			<Button style={{ width: '100%' }} variant='contained' component='label'>
				{ file ? (file.name + ' SELECTED FOR UPLOAD') : 'UPLOAD' }
				<input type='file' accept={acceptTypes.join(',')} onChange={handleFileChange} key={fileKey} style={{ display: 'none' }} />
			</Button>
		</CardContent>
	</Card>
}
