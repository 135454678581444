import * as dayjs from 'dayjs'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useEffect, useState } from 'react'
import Select from '@material-ui/core/Select'
import Stack from '../Components/Stack'
import StandardDialog from './StandardDialog'
import api from '../api'
import { CategoryOwnerKind } from '../types'
import { StandardDatePicker } from '../Components/StandardDatePicker'
import { useStore } from '../StoreProvider'

const getDefaultParams = (facilities) => ({
	ownerId: facilities[0]?.id,
	ownerKind: CategoryOwnerKind.facility,
	startAt: null,
	endAt: null,
})

export default function CreateFeatureDialog() {
	const { state, dispatch } = useStore()
	const { open, entityId, entityKind, facilities } = state.createFeatureDialog
	const [ params, setParams ] = useState(getDefaultParams(facilities))

	const handleChange = (fieldName) => (e) => {
		setParams({ ...params, [fieldName]: e.target.value })
	}

	const hide = () => {
		dispatch({ type: 'create_feature_dialog.hide' })
	}

	const handleSave = async () => {
		const params2 = {
			entity: {
				id: entityId,
				kind: entityKind,
			},
			owner: {
				id: params.ownerId,
				kind: params.ownerKind,
			},
		}

		// default to today
		const startAt = dayjs(params.startAt ?? undefined).startOf('day')
		params2.start_at = startAt.format('YYYY-MM-DD HH:mm:ss')

		if (params.endAt) {
			const endAt = dayjs(params.endAt).add(1, 'day')

			if (endAt.isBefore(startAt)) {
				await state.showError(dispatch, 'The end date must be after the start date.')
				return
			}

			params2.end_at = endAt.format('YYYY-MM-DD HH:mm:ss')
		}

		state.showLoading(dispatch)
		const res = await api.postCategoryLinksFeature(params2)
		state.hideLoading(dispatch)

		if (!res.ok) {
			await state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}

		// eslint-disable-next-line
		state.createFeatureDialog.cb?.()
		hide()
	}

	useEffect(() => {
		setParams(getDefaultParams(facilities))
		// eslint-disable-next-line
	}, [state.createFeatureDialog])

	return <StandardDialog title='Feature Drill' open={open} onCancel={hide} onComplete={handleSave}>
		<Stack>
			{ facilities.length > 1 && <FormControl>
				<FormHelperText>Facility</FormHelperText>
				<Select value={params.ownerId} onChange={handleChange('ownerId')}>
					{facilities.map(i => <MenuItem key={i.id} value={i.id}>{i.name}</MenuItem>)}
				</Select>
			</FormControl> }
			<Stack horizontal medium>
				<StandardDatePicker label='Start Date' defaultValue={params.startAt} onChange={handleChange('startAt')} />
				<StandardDatePicker label='End Date' defaultValue={params.endAt} onChange={handleChange('endAt')} />
			</Stack>
		</Stack>
	</StandardDialog>
}
