import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiDialog-paper': {
			minWidth: 300,
		},
	}
}))

export default function ConfirmationDialog2() {
	const classes = useStyles()
	const history = useHistory()
	const { state, dispatch } = useStore()
	const { open, title, message, yes, no, resolve } = state.confirmationDialog

	const confirmed = (value) => {
		dispatch({ type: 'confirmation_dialog.hide' })
		resolve(value)
	}

	return <Dialog className={classes.root} maxWidth='xs'
		open={open}
		onClose={() => confirmed(false)}
		TransitionProps={{
			onEnter: () => state.pushBackHandler(history),
			onExited: () => state.popBackHandler(),
		}}>
		<DialogTitle>{title ?? ''}</DialogTitle>
		<DialogContent>
			<DialogContentText>{message ?? ''}</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={() => confirmed(false)}>{no ?? 'No'}</Button>
			<Button onClick={() => confirmed(true)}>{yes ?? 'Yes'}</Button>
		</DialogActions>
	</Dialog>
}
