import * as utils from './utils'
import { DrillLimitGoal, DrillLimitKind } from './types'

function isLocationNormal(location) {
	return location.kind !== window.courtLib.types.LocationKind.globalBallAction
}

export default {
	isLocationNormal,
	DrillLimitKindNames: {
		[DrillLimitKind.makes]: 'MAKES',
		[DrillLimitKind.shots]: 'SHOTS',
		[DrillLimitKind.time]: 'TIME',
		[DrillLimitKind.number_of_runs]: 'RUNS',
	},
	DrillLimitGoalNames: {
		[DrillLimitGoal.makes]: 'MAKES',
		[DrillLimitGoal.shots]: 'SHOTS',
		[DrillLimitGoal.time]: 'TIME',
	},
	getLimitDescription(count, goal, kind, compactTime) {
		const suffix = count === 1 ? '' : 's'
		switch (kind) {
			case DrillLimitKind.number_of_runs:
				switch (goal) {
					case DrillLimitGoal.makes: return `Most makes in ${count} run${suffix}`
					case DrillLimitGoal.shots: return `Least shots in ${count} run${suffix}`
					case DrillLimitGoal.time: return `Quickest time to ${count} run${suffix}`
					case DrillLimitGoal.none: return `${count} run${suffix}`
					default:
				}
				break
			case DrillLimitKind.makes:
				switch (goal) {
					case DrillLimitGoal.shots: return `Least shots to ${count} make${suffix}`
					case DrillLimitGoal.time: return `Quickest time to ${count} make${suffix}`
					case DrillLimitGoal.none: return `${count} make${suffix}`
					default:
				}
				break
			case DrillLimitKind.shots:
				switch (goal) {
					case DrillLimitGoal.makes: return `Most makes in ${count} shot${suffix}`
					// case DrillLimitGoal.time: return `Quickest time to ${count} shot${suffix}`
					case DrillLimitGoal.none: return `${count} shot${suffix}`
					default:
				}
				break
			case DrillLimitKind.time:
				count = compactTime ? utils.formatDurationCompact(count) : utils.formatDuration(count)
				switch (goal) {
					case DrillLimitGoal.makes: return `Most makes in ${count}`
					// case DrillLimitGoal.shots: return `Most shots in ${count}`
					case DrillLimitGoal.none: return count
					default:
				}
				break
			default:
		}
	},
	getNormalLocationCount(locations) {
		return locations.filter(isLocationNormal).length
	},
	isDrillSupported({ data }) {
		return data.format_version <= 3
	},
	upgrade({ data }) {
		return window.courtLib.types.newDrill(data)
	},
}
