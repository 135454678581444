import {
	StandardTable as Table,
	StandardTableAction as TableAction,
	StandardTableActions as TableActions,
	StandardTableColumn as TableColumn,
	StandardTableColumns as TableColumns,
} from '../Components/StandardTable'
import Container from '@material-ui/core/Container'
import EditDialog from '../Dialogs/EditDialog'
import GunReassignDialog from '../Dialogs/GunReassignDialog'
import Header from '../Components/Header'
import IconButton from '@material-ui/core/IconButton'
import ListIcon from '@material-ui/icons/List'
import Page from './Page'
import React, { Fragment, useEffect, useState } from 'react'
import Stack from '../Components/Stack'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import { getEditSessionGetterAndSetter, startEditSession } from '../EditSession'
import { makeStyles } from '@material-ui/core/styles'
import { useAlertDialog } from '../Dialogs/AlertDialog'
import { useConfirmationDialog } from '../Dialogs/ConfirmationDialog'
import { useHistory, useLocation } from 'react-router-dom'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => ({
	header: {
		fontSize: 32,
		fontWeight: 'bold',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
}))

export default function GunsPage() {
	const classes = useStyles()
	const history = useHistory()
	const location = useLocation()
	const { state } = useStore()

	const [ fetchedData, setFetchedData ] = useState(null)
	const [ gunForGunReassignDialog, setGunForGunReassignDialog ] = useState(null)
	const [ gunReassignDialogOpen, setGunReassignDialogOpen ] = useState(false)
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const [ confirmationDialogOptions, setConfirmationDialogOptions ] = useState(null)
	const showConfirmationDialog = useConfirmationDialog(setConfirmationDialogOptions)

	const [ editSession, setEditSession ] = useState(null)

	const fetchData = async () => {
		setLoadingOverlayText('Loading...')
		const res = await api.getGuns()
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		return res
	}

	const fetchAndSetData = async () => {
		const res = await fetchData()
		if (res) setFetchedData(res)
	}

	const getTableColumns = () => {
		return [
			<TableColumn key='name' fieldName='name'>Name</TableColumn>,
			// <TableColumn key='updated_at' fieldName='updated_at' onTransform={transform.timestamp}>Updated At</TableColumn>,
			<TableColumn key='serial_number' fieldName='serial_number' width={100}>Serial #</TableColumn>,
			// <TableColumn key='makes' fieldName='makes' onTransform={(_, item) => item.stats?.makes}>Makes</TableColumn>,
			// <TableColumn key='shots' fieldName='shots' onTransform={(_, item) => item.stats?.shots}>Shots</TableColumn>,
			// <TableColumn key='runtime' fieldName='runtime' onTransform={(_, item) => {
			// 	if (!item.stats || !item.stats.runtime) return ''
			// 	return utils.formatDuration(item.stats.runtime)
			// }}>Runtime</TableColumn>,
		]
	}

	const handleEdit = async (item) => {
		await startEditSession(setEditSession, item, {
			async onCancel() {
				return await showConfirmationDialog('CONFIRM', "Are you sure you'd like to discard your changes to this Gun?")
			},
			async onComplete(item) {
				setLoadingOverlayText('Updating Gun...')
				const res = await api.patchGun(item.id, item)
				if (!res.ok) {
					setLoadingOverlayText('')
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return false
				}
				await fetchAndSetData()
				setLoadingOverlayText('')
				return true
			},
		})
	}

	const handleGunReassignDialogShow = async (item) => {
		setGunReassignDialogOpen(true)
		setGunForGunReassignDialog(item)
	}

	const handleGunReassignDialogCancel = () => {
		setGunReassignDialogOpen(false)
	}

	const handleGunReassignDialogComplete = async (facility_id) => {
		setLoadingOverlayText('Reassigning Gun...')
		const res = await api.patchGunRegister({ gun_id: gunForGunReassignDialog.id, facility_id })
		if (!res.ok) {
			setLoadingOverlayText('')
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
		setLoadingOverlayText('')
		setGunForGunReassignDialog(null)
		setGunReassignDialogOpen(false)
		return res
	}

	const handleGunReassignDialogExited = () => {
		setGunForGunReassignDialog(null)
	}

	useEffect(() => {
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [location])

	const [ get, set ] = getEditSessionGetterAndSetter(editSession, setEditSession)

	const personalGuns = []
	const gunsByOrganizationAndFacilityMap = new Map()

	if (fetchedData) {
		for (const gun of fetchedData.guns) {
			const { facility_id, facility_name, organization_id, organization_name } = gun
			if (organization_id) {
				const id = organization_id + '-' + facility_id
				const name = organization_name + ' - ' + facility_name
				let item = gunsByOrganizationAndFacilityMap.get(id)
				if (!item) {
					item = { id, name, guns: [] }
					gunsByOrganizationAndFacilityMap.set(id, item)
				}
				item.guns.push(gun)
			}
			else {
				personalGuns.push(gun)
			}
		}
	}

	const gunsByOrganizationAndFacility = []
	for (const org of gunsByOrganizationAndFacilityMap.values()) {
		org.guns.sort((a, b) => a.name.localeCompare(b.name))
		gunsByOrganizationAndFacility.push(org)
	}
	gunsByOrganizationAndFacility.sort((a, b) => a.name.localeCompare(b.name))

	const hasOrganization = state.user.info.has_organization

	return <Page padding='bottom' alertDialogOptions={alertDialogOptions} confirmationDialogOptions={confirmationDialogOptions} loadingText={loadingOverlayText}>
		<EditDialog entityName='Gun' editSession={editSession}>
			<Stack horizontal={false}>
				<TextField label='Name' fullWidth defaultValue={get('name', '')} onChange={set('name')} autoFocus />
			</Stack>
		</EditDialog>
		<GunReassignDialog gun={gunForGunReassignDialog} open={gunReassignDialogOpen}
			onCancel={handleGunReassignDialogCancel}
			onComplete={handleGunReassignDialogComplete}
			onExited={handleGunReassignDialogExited} />
		<Container>
			<Header>
				<Typography variant='inherit'>Guns</Typography>
				{ state.user.is_admin ? <Tooltip title='View All Guns'>
					<IconButton onClick={() => history.push('/admin/guns')}><ListIcon /></IconButton>
				</Tooltip> : undefined }
			</Header>
			{ personalGuns.length > 0 ? <Fragment>
				<Header className={classes.header}>
					<Typography variant='inherit'>My Guns</Typography>
				</Header>
				<Table pagination={false} items={personalGuns}>
					<TableColumns>{getTableColumns()}</TableColumns>
					<TableActions>
						<TableAction onClick={handleEdit}>Edit</TableAction>
						<TableAction onClick={handleGunReassignDialogShow} onAvailable={() => hasOrganization}>Reassign</TableAction>
					</TableActions>
				</Table>
			</Fragment> : undefined }
			{ gunsByOrganizationAndFacility.length > 0 ? <Fragment>
				{ gunsByOrganizationAndFacility.map(({ id, name, guns }) => {
					return <div key={id}>
						<Header className={classes.header}>
							<Typography variant='inherit'>{name}</Typography>
						</Header>
						<Table pagination={false} items={guns}>
							<TableColumns>{getTableColumns()}</TableColumns>
							<TableActions>
								<TableAction onClick={handleEdit}>Edit</TableAction>
								<TableAction onClick={handleGunReassignDialogShow} onAvailable={() => hasOrganization}>Reassign</TableAction>
							</TableActions>
						</Table>
					</div>
				}) }
			</Fragment> : undefined }
		</Container>
	</Page>
}
