import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import OptionsButton from '../Components/OptionsButton'
import React, { useEffect, useState } from 'react'
import Stack from '../Components/Stack'
import StandardDialog from './StandardDialog'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { TvLeaderboardEntityKind, TvLeaderboardSections, TvLeaderboardSectionNames } from '../types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useStore } from '../StoreProvider'

const RankedSections = new Set([
	TvLeaderboardSections.most_career_makes,
	TvLeaderboardSections.most_daily_makes,
	TvLeaderboardSections.most_daily_shots,
	TvLeaderboardSections.most_weekly_makes,
	TvLeaderboardSections.org_most_career_makes,
	TvLeaderboardSections.org_most_daily_makes,
	TvLeaderboardSections.org_most_daily_shots,
	TvLeaderboardSections.org_most_weekly_makes,
	TvLeaderboardSections.featured_drills,
])

const TotalsSections = new Set([
	TvLeaderboardSections.totals_lifetime,
	TvLeaderboardSections.totals_today,
])

const RankedAddSectionOptionsForFacility = Array.from(RankedSections.values())
	.map(id => ({ id, name: TvLeaderboardSectionNames[id] }))
const RankedAddSectionOptionsForTeam = Array.from(RankedSections.values())
	.filter(id => id !== TvLeaderboardSections.featured_drills)
	.map(id => ({ id, name: TvLeaderboardSectionNames[id] }))
const TotalsAddSectionOptions = Array.from(TotalsSections.values())
	.map(id => ({ id, name: TvLeaderboardSectionNames[id] }))

const useStyles = makeStyles((theme) => {
	const styles = {
		root: {
			display: 'flex',
			flexDirection: 'row',
			padding: theme.spacing(2),
			gap: theme.spacing(2),
		},
		side: {
			display: 'flex',
			flexDirection: 'column',
			height: '100%',
			minWidth: 350,
			gap: theme.spacing(2),
		},
		droppable: {
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
			height: '55vh',
			overflowY: 'auto',
			padding: theme.spacing(0.5),
			paddingTop: 0,
			width: '360px',
		},
		section: {
			alignItems: 'center',
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
			display: 'flex',
			flexDirection: 'row',
			marginTop: theme.spacing(0.5),
			paddingLeft: theme.spacing(1),
			padding: theme.spacing(0.5),
		},
		sectionDragging: {
			backgroundColor: 'rgba(0, 0, 0, 0.2)',
		},
	}

	styles[theme.breakpoints.down('xs')] = {
		side: {
			maxWidth: '100%',
			minWidth: 0,
			overflowY: 'auto',
		},
		droppable: {
			overflowY: 'visible',
			height: 'auto',
			width: 'auto',
		},
	}

	return styles
})

function SideSection({ id, name, index, onRemove }) {
	const classes = useStyles()

	return <Draggable draggableId={`${id}-${index}`} index={index}>
		{({ dragHandleProps, draggableProps, innerRef }, snapshot) => {
			const className = clsx(classes.section, snapshot.isDragging && classes.sectionDragging)
			return <div ref={innerRef} {...draggableProps} {...dragHandleProps} className={className}>
				<Typography variant='subtitle1'>{name}</Typography>
				<IconButton size={'small'} style={{ marginLeft: 'auto', width: '36px', height: '36px' }} onClick={() => onRemove(id)}>
					<DeleteOutlinedIcon />
				</IconButton>
			</div>
		}}
	</Draggable>
}

function Side({ name, sections, options, onChange }) {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

	const handleAdd = ({ target: { value: id } }) => {
		id = parseInt(id)
		onChange(sections.slice().concat([{ id }]))
	}

	const handleRemove = (index) => {
		const sections2 = sections.slice()
		sections2.splice(index, 1)
		onChange(sections2)
	}

	const handleDragEnd = ({ source, destination }) => {
		if (!destination) return
		const sections2 = sections.slice()
		const [removed] = sections2.splice(source.index, 1)
		sections2.splice(destination.index, 0, removed)
		onChange(sections2)
	}

	return <div className={classes.side} style={{ flexDirection: isMobile ? 'column' : 'row' }}>
		<DragDropContext onDragEnd={handleDragEnd}>
			<Stack horizontal={false} grow={false}>
				<Stack spacing={false} grow={false} style={{ flexGrow: 1 }}>
					<InputLabel style={{ paddingBottom: 6 }}>{name} Sections</InputLabel>
					<Droppable droppableId='ordered'>{(provided, _) =>
						<div {...provided.droppableProps} ref={provided.innerRef} className={classes.droppable} style={{ flexGrow: isMobile ? 0 : 1 }}>
							{sections.map(({ id }, index) => {
								const name = TvLeaderboardSectionNames[id]
								return <SideSection id={id} name={name} index={index} key={`${id}-${index}`} onRemove={() => handleRemove(index)} />
							})}
							{provided.placeholder}
						</div>
					}</Droppable>
				</Stack>
				<OptionsButton options={options} onClick={handleAdd}>Add {name} Section</OptionsButton>
			</Stack>
		</DragDropContext>
	</div>
}

export default function TvCombinedLeaderboardEditorDialog({ entityKind, open, sections, onCancel, onComplete }) {
	const classes = useStyles()
	const { state, dispatch } = useStore()

	const [rankedSections, setRankedSections] = useState([])
	const [totalsSections, setTotalsSections] = useState([])

	useEffect(() => {
		setRankedSections(sections.filter(({ id }) => RankedSections.has(id)))
		setTotalsSections(sections.filter(({ id }) => TotalsSections.has(id)))
		// eslint-disable-next-line
	}, [sections])

	const handleComplete = () => {
		if (rankedSections.length === 0) {
			state.showError(dispatch, 'At least once Ranked Section must be added.')
			return
		}
		onComplete(totalsSections.concat(rankedSections))
	}

	const rankedAddSectionOptions = entityKind === TvLeaderboardEntityKind.facility
		? RankedAddSectionOptionsForFacility
		: RankedAddSectionOptionsForTeam

	return <StandardDialog open={open} title='Edit Sections' contain={false} onCancel={onCancel} onComplete={handleComplete}>
		<div className={classes.root}>
			<Side name='Totals' sections={totalsSections} options={TotalsAddSectionOptions} onChange={(sections) => setTotalsSections(sections)} />
			<Side name='Ranked' sections={rankedSections} options={rankedAddSectionOptions} onChange={(sections) => setRankedSections(sections)} />
		</div>
	</StandardDialog>
}
