import * as utils from '../utils'
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import Court from '../Components/Court'
import EntitySelector from '../Components/EntitySelector'
import Page from './Page'
import Paper from '@material-ui/core/Paper'
import RadioBar from '../Components/RadioBar'
import React, { Fragment, useEffect, useRef, useState } from 'react'
import Stack from '../Components/Stack'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography'
import api from '../api'
import dayjs from 'dayjs'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import useQuery from '../hooks/useQuery'
import { Bar, Line } from 'react-chartjs-2'
import { DrillZone } from '../types'
import { StandardDatePicker } from '../Components/StandardDatePicker'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'
import { useStore } from '../StoreProvider'
import { useDebounce } from 'use-debounce'

const isTinyMediaQuery = '@media (max-width: 400px)'
const isMobileMediaQuery = '@media (max-width: 660px)'
const isTabletMediaQuery = '@media (max-width: 1130px)'

const useStyles = makeStyles((theme) => ({
	chart: {
		width: '100%',
		height: 300,
		padding: 16,
		// '& > canvas': {
		// 	touchAction: 'none',
		// },
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: 16,
		padding: 0,
	},
	court: {
		position: 'relative'
	},
	courtNoDataOverlay: {
		alignItems: 'center',
		background: 'rgba(0, 0, 0, 0.5)',
		bottom: 0,
		display: 'flex',
		justifyContent: 'center',
		left: 0,
		position: 'absolute',
		right: 0,
		top: 0,
	},
	usersTableBodyCell: {
		whiteSpace: 'nowrap',
	},
	usersTableBody: {
		'& .MuiTableRow-root': {
			cursor: 'pointer',
		},
	},
}))

function getMonthsOfYear(start) {
	return Array(12).fill(null).map((v, i) => {
		const d = start.add(i, 'month')
		return newDateRange({ label: d.format('MMM'), start: d.startOf('month'), end: d.endOf('month') })
	})
}

function generateDateRangesFromDates(startDate, endDate) {
	const dates = []

	const days = endDate.add(1, 'day').diff(startDate, 'day')
	if (days < 10) {
		for (let i = 0; i < days; i++) {
			const d = startDate.add(i, 'day')
			dates.push(newDateRange({ label: d.format('MMM D'), start: d, end: d }))
		}
		return dates
	}

	const step = Math.ceil(days / 6)
	for (let i = 0; i < 6; i++) {
		const start = startDate.add(i * step, 'day')
		const end = i < 5 ? startDate.add((i + 1) * step, 'day').add(-1, 'day') : endDate
		const label = `${start.format('MMM D')} - ${end.format(start.isSame(end, 'month') ? 'D' : 'MMM D')}`
		dates.push(newDateRange({ label, start, end }))
	}
	return dates
}

function getWeeksOfMonth(start) {
	const dates = []
	{
		const end = start.endOf('isoWeek')
		const label = `${start.format('MMM D')} - ${end.format(start.isSame(end, 'month') ? 'D' : 'MMM D')}`
		dates.push(newDateRange({ label, start, end }))
	}
	for (let i = 0; i < 5; i++) {
		const ns = dates[i].end.add(1, 'day').startOf('day')
		if (!ns.isSame(start, 'month')) break
		const end = ns.endOf('isoWeek').isSame(start, 'month') ? ns.endOf('isoWeek') : start.endOf('month')
		const label = `${ns.format('MMM D')} - ${end.format(ns.isSame(end, 'month') ? 'D' : 'MMM D')}`
		dates.push(newDateRange({ label, start: ns, end }))
	}
	return dates
}

function getDaysOfWeek(start) {
	return Array(7).fill(null).map((v, i) => {
		const d = start.add(i, 'day')
		return newDateRange({ label: d.format('MMM D'), start: d, end: d })
	})
}

function getButtonProps(isSelected) {
	return {
		fullWidth: true,
		variant: 'contained',
		color: isSelected ? 'primary' : 'default',
		style: { whiteSpace: 'nowrap' }
	}
}

function mergeDateRanges(items, label) {
	return items.reduce((obj, item, i) => {
		if (i === 0) {
			obj.start = item.start
		}
		else if (i === items.length - 1) {
			obj.end = item.end
			if (!label) {
				const startStr = dayjs(obj.start).format('MMM D YYYY')
				const endStr = dayjs(obj.end).format('MMM D YYYY')
				obj.label = `${startStr} - ${endStr}`
			}
		}

		obj.data.m += item.data.m
		obj.data.s += item.data.s

		const objZones = obj.data.zones
		const itemZones = item.data.zones

		objZones[DrillZone.z1_p2].m += itemZones[DrillZone.z1_p2].m
		objZones[DrillZone.z1_p3].m += itemZones[DrillZone.z1_p3].m
		objZones[DrillZone.z2_p2].m += itemZones[DrillZone.z2_p2].m
		objZones[DrillZone.z2_p3].m += itemZones[DrillZone.z2_p3].m
		objZones[DrillZone.z3_p1].m += itemZones[DrillZone.z3_p1].m
		objZones[DrillZone.z3_p2].m += itemZones[DrillZone.z3_p2].m
		objZones[DrillZone.z3_p3].m += itemZones[DrillZone.z3_p3].m
		objZones[DrillZone.z4_p2].m += itemZones[DrillZone.z4_p2].m
		objZones[DrillZone.z4_p3].m += itemZones[DrillZone.z4_p3].m
		objZones[DrillZone.z5_p2].m += itemZones[DrillZone.z5_p2].m
		objZones[DrillZone.z5_p3].m += itemZones[DrillZone.z5_p3].m

		objZones[DrillZone.z1_p2].s += itemZones[DrillZone.z1_p2].s
		objZones[DrillZone.z1_p3].s += itemZones[DrillZone.z1_p3].s
		objZones[DrillZone.z2_p2].s += itemZones[DrillZone.z2_p2].s
		objZones[DrillZone.z2_p3].s += itemZones[DrillZone.z2_p3].s
		objZones[DrillZone.z3_p1].s += itemZones[DrillZone.z3_p1].s
		objZones[DrillZone.z3_p2].s += itemZones[DrillZone.z3_p2].s
		objZones[DrillZone.z3_p3].s += itemZones[DrillZone.z3_p3].s
		objZones[DrillZone.z4_p2].s += itemZones[DrillZone.z4_p2].s
		objZones[DrillZone.z4_p3].s += itemZones[DrillZone.z4_p3].s
		objZones[DrillZone.z5_p2].s += itemZones[DrillZone.z5_p2].s
		objZones[DrillZone.z5_p3].s += itemZones[DrillZone.z5_p3].s

		if (item.data.highestStreak > obj.data.highestStreak)
			obj.data.highestStreak = item.data.highestStreak

		return obj
	}, newDateRange({ label }))
}

function appendDayToDateRange(day, dateRange) {
	const d = dayjs(day.day)
	if (!d.isSame(dateRange.start) && !d.isBetween(dateRange.start, dateRange.end, null, '[]')) return

	const { data } = dateRange

	data.m += day.m
	data.s += day.s

	data.zones[DrillZone.z1_p2].m += day.m_z_1
	data.zones[DrillZone.z1_p3].m += day.m_z_2
	data.zones[DrillZone.z2_p2].m += day.m_z_3
	data.zones[DrillZone.z2_p3].m += day.m_z_4
	data.zones[DrillZone.z3_p1].m += day.m_z_5
	data.zones[DrillZone.z3_p2].m += day.m_z_6
	data.zones[DrillZone.z3_p3].m += day.m_z_7
	data.zones[DrillZone.z4_p2].m += day.m_z_8
	data.zones[DrillZone.z4_p3].m += day.m_z_9
	data.zones[DrillZone.z5_p2].m += day.m_z_10
	data.zones[DrillZone.z5_p3].m += day.m_z_11

	data.zones[DrillZone.z1_p2].s += day.s_z_1
	data.zones[DrillZone.z1_p3].s += day.s_z_2
	data.zones[DrillZone.z2_p2].s += day.s_z_3
	data.zones[DrillZone.z2_p3].s += day.s_z_4
	data.zones[DrillZone.z3_p1].s += day.s_z_5
	data.zones[DrillZone.z3_p2].s += day.s_z_6
	data.zones[DrillZone.z3_p3].s += day.s_z_7
	data.zones[DrillZone.z4_p2].s += day.s_z_8
	data.zones[DrillZone.z4_p3].s += day.s_z_9
	data.zones[DrillZone.z5_p2].s += day.s_z_10
	data.zones[DrillZone.z5_p3].s += day.s_z_11

	if (day.highest_streak > data.highestStreak)
		data.highestStreak = day.highest_streak
}

function newDateRange(defaults) {
	return Object.assign({
		label: '',
		start: dayjs(0),
		end: dayjs(0),
		data: {
			m: 0,
			s: 0,
			highestStreak: 0,
			zones: {
				[DrillZone.z1_p2]: { m: 0, s: 0 },
				[DrillZone.z1_p3]: { m: 0, s: 0 },
				[DrillZone.z2_p2]: { m: 0, s: 0 },
				[DrillZone.z2_p3]: { m: 0, s: 0 },
				[DrillZone.z3_p1]: { m: 0, s: 0 },
				[DrillZone.z3_p2]: { m: 0, s: 0 },
				[DrillZone.z3_p3]: { m: 0, s: 0 },
				[DrillZone.z4_p2]: { m: 0, s: 0 },
				[DrillZone.z4_p3]: { m: 0, s: 0 },
				[DrillZone.z5_p2]: { m: 0, s: 0 },
				[DrillZone.z5_p3]: { m: 0, s: 0 },
			},
		},
	}, defaults)
}

export default function Stats2Page() {
	const classes = useStyles()
	const courtRef = useRef()
	const history = useHistory()
	const query = useQuery()
	const theme = useTheme()
	const location = useLocation()
	const isTiny = useMediaQuery(isTinyMediaQuery)
	const isMobile = useMediaQuery(isMobileMediaQuery)
	const isTablet = useMediaQuery(isTabletMediaQuery)
	const isDesktop = !isMobile && !isTablet
	const { dispatch, state } = useStore()
	const [relations, setRelations] = useState(null)
	const [dateRanges, setDateRanges] = useState(null)
	const [decisionModeData, setDecisionModeData] = useState(undefined)
	const [selectedDateRangeIndex, setSelectedDateRangeIndex] = useState(0)
	const [barOptions, setBarOptions] = useState({})
	const [lineOptions, setLineOptions] = useState({})
	const [users, setUsers] = useState([])
	const [usersSorted, setUsersSorted] = useState([])
	const [usersFiltered, setUsersFiltered] = useState([])
	const [usersPage, setUsersPage] = React.useState(0)
	const [usersPerPage, setUsersPerPage] = React.useState(25)
	const [usersForCurrentPage, setUsersForCurrentPage] = useState([])
	const [usersFilter, setUsersFilter] = useState('')
	const [usersFilterDebounced] = useDebounce(usersFilter, 250)
	const [usersTableSortDirection, setUsersTableSortDirection] = useState('asc')
	const [usersTableSortKey, setUsersTableSortKey] = useState('name')
	const [usersTableSortType, setUsersTableSortType] = useState('string')
	const [loadingRelations, setLoadingRelations] = useState(false)

	const [barData, setBarData] = useState({
		datasets: [{
			backgroundColor: '#c00',
			borderWidth: { top: 1, right: 1, bottom: 0, left: 1 },
			label: 'Makes',
		}, {
			backgroundColor: '#039',
			borderWidth: { top: 1, right: 1, bottom: 2, left: 1 },
			label: 'Shots',
		}],
	})

	const [lineData, setLineData] = useState({
		datasets: [{
			backgroundColor: '#c00',
			borderWidth: 1,
			label: 'Percent',
		}, {
			backgroundColor: '#039',
			label: 'Shots',
		}],
	})

	// TODO we'll use this setter when we add support for showing compare data in the table
	const [usersTableColumns] = useState([
		{ key: 'name', label: 'Name', align: 'left', width: '40%', type: 'string' },
		{ key: 'm', label: 'Makes', align: 'right', width: '20%', type: 'number', onFormat: (v) => v.toLocaleString() },
		{ key: 's', label: 'Shots', align: 'right', width: '20%', type: 'number', onFormat: (v) => v.toLocaleString() },
		{ key: 'p', label: 'Percent', align: 'right', width: '20%', type: 'number', onFormat: (v) => v + '%' },
	])

	const compare = query.get('compare')
	const view = query.get('view')
	let startDate = query.get('start')
	let endDate = query.get('end')
	let compareStartDate = query.get('compare_start')
	let compareEndDate = query.get('compare_end')

	switch (view) {
		case 'career': break
		case 'custom': break
		case 'month':
			startDate = dayjs().startOf('month').format('YYYY-MM-DD')
			endDate = dayjs().endOf('month').format('YYYY-MM-DD')
			compareStartDate = dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
			compareEndDate = dayjs().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
			break
		case 'year':
			startDate = dayjs().startOf('year').format('YYYY-MM-DD')
			endDate = dayjs().endOf('year').format('YYYY-MM-DD')
			compareStartDate = dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD')
			compareEndDate = dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
			break
		default:
			startDate = dayjs().startOf('isoWeek').format('YYYY-MM-DD')
			endDate = dayjs().endOf('isoWeek').format('YYYY-MM-DD')
			compareStartDate = dayjs().subtract(1, 'week').startOf('isoWeek').format('YYYY-MM-DD')
			compareEndDate = dayjs().subtract(1, 'week').endOf('isoWeek').format('YYYY-MM-DD')
			break
	}

	if (compare === 'team') {
		compareStartDate = startDate
		compareEndDate = endDate
	}

	const hasBothCompareDates = Boolean(compareStartDate) && Boolean(compareEndDate)

	// we default to showing the stats of the currently logged in user
	let selectedEntityName = state.user.name
	let entitySelectorValue = 'user-' + state.user.id
	let shouldShowEntityName = false
	{
		const user = parseInt(query.get('user'))
		const facility = parseInt(query.get('facility'))
		const team = parseInt(query.get('team'))

		if (user) {
			entitySelectorValue = 'user-' + user
			selectedEntityName = relations?.userName ?? selectedEntityName
			shouldShowEntityName = selectedEntityName !== state.user.name
		}
		else if (facility) {
			entitySelectorValue = 'facility-' + facility
			selectedEntityName = relations?.facilities?.find(i => i.id === facility)?.name ?? 'Unknown Facility'
		}
		else if (team) {
			entitySelectorValue = 'team-' + team
			selectedEntityName = relations?.teams?.find(i => i.id === team)?.name ?? 'Unknown Team'
		}
	}

	const entitySelectorGroups = [{
		key: 'user',
		name: 'User',
		options: [{
			id: query.get('user') ? parseInt(query.get('user')) : state.user.id,
			name: relations?.userName ?? state.user.name,
		}],
	}]

	if (relations?.facilities.length) {
		entitySelectorGroups.push({
			key: 'facility',
			name: 'Facility',
			options: relations.facilities,
		})
		shouldShowEntityName = true
	}

	if (relations?.teams.length) {
		entitySelectorGroups.push({
			key: 'team',
			name: 'Team',
			options: relations.teams,
		})
		shouldShowEntityName = true
	}

	const clearData = () => {
		setDateRanges(null)
		setUsers([])
	}

	const fetchData = async () => {
		if (view === 'custom') {
			if (!startDate || !endDate)
				return clearData()
			if (compare && (!compareStartDate || !compareEndDate))
				return clearData()
		}

		let [what, id] = entitySelectorValue.split('-')
		id = parseInt(id)

		const firstTeam = relations.teams[0]

		const promises = {}
		if (view === 'career') {
			// eslint-disable-next-line default-case
			switch (what) {
				case 'user':
					promises.viewRes = api.getDrillSessionStatsForUserCareer({ user_id: id })
					if (compare === 'team')
						promises.compareRes = api.getDrillSessionStatsForTeamCareer(firstTeam.id) // TODO need to handle being part of multiple teams
                    promises.decisionModeRes = api.getDrillSessionStatsForUserDecisionMode({ user_id: id })
					break
				case 'facility':
					promises.viewRes = api.getDrillSessionStatsForFacilityCareer(id)
					promises.usersRes = api.getDrillSessionStatsForFacilityCareerUsers(id)
					break
				case 'team':
					promises.viewRes = api.getDrillSessionStatsForTeamCareer(id)
					promises.usersRes = api.getDrillSessionStatsForTeamCareerUsers(id)
					break
			}
		}
		else {
			const viewParams = { start_at: startDate, end_at: endDate }
			const compareDatesParams = { start_at: compareStartDate, end_at: compareEndDate }
			// eslint-disable-next-line default-case
			switch (what) {
				case 'user':
					promises.viewRes = api.getDrillSessionStatsForUserDailyRange({ ...viewParams, user_id: id })
					if (compare === 'dates')
						promises.compareRes = api.getDrillSessionStatsForUserDailyRange({ ...compareDatesParams, user_id: id })
					else if (compare === 'team')
						promises.compareRes = api.getDrillSessionStatsForTeamDailyRange(firstTeam.id, viewParams) // TODO need to handle being part of multiple teams
					break
				case 'facility':
					promises.viewRes = api.getDrillSessionStatsForFacilityDailyRange(id, viewParams)
					promises.usersRes = api.getDrillSessionStatsForFacilityDailyRangeUsers(id, viewParams)
					if (compare === 'dates')
						promises.compareRes = api.getDrillSessionStatsForFacilityDailyRange(id, compareDatesParams)
					break
				case 'team':
					promises.viewRes = api.getDrillSessionStatsForTeamDailyRange(id, viewParams)
					promises.usersRes = api.getDrillSessionStatsForTeamDailyRangeUsers(id, viewParams)
					if (compare === 'dates')
						promises.compareRes = api.getDrillSessionStatsForTeamDailyRange(id, compareDatesParams)
					break
			}
		}

		state.showLoading(dispatch)
		await Promise.all(Array.from(Object.values(promises)))
		const viewRes = await promises.viewRes
		const compareRes = await promises.compareRes
        const decisionModeRes = await promises.decisionModeRes
		const usersRes = await promises.usersRes
		state.hideLoading(dispatch)

		if (!viewRes.ok) {
			state.showError(dispatch, viewRes.extractRemainingErrorsMessage())
			return
		}
		if (compareRes && !compareRes.ok) {
			state.showError(dispatch, compareRes.extractRemainingErrorsMessage())
			return
		}
		if (decisionModeRes && !decisionModeRes.ok) {
			state.showError(dispatch, decisionModeRes.extractRemainingErrorsMessage())
			return
		}
		if (usersRes && !usersRes.ok) {
			state.showError(dispatch, usersRes.extractRemainingErrorsMessage())
			return
		}

		if (view === 'career') {
			const { day } = viewRes
			day.day = dayjs(0)
			viewRes.days = [day]
			if (compareRes) {
				const { day } = compareRes
				day.day = dayjs(0)
				compareRes.days = [day]
			}
		}

		if (viewRes.days.length === 0 && (!compareRes || compareRes.days.length === 0))
			return clearData()

		const dates = view === 'month' ? getWeeksOfMonth(dayjs().startOf('month'))
			: view === 'year' ? getMonthsOfYear(dayjs().startOf('year'))
			: view === 'week' ? getDaysOfWeek(dayjs().startOf('isoWeek'))
			: view === 'career' ? [newDateRange({ label: 'All' })]
			: generateDateRangesFromDates(dayjs(startDate), dayjs(endDate))

		const compareDates = compareRes && (view === 'month' ? getWeeksOfMonth(dayjs().subtract(compare === 'dates' ? 1 : 0, 'month').startOf('month'))
			: view === 'year' ? getMonthsOfYear(dayjs().subtract(compare === 'dates' ? 1 : 0, 'year').startOf('year'))
			: view === 'week' ? getDaysOfWeek(dayjs().subtract(compare === 'dates' ? 1 : 0, 'week').startOf('isoWeek'))
			: view === 'career' ? [newDateRange({ label: 'All' })]
			: generateDateRangesFromDates(dayjs(compareStartDate), dayjs(compareEndDate)))

		const arrs = [[dates, viewRes.days]]
		if (compareDates)
			arrs.push([compareDates, compareRes.days])
		for (const [ dateRanges, days ] of arrs) {
			// TODO speed this up
			for (const dateRange of dateRanges) {
				for (const day of days) {
					appendDayToDateRange(day, dateRange)
				}
			}
		}

		barData.datasets[0].data = []
		barData.datasets[1].data = []
		barData.labels = []
		lineData.datasets[0].data = []
		lineData.datasets[1].data = []
		lineData.labels = []

		const sets = []
		if (compareDates) sets.push(compareDates)
		sets.push(dates)
		for (const arr of sets) {
			for (const { label, data: { m, s } } of arr) {
				barData.datasets[0].data.push(m)
				barData.datasets[1].data.push(s)
				lineData.datasets[0].data.push(s > 0 ? (m / s) * 100 : 0)
				lineData.datasets[1].data.push(100)
				barData.labels.push(label)
				lineData.labels.push(label)
			}
		}

		let compareDatesLabel
		let datesLabel
		if (compare === 'team') {
			compareDatesLabel = firstTeam.name
			datesLabel = selectedEntityName
		}

		const dateRanges = compareDates
			? [mergeDateRanges(compareDates, compareDatesLabel), mergeDateRanges(dates, datesLabel)]
			: view === 'career' ? dates
			: [mergeDateRanges(dates, 'All'), ...dates]

		for (let i = 0; i < dateRanges.length; i++) {
			if (dateRanges[i].data.s === 0) continue
			setSelectedDateRangeIndex(i)
			break
		}

        setDecisionModeData(decisionModeRes?.stats)
		setUsers(usersRes?.users.map(({ id, name, m, s }) => ({ id, name, m, s, p: s > 0 ? Math.floor((m / s) * 100) : 0 })) ?? [])
		setDateRanges(dateRanges)
		setBarData({ ...barData })
		setLineData({ ...lineData })
	}

	const updateQuery = (updates) => {
		for (const [ key, value ] of Object.entries(updates)) {
			if (!value) query.delete(key)
			else query.set(key, value)
		}
		history.push('/stats?' + query.toString())
	}

	useEffect(() => {
		const forUserId = query.get('user') ? parseInt(query.get('user')) : state.user.id
		if (relations && relations.forUserId === forUserId) return

		let cancelled = false
		;(async () => {
			setLoadingRelations(true)
			state.showLoading(dispatch)
			const res = await api.getDrillSessionStatsEntities(query.get('user'))
			state.hideLoading(dispatch)
			setLoadingRelations(false)

			if (cancelled) return
			if (!res.ok) {
				state.showError(dispatch, res.extractRemainingErrorsMessage())
				return
			}

			const { facilities, teams, user_name } = res
			setRelations({ facilities, teams, userName: user_name, forUserId })
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [location])

	useEffect(() => {
		if (!relations) return
		;(async () => {
			await fetchData()
		})()
		// eslint-disable-next-line
	}, [location, relations])

	useEffect(() => {
		const handleChartHover = (e, [ makesEl, shotsEl ]) => {
			const { clientX, clientY } = (window.TouchEvent && e instanceof window.TouchEvent) ? e.changedTouches[0] : e
			const isTouchClick = e instanceof PointerEvent && e.pointerType === 'touch'
			if (!makesEl || !shotsEl || isTouchClick) {
				courtRef.current.statChart.hide(clientX, clientY)
				return
			}
			const m = barData.datasets[makesEl._datasetIndex].data[makesEl._index]
			const s = barData.datasets[shotsEl._datasetIndex].data[shotsEl._index]
			courtRef.current.statChart.show(clientX, clientY, m, s)
		}

		const fontSize = isMobile ? 14 : 16
		const fontColor = theme.palette.text.primary

		setBarOptions({
			responsive: true,
			maintainAspectRatio: false,
			hover: {
				intersect: false,
				onHover: handleChartHover,
			},
			legend: {
				onClick: (e) => e.stopPropagation(),
				labels: {
					fontColor,
					fontSize,
				},
			},
			scales: {
				xAxes: [{
					stacked: true,
					gridLines: { color: 'rgba(255 ,255, 255, 0)' },
					ticks: { fontColor, fontSize },
				}],
				yAxes: [{
					gridLines: { color: 'rgba(255, 255, 255, 0)' },
					ticks: { fontColor, fontSize, min: 0, precision: 0 },
				}],
			},
			tooltips: { enabled: false },
		})

		setLineOptions({
			responsive: true,
			maintainAspectRatio: false,
			hover: {
				intersect: false,
				onHover: handleChartHover,
			},
			elements: {
				// line: { tension: 0 },
				point: { radius: 0, hoverRadius: 0 },
			},
			legend: {
				onClick: (e) => e.stopPropagation(),
				labels: {
					fontColor,
					fontSize,
					filter: (item) => !item.text.includes('Shots'),
				},
			},
			scales: {
				xAxes: [{
					stacked: true,
					gridLines: { color: 'rgba(255, 255, 255, 0)' },
					ticks: { fontColor, fontSize },
				}],
				yAxes: [{
					suggestedMin: 0,
					suggestedMax: 100,
					gridLines: { color: 'rgba(255, 255, 255, 0)' },
					ticks: {
						stepSize: 25,
						fontColor,
						fontSize,
						beginAtZero: true,
						max: 100,
						callback: (d) => d + '%',
					},
				}],
			},
			tooltips: { enabled: false },
		})

		barData.datasets[0].borderColor = theme.palette.text.primary
		barData.datasets[1].borderColor = theme.palette.text.primary
		lineData.datasets[0].borderColor = theme.palette.text.primary

		setBarData({ ...barData })
		setLineData({ ...lineData })
		// eslint-disable-next-line
	}, [theme, dateRanges])

	useEffect(() => {
		const arr = users.slice()
		arr.sort(utils.sortFunc(usersTableSortType, usersTableSortKey, usersTableSortDirection === 'desc'))
		setUsersSorted(arr)
	}, [users, usersTableSortDirection, usersTableSortKey, usersTableSortType])

	useEffect(() => {
		const filter = usersFilterDebounced
		setUsersPage(0)
		if (!filter) {
			setUsersFiltered(usersSorted.slice())
			return
		}
		setUsersFiltered(usersSorted.filter(({ name, s, m, p }) => {
			if (name && name.toLowerCase().includes(filter)) return true
			if (s && s.toString().includes(filter)) return true
			if (m && m.toString().includes(filter)) return true
			if (p && p.toString().includes(filter)) return true
			return false
		}))
	}, [usersSorted, usersFilterDebounced])

	useEffect(() => {
		const startIndex = usersPage * usersPerPage
		const endIndex = usersPage * usersPerPage + usersPerPage
		setUsersForCurrentPage(usersFiltered.slice(startIndex, endIndex))
	}, [usersFiltered, usersPage, usersPerPage])

	const handleChangeUserDataRowsPerPage = (event) => {
		setUsersPerPage(+event.target.value)
		setUsersPage(0)
	}

	const handleEntitySelectorChange = ({ target: { value } }) => {
		let [ what, id ] = value.split('-')
		query.set(what, id)

		if (what !== 'facility') query.delete('facility')
		if (what !== 'team') query.delete('team')
		// we default to showing stats for the currently logged in user
		// so we don't need / want to show it in the url
		if (what !== 'user' || parseInt(id) === state.user.id)
			query.delete('user')

		if (what === 'team' && compare === 'team')
			query.delete('compare')

		history.push('/stats?' + query.toString())
	}

	const handleChangeDate = (key) => ({ target: { value } }) => {
		updateQuery({ [key]: value })
	}

	const handleChangeView = (value) => () => {
		const updates = {
			view: value,
			start: null,
			end: null,
			compare_start: null,
			compare_end: null,
		}

		if (value === 'career' && compare === 'dates')
			updates.compare = null

		updateQuery(updates)
	}

	const handleChangeCompare = (value) => () => {
		const updates = {
			compare: value,
		}
		if (value !== 'dates') {
			updates.compare_start = null
			updates.compare_end = null
		}
		updateQuery(updates)
	}

	const handleClickUsersTableRow = (row) => () => {
		setLoadingRelations(true)
		updateQuery({
			facility: null,
			team: null,
			user: row.id,
		})
	}

	const handleRadioBarChange = ({ target: { value } }) => {
		setSelectedDateRangeIndex(value)
	}

	const handleUsersFilterChanged = ({ target: { value } }) => {
		setUsersFilter(value.trim().toLowerCase())
	}

    const decisionModePanel = state.user && state.user.is_admin && view === 'career' && <Card style={{ flexGrow: isDesktop ? 0 : 1, width: isTablet ? 772 : '100%', maxWidth: '100%' }}>
        <CardContent>
            <Stack medium>
                <Box sx={{ display: 'flex', gap: 8, flexDirection: isTiny ? 'column' : 'row' }}>
                    <Stack expandEqually>
                        <Typography variant={isMobile ? 'h6' : 'h5'} style={{ marginRight: 'auto' }}>Decision Mode</Typography>
                        {(({ highest_streak, m, s } = { highest_streak: 0, m: 0, s: 0 }) => <Stack horizontal>
                            <Stack small>
                                <Typography variant='subtitle1'>Makes</Typography>
                                <Typography variant='h6'>{m}</Typography>
                            </Stack>
                            <Stack small>
                                <Typography variant='subtitle1'>Shots</Typography>
                                <Typography variant='h6'>{s}</Typography>
                            </Stack>
                            <Stack small>
                                <Typography variant='subtitle1'>Percent</Typography>
                                <Typography variant='h6'>{s > 0 ? (Math.floor((m / s) * 100)) : 0}%</Typography>
                            </Stack>
                            <Stack small>
                                <Typography variant='subtitle1'>Streak</Typography>
                                <Typography variant='h6'>{highest_streak}</Typography>
                            </Stack>
                        </Stack>)(decisionModeData?.find(x => x.is_decision_mode))}
                    </Stack>
                </Box>
            </Stack>
        </CardContent>
    </Card>

	const content = <Fragment>
		<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', visibility: loadingRelations || !Boolean(relations) ? 'hidden' : '' }}>
			{ shouldShowEntityName && !isMobile &&
				<Typography variant={'h4'}>{selectedEntityName}</Typography> }
			{ entitySelectorGroups.length > 1 &&
				<EntitySelector style={{ flexGrow: isMobile ? 1 : 0 }} groups={entitySelectorGroups} value={entitySelectorValue} isMobile={isMobile} onChange={handleEntitySelectorChange} />}
		</Box>
		<Box sx={{ alignItems: isTablet ? 'center' : '', display: 'flex', flexDirection: isMobile || isTablet ? 'column' : 'row', gap: 16 }}>
			<Card style={{ width: 772, maxWidth: '100%' }}>
				<div style={{ position: 'relative' }}>
					<Court passedInRef={courtRef} mode={'stats'} interactive responsive showTotalsGradient showTotalsLabels showTotalsStatChart
						style={{ filter: dateRanges ? '' : 'blur(12px)' }}
						stats={dateRanges?.[selectedDateRangeIndex]?.data}/>
					{ !dateRanges && <div className={classes.courtNoDataOverlay}>
						<Typography variant={'h4'} style={{ color: 'white' }}>NO DATA</Typography>
					</div> }
				</div>
				{ dateRanges?.length > 1 && <RadioBar disabledTooltip='No Data' fieldName='label' items={dateRanges} isMobile={isMobile} value={selectedDateRangeIndex}
					onChange={handleRadioBarChange} onGetItemDisabled={({ data }) => data.s === 0} /> }
			</Card>
			<Box style={{ display: 'flex', gap: 16, flexGrow: 1, minWidth: 250, width: isTablet ? 772 : '', maxWidth: '100%', flexDirection: isTablet && !isMobile ? 'row' : 'column' }}>
				<Card style={{ flexGrow: isDesktop ? 0 : 1, flexBasis: isTablet && !isMobile ? '0px' : '' }}>
					<CardContent>
						<Stack medium>
							<Box sx={{ display: 'flex', gap: 8, flexDirection: isTiny ? 'column' : 'row' }}>
								<Typography variant={isMobile ? 'h6' : 'h5'} style={{ marginRight: 'auto' }}>View</Typography>
								{ view !== 'career' && <Box sx={{ display: 'flex', gap: 8 }}>
									{ view === 'custom'
										? <StandardDatePicker defaultValue={query.get('start')} onChange={handleChangeDate('start')} />
										: <Typography variant={isMobile ? 'subtitle1' : 'h6'}>{dayjs(startDate).format('MMM D YYYY')}</Typography>
									}
									<Box sx={{ display: 'flex', alignItems: 'center' }}>-</Box>
									{ view === 'custom'
										? <StandardDatePicker defaultValue={query.get('end')} onChange={handleChangeDate('end')} />
										: <Typography variant={isMobile ? 'subtitle1' : 'h6'}>{dayjs(endDate).format('MMM D YYYY')}</Typography>
									}
								</Box> }
							</Box>
							<Stack medium horizontal>
								<Button {...getButtonProps(!view)} onClick={handleChangeView(null)}>This Week</Button>
								<Button {...getButtonProps(view === 'month')} onClick={handleChangeView('month')}>This Month</Button>
								<Button {...getButtonProps(view === 'year')} onClick={handleChangeView('year')}>This Year</Button>
							</Stack>
							<Stack medium horizontal>
								<Button {...getButtonProps(view === 'career')} onClick={handleChangeView('career')}>Career</Button>
								<Button {...getButtonProps(view === 'custom')} onClick={handleChangeView('custom')}>Choose Dates</Button>
							</Stack>
						</Stack>
					</CardContent>
				</Card>
				<Card style={{ flexGrow: isDesktop ? 0 : 1, flexBasis: isTablet && !isMobile ? '0px' : '' }}>
					<CardContent>
						<Stack medium>
							<Box sx={{ display: 'flex', gap: 8, flexDirection: isTiny ? 'column' : 'row' }}>
								<Typography variant={isMobile ? 'h6' : 'h5'} style={{ marginRight: 'auto' }}>Compare</Typography>
								{ view !== 'career' && compare && <Box sx={{ display: 'flex', gap: 8 }}>
									{ (view === 'custom' && compare === 'dates')
										? <StandardDatePicker defaultValue={query.get('compare_start')} onChange={handleChangeDate('compare_start')} />
										: <Typography variant={isMobile ? 'subtitle1' : 'h6'}>{hasBothCompareDates ? dayjs(compareStartDate).format('MMM D YYYY') : ''}</Typography> }
									{ (hasBothCompareDates || (view === 'custom' && compare === 'dates')) && <Box sx={{ display: 'flex', alignItems: 'center' }}>-</Box>}
									{ (view === 'custom' && compare === 'dates')
										? <StandardDatePicker defaultValue={query.get('compare_end')} onChange={handleChangeDate('compare_end')} />
										: <Typography variant={isMobile ? 'subtitle1' : 'h6'}>{hasBothCompareDates ? dayjs(compareEndDate).format('MMM D YYYY') : ''}</Typography> }
								</Box> }
							</Box>
							<Stack medium horizontal>
								<Button {...getButtonProps(!compare)} onClick={handleChangeCompare(null)}>None</Button>
								{ view !== 'career' && <Button {...getButtonProps(compare === 'dates')} onClick={handleChangeCompare('dates')}>
									{ view === 'custom' ? 'Choose Dates' : 'Previous' }
								</Button> }
							</Stack>
							{ !entitySelectorValue.startsWith('team-') && relations?.teams.length > 0 && <Stack medium horizontal>
								 <Button {...getButtonProps(compare === 'team')} onClick={handleChangeCompare('team')}>Team</Button>
							</Stack> }
						</Stack>
					</CardContent>
				</Card>
                {!isTablet && decisionModePanel}
			</Box>
            {isTablet && decisionModePanel}
		</Box>
		{ view !== 'career' && dateRanges && barData.datasets[0]?.data?.length > 1 && <Card className={classes.chart}
			onTouchEnd={() => courtRef.current.statChart.hide()}
			onContextMenu={(e) => { e.preventDefault(); e.stopPropagation() }}>
			<Bar data={barData} options={barOptions} />
		</Card> }
		{ view !== 'career' && dateRanges && lineData.datasets[0]?.data?.length > 1 && <Card className={classes.chart}
			onTouchEnd={() => courtRef.current.statChart.hide()}
			onContextMenu={(e) => { e.preventDefault(); e.stopPropagation() }}>
			<Line data={lineData} options={lineOptions} />
		</Card> }
		{ users.length > 0 ? <Fragment>
			<Typography variant={isMobile ? 'h6' : 'h4'}>Members</Typography>
			<Card style={{ marginBottom: isDesktop ? 24 : 0 }}>
				<CardContent style={{ padding: 0 }}>
					<Stack medium>
						<Paper>
							<div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', paddingLeft: 12, paddingRight: 12, paddingTop: 12 }}>
								<TextField placeholder={`Filter Members...`} size='small' style={{ flexGrow: 1 }} variant='outlined' onChange={handleUsersFilterChanged} />
								<TablePagination
									labelRowsPerPage='Rows per page'
									rowsPerPageOptions={[10, 25, 50, 100]}
									component='div'
									count={usersFiltered.length}
									rowsPerPage={usersPerPage}
									page={usersPage}
									onPageChange={(_, page) => setUsersPage(page)}
									onRowsPerPageChange={handleChangeUserDataRowsPerPage}
								/>
							</div>
							<TableContainer>
								<Table size='small'>
								<TableHead>
									<TableRow>
										{usersTableColumns.map(({ align, key, label, type, width }, i) => {
											const active = key === usersTableSortKey
											const defaultDirection = type === 'string' ? 'asc' : 'desc'
											const direction = active ? usersTableSortDirection : defaultDirection
											return <TableCell align={align} key={i} width={width}>
												<TableSortLabel active={active} direction={direction} onClick={()=> {
													const direction = active ? (usersTableSortDirection === 'desc' ? 'asc' : 'desc') : defaultDirection
													setUsersTableSortKey(key)
													setUsersTableSortDirection(direction)
													setUsersTableSortType(type)
												}}>{label}</TableSortLabel>
											</TableCell>
										})}
									</TableRow>
								</TableHead>
								<TableBody className={classes.usersTableBody}>{usersForCurrentPage.map((row, key) =>
									<TableRow tabIndex={-1} key={key} hover onClick={handleClickUsersTableRow(row)}>
										{usersTableColumns.map(({ align, key, onFormat }) =>
											<TableCell align={align} className={classes.usersTableBodyCell} key={key}>{onFormat ? onFormat(row[key]) : row[key]}</TableCell>
										)}
									</TableRow>
								)}</TableBody>
								</Table>
							</TableContainer>
						</Paper>
					</Stack>
				</CardContent>
			</Card>
		</Fragment> : null }
	</Fragment>

	return <Page>
		{ relations ? <Container className={classes.container} style={{ paddingTop: shouldShowEntityName || !isDesktop ? 0 : 16 }}>{content}</Container> : undefined }
	</Page>
}
