import Checkbox from '@material-ui/core/Checkbox'
import React from 'react'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	checkbox: {
		'&:hover': {
			backgroundColor: 'transparent !important',
		},
	},
	group: {
		borderTop: '1px solid rgba(255, 255, 255, 0.12)',
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(1),
		paddingTop: theme.spacing(1.5),
	},
	header: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		gap: theme.spacing(1),
		paddingLeft: theme.spacing(1),
	},
	headerCount: {
		marginLeft: 'auto',
		paddingTop: 4,
	},
	link: {
		alignSelf: 'center',
		cursor: 'pointer',
		paddingBottom: theme.spacing(1),
		paddingTop: theme.spacing(1),
	},
	row: {
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'row',
		gap: theme.spacing(1),
		justifyContent: 'space-between',
		paddingLeft: theme.spacing(2.5),
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.1)',
		},
	},
}))

export default function CheckboxList({ checkedField, keyField, groups, onShowAll, onToggle }) {
	const classes = useStyles()

	const handleToggleAllClicked = (items, checked) => () => {
		if (!onToggle) return
		for (const { data } of items)
			onToggle(data, checked)
	}

	// SEE https://stackoverflow.com/a/43321596/1563399
	const preventDoubleClickTextSelection = (e) => {
		if (e.detail > 1) e.preventDefault()
	}

	return <div>
		{groups.map((group) => {
			const { name: groupName, items, totalItems, truncated } = group
			const checkedCount = items.filter(({ data }) => data[checkedField]).length
			const totalCount = items.length
			const allChecked = totalCount === checkedCount
			return <div key={groupName} className={classes.group}>
				<div className={classes.header}>
					<Typography variant='h6'>{groupName}</Typography>
					<Typography className={classes.headerCount} variant='h6'>{checkedCount}/{totalCount}</Typography>
					<Checkbox checked={allChecked} indeterminate={!allChecked && checkedCount > 0} onClick={handleToggleAllClicked(items, checkedCount === 0)} />
				</div>
			{ truncated && <Link color='secondary' underline='always' className={classes.link} onClick={() => onShowAll?.(group)}>
				List truncated. Tap to show all {totalItems} items.
			</Link> }
				{items.map(({ data, name }) => {
					const checked = data[checkedField]
					return <div key={data[keyField]} className={classes.row} onClick={() => onToggle?.(data, !checked)} onMouseDown={preventDoubleClickTextSelection}>
						<Typography variant='body1'>{name}</Typography>
						<Checkbox className={classes.checkbox} checked={checked} />
					</div>
				})}
			</div>
		})}
	</div>
}
