import {
	StandardTable as Table,
	StandardTableAction as TableAction,
	StandardTableActions as TableActions,
	StandardTableColumn as TableColumn,
	StandardTableColumns as TableColumns,
} from '../Components/StandardTable'
import ChangePasswordForUserDialog from '../Dialogs/ChangePasswordForUserDialog'
import ChangeEmailForUserDialog from '../Dialogs/ChangeEmailForUserDialog'
import Container from '@material-ui/core/Container'
import Header from '../Components/Header'
import Page from './Page'
import React, { Fragment, useEffect, useState } from 'react'
import Stack from '../Components/Stack'
import StandardTextField from '../Components/StandardTextField'
import TvDialog from '../Dialogs/TvDialog'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { startEditSession } from '../EditSession'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@material-ui/core/styles'
import { useStore } from '../StoreProvider'

export default function AdminUsersPage() {
	const location = useLocation()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

	const [ editSession, setEditSession ] = useState(null)
	const [ fetchedData, setFetchedData ] = useState(null)
	const [ filter, setFilter ] = useState('')
	const [ items, setItems ] = useState([])
	const [ shouldFetchDataCounter, setShouldFetchDataCounter ] = useState(0)
	const { state, dispatch } = useStore()
	
	const [ editSessionEmailAddress, setEditSessionEmailAddress ] = useState(null)
	const [ editSessionPassword, setEditSessionPassword ] = useState(null)

	const fetchData = async () => {
		state.showLoading(dispatch)
		const res = await api.getUserAssignments()
		state.hideLoading(dispatch)
		if (!res.ok) {
			state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}
		return res
	}

	const handleClickAddStats = async (item) => {
		const { facilities } = fetchedData
		dispatch({
			type: 'add_stats_dialog.show',
			facilities,
			userId: item.id,
			userName: item.name,
		})
	}

	const handleClickEmailChange = async (item) => {
		await startEditSession(setEditSessionEmailAddress, { newEmailAddress: item.email, errors: {} }, {
			async onCancel() { return true },
			async onComplete({ newEmailAddress }, editSession) {

				state.showLoading(dispatch, 'Updating Email Address...')
				const res = await api.postPasswordAndEmailForUser(item.id, { email: newEmailAddress })
				state.hideLoading(dispatch)

				if (!res.ok) {
					let msg = res.extractErrorMessage('new_password')
					if (msg) {
						setEditSessionPassword(editSession.clone({ errors: { emailAddress: msg } }))
						return false
					}

					msg = res.extractRemainingErrorsMessage()
					if (msg) state.showError(dispatch, msg)

					return false
				}

				const user = items.find(({ id }) => id === item.id)
				if (user) user.email = newEmailAddress

				return true
			},
		})
	}

	const handleClickPasswordChange = async (item) => {
		await startEditSession(setEditSessionPassword, { newPassword: '', newPasswordRepeat: '', errors: {} }, {
			async onCancel() { return true },
			async onComplete({ newPassword, newPasswordRepeat }, editSession) {
				if (newPassword !== newPasswordRepeat) {
					setEditSessionPassword(editSession.clone({ errors: { newPasswordRepeat: 'The passwords do not match' } }))
					return false
				}

				state.showLoading(dispatch, 'Updating Password...')
				const res = await api.postPasswordAndEmailForUser(item.id, { password: newPassword })
				state.hideLoading(dispatch)

				if (!res.ok) {
					let msg = res.extractErrorMessage('new_password')
					if (msg) {
						setEditSessionPassword(editSession.clone({ errors: { newPassword: msg } }))
						return false
					}

					msg = res.extractRemainingErrorsMessage()
					if (msg) state.showError(dispatch, msg)

					return false
				}

				return true
			},
		})
	}

	const handleFetchData = async (page, rowsPerPage) => {
		const startIndex = rowsPerPage * page
		const endIndex = startIndex + rowsPerPage - 1
		return [ items.slice(startIndex, endIndex), items.length ]
	}

	useEffect(() => {
		if (!fetchedData) return
		const f = filter.toLowerCase()
		const { users } = fetchedData
		const items = f ? users.filter(({ email, name, username }) =>
			email.toLowerCase().includes(f)
				|| name.toLowerCase().includes(f)
				|| username.toLowerCase().includes(f)
		) : users
		setItems(items)
		setShouldFetchDataCounter(shouldFetchDataCounter + 1)
		// eslint-disable-next-line
	}, [fetchedData, filter])

	useEffect(() => {
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [location])

	return <Page padding='bottom'>
		<TvDialog editSession={editSession} setEditSession={setEditSession} />
		<ChangePasswordForUserDialog editSession={editSessionPassword} />
		<ChangeEmailForUserDialog editSession={editSessionEmailAddress} />
		<Container>
			<Header>
				<Stack horizontal={!isMobile} small={isMobile}>
					<Typography variant='inherit' style={{ flexGrow: 0 }}>Members</Typography>
					<StandardTextField placeholder='Filter Members...' value={filter} onChange={({ target: { value } }) => { setFilter(value) }} />
				</Stack>
			</Header>
			{ fetchedData ? <Fragment>
				<Table pagination={true} shouldFetchDataCounter={shouldFetchDataCounter} onFetchData={handleFetchData}>
					<TableColumns>
						<TableColumn fieldName='name'>Name</TableColumn>
						<TableColumn fieldName='username'>Username</TableColumn>
						<TableColumn fieldName='email'>Email</TableColumn>
					</TableColumns>
					<TableActions>
						<TableAction onClick={handleClickAddStats}>Add Stats</TableAction>
						<TableAction onClick={handleClickEmailChange}>Change Email Address</TableAction>
						<TableAction onClick={handleClickPasswordChange}>Change Password</TableAction>
					</TableActions>
				</Table>
			</Fragment> : undefined }
		</Container>
	</Page>
}
