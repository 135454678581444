import * as utils from '../utils'
import AddIcon from '@material-ui/icons/Add'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import LastPageIcon from '@material-ui/icons/LastPage'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Paper from '@material-ui/core/Paper'
import React, { Fragment, useEffect, useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableFooter from '@material-ui/core/TableFooter'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import clsx from 'clsx'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {makeStyles, useTheme} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	table: {
		whiteSpace: 'nowrap',
	},
	tablePagination: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
	clickableTableRow: {
		cursor: 'pointer',
	},
	actionsTableCell: {
		width: 30,
	},
}))

function TablePaginationActions(props) {
	const classes = useStyles()
	const { count, page, rowsPerPage, onPageChange } = props

	const handleFirstPageClick = (event) => {
		onPageChange(event, 0)
	}

	const handleLastPageClick = (event) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
	}

	const handleNextClick = (event) => {
		onPageChange(event, page + 1)
	}

	const handlePreviousClick = (event) => {
		onPageChange(event, page - 1)
	}

	return <div className={classes.tablePagination}>
		<IconButton onClick={handleFirstPageClick} disabled={page === 0}>
			<FirstPageIcon />
		</IconButton>
		<IconButton onClick={handlePreviousClick} disabled={page === 0}>
			<KeyboardArrowLeftIcon />
		</IconButton>
		<IconButton onClick={handleNextClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
			<KeyboardArrowRightIcon />
		</IconButton>
		<IconButton onClick={handleLastPageClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
			<LastPageIcon />
		</IconButton>
	</div>
}

// these are "virtual" components, that just return themselves and are simply containers for data
export function StandardTableAction(props) { return props }
export function StandardTableActions(props) { return props }
export function StandardTableColumn(props) { return props }
export function StandardTableColumns(props) { return props }

export function StandardTable(props) {
	const {
		children, rowsPerPageOptions, items,
		pagination, placeholderContent,
		onClickRow, onCreateItem, onFetchData, onFetchingData,
		shouldFetchDataCounter, selectedItem,
		...remainingProps
	} = props
	let { component, header, idFieldName, ...rest } = remainingProps

	const classes = useStyles()
	const theme = useTheme()
	const [selectedRow, setSelectedRow] = useState(null)
	const [menuAnchor, setMenuAnchor] = useState(null)
	const [rows, setRows] = useState(null)
	const [page, setPage] = useState(0)
	const [count, setCount] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions ? rowsPerPageOptions[0] : 10)
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	header = header ?? true
	if (!idFieldName) idFieldName = 'id'
	const idFieldNameIsFunc = typeof idFieldName === 'function'

	const fetchData = async (checkCancelled) => {
		if (!onFetchData) return
		// eslint-disable-next-line
		onFetchingData?.(true)
		const [rows, count] = await onFetchData(page, rowsPerPage)
		if (!(checkCancelled?.() ?? false)) {
			const pages = Math.ceil(count / rowsPerPage)
			if (page >= pages) setPage(0)
			setRows(rows)
			setCount(count)
		}
		// eslint-disable-next-line
		onFetchingData?.(false)
	}

	useEffect(() => {
		let cancelled = false
		fetchData(() => cancelled)
		return () => cancelled = true
		// eslint-disable-next-line
	}, [page, rowsPerPage, shouldFetchDataCounter])

	const handleChangePage = (event, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10))
		setPage(0)
	}

	const handleCreateItem = async () => {
		const shouldFetchData = await onCreateItem()
		if (shouldFetchData) await fetchData()
	}

	const handleClickMenu = (row) => (event) => {
		event.preventDefault()
		event.stopPropagation()
		setSelectedRow(row)
		setMenuAnchor({ el: event.currentTarget })
	}

	const handleRightClick = (row) => (event) => {
		if (utils.hasTextSelected()) return
		event.preventDefault()
		setSelectedRow(row)
		setMenuAnchor({
			left: event.clientX - 2,
			top: event.clientY - 4,
		})
	}

	const handleCloseMenu = (event) => {
		setMenuAnchor(null)
	}

	const handleExitedMenu = () => {
		setSelectedRow(null)
	}

	const handleMenuRightClick = (event) => {
		event.preventDefault()
	}

	let actions = []
	let columns = []
	React.Children.forEach(children, (child) => {
		if (!React.isValidElement(child)) return
		if (child.type === StandardTableActions) {
			if (Array.isArray(child.props.children)) {
				actions = actions.concat(child.props.children.filter(i => !!i))
			}
			else {
				actions.push(child.props.children)
			}
		}
		else if (child.type === StandardTableColumns)
			columns = columns.concat(child.props.children)
	})
	columns = columns.filter(i => !!i)

	const anchorPosition = menuAnchor && !menuAnchor.el
		? { left: menuAnchor.left, top: menuAnchor.top } : undefined

	const anchorReference = menuAnchor?.el ? 'anchorEl' : 'anchorPosition'

	const itemsForRender = rows || items || []

	const filteredActions = actions.filter(({ props: { onAvailable } }) => {
		if (!onAvailable || !selectedRow) return true
		return onAvailable(selectedRow)
	}).map((action, index) => {
		const { children, onAvailable, onClick, onContent, ...rest } = action.props

		const wrappedOnClick = async () => {
			setMenuAnchor(null)
			if (onClick) await onClick({ ...selectedRow })
		}

		const content = onContent && selectedRow ? onContent(selectedRow) : children

		return <MenuItem key={index} onClick={wrappedOnClick} {...rest}>{content}</MenuItem>
	})

	const menu = <Menu keepMounted open={Boolean(menuAnchor)}
		anchorEl={menuAnchor?.el} anchorReference={anchorReference} anchorPosition={anchorPosition}
		onClose={handleCloseMenu} onContextMenu={handleMenuRightClick}
		TransitionProps={{ onExited: handleExitedMenu }}
	>{filteredActions}</Menu>

	const content = header || itemsForRender.length > 0 ? <TableContainer component={component ?? Paper} {...rest}>
		<Table className={classes.table} size='small'>
			{ header ? <TableHead>
				<TableRow>
					{columns.filter(i => !!i).map((column, index) => {
						const { children, fieldName, onStyle, onTransform, ...rest } = column.props
						return <TableCell key={index} {...rest}>{children}</TableCell>
					})}
					{ actions.length > 0 ? <TableCell align='right' key='actions' padding='none' className={classes.actionsTableCell}>
						{onCreateItem ? <IconButton size='small' onClick={handleCreateItem}><AddIcon /></IconButton> : null}
					</TableCell> : undefined }
				</TableRow>
			</TableHead> : undefined }
			<TableBody>
				{ itemsForRender.map((row) => {
					const isSelected = (menuAnchor && row === selectedRow) || (row === selectedItem)

					const cells = columns.map(({ props: { fieldName, onStyle, onTransform, ...rest } }, index) => {
						let value = row[fieldName]
						value = onTransform?.(value, row) ?? value
						const style = onStyle?.(value, row) ?? undefined
						return <TableCell key={index} style={style} {...rest}>{value}</TableCell>
					})

					const hasActions = actions.filter(({ props: { onAvailable } }) => onAvailable?.(row) ?? true).length > 0

					if (actions.length > 0) {
						cells.push(<TableCell align='right' padding='none' key={cells.length} className={classes.actionsTableCell}>
							{ hasActions ? <IconButton size='small' onClick={handleClickMenu(row)}><MoreVertIcon /></IconButton> : undefined }
						</TableCell>)
					}

					const key = idFieldNameIsFunc ? idFieldName(row) : row[idFieldName]

					return <TableRow key={key} hover selected={isSelected}
						className={clsx(onClickRow && classes.clickableTableRow)}
						onClick={onClickRow ? () => onClickRow(row) : undefined}
						onContextMenu={hasActions && !isMobile ? handleRightClick(row) : undefined}
					>{cells}</TableRow>
				}) }
			</TableBody>
			{ pagination || pagination === undefined ? <TableFooter>
				<TableRow>
					<TablePagination
						rowsPerPageOptions={rowsPerPageOptions || [10, 25, 50]}
						count={count}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						ActionsComponent={TablePaginationActions}
					/>
				</TableRow>
			</TableFooter> : undefined }
		</Table>
	</TableContainer> : placeholderContent

	return <Fragment>{menu}{content}</Fragment>
}
