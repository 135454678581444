import axios from 'axios'
import dayjs from 'dayjs'

const { host, protocol } = window.location

const baseUrl = protocol + '//' + host + '/api'

class Response {
	/**
	 * @param {String} fieldName - the field_name of the error we want
	 */
	extractErrorMessage(fieldName) {
		if (!Array.isArray(this.errors)) return
		for (let i = 0; i < this.errors.length; i++) {
			if (this.errors[i].field_name === fieldName) {
				let message = this.errors.splice(i, 1)[0].message ?? ''
				message = message.slice(0, 1).toUpperCase() + message.slice(1)
				return message
			}
		}
	}

	/**
	 * @param {String} reason - the reason of the error we want
	 */
	extractErrorMessageForReason(reason) {
		if (!Array.isArray(this.errors)) return
		for (let i = 0; i < this.errors.length; i++) {
			if (this.errors[i].reason === reason) {
				return this.errors.splice(i, 1)[0].message ?? ''
			}
		}
	}

	extractRemainingErrorsMessage() {
		if (!Array.isArray(this.errors)) return
		if (this.errors.length === 0) return
		let messages = []
		for (const error of this.errors) {
			let message = error.message || ''

			// only prepend the message if it's a validation error
			// other errors will already be descriptive enough
			const fieldName = error.field_name
			if (fieldName && error.is_validation_error) {
				message = `Error returned for ${fieldName}:\n${message}`
			}

			messages.push(message)
		}
		return messages.join('\n')
	}
}

async function get(url, params) {
	return request(fetch(baseUrl + url + stringifyParams(params), {
		method: 'GET',
		headers: getHeaders(),
	}))
}

async function del(url, params) {
	return request(fetch(baseUrl + url + stringifyParams(params), {
		method: 'DELETE',
		headers: getHeaders(),
	}))
}

async function patch(url, params) {
	return request(fetch(baseUrl + url, {
		method: 'PATCH',
		headers: getHeaders(),
		body: JSON.stringify(params),
	}))
}

async function post(url, params) {
	return request(fetch(baseUrl + url, {
		method: 'POST',
		headers: getHeaders(),
		body: JSON.stringify(params),
	}))
}

async function put(url, params) {
	return request(fetch(baseUrl + url, {
		method: 'PUT',
		headers: getHeaders(),
		body: JSON.stringify(params),
	}))
}

function getHeaders(headers) {
	return {
		'Content-Type': 'application/json',
		'x-timestamp': dayjs().format('YYYY-MM-DD HH:mm:ss'),
		...headers,
	}
}

async function request(promise) {
	let res
	try {
		res = await promise
		res = await res.json()
	}
	catch (e) {
		res = { ok: false, errors: [ { message: e.message } ] }
	}
	Object.setPrototypeOf(res, Response.prototype)
	return res
}

function stringifyIds(ids) {
	if (!ids || ids.length === 0) return ''
	return '/' + ids.join('/')
}

function stringifyParams(params) {
	if (!params) return ''
	return '?' + Object.entries(params).filter(([_, v]) => !!v).map(([k, v]) => {
		if (v === true) return encodeURIComponent(k)
		return encodeURIComponent(k) + '=' + encodeURIComponent(v)
	}).join('&')
}

async function uploadFile(file, kind, onProgress) {
	const formData = new FormData()
	formData.append('file', file)
	formData.append('kind', kind)
	const res = await axios.post('/upload', formData, {
		onUploadProgress: onProgress ? (progressEvent) => {
			const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
			onProgress(percentCompleted)
		} : null
	})
	Object.setPrototypeOf(res.data, Response.prototype)
	return res.data
}

export default {
	Response,
	uploadFile,
	// delete
	deleteCategoryLink: (params) => del('/categories/links', params),
	deleteCategoryLinksFeature: (id) => del(`/categories/links/features/${id}`),
	deleteFacility: (id) => del('/facilities/' + id),
	deleteFacilityUser: (facilityId, facilityUserId) => del(`/facilities/${facilityId}/users/${facilityUserId}`),
	deleteOrganization: (id) => del('/organizations/' + id),
	deleteOrganizationUser: (organizationId, userId) => del(`/organizations/${organizationId}/users/${userId}`),
	deleteSession: (id, params) => del('/sessions', params),
	deleteTeam: (id) => del('/teams/' + id),
	deleteTeamContract: (teamContractId) => del(`/teams/contracts/${teamContractId}`),
	deleteTeamUser: (teamId, teamUserId) => del(`/teams/${teamId}/users/${teamUserId}`),
	deleteTv: (id) => del('/tvs/' + id),
	// get
	getAnalyticsErrors: () => get('/analytics/errors'),
	getAnalyticsEvents: (params) => get('/analytics/events', params),
	getAnalyticsEventsLists: () => get('/analytics/events/lists'),
	getBackupsAnalysis: (backupId) => get(`/backups/${backupId}/analysis`),
	getBackupsAvailable: () => get('/backups/available'),
	getBackupsSearch: (params) => get('/backups/search', params),
	getCategory: (categoryId) => get('/categories/' + categoryId),
	getCategoryLinks: (categoryId) => get('/categories/links/' + categoryId),
	getCategoryLinksAssignments: (params) => get('/categories/links/assignments', params),
	getCategoryLinksFeatures: (params) => get('/categories/links/features', params),
	getCategoryList: (categoryIds, params) => get('/categories/list' + stringifyIds(categoryIds), params),
	getCategorySummary: (categoryId) => get('/categories/' + categoryId + '/summary'),
	getDrill: (drillId) => get('/drills/' + drillId),
	getDrillSessionBestLimitScores: (params) => get('/drill/sessions/best_limit_scores', params),
	getDrillSessionStatsForDrill: (drillId) => get('/drill/sessions/stats/user/drill/' + drillId),
	getDrillSessionStatsForFacilityCareer: (facilityId) => get(`/drill/sessions/stats/facility/${facilityId}/career`),
	getDrillSessionStatsForFacilityCareerUsers: (facilityId) => get(`/drill/sessions/stats/facility/${facilityId}/career/users`),
	getDrillSessionStatsForFacilityDailyRange: (facilityId, params) => get(`/drill/sessions/stats/facility/${facilityId}/daily/range`, params),
	getDrillSessionStatsForFacilityDailyRangeUsers: (facilityId, params) => get(`/drill/sessions/stats/facility/${facilityId}/daily/range/users`, params),
	getDrillSessionStatsForTeamCareer: (teamId) => get(`/drill/sessions/stats/team/${teamId}/career`),
	getDrillSessionStatsForTeamCareerUsers: (teamId) => get(`/drill/sessions/stats/team/${teamId}/career/users`),
	getDrillSessionStatsForTeamDailyRange: (teamId, params) => get(`/drill/sessions/stats/team/${teamId}/daily/range`, params),
	getDrillSessionStatsForTeamDailyRangeUsers: (teamId, params) => get(`/drill/sessions/stats/team/${teamId}/daily/range/users`, params),
	getDrillSessionStatsForUserCareer: (params) => get('/drill/sessions/stats/user/career', params),
	getDrillSessionStatsForUserDailyRange: (params) => get('/drill/sessions/stats/user/daily/range', params),
	getDrillSessionStatsForUserDecisionMode: (params) => get('/stats/user/decision-mode', params),
	getDrillSessionStatsEntities: (userId) => get('/drill/sessions/stats/entities', { user_id: userId }),
	getDrillSummary: (drillId) => get(`/drills/${drillId}/summary`),
	getFacilities: () => get('/facilities'),
	getFacilityUsers: (id) => get(`/facilities/${id}/users`),
	getFiles: (params) => get('/files', params),
	getFilesLinks: (params) => get('/files/links', params),
	getGuns: (params) => get('/guns', params),
	getNgrokSession: (params) => get('/ngrok/session', params),
	getOrganizationUsers: (organizationId) => get(`/organizations/${organizationId}/users`),
	getOrganizations: (params) => get('/organizations', params),
	getOrganizationsFacilities: (organizationId) => get(`/organizations/${organizationId}/facilities`),
	getOrganizationsTeams: (organizationId) => get(`/organizations/${organizationId}/teams`),
	getSessionValid: (params) => get('/sessions/valid', params),
	getTasks: () => get('/tasks'),
	getTeamContracts: (id) => get(`/teams/${id}/contracts`),
	getTeamUsers: (id) => get(`/teams/${id}/users`),
	getTvConfigEntities: () => get('/tvs/config/entities'),
	getTvs: (params) => get('/tvs', params),
	getUserAssignments: () => get('/users/assignments'),
	getUsers: () => get('/users'),
	// patch
	patchCategory: (id, params) => patch('/categories/' + id, params),
	patchCategoryLinks: (categoryId, links) => patch('/categories/links/' + categoryId, { links }),
	patchCategoryLinksAssignments: (assignments) => patch('/categories/links/assignments', { assignments }),
	patchCategoryLinkMove: (params) => patch('/categories/links/move', params),
	patchDrill: (id, params) => patch('/drills/' + id, params),
	patchFacility: (id, params) => patch('/facilities/' + id, params),
	patchGun: (id, params) => patch('/guns/' + id, params),
	patchGunRegister: (params) => patch('/guns/register', params),
	patchOrganization: (id, params) => patch('/organizations/' + id, params),
	patchOrganizationUser: (organizationId, userId, params) => patch(`/organizations/${organizationId}/users/${userId}`, params),
	patchTeam: (id, params) => patch('/teams/' + id, params),
	patchTv: (id, params) => patch('/tvs/' + id, params),
	patchUser: (id, params) => patch('/users/' + id, params),
	patchUserPassword: (params) => patch('/users/password', params),
	// post
	postBackupsImportExistingUser: (backupId, params) => post(`/backups/${backupId}/import-existing-user`, params),
	postBackupsImportNewUser: (backupId, params) => post(`/backups/${backupId}/import-new-user`, params),
	postBackupsRun: (kind) => post(`/backups/run/${kind}`),
	postCategory: (params) => post('/categories', params),
	postCategoryLinkCopy: (params) => post('/categories/links/copy', params),
	postCategoryLinksFeature: (params) => post('/categories/links/features', params),
	postDrill: (params) => post('/drills', params),
	postDrillSession: (params) => post('/drill/sessions/user', params),
	postFacility: (params) => post('/facilities', params),
	postActivationPerform: (params) => post('/activation/perform', params),
	postFacilityUser: (facilityId, params) => post(`/facilities/${facilityId}/users`, params),
	postImportTheDriveway: (params) => post('/system/import/the-driveway', params),
	postNgrokStart: (params) => post('/ngrok/start', params),
	postNgrokStop: (params) => post('/ngrok/stop', params),
	postOrganization: (params) => post('/organizations', params),
	postOrganizationUser: (organizationId, params) => post(`/organizations/${organizationId}/users`, params),
	postPasswordAndEmailForUser: (id, params) => post(`/users/${id}/reset`, params),
	postSession: (params) => post('/sessions', params),
	postSessionImpersonate: (params) => post('/sessions/impersonate', params),
	postSsePush: (params) => post('/sse/push', params),
	postTasksExecute: (id) => post(`/tasks/${id}/execute`),
	postTeam: (params) => post('/teams', params),
	postTeamContract: (teamId, params) => post(`/teams/${teamId}/contracts`, params),
	postTeamContractAccept: (tokenId) => post('/teams/contracts/accept/' + tokenId),
	postTeamContractResendInviteEmail: (teamContractId) => post('/teams/contracts/resend/' + teamContractId),
	postTeamUser: (teamId, params) => post(`/teams/${teamId}/users`, params),
	postTvWeb: (params) => post('/tvs/web', params),
	postUser: (params) => post('/users', params),
	postUserRecoverPerform: (params, tokenId) => post('/users/recover/' + tokenId, params),
	postUserRecoverRequest: (params) => post('/users/recover', params),
	postUserSetup: (params, tokenId) => post('/users/setup/' + tokenId, params),
	postUserVerify: (tokenId) => post('/users/verify/' + tokenId),
	postUserVerifyResend: (params) => post('/users/verify/resend', params),
	postZencoderTranscode: (params) => post('/zencoder/transcode', params),
	//put
	putFileLink: (params) => put('/files/links', params),
}
