class EditSession {
	constructor({ data, title, onCancel, onComplete, onDispose }) {
		// active is used to determine if ui should be shown (in EditDialog for example)
		this.active = true
		this.changed = false
		this.data = data
		this.title = title
		this.onCancel = onCancel // returns true if cancel was performed
		this.onComplete = onComplete // returns true if complete was performed
		this.onDispose = onDispose
		this.promise = new Promise((resolve) => {
			this.resolve = resolve
		})
	}

	// called when this session has been cancelled
	cancel() {
		return async () => {
			if (!this.changed || (await this.onCancel?.() ?? true)) {
				this.resolve()
				return true
			}
			return false
		}
	}

	// useState requires unique objects, so we clone the EditSession and copy our properties to it
	clone(data) {
		const copy = { ...this }
		Object.setPrototypeOf(copy, EditSession.prototype)
		if (data) Object.assign(copy.data, data)
		return copy
	}

	// called when this session has been completed
	complete() {
		return async () => {
			if (await this.onComplete?.(this.data, this) ?? true) this.resolve()
		}
	}

	// startEditSession uses this to know when it's okay to set the parent state to null
	// this way we don't have the previous edit object being used anymore
	dispose() {
		// eslint-disable-next-line
		this.onDispose?.()
	}

	// waits until our promise is resolved
	// returns a copy of us with active set to false, to use as the new state
	// (useState requires unique objects, so we clone the EditSession and copy our properties to it)
	async wait() {
		await this.promise
		const copy = this.clone()
		copy.active = false
		return copy
	}
}

// NOTE using arrow functions here breaks in SWC 1.1.48, so use the full syntax instead
export function getEditSessionGetterAndSetter(editSession, setEditSession) {
	return [
		// helper function to get a value from EditSession.data
		function(path, defaultValue) {
			defaultValue = defaultValue ?? ''
			if (!editSession) return defaultValue
			const [obj, fieldName] = getObjectAndFieldName(editSession, path)
			return obj[fieldName] ?? defaultValue
		},
		// helper function that returns a setter function that sets a value in EditSession.data
		// for use with MaterialUI components with an onChange property (like TextField)
		function(path, options) {
			options = options ?? {}
			let eventFieldName = options.eventFieldName ?? 'value'
			let onTransform = options.onTransform ?? ((v) => v)
			let updatesEditSession = options.updatesEditSession ?? false

			if (options.isCheckbox) {
				eventFieldName = 'checked'
				updatesEditSession = true
			}

			return (event) => {
				let value = onTransform(event.target[eventFieldName])
				editSession.changed = true
				const [obj, fieldName] = getObjectAndFieldName(editSession, path)
				obj[fieldName] = value
				if (updatesEditSession) setEditSession(editSession.clone())
			}
		},
	]
}

function getObjectAndFieldName(editSession, fieldName) {
	let obj = editSession.data
	const keys = fieldName.split('.')
	fieldName = keys.pop()
	for (const key of keys) obj = obj[key]
	return [obj, fieldName]
}

// performs the editing session flow
// NOTE makes a copy of item
export async function startEditSession(setState, item, options) {
	// onDispose is used so ui can reset the parent state when they're ready (onExited handler in EditDialog)
	options.data = JSON.parse(JSON.stringify(item))
	options.onDispose = () => setState(null)
	const editSession = new EditSession(options)
	setState(editSession)
	setState(await editSession.wait())
}
