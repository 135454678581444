import React, { createContext, useContext, useReducer } from 'react'

let historyBackUnblockFunc = null
const historyBackStack = []
const handleHistoryBack = (a, b, c) => {
	const func = historyBackStack[historyBackStack.length - 1]
	if (typeof func === 'function') func()
	return false
}

const StoreContext = createContext()
const initialState = {
	confirm(dispatch, title, message, yes, no) {
		return new Promise((resolve) => {
			dispatch({ type: 'confirmation_dialog.show', title, message, yes, no, resolve })
		})
	},
	createFeature(dispatch, { cb, entityId, entityKind, facilities }) {
		dispatch({ type: 'create_feature_dialog.show', cb, entityId, entityKind, facilities })
	},
	editDrill(dispatch, { clone, drill, drillId }) {
		dispatch({ type: 'drill_edit_dialog.show', clone, drill, drillId })
	},
	isLoading() {
		return this.loadingOverlay.open
	},
	popBackHandler() {
		historyBackStack.pop()
		if (historyBackStack.length > 0) return
		historyBackUnblockFunc()
		historyBackUnblockFunc = null
	},
	pushBackHandler(history, func) {
		historyBackStack.push(func || true)
		if (historyBackUnblockFunc) return
		historyBackUnblockFunc = history.block(handleHistoryBack)
	},
	setDrillsDirty(dispatch, dirty) {
		dispatch({ type: 'setDrillsDirty', dirty })
	},
	setLoadingProgress(dispatch, progress) {
		dispatch({ type: 'loading_overlay.progress', progress })
	},
	showAlert(dispatch, { title, message, okay }) {
		initialState.showAlertAsync(dispatch, { title, message, okay })
	},
	showAlertAsync(dispatch, { title, message, okay }) {
		return new Promise((resolve) => {
			dispatch({ type: 'alert_dialog.show', title, message, okay, resolve })
		})
	},
	showDrillLimitStats(dispatch, drillId) {
		dispatch({ type: 'drill_limit_stats_dialog.show', drillId })
	},
	showError(dispatch, message) {
		initialState.showErrorAsync(dispatch, message)
	},
	showErrorAsync(dispatch, message) {
		return new Promise((resolve) => {
			dispatch({ type: 'alert_dialog.show', title: 'ERROR', message, resolve })
		})
	},
	showLoading(dispatch, text = 'Loading...', { progress } = {}) {
		dispatch({ type: 'loading_overlay.show', text, progress })
	},
	showLoadingUntil(dispatch, func, text = 'Loading...', { progress } = {}) {
		initialState.showLoadingUntilAsync(dispatch, func, text, { progress })
	},
	async showLoadingUntilAsync(dispatch, func, text = 'Loading...', { progress } = {}) {
		initialState.showLoading(dispatch, text, { progress })
		await func()
		initialState.hideLoading(dispatch)
	},
	hideLoading(dispatch) {
		dispatch({ type: 'loading_overlay.hide' })
	},
	showVideo(dispatch, src) {
		dispatch({ type: 'video_overlay.show', src })
	},
	viewDrill(dispatch, { drill, drillId }) {
		dispatch({ type: 'drill_view_dialog.show', drill, drillId })
	},

	drillsDirty: false,
	drillsPageScrollTopCache: new Map(),
	drillsPageScrollLeftCache: new Map(),
	sessionChecked: false,
	user: null,
	redirectToAfterLogin: null,

	addStatsDialog: {
		open: false,
		facilities: [],
		userId: null,
		userName: '',
	},

	alertDialog: {
		open: false,
		title: null,
		message: null,
		okay: null,
		resolve: null,
	},

	confirmationDialog: {
		open: false,
		title: null,
		message: null,
		yes: null,
		no: null,
		resolve: null,
	},

	createFeatureDialog: {
		cb: null,
		entityId: null,
		entityKind: null,
		facilities: [],
		open: false,
	},

	drillEditDialog: {
		open: false,
		clone: false,
		drill: null,
		drillId: null,
	},

	drillViewDialog: {
		open: false,
		drill: null,
		drillId: null,
	},

	drillLimitStatsDialog: {
		open: false,
		drillId: null,
	},

	loadingOverlay: {
		open: false,
		opaque: null,
		progress: null,
		text: null,
	},

	videoOverlay: {
		open: false,
		src: null,
	},
}

const reducer = (state, action) => {
	switch (action.type) {
		case 'clearScrollOffsets':
			return { ...state,
				drillsPageScrollTopCache: new Map(),
				drillsPageScrollLeftCache: new Map(),
			}
		case 'redirectToAfterLogin':
			if (!action.to) return { ...state, redirectToAfterLogin: null }
			return { ...state, redirectToAfterLogin: { to: action.to, redirected: false } }
		case 'sessionChecked': return { ...state, sessionChecked: true }
		case 'setDrillsDirty': return { ...state, drillsDirty: action.dirty }
		case 'user': return { ...state, user: action.user }

		case 'add_stats_dialog.hide':
			return { ...state, addStatsDialog: { ...state.addStatsDialog, open: false, facilities: [], userId: null, userName: '' } }
		case 'add_stats_dialog.show': {
			const { facilities, userId, userName } = action
			return { ...state, addStatsDialog: { open: true, facilities, userId, userName } }
		}

		case 'alert_dialog.hide':
			return { ...state, alertDialog: { ...state.alertDialog, open: false } }
		case 'alert_dialog.show': {
			const { title, message, okay, resolve } = action
			return { ...state, alertDialog: { open: true, title, message, okay, resolve } }
		}

		case 'confirmation_dialog.hide':
			return { ...state, confirmationDialog: { ...state.confirmationDialog, open: false } }
		case 'confirmation_dialog.show': {
			const { title, message, yes, no, resolve } = action
			return { ...state, confirmationDialog: { open: true, title, message, yes, no, resolve } }
		}

		case 'create_feature_dialog.hide':
			return { ...state, createFeatureDialog: { open: false, cb: null, entityId: null, entityKind: null, facilities: [] } }
		case 'create_feature_dialog.show': {
			const { cb, entityId, entityKind, facilities } = action
			return { ...state, createFeatureDialog: { open: true, cb, entityId, entityKind, facilities } }
		}

		case 'loading_overlay.hide':
			return { ...state, loadingOverlay: { open: false } }
		case 'loading_overlay.progress':
			return { ...state, loadingOverlay: { ...state.loadingOverlay, progress: action.progress } }
		case 'loading_overlay.show':
			return { ...state, loadingOverlay: { text: action.text, open: true } }

		case 'drill_edit_dialog.hide':
			return { ...state, drillEditDialog: { ...state.drillEditDialog, open: false } }
		case 'drill_edit_dialog.loaded': {
			const { drill } = action
			return { ...state, drillEditDialog: { ...state.drillEditDialog, drill } }
		}
		case 'drill_edit_dialog.show': {
			const { clone = false, drill, drillId } = action
			return { ...state, drillEditDialog: { clone, drill, drillId, open: true } }
		}

		case 'drill_view_dialog.hide':
			return { ...state, drillViewDialog: { ...state.drillViewDialog, open: false } }
		case 'drill_view_dialog.loaded': {
			const { drill } = action
			return { ...state, drillViewDialog: { ...state.drillViewDialog, drill } }
		}
		case 'drill_view_dialog.show': {
			const { drill, drillId } = action
			return { ...state, drillViewDialog: { drill, drillId, open: true } }
		}

		case 'drill_limit_stats_dialog.hide':
			return { ...state, drillLimitStatsDialog: { ...state.drillLimitStatsDialog, open: false } }
		case 'drill_limit_stats_dialog.show':
			return { ...state, drillLimitStatsDialog: { drillId: action.drillId, open: true } }

		case 'video_overlay.hide':
			return { ...state, videoOverlay: { src: null, open: false } }
		case 'video_overlay.show':
			return { ...state, videoOverlay: { src: action.src, open: true } }

		default:
			throw new Error(`Unhandled action type: ${action.type}`);
	}
}

export const StoreProvider = ({ children }) => {
	const [ state, dispatch ] = useReducer(reducer, initialState)

	return <StoreContext.Provider value={{ state, dispatch }}>
		{children}
	</StoreContext.Provider>
}

export const useStore = () => useContext(StoreContext)
