import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import ListSubheader from '@material-ui/core/ListSubheader'
import LoadingOverlay from '../Components/LoadingOverlay'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useEffect, useState } from 'react'
import Select from '@material-ui/core/Select'
import Stack from '../Components/Stack'
import StandardDialog from './StandardDialog'
import api from '../api'
import { AlertDialog, useAlertDialog } from './AlertDialog'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	body: {
		padding: theme.spacing(2),
	},
}))

export default function GunReassignDialog({ gun, open, onCancel, onComplete, onExited }) {
	const classes = useStyles()

	const [ value, setValue ] = useState(null)
	const [ fetchedData, setFetchedData ] = useState(null)
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const handleChange = ({ target: { value } }) => {
		setValue(value)
	}

	const handleComplete = () => {
		const [ what, id ] = value.split('-')
		// eslint-disable-next-line
		onComplete?.(what === 'me' ? undefined : parseInt(id))
	}

	const fetchData = async () => {
		setLoadingOverlayText('Loading...')
		const res = await api.getFacilities()
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		setFetchedData(res)
		return res
	}

	useEffect(() => {
		setValue(gun?.facility_id ? ('facility-' + gun.facility_id) : 'me')
		if (!gun) {
			setFetchedData(null)
			return
		}
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [gun])

	const menuItems = [
		<MenuItem key='me' value='me'>My Guns</MenuItem>
	]
	if (fetchedData) {
		fetchedData.facilities.sort((a, b) => a.name.localeCompare(b.name))
		fetchedData.organizations.sort((a, b) => a.name.localeCompare(b.name))
		for (const { id: org_id, name } of fetchedData.organizations) {
			menuItems.push(<ListSubheader key={'organization-' + org_id} style={{ pointerEvents: 'none' }}>{name}</ListSubheader>)
			for (let { id, name, organization_id } of fetchedData.facilities) {
				if (organization_id !== org_id) continue
				id = 'facility-' + id
				menuItems.push(<MenuItem key={id} value={id}>{name}</MenuItem>)
			}
		}
	}

	return <StandardDialog open={open} title='Reassign Gun' contain={false} onCancel={onCancel} onComplete={handleComplete} onExited={onExited}>
		<AlertDialog options={alertDialogOptions} />
		<LoadingOverlay open={Boolean(loadingOverlayText)} text={loadingOverlayText} />
		<div className={classes.body}>
			{ fetchedData ? <Stack>
				<FormControl>
					<FormHelperText>REASSIGN TO</FormHelperText>
					<Select value={value} onChange={handleChange}>{menuItems}</Select>
				</FormControl>
			</Stack> : undefined }
		</div>
	</StandardDialog>
}
