import {
	StandardTable as Table,
	StandardTableColumn as TableColumn,
	StandardTableColumns as TableColumns,
} from '../Components/StandardTable'
import Box from '@material-ui/core/Box'
import ClearIcon from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import LoadingOverlay from '../Components/LoadingOverlay'
import React, { useEffect, useState } from 'react'
import Stack from '../Components/Stack'
import StandardDialog from './StandardDialog'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import { AlertDialog, useAlertDialog } from './AlertDialog'
import { ConfirmationDialog, useConfirmationDialog } from './ConfirmationDialog'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
	const styles = {
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			minHeight: 0,
			padding: theme.spacing(1),
			width: 400,
		},
		table: {
			height: 300,
		},
	}

	styles[theme.breakpoints.down('sm')] = {
		root: {
			width: '100%',
		},
		table: {
			height: 'auto',
			flexGrow: 1,
			minHeight: 0,
		},
	}

	return styles
})

export default function ImpersonationDialog({ open, onClose }) {
	const classes = useStyles()

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const [ confirmationDialogOptions, setConfirmationDialogOptions ] = useState(null)
	const [ fetchedData, setFetchedData ] = useState(null)
	const [ filter, setFilter ] = useState('')
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')

	const showAlertDialog = useAlertDialog(setAlertDialogOptions)
	const showConfirmationDialog = useConfirmationDialog(setConfirmationDialogOptions)

	const fetchData = async () => {
		setLoadingOverlayText('Loading...')
		const res = await api.getUsers()
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		setFetchedData(res)
		return res
	}

	useEffect(() => {
		if (!open || fetchedData) return
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [open])

	const handleClick = async ({ id, name }) => {
		const msg = `Are you sure you want to impersonate "${name}"?`
		if (!await showConfirmationDialog('Confirm Impersonation', msg)) return
		setLoadingOverlayText('Starting User Impersonation...')
		const res = await api.postSessionImpersonate({ user_id: id })
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		window.location.reload()
	}

	let content
	if (fetchedData) {
		let matchCount = 0
		const fullMatches = []
		const partialMatches = []

		if (filter) {
			const users = fetchedData.users

			for (let i = 0; i < users.length; i++) {
				const user = users[i]
				let match = false
				if (user.email.toLowerCase().includes(filter)) match = true
				else if (user.name.toLowerCase().includes(filter)) match = true
				else if (user.username.toLowerCase().includes(filter)) match = true
				if (!match) continue
				matchCount++
				fullMatches.push(user)
				user._is_full_match = true
				if (matchCount === 10) break
			}

			if (matchCount < 10) {
				const words = filter.split(' ').map(p => p.trim()).filter(v => !!v)
				for (let i = 0; i < users.length; i++) {
					const user = users[i]
					if (user._is_full_match) continue
					let match = false
					for (const word of words) {
						if (user.email.toLowerCase().includes(word)) match = true
						else if (user.name.toLowerCase().includes(word)) match = true
						else if (user.username.toLowerCase().includes(word)) match = true
						if (match) break
					}
					if (!match) continue
					matchCount++
					partialMatches.push(user)
					if (matchCount === 10) break
				}
			}
		}
		const matches = fullMatches.concat(partialMatches)

		if (matches.length > 0) {
			content = <Table className={classes.table} header={false} pagination={false} items={matches} variant='outlined' onClickRow={handleClick}>
				<TableColumns>
					<TableColumn fieldName='value' onTransform={(_, { email, name, username, _is_full_match }) =>
						<Stack spacing={false}>
							<Stack horizontal={true} grow={false}>
								<Typography variant={'body1'}>{name}</Typography>
								<div style={{ flexGrow: 1 }} />
								{ partialMatches.length > 0 ?
									<Typography style={{ color: _is_full_match ? '#0f0' : '#f00' }} variant={'caption'}>{_is_full_match ? 'EXACT' : 'PARTIAL'}</Typography>
								: undefined }
							</Stack>
							<Stack horizontal={true} grow={false}>
								<Typography variant={'caption'}>{email}</Typography>
								<div style={{ flexGrow: 1 }} />
								<Typography variant={'caption'}>{username}</Typography>
							</Stack>
						</Stack>
					} />
				</TableColumns>
			</Table>
		}
		else {
			content = <Box sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 16 }}>
				<Typography>{ filter ? 'No Matches' : 'Filter Required' }</Typography>
			</Box>
		}
	}

	return <StandardDialog open={open} title='Impersonate User' contain={false} onCancel={onClose} cancelTooltip={'Close'}>
		<AlertDialog options={alertDialogOptions} />
		<ConfirmationDialog options={confirmationDialogOptions} />
		<LoadingOverlay open={Boolean(loadingOverlayText)} text={loadingOverlayText} />
		<div className={classes.root}>
			{ fetchedData ? <Stack grow={false}>
				<Input value={filter} placeholder={'Filter users...'} autoFocus onChange={e => setFilter((e?.target?.value ?? '').toLowerCase())}
					endAdornment={ Boolean(filter) ? <InputAdornment position='end'>
						<IconButton size={'small'} onClick={() => setFilter('')}><ClearIcon /></IconButton>
					</InputAdornment> : undefined }>
				</Input>
				{content}
			</Stack> : undefined }
		</div>
	</StandardDialog>
}
