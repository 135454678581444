import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import ListSubheader from '@material-ui/core/ListSubheader'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import React from 'react'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {

	},
	desktop: {
		display: 'flex',
		flexDirection: 'row',
		gap: 16,
	},
}))

function SplitButton({ options, value, onChange }) {
	const [open, setOpen] = React.useState(false)
	const [displayValue, setDisplayValue] = React.useState(options[0].id)

	const anchorRef = React.useRef(null)
	const displayOption = options.find(({ id }) => id === displayValue)
	const color = value ? 'primary' : 'secondary'

	return <div ref={anchorRef}>
		<ButtonGroup fullWidth sx={{ p: 0 }} variant='contained' color={color}>
			<Button sx={{ p: 0 }} onClick={() => onChange({ target: { value: displayValue } })}>
				{displayOption?.name}
			</Button>
			<Button size='small' style={{ maxWidth: '30px' }} onClick={() => setOpen(!open)}>
				<KeyboardArrowDownIcon />
			</Button>
		</ButtonGroup>
		{ anchorRef.current ? <Popper transition open={open} anchorEl={anchorRef.current} placement={'bottom-end'}>
			{({ TransitionProps }) => (
				<Grow {...TransitionProps}>
					<Paper>
						<ClickAwayListener onClickAway={() => setOpen(false)}>
							<MenuList>
								{options.map(({ id, name }) => (
									<MenuItem key={id} selected={id === value} onClick={() => {
										setOpen(false)
										setDisplayValue(id)
										onChange({ target: { value: id } })
									}}>{name}</MenuItem>
								))}
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper> : undefined }
	</div>
}

function DesktopEntitySelector({ groups, value, onChange, ...rest }) {
	const classes = useStyles()

	return <div className={classes.desktop} {...rest}>
		{groups.map(({ key, options }) => {
			const groupSelected = value.startsWith(key + '-')
			const groupValue = groupSelected ? parseInt(value.split('-').pop()) : undefined

			if (options.length > 1)
				return <SplitButton key={key} options={options} value={groupValue} onChange={({ target: { value } }) => {
					onChange({ target: { value: `${key}-${value}` } })
				}} />

			const option = options[0]
			const color = groupValue === option.id ? 'primary' : 'default'
			return <Button key={key} variant='contained' color={color} onClick={() => {
				const value = `${key}-${option.id}`
				onChange({ target: { value } })
			}}>{option.name}</Button>
		})}
	</div>
}

function MobileEntitySelector({ groups, value, onChange, ...rest }) {
	const items = groups.reduce((items, { key, name, options }, i) => {
		if (i > 0 || options.length > 1)
			items.push(<ListSubheader key={key}>{name}</ListSubheader>)
		for (const { id, name } of options) {
			const value = `${key}-${id}`
			items.push(<MenuItem key={value} value={value}>{name}</MenuItem>)
		}
		return items
	}, [])

	return <Select value={value} variant={'outlined'}
		SelectDisplayProps={{ styles: { fontSize: 32, paddingTop: 20, paddingBottom: 0 } }}
		onChange={({ target: { value } }) => onChange({ target: { value } })} {...rest}>
		{items}
	</Select>
}

export default function EntitySelector({ groups, isMobile, value, onChange, ...rest }) {
	value = value ?? ''
	return isMobile ? <MobileEntitySelector groups={groups} value={value} onChange={onChange} {...rest} />
		: <DesktopEntitySelector groups={groups} value={value} onChange={onChange} {...rest} />
}

// const foo = <EntitySelector groups={groups} ></EntitySelector>
