import Button from '@material-ui/core/Button'
import LoadingOverlay from '../Components/LoadingOverlay'
import React, { useEffect, useState } from 'react'
import Stack from '../Components/Stack'
import StandardDialog from './StandardDialog'
import StandardTextField from '../Components/StandardTextField'
import api from '../api'
import { AlertDialog, useAlertDialog } from './AlertDialog'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	body: {
		padding: theme.spacing(2),
	},
}))

export default function NgrokDialog({ entityId, entityKind, open, onCancel, onExited }) {
	const classes = useStyles()

	const [ fetchedData, setFetchedData ] = useState(null)
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const fetchData = async () => {
		setLoadingOverlayText('Loading...')
		const res = await api.getNgrokSession({ entity_id: entityId, entity_kind: entityKind })
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		setFetchedData(res)
		return res
	}

	const fetchAndSetData = async () => {
		const res = await fetchData()
		if (res) setFetchedData(res)
	}

	useEffect(() => {
		if (!entityId) {
			setFetchedData(null)
			return
		}
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [entityId])

	const ngrokSession = fetchedData?.ngrok_session

	let sshCommand = ''
	if (ngrokSession) {
		const [ address, port ] = ngrokSession.url.slice(6).split(':')
		sshCommand = `ssh -o 'StrictHostKeyChecking=no' -p ${port} sa@${address}`
	}

	const handleClick = async () => {
		let res
		const params = { entity_id: entityId, entity_kind: entityKind }
		if (ngrokSession) {
			setLoadingOverlayText('Stopping ngrok session...')
			res = await api.postNgrokStop(params)
		}
		else {
			setLoadingOverlayText('Starting ngrok session...')
			res = await api.postNgrokStart(params)
		}
		if (!res.ok) {
			setLoadingOverlayText('')
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
		setLoadingOverlayText('')
	}

	const handleCopy = async () => {
		await navigator.clipboard.writeText(sshCommand)
	}

	const handleRefresh = async () => {
		setLoadingOverlayText('Checking for ngrok session...')
		await fetchAndSetData()
		setLoadingOverlayText('')
	}

	return <StandardDialog open={open} title='Ngrok Control Panel' contain={false} onCancel={onCancel} onExited={onExited}>
		<AlertDialog options={alertDialogOptions} />
		<LoadingOverlay open={Boolean(loadingOverlayText)} text={loadingOverlayText} />
		<div className={classes.body}>
			{ fetchedData ? <Stack>
				<Stack horizontal spacing={false}>
					<StandardTextField label={'SSH Command'} value={sshCommand} multiline InputProps={{ readOnly: true }} />
				</Stack>
				<Stack horizontal>
					<Button variant='contained' disabled={!ngrokSession} onClick={handleCopy}>COPY</Button>
					<Button variant='contained' onClick={handleRefresh}>REFRESH</Button>
				</Stack>
				<Button variant='contained' style={{
					background: ngrokSession ? 'red' : 'green',
					color: 'white',
				}} onClick={handleClick}>{ngrokSession ? 'STOP SESSION' : 'START SESSION'}</Button>
			</Stack> : undefined }
		</div>
	</StandardDialog>
}
