import {
	StandardTable as Table,
	StandardTableColumn as TableColumn,
	StandardTableColumns as TableColumns,
} from '../Components/StandardTable'
import Button from '@material-ui/core/Button'
import ClearIcon from '@material-ui/icons/Clear'
import CloseIcon from '@material-ui/icons/Close'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import LoadingOverlay from '../Components/LoadingOverlay'
import MenuItem from '@material-ui/core/MenuItem'
import React, { useEffect, useState } from 'react'
import SaveIcon from '@material-ui/icons/Save'
import Select from '@material-ui/core/Select'
import Stack from '../Components/Stack'
import StandardTextField from '../Components/StandardTextField'
import StatsCourt from '../Components/StatsCourt'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import parseHTML from 'html-react-parser'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { AlertDialog, useAlertDialog } from './AlertDialog'
import { DrillBallShotType } from '../types'
import { WizardDialog, WizardDialogSection } from './WizardDialog'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => {
	const styles = {
		root: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			minHeight: 0,
			padding: theme.spacing(2),
			width: 430,
		},
		searchTable: {
			maxHeight: 300,
		},
		statsCourt: {
			filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.5))',
		},
	}

	styles[theme.breakpoints.down('sm')] = {
		root: {
			width: '100%',
		},
		searchTable: {
			flexBasis: '0%',
			flexGrow: 1,
			maxHeight: 'initial',
			minHeight: 0,
		},
	}

	return styles
})

function ImportToExistingAccountSection({ users, onPrevious, onNext }) {
	const classes = useStyles()

	const hasSingleUser = users.length === 1

	const message = hasSingleUser ? [
			'An account with the selected legacy accounts ',
			`<span style='color: ${users[0].matches === 'email' ? '#0ff' : '#0f0'};'>${users[0].matches}</span> `,
			'already exists. The legacy accounts stats will be imported to this existing account.',
		].join('') : [
			'Two separate accounts already exist with the selected legacy accounts ',
			'<span style="color: #0ff;">email</span> and <span style="color: #0f0;">username</span>.',
			'<br><br>Select the account you would like to import the legacy stats into.',
		].join('')

	return <WizardDialogSection
		title={'Import To Existing Account'}
		previousTooltip={'Go Back'} onPrevious={onPrevious}
		nextIcon={<KeyboardArrowRightIcon />} nextTooltip={'Continue'} onNext={hasSingleUser ? () => onNext(false, users[0]) : undefined}>
		<Stack className={classes.root} grow={false}>
			<Typography variant={'body1'}>{parseHTML(message)}</Typography>
			<Table header={false} pagination={false} items={users} variant='outlined' onClickRow={hasSingleUser ? undefined : (user) => onNext(false, user)}>
				<TableColumns>
					<TableColumn fieldName='value' onTransform={(_, { email, matches, name, username }) => <Stack spacing={false}>
						<Typography variant={'body1'}>{name}</Typography>
						<Stack horizontal={true} grow={false}>
							<Typography variant={'caption'} style={{ color: matches === 'email' ? '#0ff' : '' }}>{email}</Typography>
							<div style={{ flexGrow: 1 }} />
							<Typography variant={'caption'} style={{ color: matches === 'username' ? '#0f0' : '' }}>{username}</Typography>
						</Stack>
					</Stack>} />
				</TableColumns>
			</Table>
			<Button type='submit' color='primary' variant='contained' onClick={() => onNext(true)}>Import To New Account</Button>
		</Stack>
	</WizardDialogSection>
}

function ImportToNewAccountSection({ user, onPrevious, onNext }) {
	const classes = useStyles()

	const [ params, setParams ] = useState({ email: '', name: '', password: '', username: '' })

	const handleChange = (fieldName) => (e) => {
		setParams({ ...params, [fieldName]: e.target.value })
	}

	const handleSubmit = () => {
		const { email, name, password, username } = params
		const newUser = {
			email: email.trim(),
			name: name.trim(),
			username: username.trim(),
		}
		if (password) newUser.password = password
		onNext(newUser)
	}

	useEffect(() => {
		setParams({ ...params,
			email: user.email ?? '',
			name: user.name ?? '',
			username: user.username ?? '',
		})
		// eslint-disable-next-line
	}, [])

	const canSubmit = params.email && params.name && params.username

	return <WizardDialogSection
		title={'Import To New Account'}
		previousTooltip={'Go Back'} onPrevious={onPrevious}
		nextIcon={<KeyboardArrowRightIcon />} nextTooltip={'Continue'} onNext={canSubmit ? handleSubmit : undefined}
	>
		<Stack className={classes.root} grow={false}>
			<StandardTextField label='Name' value={params.name} onChange={handleChange('name')} autoFocus />
			<StandardTextField label='Email' value={params.email} onChange={handleChange('email')} type='email' />
			<StandardTextField label='Username' value={params.username} onChange={handleChange('username')} />
			<StandardTextField label='Password (optional)' value={params.password} onChange={handleChange('password')} type='password' />
		</Stack>
	</WizardDialogSection>
}

function ImportSummarySection({ analysis, backup, existingUser, newUser, onPrevious, onNext }) {
	const classes = useStyles()
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const [ facilities, setFacilities ] = useState(null)
	const [ facilityId, setFacilityId ] = useState('')
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')

	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const { email, name, username } = existingUser ?? newUser

	const handleSubmit = async () => {
		if (!facilityId) {
			await showAlertDialog('ERROR', 'Select a facility to import these stats to.')
			return
		}

		setLoadingOverlayText('Performing Import...')
		let res
		if (existingUser) res = await api.postBackupsImportExistingUser(backup.id, {
			facility_id: facilityId,
			user_id: existingUser.id,
		})
		else res = await api.postBackupsImportNewUser(backup.id, {
			...newUser,
			facility_id: facilityId,
		})
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		// eslint-disable-next-line
		onNext?.()
	}

	useEffect(() => {
		let cancelled = false
		;(async () => {
			setLoadingOverlayText('Loading Facilities...')
			const res = await api.getOrganizationsFacilities(backup.organization_id)
			setLoadingOverlayText('')
			if (!res.ok) {
				await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
				return
			}
			if (res && !cancelled) setFacilities(res.facilities)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [])

	return <WizardDialogSection
		title={'Import Summary'}
		previousTooltip={'Go Back'} onPrevious={onPrevious}
		nextIcon={<SaveIcon />} nextTooltip={'Submit'} onNext={handleSubmit}
	>
		<AlertDialog options={alertDialogOptions} />
		<LoadingOverlay open={Boolean(loadingOverlayText)} text={loadingOverlayText} />
		{ facilities ? <Stack className={classes.root} grow={false} small={true}>
			<Typography variant={'h6'}>{ newUser ? 'Importing To New Account' : 'Importing To Existing Account' }</Typography>
			<div>
				<Typography variant={'caption'}>Name</Typography>
				<Typography variant={'body1'}>{name}</Typography>
			</div>
			<div>
				<Typography variant={'caption'}>Email</Typography>
				<Typography variant={'body1'}>{email}</Typography>
			</div>
			<div>
				<Typography variant={'caption'}>Username</Typography>
				<Typography variant={'body1'}>{username}</Typography>
			</div>
			<FormControl>
				<InputLabel>Facility</InputLabel>
				<Select value={facilityId} onChange={({ target: { value } }) => {
					if (!value) return
					setFacilityId(parseInt(value))
				}}>{facilities.map(({ id, name }) => <MenuItem key={id} value={id}>{name}</MenuItem>)}</Select>
			</FormControl>
			<div style={{ marginTop: '24px' }}>
				<Typography variant={'h6'}>The following stats will be imported</Typography>
				<Typography variant={'body1'}>{isMobile ? 'Tap on' : 'Hover over'} a zone to view its stats</Typography>
			</div>
			<StatsCourt
				className={classes.statsCourt}
				displayStats={true}
				shotType={DrillBallShotType.catch_and_shoot}
				stats={analysis.stats}
			/>
		</Stack> : undefined }
	</WizardDialogSection>
}

function SearchSection({ previousQuery, onPrevious, onNext }) {
	const classes = useStyles()

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const [ fetchedData, setFetchedData ] = useState(null)
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')
	const [ query, setQuery ] = useState('')

	const showAlertDialog = useAlertDialog(setAlertDialogOptions)

	const handleBackupClicked = async (backup) => {
		setLoadingOverlayText('One Moment...')
		const res = await api.getBackupsAnalysis(backup.id)
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		onNext(res, backup, query)
	}

	const search = async (query) => {
		if (!query) return
		setLoadingOverlayText('Searching Users...')
		const res = await api.getBackupsSearch({ query })
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		setFetchedData(res)
	}

	useEffect(() => {
		if (!previousQuery) return
		setQuery(previousQuery)
		// eslint-disable-next-line
		const _ = search(previousQuery)
		// eslint-disable-next-line
	}, [])

	return <WizardDialogSection
		title={'Search Importable Users'}
		previousIcon={<CloseIcon />} previousTooltip={'Close'} onPrevious={onPrevious}
	>
		<AlertDialog options={alertDialogOptions} />
		<LoadingOverlay open={Boolean(loadingOverlayText)} text={loadingOverlayText} />
		<Stack className={classes.root} grow={false}>
			<Stack grow={false} horizontal={true}>
				<Input value={query} placeholder={'Enter email, name, or username...'} style={{ flexGrow: 1 }}
					onChange={e => setQuery((e?.target?.value ?? ''))}
					onKeyPress={async (e) => {
						if (e.key !== 'Enter') return
						e.preventDefault()
						await search(query)
					}}
					endAdornment={ Boolean(query) ? <InputAdornment position='end'>
						<IconButton size={'small'} onClick={() => {
							setQuery('')
							setFetchedData(null)
						}}><ClearIcon /></IconButton>
					</InputAdornment> : undefined }>
				</Input>
				<Button type='submit' color='primary' variant='contained' onClick={() => search(query)}>Search</Button>
			</Stack>
			<Table className={classes.searchTable} header={false} pagination={false} items={fetchedData?.users ?? []} variant='outlined' onClickRow={handleBackupClicked}>
				<TableColumns>
					<TableColumn fieldName='value' onTransform={(_, { email, name, username }) => <Stack spacing={false}>
						<Typography variant={'body1'}>{name}</Typography>
						<Stack horizontal={true} grow={false}>
							<Typography variant={'caption'}>{email ?? 'EMAIL NOT SET'}</Typography>
							<div style={{ flexGrow: 1 }} />
							<Typography variant={'caption'}>{username}</Typography>
						</Stack>
					</Stack>} />
				</TableColumns>
			</Table>
		</Stack>
	</WizardDialogSection>
}

export default function ImportUserDialog({ open, onClose }) {
	const [ state, setState ] = useState({})

	const handleExited = () => {
		setState({})
	}

	return <WizardDialog open={open} onClose={onClose} onExited={handleExited}>{(state => {
		const { analysis, backup, createNewUser, existingUser, query, newUser } = state

		if (!backup) {
			return <SearchSection
				previousQuery={query}
				onPrevious={onClose}
				onNext={(analysis, backup, query) => {
					setState({ ...state, analysis, backup, query })
				}}
			/>
		}

		const handlePrevious = () => {
			if (newUser) {
				delete state.newUser
			}
			else if (existingUser) {
				delete state.existingUser
			}
			else if (createNewUser) {
				delete state.createNewUser
			}
			else {
				delete state.analysis
				delete state.backup
			}
			setState({ ...state })
		}

		if (!newUser && !existingUser) {
			const { existing_users } = analysis

			if (existing_users.length > 0 && !createNewUser) {
				return <ImportToExistingAccountSection
					users={existing_users}
					onPrevious={handlePrevious}
					onNext={(createNewUser, existingUser) => {
						if (createNewUser) {
							setState({ ...state, createNewUser: true })
							return
						}
						setState({ ...state, existingUser })
					}}
				/>
			}

			const finalNewUser = newUser || { ...backup }
			if (createNewUser && !newUser) {
				// don't pre-fill the new account section with unavailable emails and usernames
				for (const { matches } of existing_users)
					delete finalNewUser[matches]
			}

			return <ImportToNewAccountSection user={finalNewUser} onPrevious={handlePrevious} onNext={(newUser) => {
				setState({ ...state, newUser })
			}} />
		}

		return <ImportSummarySection
			analysis={analysis} backup={backup}
			newUser={newUser} existingUser={existingUser}
			onPrevious={handlePrevious} onNext={onClose}
		/>
	})(state)}</WizardDialog>
}
