import {
	StandardTable as Table,
	StandardTableAction as TableAction,
	StandardTableActions as TableActions,
	StandardTableColumn as TableColumn,
	StandardTableColumns as TableColumns,
} from '../Components/StandardTable'

import * as utils from '../utils'
import Container from '@material-ui/core/Container'
import EditDialog from './EditDialog'
import React, { useEffect, useState } from 'react'
import Stack from '../Components/Stack'
import StandardDialog from './StandardDialog'
import StandardTextField from '../Components/StandardTextField'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import dayjs from 'dayjs'
import { ConfirmationDialog, useConfirmationDialog } from './ConfirmationDialog'
import { getEditSessionGetterAndSetter, startEditSession } from '../EditSession'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { useStore } from '../StoreProvider'

const isMobileMediaQuery = '@media (max-width: 800px)'

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(2),
		minWidth: 800,
		minHeight: 600,
		overflowY: 'auto',
		maxHeight: 'calc(100vh - 124px)',
	},
	header: {
		fontSize: 32,
		fontWeight: 'bold',
		marginBottom: theme.spacing(1),
	},
	[isMobileMediaQuery]: {
		container: {
			minWidth: '100%',
			minHeight: 0,
			maxHeight: 'initial',
		},
	},
}))

class ManageTeamDialogOptions {
	constructor(setState, onDismiss, team) {
		this.open = true
		this.onDismiss = onDismiss
		this.setState = setState
		this.team = team
	}

	closed() {
		const copy = { ...this }
		copy.open = false
		Object.setPrototypeOf(copy, ManageTeamDialogOptions.prototype)
		return copy
	}
}

export function ManageTeamDialog({ options }) {
	const classes = useStyles()
	const isMobile = useMediaQuery(isMobileMediaQuery)
	const { state, dispatch } = useStore()

	const [ fetchedData, setFetchedData ] = useState(null)

	const [ createContractEditSession, setCreateContractEditSession ] = useState(null)
	const [ createContractErrors, setCreateContractErrors ] = useState(null)

	const [ teamUserEditSession, setTeamUserEditSession ] = useState(null)
	const [ teamUserErrors, setTeamUserErrors ] = useState(null)

	const [ confirmationDialogOptions, setConfirmationDialogOptions ] = useState(null)
	const showConfirmationDialog = useConfirmationDialog(setConfirmationDialogOptions)

	const dismiss = () => () => {
		// eslint-disable-next-line
		options.onDismiss?.()
		options.setState(options.closed())
	}

	const fetchData = async () => {
		if (fetchedData) {
			state.showLoading(dispatch, 'Loading...')
		}
		const [ res1, res2 ] = await Promise.all([
			api.getTeamContracts(options.team.id),
			api.getTeamUsers(options.team.id),
		])
		if (fetchedData) {
			state.hideLoading(dispatch)
		}

		if (!res1.ok) {
			await state.showErrorAsync(dispatch, res1.extractRemainingErrorsMessage())
			return
		}

		if (!res2.ok) {
			await state.showErrorAsync(dispatch, res2.extractRemainingErrorsMessage())
			return
		}

		return {
			contracts: res1.team_contracts,
			users: res2.teams_users,
		}
	}

	const fetchAndSetData = async () => {
		const res = await fetchData()
		if (!res) return
		setFetchedData(res)
	}

	useEffect(() => {
		if (!options) return
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) {
				setFetchedData(res)
			}
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [options])

	const handleCreateTeamContract = async () => {
		await startEditSession(setCreateContractEditSession, {}, {
			onCancel() { setCreateContractErrors(null) },
			async onComplete({ email, name }) {
				state.showLoading(dispatch, 'Creating Contract Invite...')
				const res = await api.postTeamContract(options.team.id, {
					invite_email: email,
					invite_name: name,
				})
				if (!res.ok) {
					state.hideLoading(dispatch)

					const errors = {}
					errors.email = res.extractErrorMessage('invite_email')
					errors.name = res.extractErrorMessage('invite_name')
					errors.startTimestamp = res.extractErrorMessage('start_at')
					errors.endTimestamp = res.extractErrorMessage('end_at')
					setCreateContractErrors(errors)

					const msg = res.extractRemainingErrorsMessage()
					if (msg) {
						await state.showErrorAsync(dispatch, msg)
					}

					return false
				}
				setCreateContractErrors(null)
				await fetchAndSetData()
				state.hideLoading(dispatch)
				return true
			},
		})
	}

	const handleCreateTeamUser = async () => {
		await startEditSession(setTeamUserEditSession, {}, {
			onCancel() { setTeamUserErrors(null) },
			async onComplete(item) {
				state.showLoading(dispatch, 'Adding User...')
				const res = await api.postTeamUser(options.team.id, item)
				if (!res.ok) {
					state.hideLoading(dispatch)

					const errors = {}
					errors.username = res.extractErrorMessage('username')
					setTeamUserErrors(errors)

					const msg = res.extractRemainingErrorsMessage()
					if (msg) {
						await state.showErrorAsync(dispatch, msg)
					}

					return false
				}
				setTeamUserErrors(null)
				await fetchAndSetData()
				state.hideLoading(dispatch)
				return true
			},
		})
	}

	const handleRemoveTeamContract = async (item) => {
		if (!await showConfirmationDialog('CONFIRM', `Are you sure you'd like to remove "${item.name}"?`)) return
		state.showLoading(dispatch, 'Removing Contract...')
		const res = await api.deleteTeamContract(item.id)
		if (!res.ok) {
			state.hideLoading(dispatch)
			await state.showErrorAsync(dispatch, res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
		state.hideLoading(dispatch)
	}

	const handleResendEmailToTeamContract = async (item) => {
		state.showLoading(dispatch, 'Resending Invite Email...')
		const res = await api.postTeamContractResendInviteEmail(item.id)
		if (!res.ok) {
			state.hideLoading(dispatch)
			await state.showErrorAsync(dispatch, res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
		state.hideLoading(dispatch)
	}

	const handleRemoveTeamUser = async (item) => {
		if (!await showConfirmationDialog('CONFIRM', `Are you sure you'd like to remove "${item.name}"?`)) return
		state.showLoading(dispatch, 'Removing User...')
		const res = await api.deleteTeamUser(item.team_id, item.user_id)
		if (!res.ok) {
			state.hideLoading(dispatch)
			await state.showErrorAsync(dispatch, res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
		state.hideLoading(dispatch)
	}

	const [ getCreateContract, setCreateContract ] = getEditSessionGetterAndSetter(createContractEditSession, setCreateContractEditSession)
	const [ getTeamUser, setTeamUser ] = getEditSessionGetterAndSetter(teamUserEditSession, setTeamUserEditSession)

	return <StandardDialog title={'Manage ' + (options?.team.name ?? '')} cancelTooltip='Close' contain={false} open={options?.open ?? false} isMobile={isMobile} onCancel={dismiss()}>
		<ConfirmationDialog options={confirmationDialogOptions} />
		<EditDialog title='Add Player' editSession={createContractEditSession}>
			<Stack>
				<StandardTextField label='Email' error={createContractErrors?.email}
					defaultValue={getCreateContract('email')} onChange={setCreateContract('email')} autoFocus />
				<StandardTextField label='Name' error={createContractErrors?.name}
					defaultValue={getCreateContract('name')} onChange={setCreateContract('name')} />
			</Stack>
		</EditDialog>
		<EditDialog title='Add Coach' editSession={teamUserEditSession}>
			<StandardTextField label='Username' fullWidth error={teamUserErrors?.username}
				defaultValue={getTeamUser('username', '')} onChange={setTeamUser('username')} autoFocus />
		</EditDialog>
		<Container className={classes.container}>
			<Typography className={classes.header}>Coaches</Typography>
			<Table variant='outlined' style={{ marginBottom: 16 }} items={fetchedData?.users} pagination={false} idFieldName='user_id' onCreateItem={handleCreateTeamUser}>
				<TableColumns>
					<TableColumn fieldName='name'>Name</TableColumn>
				</TableColumns>
				<TableActions>
					<TableAction onClick={handleRemoveTeamUser}>Remove</TableAction>
				</TableActions>
			</Table>
			<Typography className={classes.header}>Players</Typography>
			<Table variant='outlined' items={fetchedData?.contracts} pagination={false} onCreateItem={handleCreateTeamContract}>
				<TableColumns>
					<TableColumn fieldName='name' onTransform={(_, item) => item.name ?? item.invite_name}>Name</TableColumn>
					<TableColumn fieldName='joined_at' align='right' onTransform={(v) => {
						if (!v) return 'Pending Accept'
						const date = utils.timestampParse(v)
						if (date) return `Joined ${dayjs(date).fromNow()}`
						return 'Joined'
					}}>Status</TableColumn>
				</TableColumns>
				<TableActions>
					<TableAction onClick={handleResendEmailToTeamContract} onAvailable={(v) => !v.joined_at}>Resend Invite Email</TableAction>
					<TableAction onClick={handleRemoveTeamContract}>Remove</TableAction>
				</TableActions>
			</Table>
		</Container>
	</StandardDialog>
}

export function useManageTeamDialog(setState) {
	return (team) => new Promise((resolve) => {
		setState(new ManageTeamDialogOptions(setState, resolve, team))
	})
}
