import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => ({
    color: {
        height: 16,
        width: 16,
    },
    decisionEditMode: {
		display: 'flex',
        flexDirection: 'row',
        gap: 8,
    },
    decisionViewMode: {
        alignItems: 'center',
		display: 'flex',
        borderRadius: 16,
        flexDirection: 'row',
        gap: 8,
        height: 32,
        maxWidth: 312,
        justifyContent: 'center',
    },
    icon: {
        fill: 'white',
        filter: 'drop-shadow(0 1px 1px rgba(0, 0, 0, 0.5))',
        height: 16,
        width: 16,
    },
	root: {
		display: 'flex',
        flexDirection: 'column',
		gap: 8,
	},
    selectRenderValue: {
        position: 'relative',
        top: 4,
    },
    show: {
        alignItems: 'center',
        display: 'flex',
        borderRadius: 16,
    },
    text: {
        filter: 'drop-shadow(0 1px 1px rgba(0, 0, 0, 0.75))',
        position: 'relative',
        top: '1px',
    },
}))

const defaultDecisions = window.courtLib.types.getDefaultDecisions()

const colors = Object.values(window.courtLib.types.DecisionColor)
    .filter(value => !isNaN(Number(value)))
colors.sort((a, b) => a - b)

const icons = Object.values(window.courtLib.types.DecisionIcon)
    .filter(value => !isNaN(Number(value)))
icons.sort((a, b) => a - b)

export default function DecisionMode({
    decisions,
    showColor,
    showIcon,
    showText,
    onChange,
}) {
	const classes = useStyles()

	const { state, dispatch } = useStore()

    let enabledToggleButtonCount = 0
    if (showColor ?? true) enabledToggleButtonCount++
    if (showIcon ?? true) enabledToggleButtonCount++
    if (showText ?? true) enabledToggleButtonCount++

    let resetButtonVisible = false
    for (let i = 0; i < decisions.length; i++) {
        const defaultDecision = defaultDecisions[i]
        const decision = decisions[i]
        let shouldBreak = false
        for (const key of Object.keys(defaultDecision)) {
            if (decision[key] !== defaultDecision[key]) {
                resetButtonVisible = true
                break
            }
        }
        if (shouldBreak) break
    }

	return <div className={classes.root}>
        {onChange && <div className={classes.show}>
			<Typography variant={'body1'} style={{ marginLeft: 10, marginRight: 16 }}>SHOW</Typography>
			<FormControlLabel label='COLOR' control={<Checkbox
				checked={showColor ?? true}
				onChange={(e) => {
                    if (!e.target.checked && enabledToggleButtonCount === 1) return
                    onChange('decision_mode_show_color', e.target.checked)
                }}
			/>}/>
			<FormControlLabel label='ICON' control={<Checkbox
				checked={showIcon ?? true}
				onChange={(e) => {
                    if (!e.target.checked && enabledToggleButtonCount === 1) return
                    onChange('decision_mode_show_icon', e.target.checked)
                }}
			/>}/>
			<FormControlLabel label='TEXT' control={<Checkbox
				checked={showText ?? true}
				onChange={(e) => {
                    if (!e.target.checked && enabledToggleButtonCount === 1) return
                    onChange('decision_mode_show_text', e.target.checked)
                }}
			/>}/>
        </div>}
        {onChange ? decisions.map((decision, i) => {
            const color2 = window.courtLib.types.getDecisionColor(decision.color)
            const icon2 = window.courtLib.types.getDecisionIcon(decision.icon)
            const text2 = window.courtLib.types.getDecisionText(decision.text)

            return <div key={i} className={classes.decisionEditMode}>
                <Checkbox checked={decision.enabled} onChange={(e) => {
                    if (!e.target.checked) {
                        const enabledCount = decisions.filter(x => x.enabled).length
                        if (enabledCount === 2) return
                    }
                    decision.enabled = e.target.checked
                    onChange('decisions', decisions)
                }} />
                <Select
                    displayEmpty
                    onChange={(e) => {
                        for (const otherDecision of decisions) {
                            if (otherDecision.color === e.target.value) {
                                otherDecision.color = decision.color
                                break
                            }
                        }
                        decision.color = e.target.value
                        onChange('decisions', decisions)
                    }}
                    renderValue={() => {
                        return <div className={clsx(classes.color, classes.selectRenderValue)} style={{ backgroundColor: color2 }}></div>
                    }}
                    value={decision.color}
                >
                    {colors.map((color, i) => {
                        const color2 = window.courtLib.types.getDecisionColor(color)
                        return <MenuItem key={color} value={color}>
                            <div className={classes.color} style={{ backgroundColor: color2 }}></div>
                        </MenuItem>
                    })}
                </Select>
                <Select
                    displayEmpty
                    onChange={(e) => {
                        for (const otherDecision of decisions) {
                            if (otherDecision.icon === e.target.value) {
                                otherDecision.icon = decision.icon
                                break
                            }
                        }
                        decision.icon = e.target.value
                        onChange('decisions', decisions)
                    }}
                    renderValue={() => {
                        return <div className={clsx(classes.icon, classes.selectRenderValue)} dangerouslySetInnerHTML={{ __html: icon2 }}></div>
                    }}
                    value={decision.icon}
                >
                    {icons.map((icon, i) => {
                        const icon2 = window.courtLib.types.getDecisionIcon(icon)
                        return <MenuItem key={icon} value={icon}>
                            <div className={classes.icon} dangerouslySetInnerHTML={{ __html: icon2 }}></div>
                        </MenuItem>
                    })}
                </Select>
                <Input value={text2} onChange={(e) => {
                    const defaultDecision = defaultDecisions[i]
                    const newText = e.target.value
                    if (newText === window.courtLib.types.getDecisionText(defaultDecision.text)) {
                        decision.text = defaultDecision.text
                    }
                    else {
                        decision.text = newText
                    }
                    onChange('decisions', decisions)
                }}></Input>
            </div>
		}) : decisions.filter(x => x.enabled).map((decision, i) => {
            const color2 = window.courtLib.types.getDecisionColor(decision.color)
            const icon2 = window.courtLib.types.getDecisionIcon(decision.icon)
            const text2 = window.courtLib.types.getDecisionText(decision.text)

            return <div key={i} className={classes.decisionViewMode} style={{ backgroundColor: color2 }}>
                <div className={classes.icon} dangerouslySetInnerHTML={{ __html: icon2 }}></div>
                <span className={classes.text}>{text2}</span>
            </div>
        })}
        {onChange && resetButtonVisible && <Button variant='contained' style={{ marginTop: 8 }} onClick={async () => {
            if (!await state.confirm(dispatch, 'CONFIRM', 'Reset to defaults?', 'Continue', 'Cancel')) return
            decisions.length = 0
            for (const decision of window.courtLib.types.getDefaultDecisions()) {
                decisions.push(decision)
            }
            onChange('decisions', decisions)
        }}>RESET DECISIONS</Button> }
	</div>
}
