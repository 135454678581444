import Backdrop from '@material-ui/core/Backdrop'
import React from 'react'
import { Close } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => {
	return {
		root: {
			cursor: 'pointer',
			flexDirection: 'column',
			zIndex: 100000,
		},
		closeIconButton: {
			filter: 'drop-shadow(0 2px 2px rgba(0, 0, 0, 0.5))',
			position: 'absolute',
			right: theme.spacing(2),
			top: theme.spacing(2),
		},
	}
})

export default function VideoOverlay() {
	const classes = useStyles()
	const { state, dispatch } = useStore()
	const { open, src } = state.videoOverlay

	const handleClick = (skipCheck) => (e) => {
		if (!skipCheck && !e.target.classList.contains('MuiBackdrop-root')) return
		// eslint-disable-next-line
		dispatch({ type: 'video_overlay.hide' })
	}

	return <Backdrop open={open} className={classes.root} onClick={handleClick(false)}>
		{ src ? <video autoPlay controls style={{ outline: 'none', maxWidth: '100%', maxHeight: '100%' }}>
			<source src={src} type='video/mp4' />
		</video> : undefined }
		<IconButton onClick={handleClick(true)} className={classes.closeIconButton}><Close /></IconButton>
	</Backdrop>
}
