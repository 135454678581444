import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import Court from '../Components/Court'
import Page from './Page'
import React, { useRef, useState } from 'react'
import { Box } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import EntitySelector from '../Components/EntitySelector'

function TestButton({ children }) {
	return <Button color={'primary'} variant={'contained'} >{children}</Button>
}

export default function TestPage() {
	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
	const courtRef = useRef()

	const groups = [
		{
			key: 'user',
			name: 'User',
			options: [
				{ id: 1, name: 'Cam' },
			],
		},
		{
			key: 'facility',
			name: 'Facility',
			options: [
				{ id: 1, name: 'Just Hoops' },
				{ id: 2, name: 'Swish365' },
			],
		},
		{
			key: 'team',
			name: 'Team',
			options: [
				{ id: 1, name: 'Cam League' },
			],
		},
	]

	const [value, setValue] = useState('user-1')
	const handleChange = ({ target: { value } }) => {
		setValue(value)
	}

	return <Page>
		<Container>
			<EntitySelector groups={groups} value={value} isMobile={isMobile} onChange={handleChange} />
			<Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 16 }}>
				<Card style={{ alignSelf: 'flex-start', maxWidth: '100%' }}>
					<CardContent style={{ padding: 0 }}>
						<Court passedInRef={courtRef} mode='shoot-now' interactive responsive />
					</CardContent>
				</Card>
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 16, flexGrow: 1, '& > *': { flexGrow: 1 } }}>
					<Card>
						<CardContent>
							<Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
								<TestButton>THIS WEEK</TestButton>
								<TestButton>THIS MONTH</TestButton>
								<TestButton>THIS YEAR</TestButton>
								<TestButton>CHOOSE DATES</TestButton>
							</Box>
						</CardContent>
					</Card>
					<Card>
						<CardContent>
							<Box sx={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
								<TestButton>NONE</TestButton>
								<TestButton>PREVIOUS</TestButton>
							</Box>
						</CardContent>
					</Card>
				</Box>
			</Box>
		</Container>
	</Page>
}
