import {
	StandardTable as Table,
	StandardTableAction as TableAction,
	StandardTableActions as TableActions,
	StandardTableColumn as TableColumn,
	StandardTableColumns as TableColumns,
} from '../Components/StandardTable'

import * as shared from '../shared'
import Container from '@material-ui/core/Container'
import Header from '../Components/Header'
import IconButton from '@material-ui/core/IconButton'
import ListIcon from '@material-ui/icons/List'
import Page from './Page'
import React, { Fragment, useEffect, useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import TvDialog from '../Dialogs/TvDialog'
import Typography from '@material-ui/core/Typography'
import api from '../api'
import dayjs from 'dayjs'
import { TvKind, TvKindNames, TvMode, TvModeNames } from '../types'
import { startEditSession } from '../EditSession'
import { useConfirmationDialog } from '../Dialogs/ConfirmationDialog'
import { useHistory, useLocation } from 'react-router-dom'
import { useStore } from '../StoreProvider'

export default function TvsPage() {
	const [ confirmationDialogOptions, setConfirmationDialogOptions ] = useState(null)
	const [ editSession, setEditSession ] = useState(null)
	const [ fetchedData, setFetchedData ] = useState(null)
	const { state, dispatch } = useStore()

	const history = useHistory()
	const location = useLocation()
	const showConfirmationDialog = useConfirmationDialog(setConfirmationDialogOptions)

	const today = dayjs()
	let startOfLastWeek = today.startOf('week').add(1, 'day')
	if (today.startOf('day').day() === 0 /* sunday */) {
		startOfLastWeek = startOfLastWeek.subtract(1, 'week')
	}
	startOfLastWeek = startOfLastWeek.subtract(1, 'week')

	const fetchData = async () => {
		state.showLoading(dispatch, 'Loading...')
		const res = await api.getTvs()
		state.hideLoading(dispatch)
		if (!res.ok) {
			await state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}
		return res
	}

	const fetchAndSetData = async () => {
		const res = await fetchData()
		if (res) setFetchedData(res)
	}

	const handleClick = async (item) => {
		await handleEdit(item)
	}

	const handleCreate = async () => {
		const item = {
			name: '',
			mode: TvMode.info,
		}

		await startEditSession(setEditSession, item, {
			async onCancel() {
				return await showConfirmationDialog('CONFIRM', "Are you sure you'd like to discard your new TV?")
			},
			async onComplete(item) {
				const newFileLinks = item.newFileLinks
				delete item.newFileLinks

				state.showLoading(dispatch, 'Creating TV...')
				const res = await api.postTvWeb(item)
				if (!res.ok) {
					state.hideLoading(dispatch)
					await state.showError(dispatch, res.extractRemainingErrorsMessage())
					return false
				}

				if (newFileLinks) {
					for (const fileLink of Object.values(newFileLinks)) {
						const res2 = await api.putFileLink({
							entity_kind: 'tv',
							entity_id: res.tv_id,
							file_id: fileLink.file_id,
							kind: fileLink.kind,
						})
						if (!res2.ok) {
							state.hideLoading(dispatch)
							await state.showError(dispatch, res2.extractRemainingErrorsMessage())
							return false
						}
					}
				}

				await fetchAndSetData()
				state.hideLoading(dispatch)
				return true
			},
		})
	}

	const handleDelete = async ({ id }) => {
		if (!await state.confirm(dispatch, 'CONFIRM', 'Delete TV?', 'YES', 'NO')) return
		state.showLoading(dispatch)
		const res = await api.deleteTv(id)
		if (!res.ok) {
			state.hideLoading(dispatch)
			await state.showError(dispatch, res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
		state.hideLoading(dispatch)
	}

	const handleEdit = async (item) => {
		await startEditSession(setEditSession, item, {
			async onCancel() {
				return await showConfirmationDialog('CONFIRM', "Are you sure you'd like to discard your changes to this TV?")
			},
			async onComplete(item) {
				const newFileLinks = item.newFileLinks
				delete item.newFileLinks

				state.showLoading(dispatch, 'Updating TV...')
				const res = await api.patchTv(item.id, item)
				if (!res.ok) {
					state.hideLoading(dispatch)
					await state.showError(dispatch, res.extractRemainingErrorsMessage())
					return false
				}

				if (newFileLinks) {
					for (const fileLink of Object.values(newFileLinks)) {
						const res2 = await api.putFileLink({
							entity_kind: 'tv',
							entity_id: item.id,
							file_id: fileLink.file_id,
							kind: fileLink.kind,
						})
						if (!res2.ok) {
							state.hideLoading(dispatch)
							await state.showError(dispatch, res2.extractRemainingErrorsMessage())
							return false
						}
					}
				}

				await fetchAndSetData()
				state.hideLoading(dispatch)
				return true
			},
		})
	}

	const handleView = ({ token }) => {
		shared.openTvPage({ token })
	}

	const handleViewLastWeek = ({ token }) => {
		shared.openTvPage({ today: startOfLastWeek.format('YYYY-MM-DD'), token })
	}

	useEffect(() => {
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (res && !cancelled) setFetchedData(res)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [location])

	return <Page padding='bottom' confirmationDialogOptions={confirmationDialogOptions}>
		<TvDialog editSession={editSession} setEditSession={setEditSession} />
		<Container>
			<Header>
				<Typography variant='inherit'>Tvs</Typography>
				{ state.user.is_admin && <Tooltip title='View All Tvs'>
					<IconButton onClick={() => history.push('/admin/tvs')}><ListIcon /></IconButton>
				</Tooltip> }
			</Header>
			{ fetchedData ? <Fragment>
				<Table pagination={false} items={fetchedData.tvs} onCreateItem={handleCreate} onClickRow={handleClick}>
					<TableColumns>
						<TableColumn fieldName='name'>Name</TableColumn>
						<TableColumn fieldName='mode' width={135} onTransform={(v) => `${TvModeNames[v]}`}>Mode</TableColumn>
						<TableColumn fieldName='kind' width={100} onTransform={(v) => `${TvKindNames[v]}`}>Kind</TableColumn>
					</TableColumns>
					<TableActions>
						<TableAction onClick={handleView} onAvailable={(v) => v.kind === TvKind.browser}>View</TableAction>
						{ state.user.is_admin &&
							<TableAction onClick={handleViewLastWeek} onAvailable={(v) => v.kind === TvKind.browser}>
								View Last Week ({startOfLastWeek.format('MM/DD')} - {startOfLastWeek.add(6, 'days').format('MM/DD')})
							</TableAction> }
						<TableAction onClick={handleEdit}>Edit</TableAction>
						<TableAction onClick={handleDelete}>Delete</TableAction>
					</TableActions>
				</Table>
			</Fragment> : undefined }
		</Container>
	</Page>
}
