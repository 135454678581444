import {
	StandardTable as Table,
	StandardTableAction as TableAction,
	StandardTableActions as TableActions,
	StandardTableColumn as TableColumn,
	StandardTableColumns as TableColumns,
} from '../Components/StandardTable'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AddIcon from '@material-ui/icons/Add'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import DeleteIcon from '@material-ui/icons/Delete'
import EditDialog from '../Dialogs/EditDialog'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Header from '../Components/Header'
import IconButton from '@material-ui/core/IconButton'
import ImportUserDialog from '../Dialogs/ImportUserDialog'
import ListIcon from '@material-ui/icons/List'
import OrganizationDialog from '../Dialogs/OrganizationDialog'
import Page from './Page'
import React, { useEffect, useState } from 'react'
import Select from '@material-ui/core/Select'
import StandardTextField from '../Components/StandardTextField'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import api from '../api'
import useIsMounted from '../hooks/useIsMounted'
import { ManageFacilityDialog, useManageFacilityDialog } from '../Dialogs/ManageFacilityDialog'
import { ManageTeamDialog, useManageTeamDialog } from '../Dialogs/ManageTeamDialog'
import { Typography } from '@material-ui/core'
import { getEditSessionGetterAndSetter, startEditSession } from '../EditSession'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useAlertDialog } from '../Dialogs/AlertDialog'
import { useConfirmationDialog } from '../Dialogs/ConfirmationDialog'
import { useHistory } from 'react-router-dom'
import { useStore } from '../StoreProvider'

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 0,
		paddingBottom: theme.spacing(2),
	},
	organizationHeader: {
		fontSize: 32,
		fontWeight: 'bold',
		marginTop: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	sectionSummary: {
		borderRadius: 0,
		'& .MuiAccordionSummary-content': {
			'&.Mui-expanded': {
				margin: '12px 0',
			},
		},
		'&.Mui-expanded': {
			minHeight: 48,
		},
	},
	sectionDetails: {
		padding: 0,
		borderBottomLeftRadius: 4,
		borderBottomRightRadius: 4,
		overflow: 'hidden',
	},
}))

function Organization({ forceRender, ctx, organization }) {
	const classes = useStyles()
	const { state } = useStore()
	const [ expandedId, setExpandedId ] = useState(null)

	const is_owner = state.user ? state.user.id === organization.owner_user_id : false

	const handleChange = (id, expanded) => {
		setExpandedId(expanded ? id : null)
	}

	return <div>
		<Header className={classes.organizationHeader}>
			<Typography variant='inherit'>{organization.name}</Typography>
			{ is_owner ? <Tooltip title='Edit Organization'><IconButton onClick={() => ctx.organizations.onEdit(organization)}><EditIcon /></IconButton></Tooltip> : undefined }
			{ is_owner ? <Tooltip title='Delete Organization'><IconButton onClick={() => ctx.organizations.onDelete(organization)}><DeleteIcon /></IconButton></Tooltip> : undefined }
		</Header>
		<Section forceRender={forceRender} id='users' expanded={expandedId === 'users'} ctx={ctx.users(organization)} onChange={handleChange} Component={SectionUsers} />
		{ organization.facilities_enabled ?
			<Section forceRender={forceRender} id='facilities' expanded={expandedId === 'facilities'} ctx={ctx.facilities(organization)} onChange={handleChange} Component={SectionFacilities} />
		: undefined }
		<Section forceRender={forceRender} id='teams' expanded={expandedId === 'teams'} ctx={ctx.teams(organization)} onChange={handleChange} Component={SectionTeams} />
	</div>
}

function Section({ forceRender, id, expanded, ctx, onChange, Component }) {
	const [ items, setItems ] = useState(null)
	const isMounted = useIsMounted()

	const handleChange = async (e, newExpanded) => {
		if (!newExpanded) {
			onChange(id, false)
			return
		}
		const items = await ctx.onFetch()
		if (!isMounted()) return
		setItems(items)
		onChange(id, true)
	}

	useEffect(() => {
		if (!expanded) return
		let cancelled = false
		;(async () => {
			const items = await ctx.onFetch()
			if (!isMounted() || cancelled) return
			setItems(items)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [forceRender])

	return <Component ctx={ctx} items={items} AccordionProps={{ TransitionProps: { unmountOnExit: true }, expanded, onChange: handleChange }} />
}

function SectionFacilities({ AccordionProps, ctx, items }) {
	const classes = useStyles()
	return <Accordion {...AccordionProps}>
		<AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.sectionSummary}>
			<Typography>Facilities</Typography>
		</AccordionSummary>
		<AccordionDetails className={classes.sectionDetails}>
			<Table component='div' items={items} pagination={false} idFieldName={ctx.onGetItemId} onCreateItem={ctx.onCreate} onClickRow={ctx.onManage}>
				<TableColumns>
					<TableColumn fieldName='name'>Name</TableColumn>
				</TableColumns>
				<TableActions>
					<TableAction onClick={ctx.onEdit}>Rename</TableAction>
					<TableAction onClick={ctx.onManage}>Manage</TableAction>
					<TableAction onClick={ctx.onDelete}>Delete</TableAction>
				</TableActions>
			</Table>
		</AccordionDetails>
	</Accordion>
}

function SectionTeams({ AccordionProps, ctx, items }) {
	const classes = useStyles()
	return <Accordion {...AccordionProps}>
		<AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.sectionSummary}>
			<Typography>Teams</Typography>
		</AccordionSummary>
		<AccordionDetails className={classes.sectionDetails}>
			<Table component='div' items={items} pagination={false} idFieldName={ctx.onGetItemId} onCreateItem={ctx.onCreate} onClickRow={ctx.onManage}>
				<TableColumns>
					<TableColumn fieldName='name'>Name</TableColumn>
				</TableColumns>
				<TableActions>
					<TableAction onClick={ctx.onEdit}>Rename</TableAction>
					<TableAction onClick={ctx.onManage}>Manage</TableAction>
					<TableAction onClick={ctx.onDelete}>Delete</TableAction>
				</TableActions>
			</Table>
		</AccordionDetails>
	</Accordion>
}

function SectionUsers({ AccordionProps, ctx, items }) {
	const classes = useStyles()
	const { state } = useStore()

	const ownerUserId = ctx.organization.owner_user_id
	let isOwner = false
	let isAdmin = false

	if (state.user) {
		isOwner = state.user.id === ownerUserId
		isAdmin = isOwner || (items && items.some(item => item.is_admin && item.user_id === state.user.id))
	}

	const transformRole = (_, item) => item.user_id === ownerUserId ? 'Owner' : (item.is_admin ? 'Admin' : 'Member')
	// const canTransferOwnership = (item) => isOwner && item.user_id !== ownerUserId
	const canModify = (item) => isAdmin && item.user_id !== ownerUserId

	return <Accordion {...AccordionProps}>
		<AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.sectionSummary}>
			<Typography>Managers</Typography>
		</AccordionSummary>
		<AccordionDetails className={classes.sectionDetails}>
			<Table component='div' items={items} pagination={false} idFieldName={ctx.onGetItemId} onCreateItem={ctx.onCreate}>
				<TableColumns>
					<TableColumn fieldName='name'>Name</TableColumn>
					<TableColumn fieldName='role' align='right' onTransform={transformRole}>Role</TableColumn>
				</TableColumns>
				<TableActions>
					<TableAction onClick={ctx.onChangeRole} onAvailable={canModify}>Change role</TableAction>
					<TableAction onClick={ctx.onDelete} onAvailable={canModify}>Remove</TableAction>
				</TableActions>
			</Table>
		</AccordionDetails>
	</Accordion>
}

export default function OrganizationsPage() {
	const classes = useStyles()
	const history = useHistory()
	const theme = useTheme()
	const { state } = useStore()

	const [ alertDialogOptions, setAlertDialogOptions ] = useState(null)
	const [ backupsAvailable, setBackupsAvailable ] = useState(false)
	const [ changeOrganizationUserRoleEditSession, setChangeOrganizationUserRoleEditSession ] = useState(null)
	const [ confirmationDialogOptions, setConfirmationDialogOptions ] = useState(null)
	const [ facilityEditSession, setFacilityEditSession ] = useState(null)
	const [ fetchedData, setFetchedData ] = useState(null)
	const [ importUserDialogOpen, setImportUserDialogOpen ] = useState(false)
	const [ loadingOverlayText, setLoadingOverlayText ] = useState('')
	const [ manageFacilityDialogOptions, setManageFacilityDialogOptions ] = useState(null)
	const [ manageTeamDialogOptions, setManageTeamDialogOptions ] = useState(null)
	const [ organizationEditSession, setOrganizationEditSession ] = useState(null)
	const [ organizationUserEditSession, setOrganizationUserEditSession ] = useState(null)
	const [ organizationUserError, setOrganizationUserError ] = useState('')
	const [ teamEditSession, setTeamEditSession ] = useState(null)

	const showAlertDialog = useAlertDialog(setAlertDialogOptions)
	const showConfirmationDialog = useConfirmationDialog(setConfirmationDialogOptions)
	const showManageFacilityDialog = useManageFacilityDialog(setManageFacilityDialogOptions)
	const showManageTeamDialog = useManageTeamDialog(setManageTeamDialogOptions)

	// this is passed down through the component hierarchy to force re-renders
	const [ forceRender, setForceRender ] = useState(0)

	const fetchData = async () => {
		if (fetchedData) setLoadingOverlayText('Loading...')
		const res = await api.getOrganizations()
		setLoadingOverlayText('')
		if (!res.ok) {
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		return res
	}

	const fetchAndSetData = async () => {
		const res = await fetchData()
		if (!res) return
		setFetchedData(res)
		setForceRender(forceRender + 1)
	}

	const handleChangeOrganizationUserRole = async (organization, organizationUser) => {
		const ownerUserId = organization.owner_user_id
		let isOwner = false
		let isAdmin = false

		if (state.user) {
			isOwner = state.user.id === ownerUserId
			isAdmin = isOwner || organization.users.some(item => item.is_admin && item.user_id === state.user.id)
		}

		const options = []
		if (isOwner) options.push({ id: 'owner', text: 'Owner' })
		if (isAdmin) options.push({ id: 'admin', text: 'Admin' })
		options.push({ id: 'member', text: 'Member' })

		// if (organizationUser.user_id !== organization.owner_user_id)
		const originalRole = organizationUser.is_admin ? 'admin' : 'member'

		await startEditSession(setChangeOrganizationUserRoleEditSession, { role: originalRole, options }, {
			async onComplete({ role }) {
				if (role === originalRole) return

				let res
				if (role === 'owner') {
					const message = `Are you sure you'd like to transfer ownership of this organization to "${organizationUser.name}"? This can't be undone.`
					if (!await showConfirmationDialog('CONFIRM', message)) return false
					setLoadingOverlayText('Transferring Ownership...')
					res = await api.patchOrganization(organization.id, {
						owner_user_id: organizationUser.user_id,
					})
				}
				else {
					if (role === 'member' && organizationUser.user_id === state.user.id) {
						const message = `Are you sure you'd like to make yourself a normal member of this organization? This can't be undone.`
						if (!await showConfirmationDialog('CONFIRM', message)) return false
					}
					setLoadingOverlayText('Changing Role...')
					res = await api.patchOrganizationUser(organization.id, organizationUser.user_id, {
						is_admin: role === 'admin',
					})
				}

				if (!res.ok) {
					setLoadingOverlayText('')
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return false
				}
				await fetchAndSetData()
				setLoadingOverlayText('')
				return true
			},
		})
	}


	const handleCreateFacility = async (organization) => {
		await startEditSession(setFacilityEditSession, { organization_id: organization.id }, {
			async onComplete(item) {
				setLoadingOverlayText('Adding Facility...')
				const res = await api.postFacility(item)
				if (!res.ok) {
					setLoadingOverlayText('')
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return false
				}
				await fetchAndSetData()
				setLoadingOverlayText('')
				return true
			},
		})
	}

	const handleCreateOrganization = async () => {
		await startEditSession(setOrganizationEditSession, {}, {
			onCancel: () => showConfirmationDialog('CONFIRM', "Are you sure you'd like to discard your new organization?"),
			async onComplete(item) {
				setLoadingOverlayText('Creating Organization...')
				const res = await api.postOrganization(item)
				if (!res.ok) {
					setLoadingOverlayText('')
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return false
				}
				await fetchAndSetData()
				setLoadingOverlayText('')
				return true
			},
		})
	}

	const handleCreateOrganizationUser = async (organization) => {
		await startEditSession(setOrganizationUserEditSession, {}, {
			async onComplete(item) {
				setLoadingOverlayText('Adding User...')
				const res = await api.postOrganizationUser(organization.id, item)
				if (!res.ok) {
					setLoadingOverlayText('')
					let msg = res.extractErrorMessage('username')
					if (msg) setOrganizationUserError(msg)

					msg = res.extractRemainingErrorsMessage()
					if (msg) await showAlertDialog('ERROR', msg)

					return false
				}
				setOrganizationUserError('')
				await fetchAndSetData()
				setLoadingOverlayText('')
				return true
			},
		})
	}

	const handleCreateTeam = async (organization) => {
		await startEditSession(setTeamEditSession, { organization_id: organization.id }, {
			async onComplete(item) {
				setLoadingOverlayText('Adding Team...')
				const res = await api.postTeam(item)
				if (!res.ok) {
					setLoadingOverlayText('')
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return false
				}
				await fetchAndSetData()
				setLoadingOverlayText('')
				return true
			},
		})
	}


	const handleDeleteFacility = async (item) => {
		if (!await showConfirmationDialog('CONFIRM', `Are you sure you'd like to delete "${item.name}"?`)) return
		setLoadingOverlayText('Deleting Facility...')
		const res = await api.deleteFacility(item.id)
		if (!res.ok) {
			setLoadingOverlayText('')
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
		setLoadingOverlayText('')
	}

	const handleDeleteOrganization = async (item) => {
		if (!await showConfirmationDialog('CONFIRM', `Are you sure you'd like to delete "${item.name}"?`)) return
		setLoadingOverlayText('Deleting Organization...')
		const res = await api.deleteOrganization(item.id)
		if (!res.ok) {
			setLoadingOverlayText('')
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
		setLoadingOverlayText('')
	}

	const handleDeleteOrganizationUser = async (organization, item) => {
		const message = item.user_id === state.user.id
			? `Are you sure you'd like to remove yourself from "${organization.name}"?`
			: `Are you sure you'd like to remove "${item.name}" from "${organization.name}"?`
		if (!await showConfirmationDialog('CONFIRM', message)) return
		setLoadingOverlayText('Removing User...')
		const res = await api.deleteOrganizationUser(organization.id, item.user_id)
		if (!res.ok) {
			setLoadingOverlayText('')
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
		setLoadingOverlayText('')
	}

	const handleDeleteTeam = async (item) => {
		if (!await showConfirmationDialog('CONFIRM', `Are you sure you'd like to delete "${item.name}"?`)) return
		setLoadingOverlayText('Deleting Team...')
		const res = await api.deleteTeam(item.id)
		if (!res.ok) {
			setLoadingOverlayText('')
			await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
			return
		}
		await fetchAndSetData()
		setLoadingOverlayText('')
	}


	const handleEditFacility = async (item) => {
		await startEditSession(setFacilityEditSession, item, {
			onCancel: () => showConfirmationDialog('CONFIRM', "Are you sure you'd like to discard your changes to this team?"),
			async onComplete(item) {
				setLoadingOverlayText('Updating Facility...')
				const res = await api.patchFacility(item.id, item)
				if (!res.ok) {
					setLoadingOverlayText('')
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return false
				}
				await fetchAndSetData()
				setLoadingOverlayText('')
				return true
			}
		})
	}

	const handleEditOrganization = async (item) => {
		await startEditSession(setOrganizationEditSession, item, {
			onCancel: () => showConfirmationDialog('CONFIRM', "Are you sure you'd like to discard your changes to this organization?"),
			async onComplete(item) {
				setLoadingOverlayText('Updating Organization...')
				const res = await api.patchOrganization(item.id, item)
				if (!res.ok) {
					setLoadingOverlayText('')
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return false
				}
				await fetchAndSetData()
				setLoadingOverlayText('')
				return true
			}
		})
	}

	const handleEditTeam = async (item) => {
		await startEditSession(setTeamEditSession, item, {
			onCancel: () => showConfirmationDialog('CONFIRM', "Are you sure you'd like to discard your changes to this team?"),
			async onComplete(item) {
				setLoadingOverlayText('Updating Team...')
				const res = await api.patchTeam(item.id, item)
				if (!res.ok) {
					setLoadingOverlayText('')
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return false
				}
				await fetchAndSetData()
				setLoadingOverlayText('')
				return true
			}
		})
	}

	const handleImportUser = () => {
		setImportUserDialogOpen(true)
	}

	const handleManageFacility = async (item) => {
		await showManageFacilityDialog(item)
	}

	const handleManageTeam = async (item) => {
		await showManageTeamDialog(item)
	}

	useEffect(() => {
		let cancelled = false
		;(async () => {
			const res = await fetchData()
			if (cancelled) return
			setFetchedData(res)

			const res2 = await api.getBackupsAvailable()
			if (cancelled) return
			if (res.ok) setBackupsAvailable(res2.yes)
		})()
		return () => cancelled = true
		// eslint-disable-next-line
	}, [])

	const ctx = {
		organizations: {
			onDelete: handleDeleteOrganization,
			onEdit: handleEditOrganization,
		},
		users: (organization) => ({
			organization,
			onCreate: () => handleCreateOrganizationUser(organization),
			onDelete: (item) => handleDeleteOrganizationUser(organization, item),
			async onFetch() {
				setLoadingOverlayText('Loading Users...')
				const res = await api.getOrganizationUsers(organization.id)
				setLoadingOverlayText('')
				if (!res.ok) {
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return []
				}

				const ownerUserId = organization.owner_user_id
				res.organizations_users.sort((a, b) => {
					if (a.user_id === ownerUserId) return -1
					if (b.user_id === ownerUserId) return 1
					if (a.is_admin !== b.is_admin) return a.is_admin ? -1 : 1
					return a.name.localeCompare(b.name)
				})

				organization.users = res.organizations_users

				return res.organizations_users
			},
			onGetItemId: (item) => item.organization_id + '-' + item.user_id,
			onChangeRole: (organizationUser) => handleChangeOrganizationUserRole(organization, organizationUser)
		}),
		facilities: (organization) => ({
			organization,
			onCreate: () => handleCreateFacility(organization),
			onDelete: (item) => handleDeleteFacility(item),
			onEdit: handleEditFacility,
			onManage: handleManageFacility,
			async onFetch() {
				setLoadingOverlayText('Loading Facilities...')
				const res = await api.getOrganizationsFacilities(organization.id)
				setLoadingOverlayText('')
				if (!res.ok) {
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return []
				}
				res.facilities.sort((a, b) => a.name.localeCompare(b.name))
				organization.facilities = res.facilities
				return res.facilities
			},
		}),
		teams: (organization) => ({
			organization,
			onCreate: () => handleCreateTeam(organization),
			onDelete: (item) => handleDeleteTeam(item),
			onEdit: handleEditTeam,
			onManage: handleManageTeam,
			async onFetch() {
				setLoadingOverlayText('Loading Teams...')
				const res = await api.getOrganizationsTeams(organization.id)
				setLoadingOverlayText('')
				if (!res.ok) {
					await showAlertDialog('ERROR', res.extractRemainingErrorsMessage())
					return []
				}
				res.teams.sort((a, b) => a.name.localeCompare(b.name))
				organization.teams = res.teams
				return res.teams
			},
		}),
	}

	const [ getOrganization, setOrganization ] = getEditSessionGetterAndSetter(organizationEditSession, setOrganizationEditSession)
	const [ getOrganizationUser, setOrganizationUser ] = getEditSessionGetterAndSetter(organizationUserEditSession, setOrganizationUserEditSession)
	const [ getChangeOrganizationUserRole, setChangeOrganizationUserRole ] = getEditSessionGetterAndSetter(changeOrganizationUserRoleEditSession, setChangeOrganizationUserRoleEditSession)
	const [ getFacility, setFacility ] = getEditSessionGetterAndSetter(facilityEditSession, setFacilityEditSession)
	const [ getTeam, setTeam ] = getEditSessionGetterAndSetter(teamEditSession, setTeamEditSession)

	return <Page className={classes.root} alertDialogOptions={alertDialogOptions} confirmationDialogOptions={confirmationDialogOptions} loadingText={loadingOverlayText}>
		<ImportUserDialog open={importUserDialogOpen} onClose={() => setImportUserDialogOpen(false)}  />
		<ManageFacilityDialog options={manageFacilityDialogOptions} />
		<ManageTeamDialog options={manageTeamDialogOptions} />
		<EditDialog title='Change Role' editSession={changeOrganizationUserRoleEditSession}>
			<Select native style={{ width: '100%' }}
				defaultValue={getChangeOrganizationUserRole('role')}
				onChange={setChangeOrganizationUserRole('role')}>
				{changeOrganizationUserRoleEditSession?.data?.options.map(({ id, text }) => {
					return <option key={id} value={id}>{text}</option>
				})}
			</Select>
		</EditDialog>
		<OrganizationDialog editSession={organizationEditSession} setEditSession={setOrganizationEditSession} />
		<EditDialog entityName='Facility' editSession={facilityEditSession}>
			<TextField label='Name' fullWidth defaultValue={getFacility('name', '')} onChange={setFacility('name')} autoFocus />
		</EditDialog>
		<EditDialog entityName='Team' editSession={teamEditSession}>
			<TextField label='Name' fullWidth defaultValue={getTeam('name', '')} onChange={setTeam('name')} autoFocus />
		</EditDialog>
		<EditDialog title='Add User' editSession={organizationUserEditSession}>
			<StandardTextField label='Username' fullWidth error={organizationUserError}
				defaultValue={getOrganizationUser('username', '')} onChange={setOrganizationUser('username')} autoFocus />
		</EditDialog>
		<Container>
			<Header>
				<Typography variant='inherit'>Organizations</Typography>
				{fetchedData && fetchedData.organizations.some(i => i.facilities_enabled) &&
					<Button color='primary' variant='contained' style={{ marginRight: theme.spacing(2) }} onClick={() => history.push('/organizations/members')}>Manage Members</Button>
				}
				{ backupsAvailable ?
					<Button color='primary' variant='contained' style={{ marginRight: theme.spacing(2) }} onClick={handleImportUser}>Import User</Button>
				: undefined }
				{ state.user.is_admin ? <Tooltip title='View All Organizations'>
					<IconButton onClick={() => history.push('/admin/organizations')}><ListIcon /></IconButton>
				</Tooltip> : undefined }
				<Tooltip title='Create Organization'>
					<IconButton onClick={handleCreateOrganization}><AddIcon /></IconButton>
				</Tooltip>
			</Header>
			{ fetchedData ? fetchedData.organizations.map(organization =>
				<Organization forceRender={forceRender} ctx={ctx} key={organization.name} organization={organization} />)
			: undefined }
		</Container>
	</Page>
}
